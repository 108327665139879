import React, { Component } from 'react';
import { Digital } from "react-activity";
import axios from "axios";
import ApiURL from '../../api/ApiURL';
import cogoToast from "cogo-toast";
import validation from '../../validation/validation';
import "react-activity/dist/library.css";
class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "Submit",
            showLoader: false,
            mobile: ''
        }
    }
    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();
        var { mobile } = this.state;

        if (mobile.length === 0) {
            cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
        }
        else if (mobile.length > 0 && !(validation.MobileRegx).test(mobile)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
        }
        else {

            this.setState({ btn: "Processing..", showLoader: true });
            try {
                let _ref = document.referrer;
                let _url = window.location.href;

                var referrer = document.referrer;
                console.log("referrer url", referrer);
                console.log(_url);

                var formData = {

                    FirstName : "Anonymous",
                    Mobile: mobile,
                    URL: _url,
                    Referrer: _ref,

                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                cogoToast.success("Form submitted successfully.", { position: 'bottom-center' });
                this.setState({ btn: "Submit", showLoader: false })
                // Reset the form fields after submission
                this.setState({
                    mobile: '',
                    btn: "Submit",
                    showLoader: false
                });
            }
            catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Failed to submit form. Please try again later.", { position: 'bottom-center' });
            }
        }
    }

    onResetData = () => {
        this.setState({
            ...this.state,

            mobile: '',
        })
    }
    render() {
        return (
            <>
                <section className="section-parallax">
                    <div className="parallax-img d-flex align-items-center">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-7 text-center heading-section heading-section-white ftco-animate fadeInUp">
                                    <h2>How Can i help you?</h2>
                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in</p>
                                    <div className="row d-flex justify-content-center mt-5">
                                        <div className="col-md-12">
                                            <div className="subscribe-form">
                                                <div className="form-group d-flex">
                                                    
                                                    <div className='col-md-8'>
                                                    <input type="text" className="form-control" name="mobile"
                                                        placeholder="10 Digit Mobile No.*"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                        </div>
                                                        <div className='col-md-4'>
                                                    {/* <input type="submit" className="submit px-3" value="Get Quote" /> */}
                                                    <button type="button" className="btn btn-danger" onClick={this.onRequestCallbackSubmit}>Get Quote</button>
                                                    </div>
                                                </div>
                                                <div className='col-sm-3'>
                                                    {
                                                        (this.state.showLoader) &&
                                                        <div className='pull-right'><Digital size={25} /></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Newsletter;