import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_7N_8D_bagdogra_group';

class Bagdogra_7N_8D_Group_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/Bhutan-Tour Package-from-Bagdogra-Airport.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/Bhutan-Packages-from-Bagdogra-Airport.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-tour-package-from-bagdogra-via-phuentsholing.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-tour-package-from-bagdogra-and-transfer-to-thimphu.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tour-booking-from-bagdogra-airport-explore-buddha-dordenma.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/with-bhutan-trip-from-bagdogra-explore-dochula-pass.webp";
        // const navDay5ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/with-bhutan-tour-from-bagdogra-trek-taktshang-monastery.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/Bhutan-Package-Tour-Itinerary-from-Bagdogra.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/.webp";
        const TopbannerImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/Bhutan-Holidays-Package-from-Bagdogra-Airport.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-from-guwahati-8.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-llp-9.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tour-from-guwahati-with-adorable-vacation-8.webp";
        const sightseen4ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tours-from-guwahati-airport-with-adorable-vacation-10.webp";
        const sightseen5ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-tour-booking-from-guwahati-7.webp";
        const sightseen6ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-trip-tour-from-guwahati-with-adorable-vacation-9.webp";
        const sightseen7ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-trip-from-guwahati-airport-with-adorable-vacation-4.webp";
        const sightseen8ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-tour-itinerary-from-guwahati-airport-with-adorable-vacation-5.webp";
        const sightseen9ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-from-guwahati-airport-with-adorable-vacation-3.webp";
        const sightseen10ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-tour-cost-from-guwahati-airport-with-adorable-vacation-6.webp";
        const sightseen11ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-tour-from-guwahati-airport-with-adorable-vacation-2.webp";
        const sightseen12ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-1.webp";
        const sightseen14ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/BHUTAN-LUXURY-ACCOMMODATION.webp";
        const sightseen15ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-trip-from-guwahati-with-direct-flight-in-winter.webp";
        const flight1ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bagdogra-airport-1.webp";
        const flight2ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bagdogra-airport-2.webp";
        const flight3ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bagdogra-airport-3.webp";
        const Award1ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/International-Wellness-Destination-for-Paro-Travel-Leisure-Indias-Best-Awards-2024.webp";
        const Award2ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/Runner-Up-for-Favourite-Overseas-Destination-Condé-Nast-Traveller-Readers-Travel-Awards-2024.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/call-button-bablu-adorable-vacation.webp";
        const Review1ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tour-from-bagdogra-with-flight-review-1.webp";
        const Review2ImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/bhutan-package-tour-from-bagdogra-with-flight-review-2.webp";

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageFromBagdograGroupImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="Bhutan group package tour from Bagdogra Airport" />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Bhutan Group Tour Packages from Bagdogra Airport</strong> - BEST DEAL 2025</center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center>Book <strong>Bhutan Group Tour Packages from Bagdogra Airport</strong> - BEST DEAL 2025</center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <a href='tel:98833-59713'><img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan group tour packages from Bagdogra" /></a>

                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                4 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Phuentsholing
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <br />
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan group tour packages from Bagdogra Airport</strong>, you have come to the Right Place! Adorable Vacation is the best DMC for <strong>Bhutan group tour packages from Bagdogra Airport via Phuentsholing</strong>. Get the Best Price for Summer Special <strong>Bhutan group tour packages from Bagdogra</strong> with Adorable Vacation - The Best DMC for <a href="https://adorablevacation.com/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc/" target="_blank"><strong>Bhutan B2B Package Tour</strong></a> & <strong>Bhutan group tour from Bagdogra Airport</strong>.
                                        </p>
                                        <hr />
                                    </div>
                                    <br />
                                    <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong>?<br /><br />
                                        FOR BOOKING <strong>BAGDOGRA AIRPORT TO BHUTAN GROUP TOUR PACKAGES</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong>?<br /><br />
                                        FOR BOOKING <strong>BAGDOGRA TO BHUTAN PACKAGE</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h2>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> ADORABLE VACATION: TOURISM COUNCIL OF BHUTAN [TCB] APPROVED MOST TRUSTED BRAND FOR <strong>BHUTAN GRUP TOUR FROM BAGDOGRA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}> ADORABLE VACATION: TOURISM COUNCIL OF BHUTAN [TCB] APPROVED MOST TRUSTED BRAND FOR <strong>BHUTAN GRUP TOUR FROM BAGDOGRA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Bhutan, the last Himalayan kingdom, invites travelers to experience its unparalleled charm and serenity. Known as the only carbon-negative country in the world, Bhutan is a haven for nature enthusiasts and peace seekers. A <strong>Bhutan group package tour from Bagdogra Airport</strong> offers a seamless gateway to this pristine destination, where fresh air, zero pollution, and breathtaking landscapes await. The country's exceptional Air Quality Index (AQI) ensures every breath you take is rejuvenating, making it an ideal destination for relaxation and detoxification. <br /><br />

                                        What sets Bhutan apart is its ability to preserve its heritage and natural beauty. With 70% of its land covered by dense forests, the country is a green paradise with untouched valleys and serene mountain ranges. The population is small, and the lifestyle is simple, leading to minimal traffic and a peaceful environment. This tranquility is further enhanced by Bhutan's cold, refreshing climate, making it a perfect getaway for yoga, meditation, and mindfulness retreats. <br /><br />

                                        Cultural exploration is a cornerstone of any <a href='https://myadorablevacation.com/bhutan-tour-package-from-bagdogra/' target='_blank'><strong>Bhutan Package tour from Bagdogra</strong></a>. The country boasts age-old traditions and preserved architecture that can be seen in its beautiful Dzongs, monasteries, and stupas. From the awe-inspiring Tiger's Nest Monastery in Paro to the grandeur of Punakha Dzong, each site tells a story of Bhutan's spiritual and historical journey. Traveling as a group allows you to share these memorable moments, creating bonds through shared experiences of Bhutan's rich culture and traditions. <br /><br />

                                        A <strong>Bhutan group tour package cost from Bagdogra</strong> is designed to be affordable yet inclusive, offering a blend of comfort and adventure. Transfers from Bagdogra Airport through the scenic route of Phuentsholing provide an excellent introduction to Bhutan's natural splendor. Whether you're traveling with friends, family, or colleagues, the journey is tailored to provide the perfect balance of exploration and relaxation. Pristine valleys, vibrant festivals, and warm hospitality make Bhutan an unforgettable destination for group travelers. <br /><br />

                                        Embark on a <strong>Bhutan group tour</strong> and immerse yourself in a land where time slows down, and nature thrives in its purest form. Whether you seek adventure, cultural discovery, or spiritual renewal, Bhutan has something to offer everyone. Start your journey from Bagdogra Airport and step into a world of happiness and harmony. <br /><br /></p>
                                    <p>Apart from <strong>Bhutan Group Tour Package</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bangalore" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, <a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b className="tomato">Bhutan B2B Package Tour </b></a> etc at Best Price.</p>
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen1ImageURL} alt="BAGDOGRA AIRPORT TO BHUTAN GROUP TOUR PACKAGES" />
                                                    <img src={sightseen3ImageURL} alt="Bhutan Group Tour Package" />
                                                    <img src={sightseen2ImageURL} alt="BHUTAN GROUP TOUR FROM BAGDOGRA AIRPORT" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="BHUTAN GROUP PACKAGE TOUR FROM BAGDOGRA" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DURATION FOR <strong>BHUTAN GROUP TOUR FROM BAGDOGRA AIRPORT</strong>: 7N | 8D</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> DURATION FOR <strong>BHUTAN GROUP TOUR FROM BAGDOGRA AIRPORT</strong>: 7N | 8D</h4>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN <strong>BAGDOGRA AIRPORT TO BHUTAN GROUP TOUR PACKAGES</strong>: PHUENTSHOLING 2N | THIMPHU 2N | PUNAKHA 1N | PARO 2N
                                    </h4><br />
                                    <h4>PICKUP & DROP POINT FOR <strong>BAGDOGRA TO BHUTAN PACKAGE TOUR</strong> : BAGDOGRA INTERNATIONAL AIRPORT [IXB] </h4>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE SUMMERY FOR <strong>BHUTAN GROUP TOUR PACKAGES FROM BAGDOGRA AIRPORT VIA PHUENTSHOLING</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE SUMMERY FOR <strong>BHUTAN GROUP TOUR PACKAGES FROM BAGDOGRA AIRPORT VIA PHUENTSHOLING</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                            <span>
                                                                WELCOME AT BAGDOGRA AIRPORT, TRANSFER TO PHUENTSHOLING
                                                            </span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                            <span>PHUENTSHOLING TO THIMPHU</span>
                                                        </a>

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                            <span>THIMPHU FULL DAY SIGHTSEEING</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                            <span>THIMPU TO PUNAKHA DAY EXCURSION VIA DO-CHULA PASS</span>
                                                        </a>

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                            <span>PUNAKHA SIGHTSEEING & TRANSFER TO PARO</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                            <span>PARO TAKTSANG MONASTERY HIKING</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                            <span>CHELE-LA PASS EXCURSION & TRANSFER TO PHUENTSHOLING</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingEight"
                                                    >
                                                        <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                            <span>PHUENTSHOLING TO BAGDOGRA AIRPORT DROP</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS: <strong>BHUTAN GROUP PACKAGE TOUR FROM BAGDOGRA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS: <strong>BHUTAN GROUP PACKAGE TOUR FROM BAGDOGRA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Pick up & Drop at Bagdogra Airport
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            3* Hotel Accommodation [TCB Approves]
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Both Way transfers
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            All Sightseeing (SIC BASIS)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Online E-Permit assistance.
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Unlimited Breakfast, Lunch, Dinner in Buffet
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Sustainable Development Fees (SDF) Rs. 1,200/Adult/Night
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            1 Day *Special Lunch LM Paro in 5 Star Property
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            One bottle of Mineral Water Per Head Per Day
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            Professional English-Speaking Guide
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            24/7 Guest Support during trip from Bhutan & India Office
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="BHUTAN GROUP TOUR FROM BAGDOGRA" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={Review1ImageURL} alt="Bhutan group package tour from Bagdogra Airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> INFORMATION NEED TO KNOW <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> INFORMATION NEED TO KNOW <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead class="cf"><tr class="table-danger"><th>CITY</th><th>ALTITUDE</th><th>CO-ORDINATES</th></tr></thead>
                                            <tbody>
                                                <tr><td>Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                                                <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                                                <tr><td>Paro</td><td>2,200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                                                <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>
                                                <tr><td>Chele La Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                                                <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                                                <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong> : DISCOVER A GLOBAL AWARD-WINNING DESTINATION?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong> : DISCOVER A GLOBAL AWARD-WINNING DESTINATION?</h4>
                                </div>
                                <div className="card-body">
                                    <p>As the 2025 travel season approaches, there's no better time to explore Bhutan, a serene Himalayan kingdom now celebrated worldwide for its wellness offerings and cultural heritage. On December 16, 2024, Bhutan received two prestigious accolades: <b>International Wellness Destination for Paro</b> at the Travel + Leisure India's Best Awards 2024 and <b>Runner-Up in the Favourite Overseas Destination</b> category at the Condé Nast Traveller Readers' Travel Awards. These honors highlight Bhutan's growing reputation as a top destination for sustainable and rejuvenating travel. <br /><br />
                                        <table className='img-center'>
                                            <tbody>
                                                <tr>
                                                    <td className="image-cell">
                                                        <a href='tel:9883359713'><img src={Award2ImageURL} alt="BHUTAN GROUP TOUR FROM BAGDOGRA" /></a>
                                                    </td>
                                                    <td className="image-cell">
                                                        <a href='tel:9883359713'><img src={Award1ImageURL} alt="Bhutan Group Tour Cost" /></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />  <br />

                                        Starting your journey with a <strong>Bhutan group package tour from Bagdogra Airport</strong> is both convenient and economical. Bagdogra, with its excellent connectivity to major Indian cities like Mumbai, Delhi, and Chennai serves as the perfect gateway to Bhutan. From here, travelers can enjoy a seamless transition to Bhutan's tranquil landscapes, making the <strong>Bagdogra Airport to Bhutan group tour</strong> an effortless escape. <br /><br />

                                        Once in Bhutan, immerse yourself in its unspoiled beauty, where lush valleys, ancient monasteries, and iconic dzongs await. From the majestic Punakha Dzong to the breathtaking Tiger's Nest Monastery, Bhutan offers experiences that are both visually stunning and spiritually enriching. Choosing <strong>Bhutan group travel packages from Bagdogra</strong> ensures affordability and ease, making it an ideal option for exploring this award-winning destination.<br /><br />

                                        Book your <strong>Bhutan group tour</strong> with Adorable Vacation today and experience why the world is falling in love with Bhutan. Whether you're seeking wellness, adventure, or cultural discovery, Bhutan promises a journey that will stay with you forever. </p>
                                        <br /><br /><br />
                                    {/* <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    {/* <br /> */}
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BAGDOGRA AIRPORT: THE PERFECT GATEWAY FOR YOUR <strong>BHUTAN GROUP TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BAGDOGRA AIRPORT: THE PERFECT GATEWAY FOR YOUR <strong>BHUTAN GROUP TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Bagdogra Airport, officially known as the Civil Aerodrome Bagdogra, has emerged as the top choice for travelers embarking on a <strong>Bhutan tour from India</strong>. Located in the scenic town of Bagdogra, this bustling airport is strategically positioned just 160 km from Phuentsholing, the key entry point to Bhutan. Its proximity to Bhutan and excellent connectivity make it the ideal starting point for a <strong>Bhutan group package tour from Bagdogra Airport</strong>. <br /><br />

                                        Originally serving as an Indian Air Force base, Bagdogra Airport has undergone remarkable transformations to become a full-service airport catering to both domestic and international flights. The introduction of international routes has further elevated its status, enabling seamless travel for tourists from major Indian cities like Kolkata, Mumbai, Bangalore, Chennai, and Delhi. For travelers planning a <strong>Bagdogra Airport to Bhutan group tour</strong>, the airport offers an unparalleled blend of convenience and accessibility. <br /><br />

                                        One of the biggest advantages of choosing <a href="https://adorablevacation.com/bhutan-tour-package-from-bagdogra/" target="_blank"><strong>Bhutan package tour from Bagdogra Airport</strong></a> is its extensive flight network. Daily flights ensure hassle-free connections to this gateway, allowing you to minimize travel time and focus on enjoying your <strong>Bhutan vacation</strong>. Whether you're traveling with family, friends, or a larger group, the accessibility of Bagdogra simplifies your travel plans while offering affordable options. <br /><br />

                                        The journey from Bagdogra to Bhutan is nothing short of breathtaking. As you make your way to Phuentsholing, you're treated to scenic views of rolling hills, lush greenery, and tranquil landscapes. This route sets the tone for your <strong>Bhutan group travel packages from Bagdogra</strong>, offering an unforgettable prelude to the serene beauty that awaits you in Bhutan. <br /><br />

                                        Bagdogra Airport is not just a transit point - it's a gateway to convenience, affordability, and memorable experiences. Its strategic location, growing infrastructure, and easy access to Bhutan's border make it the best choice for anyone planning a <strong>Bhutan group tour</strong>. Start your Bhutan journey with ease and explore the Land of Happiness like never before. <br /><br /></p>
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={flight1ImageURL} alt="Bagdogra Airport to Bhutan group tour" />
                                                    <img src={flight2ImageURL} alt="BHUTAN GROUP TOUR PACKAGES FROM BAGDOGRA AIRPORT" />
                                                    <img src={flight3ImageURL} alt="BHUTAN GROUP TOUR COST FROM BAGDOGRA AIRPORT" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>SOME KEY FACTS ABOUT BAGDOGRA AIRPORT</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>SOME KEY FACTS ABOUT BAGDOGRA AIRPORT</h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody><tr><td>Airport Name</td><td>Customs Airport, Bagdogra (Civil Enclave)</td></tr>
                                                <tr><td>Address</td><td>Civil Aerodrome, Bagdogra, Siliguri - 734421 (WB)</td></tr>
                                                <tr><td>IATA Code</td><td>IXB</td></tr>
                                                <tr><td>Elevation</td><td>126 m</td></tr>
                                                <tr><td>Hub for</td><td>Indigo, Air India, GoAir, Jet Airways, Go First, Akasha Air</td></tr>
                                                <tr><td>Country</td><td>India</td></tr>
                                                <tr><td>Coordinates</td><td>Latitude: 26.6811°N | Longitude: 88.3283°E</td></tr>
                                                <tr><td>Phone</td><td>0353 269 8431</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>FLIGHTS TO BOOK FROM DIFFERENT CITIES FOR <strong>BHUTAN GROUP TOUR PACKAGES FROM BAGDOGRA AIRPORT</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>FLIGHTS TO BOOK FROM DIFFERENT CITIES FOR <strong>BHUTAN GROUP TOUR PACKAGES FROM BAGDOGRA AIRPORT</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr>
                                                <td><b>Airlines</b></td>
                                                <td><b>Origin</b></td>
                                                <td><b>Departure</b></td>
                                                <td><b>Destination</b></td>
                                                <td><b>Arrival</b></td>
                                                <td><b>Duration</b></td>
                                            </tr></thead>
                                            <tbody><tr><td>SpiceJet</td><td>Bangalore [BLR]</td><td>06:10</td><td>Bagdogra [IXB]</td><td>09:00</td><td>02 h 50 m</td></tr>
                                                <tr><td>Air Asia</td><td>Bangalore [BLR]</td><td>08:20</td><td>Bagdogra [IXB]</td><td>11:15</td><td>02 h 55 m</td></tr>
                                                <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>06:20</td><td>Bagdogra [IXB]</td><td>09:20</td><td>03 h 00 m</td></tr>
                                                <tr><td>SpiceJet</td><td>New Delhi [DEL]</td><td>05:45</td><td>Bagdogra [IXB]</td><td>08:00</td><td>02 h 15 m</td></tr>
                                                <tr><td>Air Asia</td><td>New Delhi [DEL]</td><td>09:25</td><td>Bagdogra [IXB]</td><td>11:30</td><td>02 h 05 m</td></tr>
                                                <tr><td>IndiGo</td><td>New Delhi [DEL]</td><td>07:30</td><td>Bagdogra [IXB]</td><td>09:35</td><td>02 h 05 m</td></tr>
                                                <tr><td>Air Asia</td><td>Kolkata [CCU]</td><td>07:15</td><td>Bagdogra [IXB]</td><td>08:20</td><td>01 h 05 m</td></tr>
                                                <tr><td>Air Asia</td><td>Kolkata [CCU]</td><td>08:45</td><td>Bagdogra [IXB]</td><td>09:55</td><td>01 h 10 m</td></tr>
                                                <tr><td>IndiGo</td><td>Kolkata [CCU]</td><td>10:05</td><td>Bagdogra [IXB]</td><td>11:05</td><td>01 h 00 m</td></tr>
                                                <tr><td>IndiGo</td><td>Mumbai [BOM]</td><td>08:10</td><td>Bagdogra [IXB]</td><td>10:55</td><td>02 h 45 m</td></tr>
                                                <tr><td>SpiceJet</td><td>Mumbai [BOM]</td><td>08:25</td><td>Bagdogra [IXB]</td><td>11:10</td><td>02 h 45 m</td></tr>
                                                <tr><td>IndiGo</td><td>Hyderabad [HYD]</td><td>10:15</td><td>Bagdogra [IXB]</td><td>12:40</td><td>02 h 25 m</td></tr>
                                                <tr><td>IndiGo</td><td>Chennai [MAA]</td><td>11:45</td><td>Bagdogra [IXB]</td><td>14:30</td><td>02 h 45 m</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={Review2ImageURL} alt="BHUTAN GROUP PACKAGE TOUR BOOKING FROM BAGDOGRA AIRPORT" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY ADORABLE VACATION IS THE BEST DMC FOR <strong>BHUTAN GROUP PACKAGE TOUR BOOKING FROM BAGDOGRA AIRPORT</strong>?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY ADORABLE VACATION IS THE BEST DMC FOR <strong>BHUTAN GROUP PACKAGE TOUR BOOKING FROM BAGDOGRA AIRPORT</strong>?</h3>
                                </div>
                                <div className="card-body">

                                    <li><b>Trusted Brand :</b> Adorable Vacation has earned the reputation as the most reliable and trusted brand for <strong>Bhutan group tour operator from Bagdogra</strong>, providing exceptional travel experiences and customer satisfaction. Our long-standing presence in the travel industry ensures that you receive only the best services available.<br /><br /></li>

                                    <li><b>Passenger Service Agent [PSA] :</b> We take pride in being the official Passenger Service Agent for <b>Bhutan Airlines</b> and <b>Druk Airlines</b>, offering our clients seamless and hassle-free travel arrangements with some of the best airlines serving Bhutan.<br /><br /></li>

                                    <li><b>Luxury Packages :</b> Indulge in the finest travel experiences with our <a href="https://adorablevacation.com/bhutan-hotel-six-senses-package-tour-with-direct-flight-from-india/" target="_blank"><strong>Bhutan Package Tour with Hotel Six Senses</strong></a>, which offers world-class accommodations at renowned luxury properties such as Amankora, COMO Uma, Le Méridien, and Pemako. These luxury experiences ensure your comfort, relaxation, and memorable stay in Bhutan.<br /><br /></li>

                                    <li><b>Super Peak Season Inventory :</b> We have secured exclusive pre-purchased hotel room inventory for the peak season, allowing us to offer guaranteed stays during the most sought-after times for travel to Bhutan. This also includes special <a href="https://adorablevacation.com/mumbai-special-chartered-flight/" target="_blank"><strong>Bhutan Package Tour from Mumbai with Chartered Flight</strong></a>, ensuring seamless travel even during the busiest seasons.<br /><br /></li>

                                    <li><b>Authorized Travel House :</b> Adorable Vacation is an officially authorized travel agency recognized by the [TCB] Tourism Council of Bhutan, <b>Holding License No: 1053228</b>, ensuring that our services meet all legal requirements and standards set by the Bhutanese authorities.<br /><br /></li>

                                    <li><b>Direct Employment :</b> Our team in Bhutan is directly employed by us, ensuring a high level of professionalism and quality control in all the services provided during your stay. This guarantees that our staff is fully invested in delivering the best possible experience.<br /><br /></li>

                                    <li><b>Customized Itineraries :</b> We specialize in offering personalized and flexible <strong>Bhutan group tour itineraries from Bagdogra Airport</strong> that cater to your unique preferences. Our expert team designs exclusive programs, ensuring that each journey reflects your individual travel interests.<br /><br /></li>

                                    <li><b>In-Depth Knowledge :</b> Our experienced team boasts in-depth knowledge of Bhutan's diverse geography, culture, hotels, and local laws, allowing us to offer informed guidance, making your trip not only enjoyable but also insightful and well-organized.<br /><br /></li>

                                    <li><b>Assured Services : </b>At Adorable Vacation, we guarantee premium services that elevate your Bhutan experience, ensuring that every aspect of your trip is handled with the utmost care, precision, and attention to detail.<br /><br /></li>

                                    <li><b>E-Permit Processing :</b> We simplify your Bhutan travel by handling the e-permit process well in advance, ensuring that all your sightseeing and travel permits are arranged, so you can enjoy a smooth and uninterrupted trip across Bhutan.<br /><br /></li>

                                    <li><b>Personalized Attention :</b> Throughout your stay in Bhutan, you will receive personalized, attentive care from our dedicated staff, ensuring that your journey is safe, secure, and customized to your needs.<br /><br /></li>

                                    <li><b>Complete Responsibility :</b> From the moment you book your <strong>Bhutan tour from Bagdogra</strong>, Adorable Vacation takes complete responsibility for every aspect of your <strong>Bhutan group tour from Bagdogra Airport</strong>, ensuring that every detail is handled efficiently and with care.<br /><br /></li>

                                    <li><b>Hassle-Free Experience :</b> With Adorable Vacation, you are assured of a hassle-free and trustworthy experience in Bhutan, where every logistical detail is taken care of, and you can enjoy your trip with peace of mind.<br /><br /></li>

                                    <li><b>Best Deals :</b> We offer you the best deals and special discounts for top hotels and services in Bhutan, tailored to your preferences, ensuring that you receive excellent value for your money while enjoying the best that Bhutan has to offer. The quality of foods Adorable vacation offers during <strong>Bagdogra to Bhutan Trip in Group</strong> is really awesome and lip smacking. You can enjoy Veg, Non-Veg as well as Jain food also so, that guests can feel homely and comfortable during <strong>Bhutan group package tour booking from Bagdogra Airport</strong>.<br /><br /></li>

                                    <li><b>Flexible Travel Options :</b> Whether you prefer a customized <strong>Bhutan private trip</strong> or want to explore as part of a <strong>Bhutan group tour</strong>, Adorable Vacation provides flexible options to suit your travel style and preferences, making it easy for you to choose the perfect way to explore Bhutan.<br /><br /></li>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BAGDOGRA AIRPORT TO BHUTAN GROUP TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BAGDOGRA AIRPORT TO BHUTAN GROUP TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th><b>PLACES</b></th>
                                                    <th><b>CITY</b></th>
                                                    <th><b>FEES</b></th>
                                                    <th><b>REMARKS</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td>
                                                </tr>
                                                <tr>
                                                    <td>Tashichhoe Dzong (Fort)</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 500</td>
                                                    <td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Buddha Dordenma Statue</td>
                                                    <td>Thimphu</td>
                                                    <td>No Entry Fee</td>
                                                    <td>Time-9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Thimphu Memorial Chorten</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 500</td>
                                                    <td>Anytime</td>
                                                </tr>
                                                <tr>
                                                    <td>Changangkha Lhakhang (Temple)</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 500</td>
                                                    <td>Anytime between 8am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Takin Preservation Centre</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 500</td>
                                                    <td>9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Simtokha Dzong (Fort)</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 500</td>
                                                    <td>9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>National Library</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 100</td>
                                                    <td>9am to 5pm on Mon to Fri</td>
                                                </tr>
                                                <tr>
                                                    <td>The Royal Textile Museum</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 250</td>
                                                    <td>Mon to Sat from 9am to 4pm</td>
                                                </tr>
                                                <tr>
                                                    <td>National Institute for Zorig Chusum (Painting School)</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 200</td>
                                                    <td>Mon to Fri 10am to 3:30pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Folk Heritage Museum</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 200</td>
                                                    <td>Open from 9am to 4pm on Mon to Sun</td>
                                                </tr>
                                                <tr>
                                                    <td>Simply Bhutan Museum</td>
                                                    <td>Thimphu</td>
                                                    <td>NU 1000</td>
                                                    <td>Open from 9am to 4pm on Mon to Sun</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td>
                                                </tr>
                                                <tr>
                                                    <td>Punakha Dzong (Fort)</td>
                                                    <td>Punakha</td>
                                                    <td>NU 500</td>
                                                    <td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Chimmi Lhakhang (Temple)</td>
                                                    <td>Punakha</td>
                                                    <td>NU 500</td>
                                                    <td>9am to 1pm and 2pm to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Khamsum Yueely Namgyal Chorten</td>
                                                    <td>Punakha</td>
                                                    <td>NU 100</td>
                                                    <td>9am to 1pm and 2pm to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td>
                                                </tr>
                                                <tr>
                                                    <td>Kyichu Lhakhang (temple)</td>
                                                    <td>Paro</td>
                                                    <td>NU 500</td>
                                                    <td>9am to 12pm & 1pm to 4pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Rinpung Dzong (Fort)</td>
                                                    <td>Paro</td>
                                                    <td>No Entry Fee</td>
                                                    <td>9am to 5pm (Summer) & 9am to 4pm (Winter)</td>
                                                </tr>
                                                <tr>
                                                    <td>Dobji Dzong</td>
                                                    <td>Paro</td>
                                                    <td>No Entry Fee</td>
                                                    <td>9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>National Museum of Bhutan</td>
                                                    <td>Paro</td>
                                                    <td>NU 300</td>
                                                    <td>Opens from 9am to 4pm throughout the week except on govt & local holidays</td>
                                                </tr>
                                                <tr>
                                                    <td>Taktshang (Tiger Nest Monastery)</td>
                                                    <td>Paro</td>
                                                    <td>NU 1000</td>
                                                    <td>9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">PHUENTSHOLING SIGHTSEEING PLACES AND THE ENTRY FEES</td>
                                                </tr>
                                                <tr>
                                                    <td>Rinchending Goenpa (temple)</td>
                                                    <td>Trongsa</td>
                                                    <td>No Entry Fee</td>
                                                    <td>9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td>Palden Tashi Choling Shedra</td>
                                                    <td>Trongsa</td>
                                                    <td>No Entry Fee</td>
                                                    <td>9am to 5pm</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">
                                                        NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST TIME TO BOOK <strong>BHUTAN TOUR PACKAGE FROM BAGDOGRA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST TIME TO BOOK <strong>BHUTAN TOUR PACKAGE FROM BAGDOGRA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Choosing the best season for a <strong>Bhutan group tour package from Bagdogra</strong> is essential for ensuring an unforgettable experience. Bhutan's unique climate offers something special in every season, but summer, autumn, and winter stand out for their distinct weather conditions and travel opportunities. <br /><br /></p>

                                    <p><b>Summer</b>, from June to August, is marked by lush greenery and blooming wildflowers, thanks to the monsoon rains. While lower altitudes experience occasional rainfall, towns like Thimphu and Paro remain relatively cooler, with temperatures ranging from 15°C to 25°C. Although mountain visibility can be limited during this time, the vibrant landscapes make it ideal for nature lovers and trekkers exploring higher altitudes. Additionally, summer sees fewer tourists, making it a great time to secure budget-friendly <strong>Bhutan group travel packages from Bagdogra</strong> without the crowds. <br /><br /></p>

                                    <p><b>Autumn</b>, from September to November, is the most popular season for a <strong>Bhutan group tour from Bagdogra</strong>. This period boasts clear skies, pleasant temperatures between 10°C and 20°C, and stunning views of the Himalayan ranges. The cultural richness of Bhutan shines in autumn, with festivals like <b>Thimphu Tsechu</b> adding a festive charm to your <strong>Bhutan trip</strong>. The excellent visibility and crisp air make it perfect for trekking, photography, and cultural sightseeing, ensuring an enriching and visually rewarding journey. <br /><br /></p>

                                    <p><b>Winter</b>, from December to February, transforms Bhutan into a serene, snow-kissed paradise. With temperatures ranging from -5°C to 15°C, the dry weather ensures crystal-clear skies and unobstructed views of the majestic snow-capped peaks. While higher altitudes experience snowfall, the lower valleys remain mild and accessible, making it an excellent season for those who enjoy cooler climates and peaceful surroundings. Winter also brings attractive discounts, making it a cost-effective time to book a <strong>Bhutan group trip package from Bagdogra</strong> while enjoying the tranquility of Bhutan's iconic monasteries and dzongs.<br /><br /></p>

                                    <p>Whether you're drawn to the vibrant greenery of summer, the cultural festivities of autumn, or the serene beauty of winter, each season offers its own charm. A <strong>Bhutan group tour package from Bagdogra</strong> ensures convenience and an unforgettable journey, no matter the season.<br /><br /><br /></p>
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>SEASON</th><th>MONTH</th><th>WEATHER</th><th>TEMPERATURE</th></tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Bhutan group tour operator from Bagdogra" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>BHUTAN GROUP TOUR PLAN FROM BAGDOGRA AIRPORT VIA PHUENTSHOLING</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Bhutan Tour Itinerary from Bagdogra Airport</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    <h4>DAY 1 : BAGDOGRA AIRPORT TO PHUENTSHOLING [DISTANCE: 170 KM | 5 HRS]</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>Your Bhutan journey begins with a smooth arrival at Bagdogra Airport. From there, embark on an exciting drive to Bhutan, heading towards the border town of Phuentsholing. On this scenic route, you'll pass through the majestic Himalayan mountains, the winding Teesta River, lush Dooars forests, and serene tea gardens. The drive from Bagdogra to Phuentsholing offers a stunning blend of natural beauty, making it an unforgettable start to your adventure. <br /><br />
                                                        Phuentsholing is a special Bhutanese town where no special permit or visa is required, allowing for easy exploration. After checking into your hotel, take some time to relax and, depending on your arrival, enjoy a leisurely evening discovering the town's charm. This is just the beginning of your Bhutan exploration. Overnight stay in Phuentsholing. <br /><br /><br /></p>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Bhutan group tour package from Bagdogra" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Bhutan Group Tour Cost From Bagdogra" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    <h4>DAY 02: PHUENTSHOLING TO THIMPHU TRANSFER</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a tasty breakfast, start your journey to Thimphu, the capital of Bhutan, as part of your <strong>Bhutan group tour package from Bagdogra</strong>. Along the way, you'll pass through the charming town of Gedu, which sits around 9,000 feet above sea level, offering beautiful views of the surrounding mountains. You’ll also pass the Chukha Dam, a major hydroelectric project, before reaching Chojum, a scenic spot where the Thimphu Chu and Paro Chu rivers meet. It's a great place to stop and take photos of the stunning scenery. <br /><br />

                                                        The drive itself is as beautiful as the destination, with amazing views all along the way. By evening, you'll arrive in Thimphu. Once there, check into your hotel in the afternoon or evening. After freshening up, take a walk around the city and enjoy the local nightlife. You can try a local beer, have some fun at a karaoke bar, or relax with a traditional hot stone bath at a spa. Finish your day with a delicious dinner and a restful night in Thimphu. <br /><br /></p>
                                                    <h4>BARS & NIGHT CLUBS TO HANGOUT NIGHTLIFE IN THIMPHU</h4><br />
                                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">

                                                            <thead className="cf"><tr><th>NAME</th><th>ADDRESS</th><th>TIMINGS</th></tr></thead>
                                                            <tbody><tr><td>Mojo Park</td><td>Chang Lam, Thimphu, Bhutan</td><td>5 PM - 12 AM</td></tr>
                                                                <tr><td>Space 34</td><td>Chang Lam, Main Town, Thimphu</td><td>9 PM - 1 AM</td></tr>
                                                                <tr><td>Om Bar</td><td>Thimphu, Bhutan</td><td>5 PM - 1 AM</td></tr>
                                                                <tr><td>Vivacity</td><td>Sheten Lam, Thimphu, Bhutan</td><td>7 PM - 12 AM</td></tr>
                                                                <tr><td>Club Ace</td><td>Thimphu, Bhutan</td><td>9 PM - 1 AM</td></tr>
                                                                <tr><td>Barz Brothers</td><td>Thimphu, Bhutan</td><td>Open 24 hours</td></tr>
                                                                <tr><td>Benez Bar</td><td>Jangchhub Lam, Thimphu, Bhutan</td><td>10 AM - 11 PM</td></tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <br /><br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Bhutan Group tour cost from Bagdogra" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Bhutan tour Itinerary from Bagdogra" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 03: THIMPHU SIGHTSEEING</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Start your day with a warm cup of <b>Suja (Butter Tea)</b> as the sun rises over the beautiful Thimphu valley. After a delicious breakfast, get ready to explore the local attractions of Thimphu. <br /><br />

                                                        <b>The National Memorial Chorten (Thimphu Chorten)</b>: This iconic monument is a place where locals gather for evening prayers. It was built in memory of Bhutan's third king, Jigme Dorji Wangchuk, and completed in 1974 after his sudden death. Visitors often walk around the chorten, spinning prayer wheels and chanting mantras. It stands as a symbol of peace and prosperity. <br /><br />

                                                        <b>The Clock Tower</b>: Located in the heart of Thimphu, Clock Tower Square showcases beautiful Bhutanese architecture. The tower features four clock faces and is adorned with intricate carvings of flowers, paintings, and dragons. The dragons face each clock and symbolize the country’s independence. The square is a popular spot for both locals and tourists, surrounded by shops, restaurants, and hotels. It’s also a lively spot, often hosting live music events. You can shop for souvenirs or relax in the nearby playground. <br /><br />

                                                        <b>Sakyamuni Buddha</b>: Atop Kuensel Phodrang hill stands the towering statue of Buddha Dordenma, a 51.5-meter gilded bronze figure offering breathtaking views of the valley. This peaceful site is also known as the Buddha Viewpoint. <br /><br />

                                                        <b>Changangkha Lhakhang</b>: One of Thimphu's oldest and most important monasteries, dating back to the 15th century. Dedicated to Avalokiteshvara, the symbol of compassion, this monastery offers a panoramic view of the Thimphu valley. <br /><br />

                                                        <b>Takin Zoo</b>: Home to Bhutan's national animal, the Takin, this zoo allows you to see these endangered creatures in a more natural, open habitat. The Takin is found only in Bhutan, making this a special experience. <br /><br />

                                                        <b>Trashi Chhoe Dzong</b>: A must-see site, known as the Fortress of the Glorious Region, located on the west bank of the Wang Chhu River. It is the coronation site of Bhutan’s fifth king and hosts the annual <strong>Tsechu Festival</strong>. Originally built in 1641, it was rebuilt in the 1960s using traditional Bhutanese construction methods, without nails or architectural plans. After visiting these stunning attractions, head back to your hotel to relax. <br /><br /><br /></p>

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Bhutan Group Tour Packages From Bagdogra" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Bhutan Trip from Bagdogra" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>Day 04 : THIMPHU TO PUNAKHA TRANSFER</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">

                                                    <p>Begin the third day of your <strong>Bhutan Trip from Bagdogra</strong> in Thimphu with a comforting cup of Hot Ngaja (sweet Bhutanese milk tea) while enjoying the stunning valley views from your room. After breakfast, set out for Punakha, Bhutan's former capital. Nestled at an altitude of 1,200 meters, Punakha offers a tranquil escape from bustling city life, with a pleasant climate-warm in winter and cool in summer. The valley is renowned for its vibrant red and white rice fields nourished by the Pho and Mo Chu rivers. En route, you'll visit: <br /><br />

                                                        <b>Dochula Pass</b>: Located at an elevation of 10,171 feet, this breathtaking mountain pass offers panoramic views of the majestic Himalayan range. It is adorned with 108 chortens (stupas) built to honor Bhutanese soldiers who sacrificed their lives during a military operation. The pass's serene atmosphere and stunning landscapes make it a must-visit on any <strong>Bhutan tour from Bagdogra</strong>. <br /><br />

                                                        <b>Punakha Dzong</b>: Known as "<b>The Palace of Great Happiness</b>", this magnificent fortress is situated at the confluence of the Pho Chhu (father) and Mo Chhu (mother) rivers. It is one of Bhutan's most picturesque dzongs and holds valuable relics from Bhutan’s monarchical history. An arched wooden bridge connects the dzong to the mainland, adding to its charm. <br /><br />

                                                        <b>Suspension Bridge</b>: This charming hidden gem spans the Punakha Valley, linking it to the old administrative center of Punakha Dzongkhag. Draped with colorful prayer flags, the bridge offers excellent birdwatching opportunities and picturesque photo spots. <br /><br />

                                                        <b>Chimi Lhakhang (Fertility Temple)</b>: Perched on a hill, this temple is dedicated to Lama Drukpa Kuenley, the 'Divine Madman' known for his unconventional teaching methods using humor and songs. Couples visit this fertility temple with the hope of being blessed with children. The temple is reached by a scenic walk through paddy fields and a short climb after crossing a stream. <br /><br />

                                                        After exploring all these attractions, return to your hotel in Punakha and unwind for the evening. <br /><br /><br /></p>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="Bhutan group package tour from Bagdogra" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="Bhutan Group Trip Cost from Bagdogra" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 05: PUNAKHA TO PARO TRANSFER & LOCAL SIGHTSEEING</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>On the morning of your Paro tour, start the day with a hearty breakfast before setting off for one of the most captivating destinations in Bhutan. Paro, located in the western part of Bhutan, is a must-visit for anyone on a <strong>Bhutan group package tour from Bagdogra</strong>. Nestled along the serene Paro Chhu or Wong Chhu River (known as Raidak downstream), this picturesque town is renowned for its breathtaking natural beauty, cultural richness, and historical significance. Whether you're a nature lover, history enthusiast, or spiritual seeker, Paro offers something for everyone. Let’s dive into the highlights of this verdant valley, often chosen for its tranquility and romantic charm. <br /><br /></p>

                                                    <p><b>National Museum of Bhutan</b> : Perched above Rinpung Dzong, the National Museum of Bhutan is an essential stop for anyone exploring Bhutan's heritage. Established in 1968 within the historic Ta Dzong building, the museum showcases Bhutan's rich culture and art. The galleries display exquisite paintings, bronze statues, and artifacts, offering an in-depth glimpse into the kingdom's vibrant history and artistic traditions. It's a must-visit for understanding the cultural tapestry of Bhutan during your <strong>Bhutan group tour</strong>. <br /><br /></p>

                                                    <p><b>Rinpung Dzong</b> : Built in 1646 by Shabdrung Ngawang Namgyal, the founder of Bhutan, Rinpung Dzong stands as a magnificent example of traditional Bhutanese architecture. This fortress-monastery serves as the monastic center for Paro and houses the administrative offices for the district. Reached via the iconic Nya Mey Zam Bridge, Rinpung Dzong offers visitors a unique blend of spirituality and architectural beauty. The intricate woodwork and traditional covered bridge make it a photographer's dream. <br /><br /></p>

                                                    <p><b>Ta Dzong</b> : Once a watchtower protecting Rinpung Dzong during 17th-century inter-valley wars, Ta Dzong was transformed into the National Museum of Bhutan in 1968. The structure itself is an architectural marvel, and its historical artifacts and exhibitions provide a deeper understanding of Bhutan's past. A stop here is essential for history buffs exploring the country as part of a <strong>Bhutan group tour package booking from Bagdogra Airport</strong>. <br /><br /></p>

                                                    <p><b>Drukgyal Dzong</b> : Located in the upper Paro Valley, Drukgyal Dzong was originally built in 1649 to commemorate Bhutan's victory over Tibetan invaders. Though now in ruins, the fortress retains its historical significance and charm. It's a great spot for history lovers and offers spectacular views of the surrounding valley, adding a touch of serenity to your journey. <br /><br /></p>

                                                    <p><b>Nya Mey Zam Bridge</b> : Known as "The Bridge with No Fish" this traditional wooden bridge connects Paro Town to the Dzong. It is said to bring ill omen if a fish is spotted in the river below. The bridge, reconstructed after being washed away by floods in 1969, is an excellent spot for photos and is an integral part of the cultural experience. <br /><br /></p>

                                                    <p><b>Paro Airport Viewpoint</b> : This is the perfect place to take in the panoramic beauty of Paro Valley. Overlooking the Paro River, the viewpoint offers an incredible perspective of the valley's natural splendor, dotted with monasteries and dzongs. Watching planes land and take off from one of the world's most challenging airports is an unforgettable experience. It's a fantastic way to capture the essence of Paro and end your sightseeing on a high note. <br /><br />

                                                        After covering these mesmerizing attractions as part of your <strong>Bhutan group tour</strong>, check in to your hotel and enjoy a relaxing evening in Paro. Let the beauty of this enchanting valley linger in your memories as you prepare for the next adventure. Overnight stay in Paro. <br /><br /></p>
                                                    <br />
                                                    {/* <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING / CHELE-LA PASS EXCURSION</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>Wake up to the serene beauty of Paro Valley as the sun bathes the surrounding mountains and rivers in a golden glow. Take a moment to appreciate the tranquil scenery before enjoying a hearty, protein-rich breakfast to fuel your body for the day ahead. Today's adventure will take you to one of Bhutan's most iconic landmarks: the legendary <b>Taktsang Monastery</b>, also known as the Tiger's Nest. <br /><br />

                                                        Your journey to Taktsang Monastery begins with a hike that ascends a steep cliff, reaching an elevation of 1,200 meters above the valley floor. The trail, though challenging, is immensely rewarding, offering unparalleled views of the verdant Paro Valley, the meandering Paro Chhu River, and the vast blue skies above. Along the way, you'll pass lush forests of pine trees adorned with fluttering prayer flags, adding a spiritual touch to your trek.<br /><br />

                                                        Taktsang Monastery, perched dramatically on the edge of a cliff, is a sacred site built in 1694. It is deeply tied to Bhutanese culture and history, as it was here that Guru Rinpoche (Padmasambhava) meditated in the 8th century after arriving on the back of a flying tigress, bringing Buddhism to Bhutan. The monastery's stunning architecture and spiritual ambiance make it a must-visit destination for travelers and pilgrims alike.<br /><br />

                                                        The hike to the monastery typically takes about three hours each way. If trekking seems daunting, you can opt for a horse ride from the base to the halfway point. Once you reach the top, the fresh mountain air and the breathtaking views of the valley below make every step worth it. The serenity and spiritual energy of this iconic site leave a lasting impression, making it a highlight of any <strong>Bhutan trip from Bagdogra</strong>. <br /><br />

                                                        After descending from the monastery, you'll return to your hotel in Paro, where you can relax and reflect on the day's incredible experiences. Enjoy a peaceful evening soaking in the tranquil atmosphere of Paro Valley. Overnight stay in Paro. <br /><br /></p>

                                                    <h3 className='tomato1'>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY </h3><br />
                                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                                <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                                <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                                <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                                                <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                                <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                                <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                                <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <br /><br /><br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 07: CHELE-LA PASS DAY EXCURSION & TRANSFER TO PUENTSHOLING</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>As we enter the 7th day of our <strong>Bhutan group package tour from Bagdogra Airport</strong>, the adventure continues with an exciting excursion to <b>Chele La Pass</b>, one of Bhutan's most remarkable destinations. Having started this journey from Bagdogra via Phuentsholing, today promises more breathtaking experiences. <br /><br />

                                                        <b>Chele La Pass</b>: standing at an altitude of 3,988 meters (13,083 feet), is the highest motorable pass in Bhutan. It serves as a gateway between the scenic Paro Valley and the tranquil Haa Valley. Known for its pristine natural beauty, Chele La is a favorite among trekkers and nature enthusiasts. As you ascend, you'll be greeted by awe-inspiring panoramic views of the Himalayan peaks, including Mount Jomolhari and other snow-capped ranges.<br /><br />

                                                        The journey to Chele La takes you through winding mountain roads lined with lush forests and colorful prayer flags fluttering in the breeze. Once at the top, the crisp mountain air and expansive vistas make it a perfect spot for photography and quiet reflection. Chele La is not only a natural marvel but also a cultural treasure, with its significance in Bhutanese traditions and spirituality.<br /><br />

                                                        If time permits, enjoy a short trek along one of the trails near the pass. These trails offer unparalleled views of the surrounding valleys and the rugged Himalayan terrain, making it a truly memorable experience. <br /><br />

                                                        Return to Phuentsholing : After exploring the wonders of Chele La Pass, it's time to begin the return journey to Phuentsholing. As you drive back, take in the last glimpses of Bhutan's enchanting landscapes. Upon arrival in Phuentsholing, check into your hotel and enjoy the lively ambiance of this bustling border town. Reflect on the incredible experiences of your Bhutan tour and savor a restful evening. Overnight stay in Phuentsholing. <br /><br /></p>
                                                    <br />
                                                
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 08: PHUENTSHOLING TO BAGDOGRA DROP</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>The final day of your unforgettable <strong>Bhutan group package tour from Bagdogra</strong> has arrived. With hearts full of joy and cherished memories, it's time to bid farewell to the Land of Thunder Dragon. Today, we'll transfer you from Phuentsholing to Bagdogra Airport, marking the end of this magical journey. <br /><br />

                                                        As you reflect on the past days, we hope you recall the breathtaking Himalayan landscapes, the serene monasteries, the vibrant Bhutanese culture, and the warm smiles of the people. From the tranquility of Paro Valley to the spiritual heights of Taktsang Monastery and the scenic wonders of Chele La Pass, every moment of this adventure has been crafted to leave an indelible mark on your soul. <br /><br />

                                                        We deeply appreciate you choosing our <strong>Bhutan group tour from Bagdogra Airport via Phuentsholing</strong> with Adorable Vacation. It has been our pleasure to guide you through this extraordinary land. As you return home, may the peace and beauty of Bhutan remain with you, a constant reminder of the joy and serenity found in this remarkable corner of the world. <br /><br />

                                                        Wishing you a safe journey back home! We look forward to welcoming you again on your future travels. Until then, take care and treasure the memories of your incredible Bhutan experience. <br /><br /></p>

                                                    <br />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DOS AND DON'TS TO NOTE FOR <strong>BHUTAN GROUP TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DOS AND DON'TS TO NOTE FOR <strong>BHUTAN GROUP TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>When traveling to a new place, it's important to understand the local culture and customs. Here’s some helpful information to know before visiting Bhutan. Please read it to make sure you enjoy your trip responsibly. <br /><br /></p>

                                    <h4><b>DO'S</b></h4>

                                    <li>Make sure to use TCB-certified hotels, tour guides, and operators for your <strong>Bhutan Trip</strong>. <br /><br /></li>
                                    <li>When booking your <strong>Bhutan Tour Package</strong>, check the cancellation policy of the tour operator or hotel, as each one has its own rules. <br /><br /></li>
                                    <li>Always carry valid travel documents and show them when needed. <br /><br /></li>
                                    <li>Dress appropriately when visiting religious sites; for example, avoid wearing miniskirts, as they are not considered respectful in Bhutan. <br /><br /></li>
                                    <li>Always ask for permission before entering temples or monasteries. <br /><br /></li>
                                    <li>Remove your shoes before entering a temple. <br /><br /></li>
                                    <li>Take off your hat when entering a fortress or temple. <br /><br /></li>
                                    <li>If photography is not allowed, store your camera safely. <br /><br /></li>
                                    <li>Always walk clockwise around Chorten (stupa), temples, or other religious sites. <br /><br /></li>
                                    <li>You need a permit to export antiques or religious artifacts. <br /><br /></li>
                                    <li>Don’t litter; be responsible for your own waste. <br /><br /></li>
                                    <li>Use the designated zebra crossings for your safety. <br /><br /></li>
                                    <li>Respect the Bhutanese people and avoid raising your voice, as it is not a common practice in Bhutan. <br /><br /></li>
                                    <li>Carry enough cash, as US dollars and other currencies can only be exchanged at the airport or major banks in larger towns. <br /></li>

                                    <br /><br />
                                    <h4><b>DON'TS</b></h4>
                                    <li>Smoking in public areas is restricted, and you will be fined if caught. <br /><br /></li>
                                    <li>Do not misbehave at monasteries, temples, or dzongs. <br /><br /></li>
                                    <li>Do not throw garbage anywhere except in designated trash bins. <br /><br /></li>
                                    <li>Make sure to register all your electronic devices, such as cameras, laptops, and mobile phones, with customs upon arrival. <br /><br /></li>
                                    <li>Do not climb on or step over religious artifacts or temples. <br /><br /></li>
                                    <li>Don’t wear hats or sunglasses inside religious places. <br /><br /></li>
                                    <li>Some places restrict photography or filming, so always check with your tour guide. <br /><br /></li>
                                    <li>Swimming, washing, or throwing objects into lakes or water bodies is prohibited, as they are considered sacred. <br /><br /></li>
                                    <li>Refrain from spitting on walls or other places. <br /><br /></li>
                                    <li>Do not touch religious paintings or stupas in temples, as it is disrespectful in Bhutanese culture. <br /><br /></li>
                                    <li>Do not sit with your legs stretched out in front of an altar, as it is culturally offensive. <br /><br /></li>
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>BAGDOGRA TO BHUTAN GROUP TOUR BOOKING</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>BAGDOGRA TO BHUTAN GROUP TOUR BOOKING</strong></h3>
                                </div>
                                <div className="card-body">
                                    <li>Locals refer to their country as <b>Druk Yul</b> which translates to <b>The Land of the Dragons</b> reflecting the deep cultural and spiritual significance of dragons in Bhutanese traditions. <br /><br /></li>

                                    <li>Bhutan has a unique and proud history, having never been conquered or ruled by any outside forces, making it a truly independent nation. <br /><br /></li>

                                    <li>Known as the only carbon-negative country in the world, Bhutan is an environmental leader, absorbing more carbon dioxide than it produces, a remarkable achievement for any nation. <br /><br /></li>

                                    <li>Until the 1970s, Bhutan remained isolated from the rest of the world, preserving its unique culture, traditions, and pristine environment, which you can experience during a <strong>Bhutan group tour</strong>. <br /><br /></li>

                                    <li>Interestingly, you won't find any traffic lights in Bhutan. Instead, traffic is managed by friendly and efficient traffic police, reflecting the country's harmonious lifestyle. <br /><br /></li>

                                    <li>Bhutan operates as a constitutional monarchy, where the King plays a significant role in governance alongside elected officials, embodying a blend of tradition and modernity. <br /><br /></li>

                                    <li>Instead of GDP, Bhutan measures its economic growth through <b>Gross National Happiness (GNH)</b>, focusing on the well-being and happiness of its people rather than mere financial metrics. <br /><br /></li>

                                    <li>Bhutan is a proud plastic-free nation, showcasing its commitment to environmental sustainability and a cleaner future. <br /><br /></li>

                                    <li>Flying into Bhutan is an exclusive experience, as fewer than 20 qualified pilots are certified to land at the country's only international airport, making your <strong>Bhutan group trip package from Bagdogra</strong> even more unique. <br /><br /></li>

                                    <li>Smoking is strictly prohibited in Bhutan and considered a punishable offense, underscoring the nation's dedication to public health and environmental purity. <br /><br /></li>

                                    <li>Bhutan boasts some of the world's highest unclimbed peaks, including the majestic Gangkhar Puensum, which stands at 7,570 meters (24,981 feet), a testament to the country's untamed natural beauty. <br /><br /></li>

                                    <li>The national animal of Bhutan is the Takin, a rare goat-antelope found only in the Eastern Himalayas, adding to the country's rich biodiversity. <br /><br /></li>

                                    <li>Spicy food lovers will delight in Bhutanese cuisine, where chilies are a staple, and the iconic national dish, <b>Ema Datshi</b>, is made with fiery chilies and creamy local cheese. <br /><br /></li>

                                    <li>Education and healthcare are provided free of charge to all citizens, reflecting Bhutan's commitment to the well-being of its people. <br /><br /></li>

                                    <li>Archery is the national sport of Bhutan and plays a central role in community gatherings, festivals, and national pride. <br /><br /></li>

                                    <li>The people of Bhutan have immense love and respect for their King, whose photo adorns nearly every home as a symbol of unity and reverence. <br /><br /></li>

                                    <li>With one of the world's lowest crime rates, Bhutan offers a safe and peaceful environment, ideal for exploring during a <strong>Bhutan group tour package booking from Bagdogra Airport</strong>. <br /><br /></li>

                                    <li>The national dress Gho for men and Kira for women is mandatory in schools, government offices, and festivals, preserving Bhutan's cultural identity and heritage. <br /><br /></li>

                                    <li>Cleanliness and environmental preservation are integral to Bhutanese life, making the country a pristine and welcoming destination for travelers. <br /><br /></li>

                                    <li>Bhutanese people often chew betel leaves and areca nuts, a traditional practice that is still widely observed across the nation. <br /><br /></li>

                                    <li>The national dish of Bhutan, <b>Ema Datshi</b>, is a must-try for anyone on a <strong>Bhutan group tour from Bagdogra</strong>, offering a delicious taste of the country's unique culinary traditions. <br /><br /></li>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>ACTIVITIES YOU CAN ENJOY DURING <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>ACTIVITIES YOU CAN ENJOY DURING <strong>BHUTAN GROUP TOUR FROM BAGDOGRA</strong></h3>
                                </div>
                                <div className="card-body">

                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Hike to Taktsang Monastery (Tiger's Nest) - Paro</td>
                                                    <td style={{ fontSize: "16px" }}>Take the Dagala Thousand Lakes Trek - Thimphu</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                                                    <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Explore Simtokha Dzong - Thimphu</td>
                                                    <td style={{ fontSize: "16px" }}>Explore the Chele La Pass</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Visit the Buddha Cave & Buddha Falls</td>
                                                    <td style={{ fontSize: "16px" }}>Visit Trongsa Dzong - Trongsa</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Visit Rinpung Dzong - Paro</td>
                                                    <td style={{ fontSize: "16px" }}>Visit Trashigang Dzong - Trashigang District</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Take a Bike Trip around Bhutan</td>
                                                    <td style={{ fontSize: "16px" }}>Go Camping in Thimphu</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Try Archery - Bhutan’s National Sport</td>
                                                    <td style={{ fontSize: "16px" }}>Go Mountain Biking in Bhutan</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Visit the Tashichho Dzong - Thimphu</td>
                                                    <td style={{ fontSize: "16px" }}>Visit the Buddha Dordenma - Thimphu</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Hike in the beautiful Haa Valley - Haa</td>
                                                    <td style={{ fontSize: "16px" }}>Relax with a Hot Spring Therapy</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Experience the Nimalung Tsechu Festival</td>
                                                    <td style={{ fontSize: "16px" }}>Take a scenic drive at Dochula Pass</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Attend the Annual Black-Necked Crane Festival</td>
                                                    <td style={{ fontSize: "16px" }}>Try Kayaking on the Wang Chhu River - Paro</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Go Rafting in the Mo Chhu River - Punakha</td>
                                                    <td style={{ fontSize: "16px" }}>Explore Punakha Dzong - Punakha</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Get a Blessing at Gangtey Monastery - Wangdue Phodrang</td>
                                                    <td style={{ fontSize: "16px" }}>Pray at Chimi Lhakhang Temple - Punakha</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: "16px" }}>Spot Snow Leopards at Jigme Dorji National Park</td>
                                                    <td style={{ fontSize: "16px" }}>Try Bhutanese Cuisine - especially Ema Datshi</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />

                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Bhutan Group Tour Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    Which is the National Game of Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Archary is the National Game of Bhutan. During your Bhutan Tour from Bagdogra, in many places you can try your archary skill with bamboo bow. In some places, you can also find recurve bow and compound bow also.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    What is the language of Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Dzongkha is the national language of Bhutan. In all over two dozen languages are spoken in Bhutan among which Nepali holds one major part.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    Which is the National Bird of Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Raven is the national bird of Bhutan. It has made a significant presence in the Royal Crown which is also known as The Raven Crown. The raven represents the deity Gonpo Jarodongchen - one of the chief guardian deities of Bhutan.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    Which is the National Flower of Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The blue poppy or Himalayan blue poppy (Meconopsis) is the national flower of Bhutan. It is mostly found in high altitude regions and come in different colour like red, pink and white also.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    What is the currency of Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Ngultrum (Nu, BTN) is the official currency of Bhutan. 1 Ngultrum is equal to 1 Indian Rupee.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    When is the ideal time to Bhutan tours from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    October to December is the best time to enjoy a pleasant holiday experience in the country. IF you want to experience summer vibes, then travel Bhutan during April to June.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    Is it safe to visit to Bhutan trip from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan is typically safe and has an extremely low crime rate. However, one should use caution and abstain from going alone at night into remote locations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    Do I need a visa to visit Bhutan from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, foreign nationals (except Indian, Bangladesh, and Maldivian nationals) require a visa to visit Bhutan. Bhutanese visas are issued only through a licensed tour operator. You can arrange your visa through Adorable Vacation - handling your <strong>Bhutan package tour from Bagdogra</strong>.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    What documents do I need to travel to Bhutan from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    For Indian nationals, a valid passport or Voter ID card is required to enter Bhutan. For other foreign nationals, you will need a visa and a passport with at least six months validity. Additionally, you will need to carry a photograph for the visa process.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    How long does it take to reach Bhutan from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bagdogra to Phuentsholing - the nearest town of Bhutan is only 150km, it typically takes about 4-5 hours to reach this border town depending on weather and traffic.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    What is the currency used in Bhutan? Can I exchange money at Phuentsholing?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan's currency is the Bhutanese Ngultrum (BTN), which is pegged to the Indian Rupee (INR). Indian Rupees (INR) are also widely accepted in Bhutan. You can exchange currency at banks or exchange centers in Phuentsholing or Thimphu.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    Can I customize my Bhutan package tour from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, many tour operators offer customized packages where you can tailor your itinerary based on your interests, budget, and time constraints. You can choose from various options like trekking, cultural tours, or exploring offbeat destinations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    Is food available in Bhutan for vegetarians and non-vegetarians?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan offers a wide variety of food options, including both vegetarian and non-vegetarian dishes. Bhutanese cuisine includes rice, meat (mostly pork, chicken, and beef), and vegetables, with dishes like Ema Datshi (chili and cheese), Phaksha Paa (pork with red chili), and Red Rice. International cuisines are also available in most tourist areas.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Bhutan <span className="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>

                    </div>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-98833-59713">
                        <i className="fa fa-phone"></i>
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                </div>
            </>
        );
    }
}

export default Bagdogra_7N_8D_Group_Package;