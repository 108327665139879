import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Meghalaya/InclusionExclusion_KazirangaShillongMeghalaya_7n_8d";

class KazirangaShillongMeghalayaTour_7N_8D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,

    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919830889567&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };


  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Package-from-Guwahati.webp";
    const packageTopBanner2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Tour-Package-from-Guwahati.webp";
    const sightseen1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/shillong-cherrapunji-tour--from-guwahati.webp";
    const sightseen2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/shillong-cherrapunji-package-tour-from-guwahati.webp";
    const sightseen3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/shillong-cherrapunji-package--from-guwahati.webp";
    const sightseen4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-packages.webp";
    const sightseen5ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-itinerary-from-guwahati.webp";
    const sightseen6ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-itinerary.webp";
    const sightseen7ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-from-guwahati.webp";
    const sightseen8ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/shillong-cherrapunji-tour-package-from-guwahati.webp";
    const sightseen9ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-package-tour.webp";
    const sightseen10ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-package-cost-from-guwahati-airport.webp";
    const sightseen11ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-cherrapunji-tour-package-booking-from-guwahati.webp";
    const sightseen12ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/kaziranga-shillong-cherrapunji--tour-from-guwahati.webp";
    const sightseen13ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/kaziranga-shillong-cherrapunji-package-tour-from-guwahati.webp";
    const sightseen14ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-tour-package-cost-from-guwahati.webp";
    const sightseen15ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-tour-package-boking-from-guwahati.webp";
    const sightseen16ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-tour-from-guwahati.webp";
    const sightseen17ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-package-tour-itinerary-from-guwahati.webp";
    const sightseen18ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-package-tour-from-guwahati.webp";
    const sightseen19ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-package-tour-cost-from-guwahati.webp";
    const sightseen20ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-package-tour-booking-from-guwahati.webp";
    const sightseen21ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-package-from-guwahati.webp";
    const sightseen22ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-booking.webp";
    const sightseen23ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package.webp";
    const sightseen24ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-from-guwahati.webp";
    const sightseen25ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-tour-package-booking-from-guwahati.webp";
    const sightseen26ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-shillong-cherrapunji-tour-package-from-guwahati.webp";
    const sightseen27ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-cost-from-guwahati-airport.webp";
    const sightseen28ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-cost-from-guwahati.webp";
    const sightseen29ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-cost.webp";
    const sightseen30ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/meghalaya-tour-package-booking-from-guwahati.webp";
    const navDay1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Indian-one-horned-rhinoceroses-KAZIRANGA.webp";
    const navDay2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Western-Zone-of Kaziranga-National-Park-Jungle-Safari.webp";
    const navDay3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Sholling-Barapani-Lake-Umiam-Lake.webp";
    const navDay4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/JOWAI-SHILLONG-Krang-Suri-Waterfalls.webp";
    const navDay5ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/MAWLYNNONG-DAWKI–CHERRAPUNJEE.webp";
    const navDay6ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CHERRAPUNJEE–NONGRIAT-TREK-DOUBLE-DECKER-BRIDGE.webp";
    const navDay7ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/SEVEN-SISTER-WATERFALLS-CHERAPUNJEE.webp";
    const navDay8ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kamakhya-Temple-Guwahati.webp";

    const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/call-button-bablu-adorable-vacation.webp";

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kaziranga Shillong Cherrapunjee Package Tour from Guwahati</strong></center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center><strong>Kaziranga Shillong Cherrapunjee Package Tour from Guwahati</strong></center>
                      <hr />
                    </h3>

                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        8 Places:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Kaziranga
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Shillong
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Jowai
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Mawlynnong
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Dawki
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Nongriat
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Cherrapunjee
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Guwahati
                        </a>
                      </div>
                      <div className="row">
                        <div className="reviews mx-5">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                    <br />
                    <p className="p-2">
                      Explore the hidden gems of Northeast India with the <strong>Kaziranga Shillong Cherrapunjee package tour from Guwahati</strong>. This incredible journey takes you through the lush wilderness of Kaziranga, where you can enjoy thrilling Jungle Safaris, Elephant Safaris, and Jeep Safaris, offering a chance to spot the rare one-horned rhinoceros. The tour then leads you to Shillong, the "<b>Scotland of the East</b>" and Cherrapunjee, known for its mesmerizing waterfalls, living root bridges, and natural caves. Discover Dawki Lake, one of India's cleanest lakes, and Mawlynnong Village, the cleanest village in India. This <strong>Kaziranga Shillong Cherrapunjee tour packages from Guwahati</strong> is perfect for those looking to explore the untouched beauty and unique culture of North East India. Book your <strong>Kaziranga Shillong Cherrapunjee package tour</strong> now for an unforgettable experience!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJI TOUR PACKAGES FROM GUWAHATI</strong>?<br /><br /> FOR BOOKING <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE</strong> <a href="tel:+919674738480"> CALL ☎ +91-96747-38480</a><br /><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>  LOOKING FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJI TOUR PACKAGES FROM GUWAHATI</strong>?<br /><br /> FOR BOOKING <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE</strong> <a href="tel:+919674738480"> CALL ☎ +91-96747-38480</a><br /><br /></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> EXPLORE THE UNTOUCHED PARADISE OF NORTH EAST INDIA [KAZIRANGA, SHILLONG, CHERRAPUNJI] WITH ADORABLE VACATION</h3>
                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> EXPLORE THE UNTOUCHED PARADISE OF NORTH EAST INDIA [KAZIRANGA, SHILLONG, CHERRAPUNJI] WITH ADORABLE VACATION</h4>
                    </div>
                    <div className="card-body">
                      <p>Embark on the ultimate journey through Northeast India with our <strong>Kaziranga Shillong Cherrapunji package tour from Guwahati</strong>. Get ready to be captivated by the vibrant blend of culture, nature, and adventure as you explore the heart of this magical region.<br /><br />

                        Uncover the natural wonders of Northeast India with our comprehensive <strong>Kaziranga Shillong Cherrapunji tour</strong>. Experience the beauty of Kaziranga National Park in Assam and the enchanting landscapes of Meghalaya. These destinations offer a perfect mix for nature lovers, with lush forests, breathtaking waterfalls, towering hills shrouded in mist, serene lakes, and mysterious caves that showcase the best of India's natural splendor.<br /><br />

                        Feel the thrill of a lifetime on a <strong>Kaziranga elephant safari</strong>, where you'll venture through dense forests and sprawling grasslands, encountering wildlife in their pristine habitat. Alternatively, take a Jeep Safari through Kaziranga National Park, navigating its varied terrain and spotting elusive predators like tigers and leopards.<br /><br />

                        Kaziranga National Park is a haven for wildlife enthusiasts, home to the endangered Great Indian One-Horned Rhinoceros and other remarkable creatures, such as the Royal Bengal Tiger, Indian Elephant, Indian Gaur, and Water Buffaloes. The park's vast grasslands, marshes, and shallow pools offer an unparalleled safari experience.<br /><br />

                        Next, sail along the tranquil Brahmaputra River with an exclusive river cruise. Witness a stunning sunset as the golden light bathes the peaceful landscape, and enjoy the cool breeze as you relax on deck, soaking in the serene beauty of the region. <br /><br />

                        Meghalaya, often called the "<b>Scotland of the East</b>" is a paradise for nature lovers, boasting evergreen forests, stunning waterfalls, sprawling caves, and architectural marvels like the Living Root Bridges. The <strong>Kaziranga Shillong Cherrapunji package tour from Guwahati</strong> offers a perfect mix of tranquility and scenic views of terraced fields, fog-covered hills, winding rivers, and cascading waterfalls. Explore the picturesque and less-traveled parts of this stunning land, where nature thrives undisturbed. <br /><br />

                        No trip to Shillong and Cherrapunji is complete without exploring their mystical caves. Delve into the underground world, discovering intricate rock formations and hidden rivers that have been shaped over centuries. <br /><br />

                        Your adventure continues in Cherrapunji, famous for its lush greenery and heavy rainfall. Here, you'll encounter the incredible living root bridges-natural engineering feats that have withstood the test of time. Trek through dense forests and across suspension bridges, marveling at the ingenuity of the local Khasi people. <br /><br />

                        Throughout the journey, immerse yourself in the rich cultural traditions of Northeast India. From the delicious local cuisine, such as Jadoh (a traditional rice and meat dish) and Doh Khlieh (spicy pork salad), to the vibrant festivals filled with music, dance, and infectious energy, you'll experience the warmth and hospitality of the region's people. <br /><br />

                        Join us on this unforgettable <strong>Kaziranga Shillong Cherrapunji tour from Guwahati</strong>. Traverse diverse landscapes, discover the region's unique culture, and create memories that will last a lifetime. Experience Northeast India like never before with Adorable Vacation.<br /></p>
                        <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Kaziranga Shillong Cherrapunji package tour from Guwahati" />
                          <img src={sightseen2ImageURL} alt="Kaziranga Shillong Cherrapunji tour from Guwahati" />
                          <img src={sightseen3ImageURL} alt="Kaziranga elephant safari" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />

                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DURATION FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR FROM GUWAHATI</strong> : 7N | 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> DURATION FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR FROM GUWAHATI</strong> : 7N | 8D</h4>
                </div>
                <div className="card-body">
                  <h4>DESTINATIONS COVERED IN <strong className="tomato">SHILLONG CHERRAPUNJEE PACKAGE TOUR FROM GUWAHATI AIRPORT</strong> : KAZIRANGA 2N | SHILLONG 2N | CHERRAPUNJEE 2N | GUWAHATI 1N</h4><br /><br />

                  <h4>PICKUP & DROP FOR <strong className="tomato">KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE</strong> : LOKPRIYA GOPINATH BORDOLOI INTERNATIONAL AIRPORT [GAU] | GUWAHATI RAILWAY STATION [GHY]</h4>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DAY WISE PLAN FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE PLAN FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                FLY TO GUWAHATI AND PROCEED TO KAZIRANGA
                              </span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>KAZIRANGA JUNGLE SAFARI</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>KAZIRANGA TO SHILLONG TRANSFERS</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>SHILLONG TO MAWSYNRAM EXCURSION</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>SHILLONG TO CHERRAPUNJEE VIA MAWLYNNONG AND DAWKI RIVER</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>CHERAPUNJEE LOCAL SIGHTSEEING</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>CHERRAPUNJI TO GUWAHATI TRANSFER, BRAHMAPUTRA CRUISE</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>TRANSFER TO GUWAHATI AIRPORT</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Kaziranga Shillong Meghalaya Tour Package" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS : <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS : <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              ACCOMMODATION IN THE LISTED 3-STAR CATEGORY HOTEL
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              MEALS INCLUDED [BREAKFAST + DINNER]
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              EXCLUSIVE VEHICLE FOR ALL TRANSFERS & SIGHTSEEING
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              JEEP SAFARI AT KAZIRANGA
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              ALL TOLL, PARKING, FUEL & DRIVER ALLOWANCE
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              ELEPHANT SAFARI AT KAZIRANGA
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              OPERATION EXECUTIVE ON CALL FOR 24X7
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR FROM GUWAHATI" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /> <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY NORTH EAST INDIA [KAZIRANGA, SHILLONG & CHERRAPUNJI] SHOULD BE IN YOUR TRAVEL LIST?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY NORTH EAST INDIA [KAZIRANGA, SHILLONG & CHERRAPUNJI] SHOULD BE IN YOUR TRAVEL LIST?</h4>
                </div>
                <div className="card-body">
                  <p>Northeast India is a treasure trove of mesmerizing landscapes, thrilling adventures, and rich cultural heritage. Among its many gems, Kaziranga, Shillong, and Cherrapunji stand out as must-visit destinations for any traveler. Here's why these locations should be on your travel list while booking a <strong>Kaziranga Shillong Cherrapunjee package tour from Guwahati Airport</strong> :<br /><br /></p>


                  <h4 className="tomato1">MESMERIZING NATURAL BEAUTY</h4>
                  <p>North East India is renowned for its stunning natural landscapes, and the regions of Kaziranga, Shillong, and Cherrapunji are perfect examples of this beauty. From the lush green rolling hills of Shillong to the dramatic waterfalls of Cherrapunji, the region offers a variety of awe-inspiring vistas. Kaziranga National Park, a UNESCO World Heritage Site, is famous not only for its wildlife but also for its scenic beauty with its wide grasslands and the mighty Brahmaputra River flowing alongside. The lakes, valleys, and waterfalls add to the allure, making it an ideal destination for nature lovers. <br /><br /></p>


                  <h4 className="tomato1">THRILLING ADVENTURES</h4>
                  <p>If you're an adventure enthusiast, Kaziranga, Shillong, and Cherrapunji won't disappoint. In Cherrapunji, embark on a thrilling trek through the famous Double Decker Root Bridges, an experience that combines nature's wonders with adrenaline-pumping activity. For those looking for an exploration of stunning waterfalls, take part in waterfall expeditions to explore the hidden cascades of the region. Cave expeditions in Meghalaya's limestone caves, including Siju and Mawsmai, offer an entirely different kind of adventure. Don't forget a boating trip through the crystal-clear waters of Dawki River, which is known to be one of the cleanest rivers in the world. Hiking in Shillong Peak, the highest point in the region, offers panoramic views that are sure to leave you breathless. With camping opportunities along riversides and mountain slopes, the natural beauty combined with thrilling activities makes it an adventurer's paradise. If you're considering a <strong>Kaziranga Shillong Cherrapunjee package tour from Guwahati</strong>, this adventure awaits! <br /><br /></p>


                  <h4 className="tomato1">CULTURAL RICHNESS AND TRIBAL TRADITIONS</h4>
                  <p>The cultural fabric of Northeast India is vibrant, unique, and deeply rooted in tribal traditions. The Khasi tribes of Shillong, the Naga tribes in Nagaland, and the Apatani tribes of Arunachal Pradesh each have their own distinct customs, languages, and festivals. One of the most fascinating cultural experiences is witnessing the Hornbill Festival in Nagaland, which is a grand celebration of Naga culture with music, dance, and rituals. The Losar festival, celebrated by the Apatani tribes in Ziro, marks the start of the New Year, and it's a feast for the senses with traditional dances, songs, and food. These festivals are just a glimpse into the region's diverse cultural traditions, making a <strong>Shillong Cherrapunjee tour packages from Guwahati</strong> not just a journey through nature, but also a cultural immersion. <br /><br /></p>


                  <h4 className="tomato1">SERENITY AND POLLUTION-FREE ENVIRONMENT</h4>
                  <p>Meghalaya, in particular, offers one of the most serene and pollution-free environments in India. Shillong, often referred to as "<b>The Scotland of the East</b>" is known for its cool weather, lush greenery, and pristine surroundings. The cloudy hills, which are often mist-covered, combined with the soothing sounds of plunging waterfalls, create a peaceful atmosphere that feels like a world away from the hustle and bustle of city life. The tranquility of the area makes it the perfect escape for anyone looking to unwind and rejuvenate. Visitors to <strong>Shillong Cherrapunjee tour from Guwahati</strong> are often struck by the purity and beauty of the region, making it an ideal location for those seeking a peaceful retreat. <br /><br /></p>


                  <h4 className="tomato1">A WIDE RANGE OF FLORA AND FAUNA</h4>
                  <p>Kaziranga National Park is not only a UNESCO World Heritage Site but also a haven for wildlife lovers. It is home to the famous one-horned rhinoceros, and visitors can enjoy safari rides through its vast grasslands, spotting elephants, tigers, and a variety of bird species. For nature lovers, the variety of flora and fauna in this region is an attraction that cannot be missed. The nearby Kaziranga-Karbi Anglong reserve is another biodiverse haven that attracts tourists from all over the world. With <strong>Kaziranga Shillong Cherrapunjee tour packages from Guwahati</strong>, you get the chance to experience both the incredible wildlife and breathtaking natural beauty of the region. <br /><br /></p>


                  <h4 className="tomato1">GASTRONOMIC DELIGHTS</h4>
                  <p>North East India offers a unique culinary experience, especially in areas like Shillong and Cherrapunji. The food here is heavily influenced by tribal customs, and you'll find a variety of dishes made with locally grown ingredients. From rice beer, smoked pork, and fish to spicy chutneys and traditional stews, the food of this region offers a delightful burst of flavors. Don't miss out on the chance to sample local delicacies during your <strong>Shillong Cherrapunjee tour from Guwahati</strong>. <br /><br /></p>


                  <h4 className="tomato1">PICTURESQUE VILLAGES AND TRIBAL HOMES</h4>
                  <p>One of the highlights of traveling to North East India is the chance to explore quaint villages and experience the traditional tribal homes. The Khasi and Naga tribes live in unique houses built from bamboo and thatch, which are a striking representation of their cultural practices. Visiting these villages provides an intimate insight into their way of life, and you can interact with the local people to learn about their traditions, music, and folklore. This rural charm is best experienced with a <strong>Kaziranga Shillong Cherrapunjee trip from Guwahati</strong>, which takes you deep into the heart of the region's villages. <br /><br /></p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Kaziranga Shillong Cherrapunjee package tour from Guwahati" />
                          <img src={sightseen5ImageURL} alt="Kaziranga Shillong Cherrapunjee trip from Guwahati" />
                          <img src={sightseen6ImageURL} alt="Shillong Cherrapunjee tour from Guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES OF ATTRACTIONS IN <strong>SHILLONG CHERRAPUNJI KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES OF ATTRACTIONS IN <strong>SHILLONG CHERRAPUNJI KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><th><b>PLACES</b></th><th><b>ATTRACTIONS</b></th></tr></thead>

                      <tbody>
                        <tr><td><b>Guwahati</b></td><td>Assam State Zoo cum Botanical Garden, Kaziranga National Park, Haflong, Guwahati Planetarium, Guwahati Zoo, Umananda Island, Chandubi Lake, Dispur, Dipor Bil, Umananda Temple, Kamakhya Temple, ISKCON Guwahati, Guwahati War Memorial etc.</td></tr>

                        <tr><td><b>Kaziranga</b></td><td>Kaziranga National Park, Jeep Safari Tours, Elephant Safari, Kaziranga Elephant Festival, Orchid and Biodiversity Park, Kakochang Waterfalls, Numaligarh Ruins, Panbari Reserve Forest
                          Deopahar Ruins, Tea Gardens in the vicinity, etc.</td></tr>

                        <tr><td><b>Shillong</b></td><td>Umiam Lake, Elephant Value, Shillong Peak, Laitlum Canyons, Mawlynnong, Ward's Lake, Police Bazar, Don Bosco Museum, Lady Hydari Park, Nohsngithiang Falls, Mawsynram, Mawphlang, David Scott Trail, Sweet Falls, Spread Eagle Falls, Air Force Museum, Cathedral Of Mary Help Of Christians, Mawjymbuin Cave, Meghalaya State Museum, Rhino Heritage Museum, Sohpetbneng, Kyllang Rock, Butterfly Museum, Shillong, Saints Church, Jakrem, Diengiei Peak, Crinoline Falls, Malki Forest, Ever Living Museum, Khasi Hills, Jaintia Hills, Garo Hills, Museums in Shillong etc.</td></tr>

                        <tr><td><b>Cherrapunji</b></td><td>Seven Sister Falls, Double Decker Living Root Bridges, Dainthlen Waterfalls, Khasi Monoliths, Laitmawsiang, Mawsynram, Mawkdok Dympep Valley View, Mawsmai Cave, Krem Phyllut, Mawsmai Falls, Mawphlang Sacred Forest Meghalaya, Nongsawlia, Nokrek National Park, Eco Park etc.</td></tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>TRAVEL DISTANCES TO NOTE FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>TRAVEL DISTANCES TO NOTE FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><th><b>Places</b></th><th><b>Distances</b></th></tr></thead>
                      <tbody>
                        <tr><td>Guwahati to Kaziranga Distance [By Road]</td><td>193.1 Kms</td></tr>
                        <tr><td>Kaziranga to Shillong Distance [By Road]</td><td>252.2 Kms</td></tr>
                        <tr><td>Guwahati to Shillong Distance [By Road]</td><td>98.2 km</td></tr>
                        <tr><td>Shillong to Cherrapunji Distance [By Road]</td><td>54 Kms</td></tr>
                        <tr><td>Shillong to Mawlynnong Distance [By Road]</td><td>73 Kms</td></tr>
                        <tr><td>Shillong to Dawki Distance [By Road]</td><td>81.8 Kms</td></tr>
                        <tr><td>Kaziranga to Cherrapunji Distance [By Road]</td><td>300.8 Kms</td></tr>
                        <tr><td>Guwahati to Cherrapunji Distance [By Road]</td><td>147.4 Kms</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="shillong meghalaya tour package" />
                          <img src={sightseen8ImageURL} alt="shillong cherrapunji tour from guwahati " />
                          <img src={sightseen9ImageURL} alt="KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY ADORABLE VACATION IS <strong>NO 1 NORTH EAST INDIA TOUR PACKAGE SPECIALIST FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY ADORABLE VACATION IS <strong>NO 1 NORTH EAST INDIA TOUR PACKAGE SPECIALIST FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Pre-Purchased Room Inventory</b>: We offer a diverse range of pre-booked rooms in Kaziranga Shillong & Cherrapunjee, guaranteeing you the best valley, mountain, or river-view rooms based on your booking date for the <strong>Kaziranga Shillong Cherrapunjee package tour</strong>.<br /></p>

                  <p><b>Premium Stays</b>: Enjoy a luxurious stay with our selection of Premium & Deluxe Hotels and Luxury Home Stays, offering the finest amenities for a comfortable and memorable <strong>Kaziranga Shillong Cherrapunjee tour packages</strong>.<br /></p>

                  <p><b>Seamless Transport</b>: Your <strong>Shillong Cherrapunjee package tour with Kaziranga</strong> will include well-managed transportation options from starting Guwahati ensuring smooth and hassle-free travel.<br /></p>

                  <p><b>Vehicle Variety</b>: Depending on your budget and preferences, choose from a range of vehicles such as Innova, Ertiga, Scorpio, or XUV 500 (Sunroof) to make your <strong>Guwahati to Shillong Cherrapunjee tour</strong> comfortable and convenient.<br /></p>

                  <p><b>Dedicated Operations Team</b>: We have a dedicated operations team based in the Northeast, ensuring uninterrupted support throughout your <strong>Shillong Cherrapunjee tour packages from Guwahati</strong>.<br /></p>

                  <p><b>Expert Guidance</b>: As North East India Super Specialists, we guide you through your journey, taking into account peak season rush, weather, and off-season conditions. Your safety and satisfaction are our top priorities, and we always ensure you get the best value for your money.<br /></p>

                  <p><b>Experience You Can Trust</b>: With over 12+ years of experience in organizing <strong>Kaziranga & Meghalaya tour packages from Guwahati</strong>, Adorable Vacation offers top-tier accommodations including Deluxe Homestays, Premium Stays, and Luxury Boutique Resorts for a comfortable stay.<br /></p>

                  <p><b>Personalized Travel Experience</b>: Our team of passionate and experienced travelers personally inspects and certifies every hotel included in your itinerary, ensuring you have the best experience during your <strong>Assam Meghalaya package tour from Guwahati</strong>.<br /></p>

                  <p><b>Complete Assistance</b>: From start to finish, our team provides day-to-day guidance and on-ground assistance during your <strong>Meghalaya tour with Shillong & Kaziranga</strong>. We handle everything ourselves, with no third-party sub-agents involved, ensuring the highest level of service and attention throughout your trip.<br /></p>

                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="shillong meghalaya tour package" />
                          <img src={sightseen11ImageURL} alt="shillong cherrapunji tour from guwahati " />
                          <img src={sightseen12ImageURL} alt="Assam Meghalaya package tour from Guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>HOTELS WE PROVIDE FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJI TOUR PACKAGES FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>HOTELS WE PROVIDE FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJI TOUR PACKAGES FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><th>HOTEL NAME</th><th>ADDRESS</th></tr></thead>
                      <tbody>
                        <tr><td>Windermere Inn, Shillong</td><td>Address: Lumheh, Umpling, Near B.S.F Gate, No.04, Neigrihms Cir Rd, Shillong, Meghalaya 793006</td></tr>
                        <tr><td>Golf View Residency shillong</td><td>Address: Dog squad, Golf course, Pynthorumkhrah, Shillong, Meghalaya 793001</td></tr>
                        <tr><td>Rishatsngi Resort</td><td>Address: Umiam, Dyke, GS Rd, Mawlyngkhung, Meghalaya 793103</td></tr>
                        <tr><td>Ri Kynjai - Serenity By The Lake</td><td>Address: Umniuh Khwan, Road, UCC, Meghalaya 793122</td></tr>
                        <tr><td>La Nicholas Dei Da Kine Resort</td><td>Address: UMBIR-MAIRANG ROAD, NEAR SILOAM , Umbir Village, Shillong, Meghalaya 793122</td></tr>
                        <tr><td>Cherrapunji Holiday Resort</td><td>Address: Laitkynsew View Point Rd, Village, Cherrapunji, Sohsarat, Meghalaya 793108</td></tr>
                        <tr><td>Sulawado Resort</td><td>Address: Nongthymmai, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>San Naela Resort</td><td>Address: SOHRA, Pynshadkhurai - Shella Road Saitsohpen, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Saimika Resort</td><td>Address: Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Jiva Resort</td><td>Address: Saitsohpen, Near Kutmadan, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Royal View Resort</td><td>Address: Lumsohphie, Via, Tyrna, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Four Season Eco Resort</td><td>Address: Eco Park, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Polo Orchid Resort, Cherrapunji</td><td>Address: Mawsmai, Nongthymmai, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Pala Resort</td><td>Address: Kutmadan, Sohra, Cherrapunji, Shillong, Meghalaya 793111</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen13ImageURL} alt="shillong meghalaya tour package" />
                          <img src={sightseen14ImageURL} alt="shillong cherrapunji tour from guwahati " />
                          <img src={sightseen15ImageURL} alt="KAZIRANGA SHILLONG CHERRAPUNJI TOUR PACKAGES FROM GUWAHATI" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY GUWAHATI AIRPORT IS THE BEST OPTION TO START ANY <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY GUWAHATI AIRPORT IS THE BEST OPTION TO START ANY <strong>KAZIRANGA SHILLONG CHERRAPUNJEE TOUR</strong></h4>
                </div>

                <div className="card-body">
                  <p><b>Guwahati Airport</b> is the ideal starting point for your <strong>Shillong Cherrapunjee package tour</strong> due to its excellent connectivity, affordability, and convenient location. As a major airport in the Northeast, it offers seamless flight connectivity from various key cities like <b>Mumbai</b>, <b>Bangalore</b>, <b>Chennai</b>, <b>Pune</b>, <b>Delhi</b>, and <b>Kolkata</b>, making it easily accessible for travelers across India. Opting for a <strong>Guwahati to Kaziranga Shillong tour by Flight</strong> ensures that you can quickly and comfortably begin your journey to the serene landscapes of Assam & Meghalaya. <br /><br />

                    The <strong>Kaziranga Shillong Cherrapunjee trip cost from Guwahati</strong> is also highly cost-effective, with several airlines offering competitive fares, making it a budget-friendly option for travelers. Whether you're flying with <b>Air India</b>, <b>IndiGo</b>, or <b>SpiceJet</b>, you can find affordable flights that cater to different schedules, allowing you to plan your trip according to your convenience. Furthermore, with <strong>North East India Tour Operators from Guwahati</strong>, like Adorable Vacation, managing your <strong>Kaziranga Shillong Cherrapunjee package tour itinerary</strong>, you can expect a hassle-free experience from the moment you land. The proximity of the airport to major destinations in Assam & Meghalaya, combined with easy transport options, ensures that your <strong>Assam & Meghalaya tour</strong> is off to a smooth and efficient start.<br /><br /><br /></p>
                    <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen16ImageURL} alt="shillong meghalaya tour package" />
                          <img src={sightseen17ImageURL} alt="shillong cherrapunji tour from guwahati " />
                          <img src={sightseen18ImageURL} alt="meghalaya shillong cherrapunji package tour from guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FLIGHTS TO CHOOSE FROM DIFFERENT CITIES FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR BOOKING FROM GUWAHATI AIRPORT</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FLIGHTS TO CHOOSE FROM DIFFERENT CITIES FOR <strong>KAZIRANGA SHILLONG CHERRAPUNJEE PACKAGE TOUR BOOKING FROM GUWAHATI AIRPORT</strong></h4>
                </div>

                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><td>AIRLINES</td><td>ORIGIN</td><td>DESTINATION</td><td>DURATION</td></tr></thead>
                      <tbody><tr><td>IndiGo</td><td>Kolkata [07:25]</td><td>Guwahati [08:45]</td><td>01 h 20 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Kolkata [10:05]</td><td>Guwahati [11:55]</td><td>01 h 50 m (non-stop)</td></tr>
                        <tr><td>Air India</td><td>Kolkata [05:45]</td><td>Guwahati [07:00]</td><td>01 h 15 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Mumbai [06:25]</td><td>Guwahati [09:20]</td><td>02 h 55 m (non-stop)</td></tr>
                        <tr><td>SpiceJet</td><td>Mumbai [06:45]</td><td>Guwahati [10:00]</td><td>03 h 15 m (non-stop)</td></tr>
                        <tr><td>AirAsia</td><td>Delhi [06:05]</td><td>Guwahati [08:25]</td><td>02 h 20 m (non-stop)</td></tr>
                        <tr><td>AirAsia</td><td>Delhi [07:15]</td><td>Guwahati [09:40]</td><td>02 h 25 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Delhi [05:00]</td><td>Guwahati [07:20]</td><td>02 h 20 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Delhi [07:25]</td><td>Guwahati [09:45]</td><td>02 h 20 m (non-stop)</td></tr>
                        <tr><td>AirAsia</td><td>Bangalore [08:25]</td><td>Guwahati [11:20]</td><td>02 h 55 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Bangalore [08:20]</td><td>Guwahati [11:20]</td><td>03 h 00 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Chennai [05:30]</td><td>Guwahati [08:15]</td><td>02 h 45 m (non-stop)</td></tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen19ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen21ImageURL} alt="meghalaya shillong cherrapunji package from guwahati" />
                          <img src={sightseen20ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST TIME TO BOOK <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST TIME TO BOOK <strong>KAZIRANGA SHILLONG CHERRAPUNJI PACKAGE TOUR FROM GUWAHATI</strong></h4>
                </div>

                <div className="card-body">
                  <p>North East India is a paradise that transforms beautifully with every season. To make the most of your journey, choosing the right season for your <strong>Kaziranga Shillong Cherrapunjee package tour</strong> is essential:<br /><br /><br /></p>

                  <h4 className="tomato1">WINTER [NOV - MAR]</h4>
                  <p>Winter, from November to March, is the best time for <strong>North East India package tour</strong> if you enjoy wildlife safaris and adventure. This season is perfect for visiting Kaziranga National Park, where you can experience its famous Elephant and Jeep Safari. The weather remains pleasantly cold, with temperatures ranging from 6 to 24 degrees Celsius, making it ideal for wildlife spotting. Winter also offers an excellent opportunity for trekking to the fascinating caves and root bridges of Cherrapunjee, where the cool and comfortable climate enhances the adventure. For those who love snow, Arunachal Pradesh during winter is a dream destination, with places like Tawang and Dirang offering mesmerizing snow-capped landscapes. If you are planning a <strong>Kaziranga Shillong Cherrapunjee package tour from Guwahati</strong>, winter is the perfect time to embark on this adventure. <br /><br /><br /></p>


                  <h4 className="tomato1">MONSOON [JUL - SEP]</h4>
                  <p>Monsoon, from July to September, is a time when North East India showcases its breathtaking waterfalls and lush greenery. This is the best season to witness the mesmerizing waterfalls of Cherrapunjee at their fullest. The entire region transforms into a green paradise with mist-covered hills, offering an enchanting experience for nature lovers. The heavy rainfall makes the surroundings even more magical, making monsoon the perfect time to enjoy the true beauty of the region. A <strong>Shillong Cherrapunjee package tour</strong> during monsoon ensures that you witness the waterfalls in their prime glory. Note: monsoon is not right time to visit Kaziranga National Park as during monsoon most of the area are flooded by Brahmaputra river and Kaziranga remain close for breeding season for the wild life there.<br /><br /><br /></p>


                  <h4 className="tomato1">SUMMER [APR - JUN]</h4>
                  <p>Summer, from April to June, can be hot and humid in many parts of North East India, but it is the perfect season to explore Arunachal Pradesh. If you’re looking to escape the scorching summer heat of other Indian states, Arunachal Pradesh provides a refreshing retreat. The cool climate and stunning landscapes make it an excellent summer destination. A <strong>Arunacal package tour from Guwahati</strong> with Tawang, Dirang, Zemithang, Sangti Valley, and Shergaon during this season offers an unforgettable experience amidst pleasant weather. <br /><br />

                    Exploring Kaziranga in summer may be a bad descision as it may be hot & humid too, even waterfalls in most of cherrapunji may become dry. So, it is recommend to focus on Arunachal Pradesh during summer season. Adorable vacation offers a very good <strong>Arunachal Pradesh package tour from Guwahati Airport </strong>at best price & offer. Plz try it. <br /><br /><br /></p>



                  <h3 className="tomato1">SHILLONG WEATHER & TEMPERATURE TO NOTE</h3>
                  <p>Shillong has a subtropical highland climate with cool and rainy summer and cold dry winter. Have a look of the Shillong Weather Table.</p>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Travel Month</th><th>Min / Max Temperature</th><th>Season</th></tr></thead>
                      <tbody><tr><td>March to June</td><td>12&#176; - 24&#176;C</td><td>Pleasant</td></tr>
                        <tr><td>July to September</td><td>10&#176; - 22&#176;C</td><td>Rainy Season</td></tr>
                        <tr><td>October to February</td><td>5&#176; - 14&#176;C</td><td>Cold</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />


                  <h3 className="tomato1">CHERRAPUNJEE WEATHER & TEMPERATURE TO NOTE</h3>
                  <p>Cherrapunjee gets heavy rainfall all year, making it humid. However, the cloudy skies keep the weather cool and pleasant for visits anytime. August is the warmest and wettest month, while January is the coolest and driest. The table below gives an overview of Cherrapunjee’s weather.</p>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Travel Month</th><th>Min / Max Temperature</th><th>Season</th></tr></thead>
                      <tbody><tr><td>March to June</td><td>15&#176; - 25&#176;C</td><td>Warm but Pleasant</td></tr>
                        <tr><td>July to September</td><td>18&#176; - 28&#176;C</td><td>Warm and Wet</td></tr>
                        <tr><td>October to February</td><td>8&#176; - 15&#176;C</td><td>Cold & Pleasant</td></tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen22ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen23ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen24ImageURL} alt="meghalaya shillong cherrapunji package from guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHAT IS THE BEST TIME TO TRAVEL KAZIRANGA?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHAT IS THE BEST TIME TO TRAVEL KAZIRANGA?</h4>
                </div>

                <div className="card-body">
                  <p>Winter is the ideal season to visit Kaziranga National Park. From late November to the first week of April, you can explore the park at its best. Winter in Northeast India begins in early November and lasts until the end of March, offering a comfortable and pleasant climate for travel. During this time, temperatures in Kaziranga range from a maximum of 24°C to a minimum of 6°C. The vast, swampy landscapes of Kaziranga, shrouded in winter mist, look absolutely majestic. The best way to explore this beautiful park is on an elephant safari, as elephants roam freely, taking you through areas that jeeps can't access. Jeep safaris are also enjoyable during winter, offering a close-up view of Kaziranga's stunning wildlife. While jeep rides can be a bit dusty, the experience is still incredibly rewarding, especially when traveling in an open-top jeep to explore the park's diverse ranges. <br /><br />

                    Summer is another time to visit Kaziranga, though the hot and humid weather may be uncomfortable for some. The monsoon season, however, is not ideal for a Kaziranga tour. From June to September, the region experiences heavy rainfall and flooding caused by the Brahmaputra River. During this time, both human and wildlife activity come to a standstill, making it better to avoid visiting the park during these months.</p>
                  <br />
                  <h4 className="tomato1">PRICE RANGE : KAZIRANGA JEEP SAFARI</h4>
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead><tr><th><b>Range</b></th><th><b>Vehicle</b></th><th><b>Entry Fee / Indian</b></th><th><b>Entry Fee / Foreigner</b></th></tr></thead>
                      <tbody><tr><td>Central Range (Kohora)</td><td>INR 2700 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td></tr>
                        <tr><td>Western Range (Bagori)</td><td>INR 2800 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td></tr>
                        <tr><td>Eastern Range (Agaratoli)</td><td>INR 3200 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td></tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><br />
                  <h4 className="tomato1">PRICE RANGE : KAZIRANGA ELEPHANT SAFARI</h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead><tr><th><b>Citizen</b></th><th><b>Ranges</b></th><th><b>Rate/Hour</b></th></tr></thead>
                      <tbody><tr><td>Indian</td><td>Western Range (Bagori)</td><td>INR 1,000</td></tr>
                        <tr><td>Foreigner</td><td>Central Range (Kohora)</td><td>INR 2,100</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen25ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen26ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen27ImageURL} alt="meghalaya shillong cherrapunji package from guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT POINTS TO NOTE FOR KAZIRANGA JEEP SAFARI & ELEPHANT SAFARI</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT POINTS TO NOTE FOR KAZIRANGA JEEP SAFARI & ELEPHANT SAFARI</h4>
                </div>

                <div className="card-body">
                  <li><b>Carry Proper Identification Documents:</b> It is important to bring a valid ID, such as a passport, driving license, or Aadhar card, when entering the national park. You will be asked to show it at the entry gate for security purposes. <br /><br /></li>

                  <li><b>Follow the Rules and Regulations:</b> Kaziranga National Park has strict rules that all visitors must adhere to, including no littering, no smoking, and no carrying weapons. Be sure to follow these regulations to avoid any issues. <br /><br /></li>

                  <li><b>Choose the Right Time:</b> The best time to visit Kaziranga National Park for a jeep safari or jungle safari is from November to April, as the weather is comfortable and animals are more visible. Avoid visiting during the monsoon season, as the park remains closed due to heavy rains. <br /><br /></li>

                  <li><b>Dress Appropriately:</b> Wear lightweight and comfortable clothes for your safari tour. Choose earthy tones such as brown, green, or khaki, and avoid wearing bright colors. Also, make sure to wear comfortable shoes, especially for walking during the Elephant Safari in Kaziranga. <br /><br /></li>

                  <li><b>Carry Essential Items:</b> Don't forget to bring important items like sunscreen, mosquito repellent, a hat or cap, sunglasses, and a water bottle. A camera with a good zoom lens is also recommended for capturing the park's stunning wildlife and landscapes. <br /><br /></li>

                  <li><b>Hire a Local Guide:</b> Hiring a local guide is highly recommended for exploring Kaziranga National Park. They are familiar with the park's wildlife and vegetation and can help you spot animals more easily. <br /><br /></li>

                  <li><b>Be Patient:</b> Wildlife sightings are not guaranteed during the safari, so patience is key. Respect the animals and their environment, and avoid disturbing them in any way. <br /><br /><br /></li>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen28ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen29ImageURL} alt="meghalaya shillong cherrapunji package tour booking from guwahati" />
                          <img src={sightseen30ImageURL} alt="meghalaya shillong cherrapunji package from guwahati" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DAY WISE ITINERARY FOR <strong className="tomato">KAZIRANGA SHILLONG CHERRAPUNJEE TOUR PACKAGE FROM GUWAHATI</strong>
                      </h2>
                      <h4>7 Nights 8 Days <strong className="tomato">Kaziranga Shillong Tour Itinerary</strong></h4>

                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <h4>DAY 1 : GUWAHATI TO KAZIRANGA [APPROX. 4HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Kaziranga National Park is a UNESCO World Heritage Site located in the Indian state of Assam. It is renowned for its population of Indian one-horned rhinoceroses, which are one of the main attractions of the park. Welcome on arrival at Guwahati airport/ Railway station and transfer to Kaziranga National Park, the home of the “One Horned Rhinoceros”. Check-in at your hotel/Lodge/resort. Evening free for leisure.Overnight stay at Kaziranga Hotel.</p>

                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <h4>DAY 2 : JUNGLE SAFARI AT KAZIRANGA</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>Early morning explores the Western Zone of Kaziranga National Park on the back of an elephant. Apart from world endangered One-horned Rhinoceros, the Park sustains half the world’s population of genetically pure Wild Water Buffaloes, over 1000 Wild elephants, and perhaps the densest population of Royal Bengal Tigers anywhere. Kaziranga is also a bird watchers paradise and home to some 500 species of birds. The Crested Serpent Eagle, Pallas Fishing Eagle, Grey-headed Fishing Eagle, Swamp Partridge, Bar-headed goose, whistling Teal, Bengal Florican, Storks, Herons, and Pelicans are some of the species found here.</p>
                          <p>We will return to the resort for breakfast. Afternoon experience jeep safari through the Central Zone of the National Park. In the evening enjoy the cultural program in Orchid Park.Overnight stay at Kaziranga.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 3 : KAZIRANGA TO SHILLONG [APPROX. 5HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Early morning explores Western Zone of Kaziranga National Park on back of elephant. After breakfast check out from hotel and transfer to Shillong. Often called the ‘Scotland of the East’, Shillong has lots to discover from lakes to hills to the jaw-dropping waterfalls. En route, stop at Barapani Lake (Umiam Lake)  for some amazing view & water activities. On arrival at Shillong, if Time Permits visit Don Bosco Musuem, Wards lake, Cathedral church, Lady Hydari Park</p>
                          <p>check in to the hotel. Afternoon time free for personal activity.Overnight stay at Shillong. </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          <h4>DAY 4 : SHILLONG – JOWAI - SHILLONG</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, start for Jowai to visit India's most magical waterfall: </p>
                          <ul>
                            <li>● Krang Suri Waterfalls</li>
                            <li>● Laitlum Canyon</li>
                            <li>● Thadlaskein Lake</li>
                            <li>● Stone Bridge.</li>
                          </ul>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                          <p>After getting there, get drenched and swim in the natural pools. Evening returns & free for Leisure.<b> Overnight at Shillong Stay. </b></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          <h4>DAY 5 : SHILLONG - MAWLYNNONG - DAWKI – CHERRAPUNJEE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Morning after breakfast we will proceed to visit the cleanest village of Asia - Mawlynnong. You will also get a chance to explore the nearby villages and witness the amazing living root bridges. Later on, you will be transferred to the international border of Bangladesh, wherein you will be visiting Dawki Village. This village is known for having some of the cleanest rivers, a classic example of which is the enchanting Umngot River.</p>
                          <p>Later on, you will go to Cherrapunjee for a relaxing overnight stay.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          <h4>DAY 6 : CHERRAPUNJEE – NONGRIAT TREK DOUBLE DECKER BRIDGE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, we proceed for a full day trekking to the Double Decker Living Root Bridge at Nongriat Village. We have to trek down 3200 steps approximately each way to the Double Decker Living Root Bridge (7-hour trek). Evening returns to Cherrapunjee. </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          <h4>DAY 7 : CHERRAPUNJEE LOCAL SIGHTSEEING AND TRANSFER TO GUWAHATI [APPROX. 2HRS]</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Entire Day Cherrapunjee Local, Includes, Today post breakfast, you will proceed to Shillong. En-route visit </p>
                          <ul>
                            <li>●  Wei Sawdong Falls</li>
                            <li>●  Seven Sisters Waterfalls</li>
                            <li>●  Arwah Cave</li>
                            <li>●  Nohkalikai Falls</li>
                            <li>●  Mawsmai Cave</li>
                          </ul>
                          <br />
                          <p><b>Overnight stay at Hotel Guwahati.</b></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          <h4>DAY 8 : DEPARTURE AT GUWAHATI AIRPORT</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After breakfast check out from the hotel and drop at Guwahati Airport as per timings. If time permits, visits to Basistha Ashram, Srimanta Sankaradeva Kalakshetra, ‘Jagran’ store for authentic Assamese silks & handicrafts, trip ends with memories.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              {/* inclusion End  */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP SIGHTSEEING PLACES TO EXPLORE IN <strong>KAZIRANGA SHILLONG TOUR PACKAGE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP SIGHTSEEING PLACES TO EXPLORE IN <strong>KAZIRANGA SHILLONG TOUR PACKAGE</strong></h4>
                </div>
                <div className="card-body">
                  <p>Here are some must-visit palces to explore during <strong>Kaziranga Shillong Cherrapunjee Tour Package from Guwahati</strong> :</p>
                  <br />
                  <h4>KAZIRANGA </h4>
                  <ul>
                    <li><b>•	Kaziranga National Park:</b> Famous for its one-horned rhinoceroses and home to many endangered species.</li><br />
                    <li><b>• Kaziranga Orchid and Biodiversity Park:</b> A paradise for nature lovers with over 500 species of orchids and many rare plants.</li><br />
                    <li><b>• Panbari Reserve Forest:</b> Perfect for birdwatching and nature walks.</li><br />
                    <li><b>• Deoparbat Ruins:</b> Ancient ruins dating back to the 9th century, great for history enthusiasts.</li><br />
                    <li><b>• Hoollongapar Gibbon Sanctuary:</b> Home to the only ape species in India, the Hoolock Gibbon.</li><br />
                  </ul>
                  <br />
                  <h4>SHILLONG</h4>
                  <ul>
                    <li><b>•	Umiam Lake:</b> Popular for picnics, boating, and kayaking, surrounded by lush green hills.</li><br />
                    <li><b>• Shillong Peak:</b> The highest point in Shillong, offering breathtaking views of the city and hills.</li><br />
                    <li><b>• Ward's Lake:</b> A beautiful lake in the city center, perfect for relaxing strolls and boating.</li><br />
                    <li><b>• Don Bosco Museum:</b> Showcasing the culture and heritage of North East India.</li><br />
                    <li><b>• Elephant Falls:</b> A stunning waterfall cascading over 100 feet, a favorite among tourists.</li><br />
                  </ul>
                  <br />
                  <h4>CHERRAPUNJEE</h4>
                  <li><b>• Nohkalikai Falls:</b> One of the tallest waterfalls in India, ideal for nature lovers and adventure enthusiasts.</li><br />
                  <li><b>• Mawsmai Cave:</b> Limestone caves offering a unique underground experience.</li><br />
                  <li><b>• Seven Sisters Falls:</b> Named after the seven northeastern states, a mesmerizing waterfall for nature lovers.</li><br />
                  <li><b>• Thangkharang Park:</b> Offers stunning views of the Bangladesh plains and Khasi hills.</li><br />
                  <li><b>• Dainthlen Falls:</b> Surrounded by lush green forests, perfect for nature lovers and photographers.</li><br />
                  <p>These are some of the top sightseeing places to include in your itinerary for an unforgettable trip to <strong>Shillong Cherrapunjee Tour Package</strong>.</p>
                </div>
              </div>
              <br />  <br />  <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>OFFBEAT PLACES TO EXPLORE IN <strong>SHILLONG CHERRAPUNJEE PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>OFFBEAT PLACES TO EXPLORE IN <strong>SHILLONG CHERRAPUNJEE PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <p>Here are the must-visit spots in Kaziranga, Shillong, and Cherrapunjee for a memorable trip to Shillong Cherrapunjee Tour Package :</p><br />
                  <h4>SHILLONG </h4>
                  <ul>
                    <li><b>• Mawphlang Sacred Forest:</b> A peaceful forest considered sacred by the Khasi tribe, great for exploring local culture and biodiversity.</li>      <br />
                    <li><b>• Laitlum Canyons:</b> Hidden canyons with panoramic views of hills and valleys.</li>      <br />
                    <li><b>• David Scott Trail:</b> A scenic trek through forests, waterfalls, and Khasi villages.</li>      <br />
                    <li><b>• Wah Kaba Falls:</b> A secluded waterfall, perfect for picnics and relaxation.</li>      <br />
                    <li><b>• Mawlynnong Village:</b> Known as the cleanest village in Asia, famous for eco-tourism and living root bridges.</li>      <br />
                  </ul>
                  <br />
                  <h4>KAZIRANGA </h4>
                  <ul>
                    <li><b>• Biswanath Chariali:</b> A small town near Kaziranga, known for tea gardens and wildlife sanctuaries.</li>      <br />
                    <li><b>• Pobitora Wildlife Sanctuary:</b> A lesser-known sanctuary home to the Indian one-horned rhinoceros, a great alternative to Kaziranga.</li>      <br />
                    <li><b>• Diphlu River Lodge:</b> An eco-friendly lodge near Kaziranga National Park, offering a unique wildlife experience.</li>      <br />
                    <li><b>• Kakochang Waterfall:</b> A picturesque waterfall near Kaziranga, perfect for a relaxing picnic.</li>      <br />
                    <li><b>• Gibbon Wildlife Sanctuary:</b> A lesser-known sanctuary home to the hoolock gibbon and other endangered species.</li><br />
                  </ul>
                  <br />
                  <h4>CHERRAPUNJEE </h4>
                  <li><b>Mawmluh Cave:</b> A lesser-known cave near Cherrapunjee, offering a unique underground experience.</li><br />
                  <li><b>Nongriat Village:</b> A remote village famous for its living root bridges and scenic trekking trails.</li><br />
                  <li><b>Mawkdok Dympep Valley:</b> A scenic valley between Cherrapunjee and Shillong, with stunning views of hills and valleys.</li><br />
                  <li><b>Nartiang Monoliths:</b> Ancient monoliths from the 15th century, a must-visit for history buffs.</li><br />
                  <li><b>Rainbow Falls:</b> A beautiful waterfall named for the rainbows that form over it, perfect for nature lovers and photographers.</li><br />
                </div>
              </div>
              <br /><br />


              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <strong>Frequently Ask <span className="tomato">Questions</span></strong>
                      </h3>
                      <h4><strong>GUWAHATI SHILLONG CHERRAPUNJEE KAZIRANGA PACKAGE</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the Bihu Festival in North East Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Bihu is a famous festival in Assam, celebrated every year on April 14th to welcome the Assamese New Year, called Bohag Bihu. The festival features music, dance, local food, and drinks. It's celebrated across Assam and nearby states like Meghalaya and Arunachal Pradesh. The celebration lasts for about a month, with traditional Bihu dance performances held in various parts of the state.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How to reach the Single-Decker Living Root Bridge in Riwai?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The Single-Decker Living Root Bridge in Riwai is spectacular. To get there, take the Shillong-Mawlynnong road, about 75 km and a 2.5-hour drive from Shillong. After reaching Mawlynnong, trek 1 km through the forest to reach the bridge. You can stay in local homestays and enjoy the serene environment. Remember to pack essentials like a backpack, warm clothes, a raincoat, mosquito repellent, a torch, and a power bank.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Is Kaziranga National Park worth visiting?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, Kaziranga National Park is a UNESCO World Heritage site and is famous for its one-horned rhinoceroses. An elephant safari offers a close view of various animals like water buffalo, wild hogs, deer, wild elephants, and many bird species. It's a must-visit for wildlife enthusiasts.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          What food should I try during my Meghalaya Trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try local Assamese dishes like Laksa, Khar, Tenga, and Mati Dal. Popular dining spots include Anurag Dhaba on the way to Kaziranga, Punjab Da Dhaba on the way to Meghalaya, and street food at Police Bazaar in Shillong. In Cherrapunjee, try Jiva Veg Restaurant or Orange Roots Restaurant.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What is the best family dining place in Kaziranga Tour Plan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try the Brahmaputra River Cruise for a unique dining experience. Book in advance or ask your tour operator to arrange it. Other options include Naga Kitchen and Paradise, or street food in Paltan Bazar for authentic Assamese cuisine.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          How can I book a car for a full day Mawlynnong, Dawki Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can book a car from Guwahati to Shillong and from Shillong to Mawlynnong and Dawki. For a hassle-free experience, book through a specialized Northeast India tour operator.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Are there any government transport facilities for Shillong sightseeing?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, you can book through MTDC (Meghalaya Tourism Development Corporation), which offers coach services for sightseeing. Contact their Tourist Information Centre at Police Bazaar, Ph: 0364-2226220.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          When is the best time to visit Nohkalikai Falls?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to visit Nohkalikai Falls is during the monsoon (May to September) for the fullest view of the falls or from October to January for trekking and enjoying the scenery.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          How many Root Bridges are found in Meghalaya?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Meghalaya has about 11 living root bridges, including the famous Double Decker Root Bridge in Cherrapunjee, Ummunoi Root Bridge, Ritymmen Root Bridge, Mawsaw Root Bridge, and Mawlynnong Single-Decker Root Bridge.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          What are the best things to do in Cherrapunjee?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>• Capture the scenic beauty<br />
                            • Bird watching<br />
                            • Walks through beautiful streets<br />
                            • Shopping for handmade and bamboo items<br />
                            • Enjoy local delicacies<br />
                            • Hike the Double Decker Root Bridge</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          What food should I try in Cherrapunjee during the Kaziranga Shillong Cherrapunjee Tour Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try local dishes like Pork Rice, Sohra Pulao, Pork Momos, various pork delicacies, Thukpa, Rice Beer, and Jadoh (rice cooked in pork blood).</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Do we need a permit to visit Cherrapunjee?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>No, Indian citizens do not need a permit, but you must carry a valid ID card.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          What kind of clothes should we pack for a North East Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>In summer, bring comfortable clothes and rain gear (raincoat, umbrella, rain shoes). In winter, pack heavy woolen garments as it gets very cold.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          How can I offer Puja at Kamakhya Temple avoiding rush?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Get a VIP Pass for Rs. 500 per person from the counter, which opens at 7:00 A.M. This will reduce waiting time, and you can wait in the VIP room until it's your turn.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          What food should I taste on a Shillong trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Try the local pineapple and corn. Enjoy street food at Police Bazaar, including barbeque chicken, fish, pork, momos, and more.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Can I enjoy solo kayaking in Umiam Lake and Dawki River?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, solo kayaking is available. You can book tickets yourself. Dawki River offers two types of boat services: government and private, costing around Rs. 6,000 per boat for one hour.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeventeen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeventeen"
                        >
                          Can I enter Bangladesh via Dawki border at Shillong?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeventeen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeventeen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>No, crossing the border is prohibited and monitored closely by the BSF.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEighteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEighteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseEighteen"
                        >
                          What is the best festive season to visit Assam Meghalaya Trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEighteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEighteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to visit Assam is from October to mid-March and Meghalaya from October to May. Enjoy festivals like:</p>
                          <p>• Khasi Festival in April<br />
                            • Jaintia Festival in July<br />
                            • Garo Festival in November<br />
                            • Autumn Festival in October</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Kaziranga Shillong Cherrapunjee Package Tour <span className="tomato"> Reviews</span>
                        </b>
                      </h3>
                      <p>
                        <i>"Your Happiness is our Priority"</i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-96747-38480">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div >
      </>
    );
  }
}
export default KazirangaShillongMeghalayaTour_7N_8D_Package;
