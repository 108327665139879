import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSION: <strong>BANGALORE TO BHUTAN PACKAGE</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Transfer all on a private basis
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Sightseeing as per the itinerary point-to-a-point basis.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Pick up and drop from Bagdogra Airport (IXB).</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Accommodation in all places.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Meal plan: Breakfast & Dinner.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One bottle of Mineral Water per head per day during sightseeing.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Professional English-Speaking Guide.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One tourist SIM card on Arrival for a family.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;SDF Per person per night - per head - Rs. 1200/-</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;24x7 Operation Executive Support</li>
                </ul><br/><br/><br/>
                <h4 className='tomato'><u>EXCLUSION: <strong>BHUTAN TOUR FROM BANGALORE </strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Extras of a personal nature include personal tips, laundry charges, and additional tour charges.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Does not include any Lunch & Evening Tea/snacks.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Travel Insurance.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Insurance, Medical & Emergency rescue costs.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Does not include Hard drinks or liquor or soft drinks/juices.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Does not include Entrance Fees at Monasteries / Monuments /Museums etc.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Air Fare / Train Tickets.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Additional sightseeing or extra usage of vehicles, other than those mentioned in the itinerary.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any cost arising due to natural calamities like landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot).</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any increase in taxes or fuel price, leading to an increase in cost on surface transportation & land arrangements, which may come into effect prior to departure.</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion;