import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSION : <strong>ARUNACHAL PACKAGE TOUR FROM MUMBAI</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All transfer and local sightseeing arranged by one exclusive private vehicle as per itinerary.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Driver’s allowance, fuel, toll, parking
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Meal as mentioned in Table</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Hotels as mentioned above</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Permit fees & assistance for Arunachal</li>
                    
                </ul>
                <br/><br/><br/>
                <h4 className='tomato'><u>EXCLUSION : <strong>ARUNACHAL TOUR PACKAGE FROM MUMBAI</strong> </u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All items which are not included in inclusion list</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Air/train fares</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Entry fees, camera permit, Ferry rides,Tour guide</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Laundry, Tips, Phone Call, Porter or any personal expense</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any cost arising due to any unavoidable circumstances or changes in program by guests</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Bumla Pass Excursion cost as it depends on Army Permit basis / Weather conditions</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Applicable 5% GST</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;