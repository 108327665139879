import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ApiURL from "../../../api/ApiURL";

class IndividualIntervalsExample extends Component {
    
  render() {
    const slideimage1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-chartered-flight-1.webp";
    const slideimage2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-chartered-flight-6.webp";
    const slideimage3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-chartered-flight-2.webp";
    const slideimage4ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-chartered-flight-5.webp";
    const slideimage5ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-chartered-flight-4.webp";
   
    return (
      <Carousel fade>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={slideimage1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Bhutan Tour Package from Mumbai with Direct Flight</h3>
            {/* <p className='carousel-text-p'>Fly directly from Bangalore to Bhutan for a faster, more convenient travel experience</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={slideimage2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Bhutan Group tour from Mumbai</h3>
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={slideimage3ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Bhutan Group tour Package from Mumbai</h3>
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={slideimage4ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Mumbai to Bhutan Direct Flight</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={slideimage5ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Bhutan Group tour from Mumbai</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default IndividualIntervalsExample;
