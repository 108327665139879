import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Arunachal_Pradesh/InclusionExclusion_grouparunachalguwahati_7n_8d';

class ArunachalGroupTourGuwahati_7N_8D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919830889567&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-group-tour-packages-from-guwahati-airport.webp";
        const packageTopBanner2ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/ARUNACHAL-GROUP-TOUR-MOBILE.webp";
        const navDay1ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-bhalukpong.webp";
        const navDay2ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-dirang.webp";
        const navDay3ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-tawang.webp";
        const navDay6ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-bomdila.webp";
        const navDay5ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-dirang.webp";
        const navDay7ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-nameri.webp";
        const navDay9ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-ziro.webp";
        const navDay10ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-ziro-valley.webp";
        const navDay12ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-aalo.webp";
        const navDay14ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-with-mechuka.webp";

        const sightseen1ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati.webp";
        const sightseen2ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-from-guwahati.webp";
        const sightseen3ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-package-from-guwahati.webp";
        const sightseen4ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-cost-from-guwahati.webp";
        const sightseen5ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-from-guwahati-airport.webp";
        const sightseen6ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-pradesh-package-tour-from-guwahati-airport.webp";
        const sightseen7ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-pradesh-tour-package-from-guwahati-airport.webp";
        const sightseen8ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-from-guwahati.webp";
        const sightseen9ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-package-from-guwahati-airport.webp";
        const sightseen10ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-from-guwahati-with-flight.webp";
        const sightseen11ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-itinerary-from-guwahati.webp";
        const sightseen12ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-itinerary-from-guwahati.webp";
        const sightseen13ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-plan-from-guwahati.webp";
        const sightseen14ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/guwahati-to-arunachal-package-tour.webp";
        const sightseen15ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/guwahati-to-arunachal-package-tour-booking.webp";
        const sightseen16ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-group-packages-from-guwahati.webp";
        const sightseen17ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-pradesh-tour-package-cost-from-guwahati-airport.webp";
        const sightseen18ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/explore-arunachal-pradesh-from-guwahati.webp";
        const sightseen19ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-package-tour-booking-from-guwahati.webp";
        const sightseen20ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-pradesh-package-tour-itinerary-from-guwahati.webp";
        const CallButtonSonaliImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/call-button-north-east-adorable-vacation.webp";
        const Adventure1ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-with-adventure-1.webp";
        const Adventure2ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-with-adventure-2.webp";
        const Adventure3ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-with-adventure-3.webp";
        const valley1ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-with-valley-1.webp";
        const valley2ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-with-valley-2.webp";
        const valley3ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachal-tour-with-valley-3.webp";
        const Review1ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachak-package-tour-from-guwahati-review-1.webp";
        const Review2ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachak-package-tour-from-guwahati-review-2.webp";
        const Review3ImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/arunachak-package-tour-from-guwahati-review-3.webp";
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Arunachal_7N_8D_group_guwahatiPackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img
                            src={packageTopBannerImageURL}
                            className="mobile-image2"
                            alt="Arunachal Pradesh Group Package Tour from Guwahati"
                        />
                    </div>
                </div>

                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Arunachal Pradesh Group Package Tour from Guwahati</strong> - BEST DEAL 2025</center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center><strong>Arunachal Pradesh Group Package Tour from Guwahati</strong> - BEST DEAL 2025</center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <a href="tel:9674738480"><img src={packageTopBanner2ImageURL} className="mobile-image" alt="Arunachal Pradesh Group Tour from Guwahati" /></a>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                6 Places:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Guwahati
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Bhalukpong
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Bomdila
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Tawang
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Dirang
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Bumla Pass
                                                </a>
                                            </div>
                                        </div>
                                        <br />
                                        <p className="p-2">
                                            Embark on an unforgettable adventure with the <strong>Arunachal Pradesh group package tour from Guwahati</strong>, offering a seamless journey through the mesmerizing landscapes of Bhalukpong, Bomdila, Dirang, and Tawang. Nestled in the northeastern corner of India, Arunachal Pradesh is a paradise for nature lovers and thrill-seekers, featuring snow-capped mountains, lush forests, and serene lakes. <br /><br />

                                            Starting from Guwahati, the gateway to the Northeast, this tour ensures a hassle-free and comfortable experience. From accommodations and transportation to meals and expert guidance, every detail is taken care of. Explore the iconic destinations of Tawang, Bomdila, and Dirang, while immersing yourself in the rich tribal cultures and breathtaking natural beauty. <br /><br />

                                            With Adorable Vacation's curated <a href="https://adorablevacation.com/arunachal-pradesh-package-tour-from-guwahati/" target="_blank"><strong>Arunachal Pradesh package tour from Guwahati</strong></a>, you can enjoy a personalized itinerary that balances adventure and relaxation. Whether you're trekking through scenic valleys or discovering ancient monasteries, this tour promises an experience like no other. Book your <strong>Arunachal Pradesh group package tour from Guwahati</strong> today and get ready to create memories that will last a lifetime!<br /><br />
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong className='tomato'>ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI AIRPORT</strong>?<br /><br />
                                            FOR BOOKING <strong className='tomato'>ARUNACHAL GROUP TOUR FROM GUWAHATI</strong> <a href="tel:+91-9674738480"> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong className='tomato'>ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI AIRPORT</strong>?<br /><br />
                                            FOR BOOKING <strong className='tomato'>ARUNACHAL GROUP TOUR FROM GUWAHATI</strong> <a href="tel:+91-9674738480"> ☎ CALL +91-96747-38480</a><br /><br /></h3>
                                        <br />

                                        <div className="card">
                                            <div
                                                className="card-header custHeaer"
                                            >
                                                <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> TAKE A LOOK FOR <strong>ARUNACHAL PRADESH GROUP TOUR FROM GUWAHATI</strong></h3>
                                                <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> TAKE A LOOK FOR <strong>ARUNACHAL PRADESH GROUP TOUR FROM GUWAHATI</strong></h4>
                                            </div>
                                            <div className="card-body">
                                                <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody>
                                                            <tr class="table-danger"><td>Duration of <strong>Arunachal Pradesh Group Tour Package</strong></td><td>7 Nights / 8 Days</td></tr>

                                                            <tr><td>Destinations Covered in <strong>Arunachal Group Departure</strong></td><td>Bhalukpong 1N | Dirang 1N | Tawang 3N | Bomdila 1N | Guwahati 1N</td></tr>

                                                            <tr><td><strong>Arunachal Pradesh Group Tour Date</strong></td>
                                                                <td>
                                                                    <p>Group 1  : 19th Apr to 26th April 2025</p>
                                                                    <p>Group 2  : 26th Apr to 3rd May 2025</p>
                                                                    <p>Group 3  : 10th May to 17th May 2025</p>
                                                                    <p>Group 4  : 17th May to 24th May 2025</p>
                                                                </td></tr>

                                                            <tr><td>7N/8D <strong>Arunachal Group Package Cost from Guwahati</strong></td><td><strong>₹ </strong>31,500/Per Person</td></tr>

                                                            <tr><td>Pickup & Drop Point for <strong>Arunachal Group Departure</strong></td><td>Guwahati Airport [GAU]</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                                <table className='img-center'>
                                                    <tbody>
                                                        <tr>
                                                            <td className="image-cell">
                                                                <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Arunachal Pradesh Package Tour Cost" /></a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br /><br />
                                            </div>
                                        </div>
                                        <br /><br /><br />
                                        <div className="card">
                                            <div
                                                className="card-header custHeaer"
                                            >
                                                <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> EXPLORE THE UNTOUCHED PARADISE OF ARUNACHAL PRADESH WITH ADORABLE VACATION</h3>
                                                <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> EXPLORE THE UNTOUCHED PARADISE OF ARUNACHAL PRADESH WITH ADORABLE VACATION</h4>
                                            </div>
                                            <div className="card-body">
                                                <p>If you're dreaming of an extraordinary getaway, the <strong>Arunachal Pradesh group package tour from Guwahati</strong> is the ultimate adventure. Starting at Guwahati Airport, this carefully curated journey winds through the enchanting landscapes of Arunachal Pradesh, ending at Dibrugarh Airport. Along the way, you'll explore iconic destinations like Bhalukpong, Bomdila, Tawang, Dirang, Ziro, Pasighat, Aalo, and the breathtaking Mechuka Valley. Known as the "Land of the Rising Sun," Arunachal Pradesh offers an unmatched blend of natural beauty, cultural richness, and serene landscapes, making it a must-visit destination. <br /><br />

                                                    A highlight of the <strong>Arunachal Pradesh group tour packages from Guwahati Airport</strong> is Mechuka, a serene and pristine village located in the westernmost part of Arunachal Pradesh. Known for its unspoiled beauty, this hidden gem is surrounded by snow-capped peaks, crystal-clear rivers, and lush valleys. The peaceful ambiance of Mechuka, coupled with its unique tribal culture, offers a perfect retreat for those seeking tranquility and an authentic experience of Arunachal's untouched charm. <br /><br />

                                                    <b>Tawang</b>, another star attraction in the <strong>Arunachal group package tour from Guwahati</strong>, enchants visitors with its majestic Tawang Monastery, the largest Buddhist monastery in India. Perched at an altitude of 10,000 feet, the monastery exudes spiritual tranquility and architectural grandeur. Nearby, the mesmerizing Madhuri Lake (Sangetsar Lake) captivates with its ethereal beauty and serene surroundings, making it a must-visit for nature lovers and photographers alike. The <strong>Arunachal Pradesh group tour package from Guwahati Airport</strong> also includes visits to Bomdila, renowned for its panoramic Himalayan views, apple orchards, and vibrant handicrafts. <br /><br />

                                                    <b>Ziro</b>, a UNESCO World Heritage Site, is another gem on this journey, famous for its lush landscapes and the vibrant Ziro Music Festival that celebrates Arunachal's rich cultural tapestry. The <strong>Arunachal group departure from Guwahati</strong> also feature stops at Nameri National Park, Itanagar's historic landmarks, and Guwahati's sacred Maa Kamakhya Temple, blending spiritual discovery with natural exploration. <br /><br />

                                                    With seamless travel, comfortable accommodations, and expert-guided tours, the <strong>Arunachal tour packages from Guwahati in group</strong> offer a hassle-free and enriching experience. Whether you're captivated by Mechuka's untouched beauty, Tawang's cultural heritage, or Bomdila's Himalayan vistas, the <strong>Arunachal Pradesh group departure from Guwahati Airport</strong> promise a journey filled with unforgettable memories. Embark on this remarkable adventure with Adorable Vacation and let Arunachal's magic leave you spellbound. <br /><br />
                                                    Apart from this <strong>Arunachal group tour package</strong>, we also offer <a href="https://adorablevacation.com/arunachal-pradesh-package-tour-from-guwahati/" target="_blank"><strong>Arunachal Pradesh package tour from Guwahati</strong></a>, <a href="https://adorablevacation.com/arunachal-pradesh-package-tour-from-mumbai/" target="_blank"><strong>Arunachal Pradesh package tour from Mumbai</strong></a>, <a href="https://adorablevacation.com/arunachal-pradesh-package-tour-from-kolkata/" target="_blank"><strong>Arunachal package tour from Kolkata</strong></a> at Best Price. So, for any <strong>Arunachal Pradesh tour plan</strong>, Think Adorable Vacation - <strong>Arunachal tour specialist</strong>.</p>
                                                <br /><br />
                                                <table className="image-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="image-cell">
                                                                <img src={sightseen1ImageURL} alt="Arunachal tour packages from Guwahati in group" />
                                                                <img src={sightseen2ImageURL} alt="Arunachal Pradesh group departure from Guwahati Airport" />
                                                                <img src={sightseen3ImageURL} alt="Arunachal group departure from Guwahati" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br /><br /><br />
                                                <table className='img-center'>
                                                    <tbody>
                                                        <tr>
                                                            <td className="image-cell">
                                                                <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="ARUNACHAL GROUP TOUR PACKAGE" /></a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>ARUNACHAL GROUP TOUR PACKAGE</strong> : 7N | 8D</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> DURATION FOR <strong>ARUNACHAL GROUP TOUR PACKAGE</strong> : 7N | 8D</h4>
                                </div>
                                <div className="card-body">
                                    <br />
                                    <h4>DESTINATIONS COVERED IN <strong className='tomato'>ARUNACHAL GROUP TOUR STARTING FROM GUWAHATI AIRPORT</strong> : BHALUKPONG 1N | BOMDILA 1N | DIRANG 1N | TAWANG 3N | GUWAHATI 1N</h4><br />
                                    <h4>PICKUP & DROP POINT FOR <strong className='tomato'>ARUNACHAL GROUP TOUR PACKAGES</strong> : LOKPRIYA GOPINATH BORDOLOI INTERNATIONAL AIRPORT [GAU]
                                    </h4>
                                    <br /><br />
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead class="cf"><tr class="table-danger">
                                                <th>CITY</th>
                                                <th>ALTITUDE</th>
                                                <th>CO-ORDINATES</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Guwahati</td><td>51 m</td><td>26.1158° N, 91.7086° E</td></tr>
                                                <tr><td>Bhalukpong</td><td>213 m</td><td>27.0137° N, 92.6345° E</td></tr>
                                                <tr><td>Bomdila</td><td>2,415 m</td><td>27.2645° N, 92.4159° E</td></tr>
                                                <tr><td>Dirang</td><td>1,620 m</td><td>27.3584° N, 92.2409° E</td></tr>
                                                <tr><td>Tawang</td><td>3,048 m</td><td>27.6325° N, 91.7539° E</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />  <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="Arunachal Pradesh Tour Cost" />
                                                    <img src={sightseen11ImageURL} alt="arunachal pradesh tour itinerary" />
                                                    <img src={sightseen12ImageURL} alt="ARUNACHAL GROUP DEPARTURE FROM GUWAHATI" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE PLAN FOR <strong>ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE PLAN FOR <strong>ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <b>DAY 1 → </b>
                                                        <span>
                                                            GUWAHATI TO BHALUKPONG
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <b>DAY 2 → </b>
                                                        <span>BHALUKPONG TO DIRANG</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <b>DAY 3 → </b>
                                                        <span> DIRANG TO TAWANG</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <b>DAY 4 → </b>
                                                        <span>TAWANG - BUMLA PASS - TAWANG</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <b>DAY 5 → </b>
                                                        <span>TAWANG LOCAL SIGHTSEEING</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <b>DAY 6 → </b>
                                                        <span>TAWANG TO BOMDILA</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <b>DAY 7 → </b>
                                                        <span>BOMDILA TO GUWAHATI</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingEight"
                                                    >
                                                        <b>DAY 8 → </b>
                                                        <span>DROP AT GUWAHATI AIRPORT</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br /><br />
                                        <table className='img-center'>
                                            <tbody>
                                                <tr>
                                                    <td className="image-cell">
                                                        <img src={Review2ImageURL} alt="ARUNACHAL GROUP DEPARTURE FROM GUWAHATI" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS : <strong>ARUNACHAL GROUP DEPARTURE FROM GUWAHATI</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS : <strong>ARUNACHAL GROUP DEPARTURE FROM GUWAHATI</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <a href="#" style={{ color: 'black' }}>
                                                            ACCOMMODATION ON DOUBLE SHARING BASIS
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            TRANSFERS AND SIGHTSEEING BY TEMPO TRAVELLER
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            DRIVER'S ALLOWANCE, FUEL, TOLL, AND PARKING CHARGES INCLUDED
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            MINERAL WATER PROVIDED DURING SIGHTSEEING
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            MEAL PLAN : BREAKFAST & DINNER AT HOTELS, LUNCH AT LOCAL RESTAURANTS
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            ONE PROFESSIONAL LICENSED TOUR GUIDE (GUWAHATI TO GUWAHATI)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            BUMLA PASS EXCURSION COST INCLUDED (PASS SUBJECT TO ARMY PERMIT AND WEATHER CONDITIONS)
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            24/7 TOUR ASSISTANCE SERVICES AVAILABLE
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <a href="#" style={{ color: 'black' }}>
                                                            INNER LINE PERMIT (ILP) FEES AND OTHER FORMALITIES INCLUDED
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Arunachal Pradesh group package tour from Guwahati" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /> <br /><br />

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WITH <strong>ARUNACHAL PRADESH GROUP TOUR</strong> DISCOVER A TRUE DREAM DESTINATION</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WITH <strong>ARUNACHAL PRADESH GROUP TOUR</strong> DISCOVER A TRUE DREAM DESTINATION</h3>
                                </div>
                                <div className="card-body">
                                    <p>Arunachal Pradesh, often called the "<b>Land of Dawn-Lit Mountains</b>" is a breathtaking destination that combines unspoiled natural beauty, rich cultural heritage, and thrilling adventures, making it a must-visit for travelers. If you're looking for a budget-friendly way to explore this northeastern gem, the <strong>Arunachal Pradesh group package tour from Guwahati</strong> offers an excellent opportunity to discover the best of this enchanting state. With its stunning landscapes, spiritual sites, and unique tribal cultures, this group tour is perfect for travelers seeking an unforgettable experience at an affordable price. <br /><br />


                                        The <strong>Arunachal Pradesh group package tour from Guwahati Airport</strong> covers some of the most iconic destinations in the state. One of the highlights of the tour is the majestic Tawang Monastery, which stands as the largest Buddhist monastery in India and the second largest in the world. Located at a height of 10,000 feet, it offers breathtaking views and serves as a peaceful retreat for spiritual seekers. Along with Tawang, you'll also visit Bomdila, a town surrounded by stunning hills and valleys that are perfect for nature lovers, and Dirang, a serene location known for its lush greenery and picturesque landscape. The Bumla Pass near the Indo-China border is another thrilling stop on this tour, offering not only a unique historical perspective but also an exciting adventure for those interested in exploring the region's strategic significance. <br /><br />


                                        With an <strong>Arunachal Pradesh Group Departure</strong>, you'll enjoy the benefits of traveling with a group, including lower costs compared to private trips. This makes it an ideal choice for those looking to explore Arunachal Pradesh in a more affordable yet comprehensive way. Adorable Vacation ensures that every aspect of your journey is well-organized, from accommodation and transportation to expert guidance and seamless travel arrangements. Whether you're trekking through scenic valleys, exploring ancient monasteries, or immersing yourself in local tribal traditions, you'll have an enriching experience that blends adventure and tranquility. <br /><br />


                                        From the stunning beauty of the Sela Pass to the cultural richness of the tribal villages, this <strong>Arunachal group package tour from Guwahati Airport</strong> offers a perfect balance of nature, culture, and adventure. Whether you're an adventure enthusiast, a nature lover, or someone looking to explore a rich cultural heritage, Arunachal Pradesh has something for everyone. So, if you're ready for a life-changing journey, book your spot on the <strong>Arunachal Pradesh group package tour from Guwahati</strong> and create memories that will last a lifetime. <br /><br /></p>
                                    <br />
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="Arunachal Pradesh group package tour from Guwahati" />
                                                    <img src={sightseen5ImageURL} alt="ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI AIRPORT" />
                                                    <img src={sightseen6ImageURL} alt="Arunachal group package tour" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES OF ATTRACTIONS IN <strong>ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI AIRPORT</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES OF ATTRACTIONS IN <strong>ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI AIRPORT</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead class="cf">
                                                <tr>
                                                    <th>PLACES</th>
                                                    <th>ATTRACTIONS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><b>Guwahati</b></td><td>Assam State Zoo cum Botanical Garden, Kaziranga National Park, Haflong, Guwahati Planetarium, Guwahati Zoo, Umananda Island, Chandubi Lake, Dispur, Dipor Bil, Umananda Temple, Kamakhya Temple, ISKCON Guwahati, Guwahati War Memorial etc.</td></tr>

                                                <tr><td><b>Bhalukpong</b></td><td>Sessa Orchid Sanctuary, Pakhui Wildlife Sanctuary, Bhalukpong Fort, Gontse Garden Rabgye Ling etc.</td></tr>

                                                <tr><td><b>Bomdila</b></td><td>Bomdila Monastery, Apple Orchards, Bomdila View Point, Eaglenest Wildlife Sanctuary, Tipi Orchid Reserve, Upper Gompa, Lower Gompa, R.R Hills etc.</td></tr>

                                                <tr><td><b>Tawang</b></td><td>Kameng River, Jaswant Garh, PT Tso Lake, Gorichen Peak, Tawang War Memorial, Tawang War Memorial, Madhuri Lake, Tawang Monastery, Sela Pass etc.</td></tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen13ImageURL} alt="Arunachal group tour package from Guwahati" />
                                                    <img src={sightseen14ImageURL} alt="Arunachal Pradesh tour from Guwahati" />
                                                    <img src={sightseen15ImageURL} alt="Arunachal group departure" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY ADORABLE VACATION IS BEST <strong>ARUNACHAL GROUP TOUR OPERATOR FROM GUWAHATI</strong>
                                    </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY ADORABLE VACATION IS BEST <strong>ARUNACHAL GROUP TOUR OPERATOR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Pre-Purchased Room Inventory</b>: We offer a diverse range of pre-booked rooms in Arunachal Pradesh, guaranteeing you the best valley, mountain, or river-view rooms based on your booking date for the <strong>Arunachal group package tour</strong>.<br /><br /></p>

                                    <p><b>Premium Stays</b>: Enjoy a luxurious stay with our selection of Premium & Deluxe Hotels and Luxury Home Stays, offering the finest amenities for a comfortable and memorable <strong>Arunachal group tour</strong> experience.<br /><br /></p>

                                    <p><b>Seamless Transport</b>: Your <strong>Arunachal group tour package from Guwahati</strong> will include well-managed transportation options from key locations like Guwahati, Tezpur, Dibrugarh, Itanagar, and Jorhat, ensuring smooth and hassle-free travel.<br /><br /></p>

                                    <p><b>Vehicle Variety</b>: Depending on your budget and preferences, choose from a range of vehicles such as Innova, Ertiga, Scorpio, or XUV 500 (Sunroof) to make your <strong>Guwahati to Arunachal group tour</strong> comfortable and convenient.<br /><br /></p>

                                    <p><b>Dedicated Operations Team</b>: We have a dedicated operations team based in the Northeast, ensuring uninterrupted support throughout your <strong>Arunachal group tour from Guwahati</strong>.<br /><br /></p>

                                    <p><b>Expert Guidance</b>: As Arunachal Super Specialists, we guide you through your journey, taking into account peak season rush, weather, and off-season conditions. Your safety and satisfaction are our top priorities, and we always ensure you get the best value for your money.<br /><br /></p>

                                    <p><b>Experience You Can Trust</b>: With over 11 years of experience in organizing <strong>Arunachal group tour packages from Guwahati</strong>, Adorable Vacation offers top-tier accommodations including Deluxe Homestays, Premium Stays, and Luxury Boutique Resorts for a comfortable stay.<br /><br /></p>

                                    <p><b>Personalized Travel Experience</b>: Our team of passionate and experienced travelers personally inspects and certifies every hotel and homestay included in your itinerary, ensuring you have the best experience during your <strong>Arunachal Pradesh tour from Guwahati</strong>.<br /><br /></p>

                                    <p><b>Group Tours Expertise</b>: We specialize in conducting <strong>Arunachal Pradesh group tour</strong>. Every year, we host group departures during peak seasons like Summer Vacations, New Years Holidays, offering affordable and enjoyable travel for groups.<br /><br /></p>

                                    <p><b>Complete Assistance</b>: From start to finish, our team provides day-to-day guidance and on-ground assistance during your <strong>Arunachal group departure</strong>. We handle everything ourselves, with no third-party sub-agents involved, ensuring the highest level of service and attention throughout your trip.<br /><br /></p>

                                    <br />
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen16ImageURL} alt="ARUNACHAL GROUP TOUR FROM GUWAHATI" />
                                                    <img src={sightseen17ImageURL} alt="Arunachal group tour cost from Guwahati" />
                                                    <img src={sightseen18ImageURL} alt="Arunachal group tour cost from Guwahati" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>TRAVEL DISTANCES TO NOTE FOR <strong>ARUNACHAL GROUP TOUR FROM GUWAHATI</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>TRAVEL DISTANCES TO NOTE FOR <strong>ARUNACHAL GROUP TOUR FROM GUWAHATI</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead class="cf"><tr class="table-danger"><th>FROM</th><th>TO</th><th>DISTANCE</th></tr></thead><tbody>
                                                <tr><td>Guwahati</td><td>Bhalukpong</td><td>5 hr 9 min (225.7 km) via NH 15</td></tr>
                                                <tr><td>Guwahati</td><td>Kaziranga</td><td>4 hr 16 min (192.8 km) via NH27</td></tr>
                                                <tr><td>Bhalukpong</td><td>Dirang</td><td>4 hr 49 min (138.4 km) via Chariduar - Tawang Rd</td></tr>
                                                <tr><td>Bhalukpong</td><td>Tezpur</td><td>1 hr 12 min (57.7 km) via Chariduar - Tawang Rd and NH 15</td></tr>
                                                <tr><td>Dirang</td><td>Sangti Valley</td><td>31 min (13.5 km) via NH13</td></tr>
                                                <tr><td>Dirang</td><td>Tawang</td><td>4 hr 46 min (134.5 km) via NH13</td></tr>
                                                <tr><td>Tawang</td><td>Bomdila</td><td>5 hr 57 min (175.7 km) via NH13</td></tr>
                                                <tr><td>Bomdila</td><td>Sangti Valley</td><td>1 hr 30 min (49.6 km) via NH13</td></tr>
                                                <tr><td>Bomdila</td><td>Tezpur</td><td>4 hr 46 min (153.9 km) via Chariduar - Tawang Rd</td></tr>
                                                <tr><td>Bomdila</td><td>Guwahati</td><td>7 hr 16 min (269.9 km) via NH 15</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Arunachal Pradesh group tour from Guwahati" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY TRAVELLING FROM GUWAHATI AIRPORT IS THE BEST OPTION TO START <strong>ARUNACHAL GROUP TOUR</strong>?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY TRAVELLING FROM GUWAHATI AIRPORT IS THE BEST OPTION TO START <strong>ARUNACHAL GROUP TOUR</strong>?</h3>
                                </div>
                                <div className="card-body">
                                    <p>Guwahati Airport is the perfect starting point for your <strong>Arunachal Pradesh group tour from Guwahati</strong>, thanks to its excellent connectivity, affordability, and convenient location. As a major hub in the Northeast, it offers seamless flight connections from key cities like <b>Mumbai</b>, <b>Bangalore</b>, <b>Chennai</b>, <b>Pune</b>, <b>Delhi</b>, and <b>Kolkata</b>, making it easily accessible for travelers across India. Choosing a <strong>Guwahati to Arunachal tour by Flight</strong> ensures a quick and comfortable journey to the beautiful landscapes of Arunachal Pradesh. <br /><br />

                                        The <strong>Arunachal group tour cost from Guwahati</strong> is incredibly budget-friendly, with multiple airlines offering competitive prices. Whether you fly with <b>Air India</b>, <b>IndiGo</b>, or <b>SpiceJet</b>, you'll find affordable flight options that suit your schedule, making it easy to plan your trip. Additionally, with expert <strong>Arunachal Tour Operators from Guwahati</strong> like Adorable Vacation managing your itinerary, you can expect a smooth, hassle-free experience from the moment you arrive. The airport's proximity to major destinations in Arunachal Pradesh, combined with convenient transport options, ensures that your <strong>Arunachal Pradesh tour</strong> starts off efficiently and comfortably.<br /><br /><br /></p>

                                    <h3 className='tomato1'>FLIGHTS TO CHOOSE FROM DIFFERENT CITIES FOR <strong>ARUNACHAL PRADESH GROUP PACKAGE TOUR BOOKING FROM GUWAHATI AIRPORT</strong></h3>

                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead class="cf"><tr class="table-danger"><td>AIRLINES</td><td>ORIGIN</td><td>DESTINATION</td><td>DURATION</td></tr></thead>
                                            <tbody><tr><td>IndiGo</td><td>Kolkata [07:25]</td><td>Guwahati [08:45]</td><td>01 h 20 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Kolkata [10:05]</td><td>Guwahati [11:55]</td><td>01 h 50 m (non-stop)</td></tr>
                                                <tr><td>Air India</td><td>Kolkata [05:45]</td><td>Guwahati [07:00]</td><td>01 h 15 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Mumbai [06:25]</td><td>Guwahati [09:20]</td><td>02 h 55 m (non-stop)</td></tr>
                                                <tr><td>SpiceJet</td><td>Mumbai [06:45]</td><td>Guwahati [10:00]</td><td>03 h 15 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Delhi [06:05]</td><td>Guwahati [08:25]</td><td>02 h 20 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Delhi [07:15]</td><td>Guwahati [09:40]</td><td>02 h 25 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Delhi [05:00]</td><td>Guwahati [07:20]</td><td>02 h 20 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Delhi [07:25]</td><td>Guwahati [09:45]</td><td>02 h 20 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Bangalore [08:25]</td><td>Guwahati [11:20]</td><td>02 h 55 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Bangalore [08:20]</td><td>Guwahati [11:20]</td><td>03 h 00 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Chennai [05:30]</td><td>Guwahati [08:15]</td><td>02 h 45 m (non-stop)</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={Review1ImageURL} alt="ARUNACHAL PRADESH GROUP PACKAGE TOUR BOOKING FROM GUWAHATI AIRPORT" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST TIME TO BOOK <strong>ARUNACHAL PRADESH GROUP DEPARTURE FROM GUWAHATI</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST TIME TO BOOK <strong>ARUNACHAL PRADESH GROUP DEPARTURE FROM GUWAHATI</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Planning a <strong>trip to Arunachal Pradesh in Group</strong> involves choosing the best time to visit, as the weather and landscapes vary throughout the year. Here's a season-by-season guide to help you select the ideal time for your <strong>Arunachal Pradesh group package tour from Guwahati</strong>:<br /><br /><br /></p>

                                    <h4 className='tomato1'>SUMMER [MAR - JUN]</h4>
                                    <p>Summer is one of the most favorable times to book an <strong>Arunachal group tour from Guwahati</strong>. The weather is pleasant, with daytime temperatures ranging from 15°C to 25°C, making it perfect for outdoor activities. During this period, the lush green landscapes of Arunachal Pradesh come to life, offering stunning views of mountains, valleys, and lakes. Key destinations such as <b>Tawang</b>, <b>Bomdila</b>, <b>Mechuka</b>, and <b>Ziro</b> are ideal for sightseeing, trekking, and cultural experiences. The demand for <strong>Arunachal group travel packages from Guwahati</strong> peaks during these months, making it a great time to travel with family, friends, or solo. <br /><br /><br /></p>


                                    <h4 className='tomato1'>MONSOON [JUL - SEPT]</h4>
                                    <p>The monsoon season brings heavy rainfall to Arunachal Pradesh, turning the region into a lush green paradise. However, this is not the best time for outdoor adventures, such as trekking or road travel, due to the risk of landslides and flooding in some areas. If you're planning an Arunachal Pradesh package tour from Guwahati during the monsoon, it's essential to check weather and road conditions before booking. This is the off-peak season, so <strong>Arunachal group tour from Guwahati</strong> could be more affordable, but it's best suited for travelers who enjoy the monsoon atmosphere and don't mind occasional disruptions. <br /><br /><br /></p>


                                    <h4 className='tomato1'>AUTUMN [OCT - NOV]</h4>
                                    <p>Autumn is another fantastic time to book an Arunachal Pradesh package tour from Guwahati. After the monsoon rains, the weather clears up, with temperatures ranging from 10°C to 20°C. The crisp weather and clear skies make it perfect for trekking, sightseeing, and attending vibrant cultural festivals, especially in places like <b>Ziro</b>. This season provides ideal conditions for exploring the <b>Tawang Monastery</b>, <b>Sela Pass</b>, and <b>Nuranang Falls</b>. The <strong>Arunachal group travel packages from Guwahati</strong> during this time offer great weather and good prices, making it a prime season for an enriching and memorable experience. <br /><br /><br /></p>


                                    <h4 className='tomato1'>WINTER [DEC - FEB]</h4>
                                    <p>Winter in Arunachal Pradesh is cold, with temperatures often dipping below 0°C in high-altitude regions like <b>Tawang</b> and <b>Dirang</b>. For those who love snow and adventure, this is the perfect time to visit. Winter also brings out the beauty of snow-capped mountains and frozen lakes. The <strong>Arunachal group tour from Guwahati</strong> during this period offers a chance to explore stunning landscapes, visit Buddhist monasteries, and relax in cozy homestays or luxury resorts. As this is the peak tourist season, it's recommended to pack warm clothing and book your <strong>Arunachal Pradesh package tour from Guwahati in group</strong> well in advance to secure your spot. <br /><br /><br /></p>
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="Arunachal Pradesh group package tour from Guwahati" />
                                                    <img src={sightseen9ImageURL} alt="Arunachal Pradesh package tour from Guwahati in group" />
                                                    <img src={sightseen8ImageURL} alt="Arunachal group travel packages from Guwahati" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br /><br />

                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>SEASON</th><th>MONTH</th><th>WEATHER</th><th>TEMPERATURE</th></tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>1°C-3°C</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>0°C-3°C</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>3°C-5°C</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>8°C-15°C</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>15°C-22°C</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>20°C-28°C</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>22°C-29°C</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>22°C-29°C</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C-34°C</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>18°C-22°C</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>16°C-20°C</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>5°C-10°C</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Arunachal Group Tour Package from Guwahati" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>

                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                DAY WISE DETAIL ITINERARY FOR <strong className="tomato">ARUNACHAL PRADESH GROUP TOUR PACKAGES FROM GUWAHATI AIRPORT / STATION</strong>
                                            </h3>
                                            <h4>7 Nights 8 Days <strong>Arunachal Group Tour Package from Guwahati</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    <h4>DAY 01: GUWAHATI TO BHALUKPONG </h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>Upon your arrival at Guwahati Airport, you will be picked up and driven to Bhalukpong (260 km), the first stop of your <strong>Arunachal Pradesh group package tour from Guwahati</strong>. Bhalukpong is a picturesque town located by the scenic Kameng River in the West Kameng District, just 56 km from Tezpur. Situated at an altitude of 700 feet above sea level, Bhalukpong is nestled along the dense forests that border the Pakhui Wildlife Sanctuary. This charming town is a haven for wildlife enthusiasts and nature lovers alike, offering stunning scenic beauty, majestic waterfalls, and the tranquil Kameng River, which is surrounded by lush evergreen forests. Bhalukpong is a photographer's paradise, offering plenty of opportunities to capture the region's natural wonders. Enjoy an overnight stay at Bhalukpong, as you begin your exploration of Arunachal Pradesh's captivating landscapes.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Arunachal Pradesh group package tour from Guwahati" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Arunachal group package tour from Guwahati Airport" /></center>
                                                    </div>
                                                    <br /><br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    <h4>DAY 02: BHALUKPONG TO DIRANG</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a hearty breakfast, check out from the hotel and begin your journey towards Dirang, one of the most scenic destinations on your <strong>Arunachal group package tour from Guwahati Airport</strong>. You'll drive through the stunning Sela Pass, which stands at an impressive altitude of 14,000 feet above sea level. The journey to Dirang takes you through winding roads with awe-inspiring views of the Eastern Himalayas, and you'll arrive in Dirang by the afternoon.<br /><br />

                                                        From there, you will head to the Shangti Valley, a tranquil and captivating spot renowned for its migratory Black Necked Cranes. These majestic birds visit the valley between October and May, making it a unique natural attraction for bird watchers and nature enthusiasts. Shangti Valley is considered one of the most beautiful valleys in the Eastern Himalayan Zone, offering panoramic views that are nothing short of breathtaking.<br /><br />

                                                        During your visit, you'll explore some must-see sites, such as the Tipi Orchidarium, which showcases a wide variety of vibrant orchids, and the apple orchards that bloom with colorful fruit. Additionally, you'll visit the Nag Mandir, a revered temple that adds a cultural dimension to your journey. The serene and picturesque surroundings of Sangti Valley will provide a perfect end to your day. After a day filled with natural beauty and exploration, you'll settle in for an overnight stay at Dirang, ready for more adventures ahead. <br /><br /><br /></p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Arunachal Pradesh group departure" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Arunachal Pradesh fixed departure tour packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 03: DIRANG TO TAWANG</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Day three of your <strong>Arunachal Pradesh group departure</strong> is one of the most exciting and pivotal days of the entire trip, as you head towards the enchanting town of Tawang. Your journey begins with a drive through the breathtaking Sela Pass, which stands at an altitude of 13,720 feet. The pass offers magnificent views of snow-capped peaks and lush valleys, and you'll have the opportunity to stop and enjoy the serene beauty of the Sella Pass and the stunning Paradise Lake, nestled beside a small ridge. It's the perfect spot to capture some memorable photographs.

                                                        As you continue your journey, the road winds down to Jong Village. On the way, you'll make a stop at the <b>Yashwant Garh War Memorial</b>, a tribute to the soldiers who valiantly fought during the 1962 Indo-China War. It's a place of deep historical significance and offers a moment of reflection before continuing your adventure. <br /><br />

                                                        After lunch in Jong Village, the trip resumes towards Tawang. One of the highlights of this leg of the journey is a visit to the magnificent <b>Nuranang Waterfall</b>, also known as the Jang Waterfall. Located just off the main road, Nuranang is one of the most spectacular waterfalls in the region. The waterfall cascades down from a height of around 100 meters, surrounded by lush greenery and a tranquil atmosphere. The sight and sound of the water crashing down into the rocky base is truly awe-inspiring, making it a must-visit on your journey to Tawang. <br /><br />

                                                        After soaking in the beauty of Nuranang, you will continue your drive to Tawang, where you will spend the night. This day promises an unforgettable experience filled with natural beauty, history, and awe-inspiring landscapes. <br /><br /><br /></p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Arunachal Pradesh group departure" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Arunachal Pradesh fixed departure tour packages" /></center>
                                                    </div>
                                                    <br /><br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>Day 04: BUMLA PASS EXCURSION</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>Day 4 of your <strong>Arunachal Pradesh fixed departure tour packages</strong> promises an exciting and scenic adventure. Today, enjoy a full-day excursion that takes you to some of the most iconic spots in the region, starting with the serene PT Tso Lake. Following that, head towards Bumla Pass, a stunning high-altitude location that offers breathtaking views. Next, visit the picturesque Sangetsar Lake, often referred to as Madhuri Lake, after the famous Bollywood actress Madhuri Dixit. This lake, situated about 35 km from Tawang near the China border, is surrounded by dramatic landscapes. The journey to Sangetsar Lake is an exhilarating experience, with scenic roads that still bear remnants of the war bunkers from the 1962 Sino-Indian conflict. After exploring these remarkable sites, drive back to Tawang for an overnight stay, reflecting on the day's unforgettable sights and experiences. This day is truly one of the highlights of your <strong>Arunachal Pradesh Fixed Departure Tour Package</strong>!<br /><br /><br /></p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4>DAY 05: TAWANG LOCAL SIGHTSEEING</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>Today, your <strong>Tawang package tour</strong> takes you on a comprehensive exploration of Tawang, with a focus on its rich cultural and historical landmarks. The day begins with a visit to the renowned Tawang Monastery, a 400-year-old architectural marvel that serves as the spiritual heart of the region. This monastery, one of the largest in India, houses over 500 monks and is home to invaluable Buddhist texts and scriptures. As you walk through its halls, you'll be struck by the striking Tibetan influence in the design, especially the intricately painted wooden windows and other traditional motifs. At the heart of the monastery sits an 8-meter-high gilded Buddha statue, exuding peace and grandeur. You'll also have the opportunity to admire ancient Thankas (traditional Tibetan scroll paintings) and manuscripts, which are treasures of Buddhist art and culture.<br /><br />

                                                        Next, visit the Craft Centre, where you can observe local artisans at work, creating traditional Tawang handicrafts, such as carpets, hand-woven fabrics, and intricate woodwork. This is a perfect opportunity to pick up a unique souvenir to remember your journey. <br /><br />

                                                        In the afternoon, head to the Tawang War Memorial, a poignant tribute to the soldiers who sacrificed their lives during the 1962 Indo-China War. The memorial, surrounded by the beauty of Tawang's natural landscape, stands as a reminder of the region's historical significance. <br /><br />

                                                        The evening is yours to relax and explore at your own pace. You can wander around the peaceful town of Tawang or simply enjoy the beautiful surroundings. After a day filled with rich history and breathtaking sights, you'll return to your accommodation for an overnight stay in Tawang, reflecting on the day's experiences. <br /><br />

                                                        This Tawang sightseeing experience offers a deep dive into the cultural and historical essence of the region, making it an unforgettable part of your Tawang package tour. <br /><br /><br /></p>
                                                    {/* <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="arunachal package tour from guwahati with dirang" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="arunachal package tour from guwahati with dirang" /></center>
                                                    </div> */}
                                                    <br /><br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4> DAY 06: TAWANG TO BOMDILA</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>After enjoying a hearty breakfast in the morning, embark on your journey towards Bomdila. Situated at an impressive altitude of over 8,500 feet, Bomdila is nestled in the northeastern state of Arunachal Pradesh and offers visitors a serene and breathtaking experience. As part of your <strong>Guwahati to Arunachal tour by Flight in group</strong>, you'll be driven from the nearest airport to Bomdila, taking in the spectacular landscape along the way.<br /><br />

                                                        Bomdila, the headquarters of the West Kameng district, is framed by the majestic Himalayan mountain ranges, creating a picture-perfect setting for travelers. The panoramic views of the surrounding Himalayan terrain are simply awe-inspiring, making Bomdila a must-visit destination for nature lovers and adventure seekers alike. The tranquil atmosphere, coupled with the cool, crisp air, makes it an ideal spot for relaxation and a perfect escape from the hustle and bustle of city life.<br /><br />

                                                        As you explore Bomdila, you'll have the chance to immerse yourself in the natural beauty and calmness of the region. The town is also home to several monasteries, reflecting the strong Tibetan Buddhist influence in the area. These serene places of worship further enhance the peaceful aura of the location.
                                                        <br /><br />

                                                        After a day filled with scenic views and exploration, you'll enjoy a comfortable overnight stay in Bomdila, resting up for the adventures that lie ahead in your <strong>Guwahati to Arunachal tour by Flight in group</strong>. This journey offers a perfect blend of breathtaking landscapes and rich cultural experiences, making it an unforgettable part of your Arunachal Pradesh tour. <br /><br /><br /></p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="Arunachal Group Tour Package" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="Arunachal Tour Package From Guwahati" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4> DAY 07: BOMDILA TO GUWAHATI</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>After an early breakfast, check out from your hotel and embark on the scenic drive to Guwahati. The journey is long but incredibly picturesque, as you'll travel through the stunning Himalayan terrain, surrounded by lush hills and flowing rivers. The breathtaking landscapes will keep you captivated throughout the route, offering some of the most memorable views of the entire trip. You'll arrive in Guwahati by late evening, where you'll check into your accommodation for an overnight stay, reflecting on the beauty of the day's drive and the adventures that await. <br /><br /><br /></p>
                                                    {/* <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br /><br /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    <h4> DAY 08: TRANSFER TO GUWAHATI AIRPORT</h4>
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>Following breakfast on your final morning, check out from your hotel and begin your last sightseeing tour in Guwahati. Your first stop will be the revered Kamakhya Temple, where you'll have the opportunity to seek blessings from Maa Kamakhya, one of the holiest sites in Assam. The temple's spiritual significance and the panoramic views from the hilltop will leave a lasting impression. Next, visit Umananda Temple on Peacock Island, located in the Brahmaputra River. Accessible by boat, this peaceful temple is dedicated to Lord Shiva and offers a serene atmosphere that is perfect for reflection.
                                                        <br /><br />

                                                        After your temple visits, you'll be transferred to Guwahati Airport, marking the end of your remarkable journey. As you board your flight, you'll carry with you the sweet memories of your trip, cherishing every moment of your unforgettable adventure in Arunachal Pradesh and Assam. <br /><br /><br /></p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">{<InclusionExclusion />}</div>
                                </div>
                            </div>

                            <br />  <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES TO COVER WITH <strong>ARUNACHAL GROUP TOUR STARTING FROM GUWAHATI AIRPORT</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES TO COVER WITH <strong>ARUNACHAL GROUP TOUR STARTING FROM GUWAHATI AIRPORT</strong></h4>
                                </div>
                                <div className="card-body">
                                    <h3 className='tomato1'><b>BHALUKPONG</b></h3> <p>Start your adventure with a visit to Bhalukpong, a destination that offers both excitement and serenity. Engage in thrilling activities like trekking and river rafting in the Kameng River. For nature enthusiasts, the Pakhui Wildlife Sanctuary presents excellent wildlife spotting opportunities, where you can observe tigers, elephants, and various species of birds. Don't forget to stop by the Tipi Orchid Research Center, home to a vast collection of orchid varieties. Experience the local Nyishi tribal culture and immerse yourself in the beauty of this captivating region. An <strong>Arunachal group trip from Guwahati</strong> would be the perfect way to experience Bhalukpong's adventure and tranquility. <br /><br /></p>

                                    <h3 className='tomato1'><b>BOMDILA</b></h3>
                                    <p>Nestled in the majestic Himalayas, Bomdila is a peaceful retreat that offers stunning natural beauty and a serene atmosphere. Explore the town's craft centers, where you can purchase local handicrafts, or take a short hike to enjoy the fresh mountain air. Visit the Bomdila Monastery and Upper Gompa for a spiritual experience, surrounded by panoramic views of the Himalayas that will surely leave you in awe. Whether you're looking for relaxation or adventure, Bomdila provides a perfect balance for travelers. If you're considering an <strong>Arunachal group trip cost from Guwahati</strong>, this destination offers a mix of experiences that make it worth every penny. <br /><br /></p>

                                    <h3 className='tomato1'><b>TAWANG</b></h3>
                                    <p>A trip to Tawang is undoubtedly the highlight of any Arunachal Pradesh tour package from Guwahati. Famous for its dramatic landscapes, Tawang is home to the Tawang Monastery, the largest Buddhist monastery in India. Pay your respects at the Tawang War Memorial and soak in the incredible views from Sela Pass and Sela Lake. You can also visit the Urgelling Monastery, the birthplace of the 6th Dalai Lama, and explore the vibrant local markets for beautiful handicrafts. Whether you're seeking spiritual enrichment or natural beauty, Tawang offers it all, making it a must-visit spot for anyone on an <strong>Arunachal Pradesh tour package from Guwahati</strong>.<br /><br /></p>

                                    <h3 className='tomato1'><b>DIRANG</b></h3>
                                    <p>Nestled in the heart of Arunachal Pradesh, Dirang offers a perfect blend of natural beauty and cultural richness. Relax in the soothing hot water springs or explore the historical Dirang Dzong. Take a stroll through lush apple orchards and kiwi farms, or embark on a trek to Sangti Valley for some of the best scenic views in the region. For those seeking a spiritual touch, the Dirang Monastery and Kalachakra Gompa are ideal places to visit. <strong>Travel from Guwahati to Arunachal in a group</strong> and enjoy the unique combination of natural wonders and cultural experiences that Dirang has to offer.<br /><br /></p>
                                    <br /><br />
                                    <table className='img-center'>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Arunachal group trip cost from Guwahati" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4><strong>Arunachal Pradesh Group tour from Guwahati</strong> Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        Can I get Jain Veg. food during my Arunachal Tour Package from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, but please inform us at the time of booking.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        Are medical shops available widely across Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">It is better to carry prescribed and general medicines as many remote areas lack proper medical shops.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        Do I need to carry high altitude medicines while traveling to Arunachal Pradesh Tour Plan from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Generally not required, but consult with your physician to be safe.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        Can we visit any offbeat destination during our Arunachal Pradesh package Tour from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, offbeat destinations like Ziro, Mechuka Valley, Pasighat, and Aalo can be included in your package.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        How to reach Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Arunachal Pradesh is about 350 km from Guwahati Airport. You can book a car to reach Itanagar, the capital.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        Who is the chief minister of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Shri Pema Khandu is the chief minister.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        How many districts are there in Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">There are 26 districts as of 2024.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        How many Airports are there in Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">There are a few commercial airports, including Itanagar Airport (Donyi Polo Airport). The Indian Air Force also has several Advance Landing Grounds (ALG) in the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEleven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                        Which is the state animal of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Gayal, locally known as Mithun (Bos frontalis).</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwelve">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                        Which is the state bird of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Great hornbill (Buceros bicornis).</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                        Which is the state flower of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Lady Slipper Orchid (Cypripedioideae).</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFourteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                        Which line separates Arunachal Pradesh from Tibet?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">McMahon Line.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFifteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                        Which is the main river in Arunachal Trip?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Brahmaputra River, known as Yarlung Tsangpo in Tibet, Luit/Dilao in Assam, and Siang/Dihang in Arunachal.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSixteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                        Is it safe to travel to Arunachal Pradesh Tour Packages from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Arunachal Pradesh is very safe, but carry your identity cards and an Inner Line Permit (ILP).</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeventeen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                        Do I need a permit to travel to Arunachal Pradesh Tour Package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, an Inner Line Permit (ILP) is required.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        Can I receive snowfall in Tawang?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, from the end of November to the end of April.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        How many days does it take to complete the Arunachal Tour Booking from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">A 10-day tour package is enough to cover vital places.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwenty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                                        How are the accommodations in Arunachal Trip from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Accommodations are limited, so pre-booking is recommended. Homestays are available, and travel agents can help secure the best options.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Arunachal <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Your Experience is our Marketing"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-96747-38480">
                        <i className="fa fa-phone"></i>
                    </a>
                </div>
            </>
        );
    }
}

export default ArunachalGroupTourGuwahati_7N_8D_Package;