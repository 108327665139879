import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS : <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All transportation by a dedicated and exclusive vehicle
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation in best properties in mentioned places
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Elephant safari, Jeep Safari & Entry fee charges in Kaziranga National Park</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Arunachal Pradesh entry permits (ILP)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All parking, toll charges, fuel charges, state permits, etc.</li>
                    
                </ul><br/><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS : <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All items not included in the inclusion list</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Personal expenses</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Train, Air Fare</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Laundry, Tips, Phone Calls, Porter, or any personal expense</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Local Car Charges for visiting Bumla Pass</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Applicable Govt. GST</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;