import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_kolkata";

class KolkataToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,
      btn: "Submit",
      showLoader: false,
      isHidden: true,
      showModal: false,
      firstName: '',
      mobile: '',
      email: '',
      comments: ''
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };

  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };

  onRequestCallback = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }



  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      showModal: false,
      firstName: '',
      mobile: '',
      email: '',
      comments: ''
    });
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // Function to handle form submission
  onRequestCallbackSubmit = async (e) => {
    e.preventDefault();

    const { firstName, email, mobile, comments } = this.state;

    // Validation
    if (firstName.trim() === '') {
      cogoToast.error("Full Name Required.", { position: 'bottom-center' });
    } else if (email.trim() === '') {
      cogoToast.error("Email Required.", { position: 'bottom-center' });
    } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
      cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
    } else {
      this.setState({ btn: "Processing..", showLoader: true });
      // Proceed with form submission
      try {
        const _ref = 'yes';
        const _url = window.location.href;

        const formData = {
          FirstName: firstName,
          //LastName: lastName,
          Email: email,
          Mobile: mobile,
          // City: city,
          // TravelDate: travelDate,
          // TicketBooked: ticketBookedStatus,
          // NoOfGuests: noOfGuests,
          // HotelCategory: hotelCategory,
          Comments: comments,
          Referrer: _ref,
          URL: _url
        };

        const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
        console.log('Data submitted successfully:', response.data);
        // Optionally, reset the form state after successful submission
        this.setState({ firstName: '', email: '', mobile: '', comments: '' });

        // Optionally, show a success message to the user
        cogoToast.success("Form submitted successfully.", { position: 'bottom-center' });
        this.setState({ btn: "Submit", showLoader: false, showModal: false });
      } catch (error) {
        console.error('Error submitting data:', error);
        this.setState({ btn: "Submit", showLoader: false });
        // Optionally, show an error message to the user
        cogoToast.error("Failed to submit form. Please try again later.", { position: 'bottom-center' });
      }

    }

  };


  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-package-tour-from-kolkata-starting-from-hasimara-newalipurduar-station.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-group-tour-from-kolkata-via-hasimara-newalipurduar.webp";
    const flightBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-Flight-from-Kolkata.webp";
    const bhutanTravelAgentImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-Travel-Agents.webp";
    const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Six-Sense-Bhutan-Tour-Packages.webp";
    const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Aman-Kora-Bhutan-Packages.webp";
    const flightBhutanPackagesmobile0ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-to-Kolkata-Flight-Everyday.webp";
    const flightBhutanPackagesmobile1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Kolkata-to-Bhutan-Flight-Everyday.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-KOLKATA (2).webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-KOLKATA (3).webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-KOLKATA.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING (2).webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING (3).webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/WHY-SHOULD-BHUTAN-BE-ON-YOUR-TRAVEL-WISH-LIST (2).webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/WHY-SHOULD-BHUTAN-BE-ON-YOUR-TRAVEL-WISH-LIST (3).webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/WHY-SHOULD-BHUTAN-BE-ON-YOUR-TRAVEL-WISH-LIST.webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/BHUTAN-CULTURE (2).webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/BHUTAN-CULTURE (3).webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/BHUTAN-CULTURE.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/The-Gateway-of-Bhutan-by-Road-from-India.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Punakha-Wangdue-and-drive-to-Paro.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Tourist-sites-in-Thimphu.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Excursion-tour-to-Taktshang-Monastery.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-package-tour-cost-from-mumbai-and-explore-chele-la-pass.webp";
    // const navDay6ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Excursion-tour-to-Taktshang-Monastery.webp";
    const Award1ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/International-Wellness-Destination-for-Paro-Travel-Leisure-Indias-Best-Awards-2024.webp";
    const Award2ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/Runner-Up-for-Favourite-Overseas-Destination-Condé-Nast-Traveller-Readers-Travel-Awards-2024.webp";
    const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/call-button-bablu-adorable-vacation.webp";
    const flight1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-package-from-guwahati-with-direct-flight.webp";
    const flight2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
    const flight3ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
    const hotPackagesDataList = this.props.HotPackagesData;
    const hotPackagesView = hotPackagesDataList.length > 0 ? (
      hotPackagesDataList.map((dataItem, i) => {
        const hotelImgURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + dataItem.ImageName;
        const packageURL = dataItem.Url;
        return (
          <div className="col-md-4" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h5 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}/-</span></h5>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">{dataItem.Description}</p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">{dataItem.MutedText}</small>
                <Link
                  to={packageURL}
                  target="_blank"
                  className="btn btn-Warning btn-sm float-end"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL =
        ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img
            src={hotelImageURL}
            className="rounded mx-auto d-block"
            alt={hotelItem.AllternateText}
          />
          <br />
          <span>
            <b>{hotelItem.HotelName}</b> |
            <span className="tomato">{hotelItem.HotelStar}</span>
          </span>
          <br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span>{" "}
          &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      );
    });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromKolkataImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bhutan Package Tour from Kolkata</strong> - BEST DEAL 2025</center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center><strong>Bhutan Package Tour from Kolkata</strong> - BEST DEAL 2025</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <a href="tel:9883359713"><img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." /></a>
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        4 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuentsholing
                        </a>
                      </div>
                      <div className="reviews mx-2">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you're planning an unforgettable Bhutan trip and looking for a great <strong>Bhutan Package Tour from Kolkata</strong>, you've found the perfect place! Adorable Vacation is a leading Destination Management Company (DMC) that offers amazing <strong>Bhutan tour packages from Kolkata</strong>. Our Bhutan tour options include departures from various places such as Hasimara Station, New Alipurduar Station, NJP Station, Siliguri Station, Jaigaon or Phuentsholing Town. We offer the best rates for <strong>Kolkata to Bhutan packages</strong>, ensuring your <strong>Bhutan trip from Kolkata</strong> is both affordable and an unforgettable adventure. So, when you think of a <strong>Bhutan tour</strong>, think Adorable Vacation! <br />
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong className="tomato">BHUTAN PACKAGE TOUR FROM KOLKATA</strong><br /><br />  FOR BOOKING <strong className="tomato">KOLKATA TO BHUTAN PACKAGES</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h2>
                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong className="tomato">BHUTAN PACKAGE TOUR FROM KOLKATA</strong><br /><br />  FOR BOOKING <strong className="tomato">KOLKATA TO BHUTAN PACKAGES</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h3>
                    <br />
                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> START YOUR <strong>BHUTAN TOUR FROM KOLKATA</strong> WITH ADORABLE VACATION - <strong>BEST BHUTAN DMC IN INDIA</strong></h3>
                        <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> START YOUR <strong>BHUTAN TOUR FROM KOLKATA</strong> WITH ADORABLE VACATION - <strong>BEST BHUTAN DMC IN INDIA</strong></h4>
                      </div>
                      <div className="card-body">
                        <p>Nestled in the heart of the Himalayas, Bhutan is a land of stunning natural beauty, vibrant culture, and spiritual tranquility, making it the perfect destination for travelers seeking an enriching experience. If you are looking for a <strong>Bhutan package tour from Kolkata</strong>, Adorable Vacation offers a seamless and unforgettable journey. As one of the leading <strong>Bhutan tour operators from Kolkata</strong>, we specialize in crafting tours that allow you to explore Bhutan's unique heritage, breathtaking landscapes, and deep spiritual traditions. Whether you choose to embark on your journey by train or flight, we ensure that your <strong>Bhutan trip</strong> is Comfortable, Convenient, and rich in Experiences. <br /><br />

                          Bhutan is a country that follows a <b>High Value, Low Impact</b> tourism policy, which ensures the preservation of its age-old culture and pristine environment while offering visitors an extraordinary travel experience. This eco-friendly approach has allowed Bhutan to remain one of the world's most unspoiled and carbon-negative countries. It is an ideal destination for those seeking a sustainable, responsible travel experience, where you can explore nature and culture without contributing to pollution or overcrowding. Bhutan's commitment to sustainability makes it a haven for travelers who wish to make a positive impact while exploring new destinations. <br /><br />

                          When you choose to book a <strong>Bhutan tour package from Kolkata</strong>, you are opening the door to a journey that offers much more than just sightseeing. Bhutan is known for its majestic Dzongs, intricate fortress-like structures that serve as both religious and administrative centers. These architectural wonders, adorned with beautiful artwork and steeped in history, provide a glimpse into the heart of Bhutanese culture. You'll also have the opportunity to experience Bhutan's vibrant festivals, such as the <b>Paro Tsechu</b> and <b>Punakha Drubchen</b>, where traditional dances, masked performances, and sacred rituals offer a window into the spiritual and cultural richness of the country. <br /><br />

                          The country's stunning landscapes, from the lush Paro Valley to the majestic Tiger's Nest Monastery, perched high on a cliff, will leave you in awe. Bhutan's commitment to wellness tourism is also evident in its tranquil surroundings, making it an ideal destination for those seeking rejuvenation. Traditional healing practices, wellness retreats, and peaceful natural environments provide the perfect setting for spiritual growth and relaxation. Bhutan's holistic approach to life, focusing on Gross National Happiness [GNH] over GDP, offers travelers an experience that nurtures the Mind, Body, and Soul. <br /><br />

                          If you're considering the <strong>Bhutan tour package cost from Kolkata</strong>, Adorable Vacation ensures that you receive the best value for your investment. We offer competitive pricing, with packages that include premium hotels, expert guides, and hassle-free transport options. Whether you are traveling by train from NJP Station, Hasimara Station, New Alipurduar, or Siliguri Station, or flying directly from Kolkata to Paro, our <strong>Bhutan packages from Kolkata</strong> cater to your preferences and budget. Our experienced drivers and guides ensure that your journey is smooth, informative, and enjoyable, allowing you to immerse yourself fully in the rich culture and breathtaking landscapes of Bhutan. <br /><br />

                          Bhutan's philosophy of sustainable tourism, combined with its natural beauty and unique cultural heritage, makes it a must-visit destination for travelers from all over the world. Whether you are an adventure enthusiast seeking to trek through Bhutan's scenic trails, a culture lover eager to experience Bhutanese traditions, or someone looking to relax in a serene and peaceful environment, Bhutan offers a wealth of experiences that cater to every type of traveler. <br /><br />

                          So, if you're ready for an adventure that combines stunning landscapes, rich culture, and spiritual growth, book your <strong>Bhutan tour from Kolkata</strong> today with Adorable Vacation. With our expert guidance and thoughtfully designed itineraries, you'll experience the best of Bhutan while supporting its commitment to sustainable, high-value tourism. Let Bhutan's magical beauty and deep-rooted traditions create memories that will last a lifetime.<br /></p>
                        <p>Apart from <strong>Bhutan Package tour from Kolkata</strong>, Adorable Vacation also offer <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chertered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bangalore" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>,<a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-chennai" target="_blank"><b className="tomato">Bhutan Package Tour from Chennai</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p>
                        <br /><br />
                        <table className="image-table">
                          <tbody>
                            <tr>
                              <td className="image-cell">
                                <img src={sightseen1ImageURL} alt="Sightseeing 7" />
                                <img src={sightseen2ImageURL} alt="Sightseeing 9" />
                                <img src={sightseen3ImageURL} alt="Sightseeing 8" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br /><br /><br />
                        <table className='img-center'>
                          <tbody>
                            <tr>
                              <td className="image-cell">
                                <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : 5N | 6D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DURATION FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : 5N | 6D</h4>
                </div>
                <div className="card-body">
                  <br />
                  <h4>DESTINATIONS COVERED IN <strong className="tomato">BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : THIMPHU 2N | PARO 2N | PHUENTSHOLING 1N</h4><br />
                  <h4>PICKUP & DROP POINT FOR <strong className="tomato">KOLKATA TO BHUTAN TOUR PACKAGE</strong> : HASIMARA STATION | NEW ALIPURDUAR JUNCTION</h4>
                  <br /><br />
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr class="table-danger"><th>CITY</th><th>ALTITUDE</th><th>CO-ORDINATES</th></tr></thead>
                      <tbody>
                        <tr><td>Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                        <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                        <tr><td>Paro</td><td>2,200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                        <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>
                        <tr><td>Chele La Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                        <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                        <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE SUMMERY FOR <strong>BHUTAN TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE SUMMERY FOR <strong>BHUTAN TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              PICKUP FROM HASIMARA, TRANSFER TO THIMPHU
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>THIMPHU TO PUNAKHA DAY EXCURSION</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span> THIMPU SIGHTSEEING AND TRANSFER TO PARO</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>PARO TIGERS NEST MONASTERY HIKING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>CHELE-LA PASS EXCURSION & TRANSFER TO PHUENTSHOLING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>PHUNTSHOLING TO HASIMARA RLY STN DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS : <strong>KOLKATA TO BHUTAN PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS : <strong>KOLKATA TO BHUTAN PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              Accommodation in TCB listed Hotels (Double/Twin Sharing Room)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Meal Plan MAP (Breakfast and Dinner)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Transfers & all sightseeing in exclusive Non-AC Vehicle
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Parking, Toll Tax, E-Permits
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Thimphu Local Sightseeing
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Paro City Tour
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              English / Hindi Speaking Tour Guide
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              All transfer will be on point-to-point basis
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              SDF Charges (Sustainable Development Fee)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              01 Bottle Packaged Drinking Water Per Day on Vehicle
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Guest Service Support during the trip from Bhutan & India Office 24/7
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /> <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KOLKATA TO BHUTAN TOUR PACKAGES FROM HASIMARA STATION</strong> : YOUR GATEWAY TO PEACE AND HAPPINESS</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KOLKATA TO BHUTAN TOUR PACKAGES FROM HASIMARA STATION</strong> : YOUR GATEWAY TO PEACE AND HAPPINESS</h3>
                </div>
                <div className="card-body">
                  <p>If you're looking for a smooth and scenic route to Bhutan from Kolkata, then the <strong>Kolkata to Bhutan tour by Train via Hasimara Station</strong> is the ideal option for you. Adorable Vacation offers exclusive <a href="/bhutan-package-tour-from-hasimara-new-alipurduar" target="_blank"><strong>Bhutan tour packages from Hasimara Station</strong></a>, making it a convenient and stress-free travel option for those wanting to experience the beauty and tranquility of Bhutan. <br /><br />

                    Hasimara Station, located in the northern part of West Bengal, is just a short 45-minute drive from Jaigaon, the nearest town on the Indo-Bhutan border. This makes it one of the best entry points into Bhutan, offering easy and quick access to the country's breathtaking landscapes and rich culture. The convenience of traveling by train combined with the proximity to the Bhutan border allows for a smooth and hassle-free journey. <br /><br />

                    The <strong>Kolkata to Bhutan tour package from Hasimara Station</strong> is perfect for those who prefer to travel by train. The Kanchankanya Express from Sealdah Station is the best train option, as it directly stops at Hasimara Station. This makes it extremely convenient for travelers, as they don't have to make long transfers or face delays typical of other routes. Once you arrive at Hasimara, the drive to Jaigaon is scenic, comfortable, and quick, allowing you to reach Bhutan in no time. <br /><br />

                    Booking a <strong>Bhutan tour from Kolkata by train</strong> not only makes your journey more relaxed but also offers a chance to witness the beautiful landscapes of West Bengal as you travel through picturesque towns and scenic rail routes. Upon reaching Jaigaon, you can easily cross into Bhutan and start your incredible journey exploring this Himalayan kingdom. <br /><br />

                    Adorable Vacation ensures that your <strong>Bhutan tour</strong> experience is as seamless as possible, offering comfortable accommodations, experienced guides, and a fully planned itinerary. Our <strong>Bhutan tour package from Kolkata</strong> are designed to suit your travel preferences, whether you want to experience Bhutan's cultural heritage, stunning monasteries, or pristine nature. <br /><br />

                    Choose Adorable Vacation for your next adventure and let us guide you to Bhutan with the ease and comfort of <strong>Kolkata to Bhutan tour packages from Hasimara Station</strong>. Enjoy an unforgettable journey to the Land of Happiness, where peace, spirituality, and natural beauty await you at every turn. Book your <strong>Bhutan tour from Kolkata</strong> today and embark on a journey that promises to be both relaxing and inspiring. </p>
                  <br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> WITH <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : DISCOVER A GLOBAL AWARD-WINNING DESTINATION</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> WITH <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : DISCOVER A GLOBAL AWARD-WINNING DESTINATION</h4>
                </div>
                <div className="card-body">
                  <p>As the 2025 travel season draws near, now is the perfect time to explore Bhutan - a serene Himalayan kingdom that's rapidly gaining recognition for its wellness offerings and rich cultural heritage. On December 16, 2024, Bhutan received two prestigious awards that reflect its rising prominence in global tourism: <b>International Wellness Destination for Paro</b> at the Travel + Leisure India's Best Awards 2024 and <b>Runner-Up in the Favourite Overseas Destination category</b> at the Condé Nast Traveller Readers' Travel Awards. These accolades highlight Bhutan's growing reputation as a top destination for sustainable and rejuvenating travel experiences.<br /><br />

                    If you're looking for a peaceful retreat that combines wellness, nature, and cultural immersion, Bhutan is a must-visit destination for you. Known for its pristine landscapes, spiritual ambiance, and commitment to sustainable tourism, Bhutan offers a unique opportunity to reconnect with nature and restore your inner balance. Whether you're looking to unwind in the tranquil valleys of Paro, explore ancient monasteries, or experience Bhutan's holistic wellness programs, the kingdom offers something for everyone seeking rejuvenation.<br /><br />

                    A <strong>Bhutan package tour from Kolkata</strong> is the perfect way to experience this peaceful haven. This once-in-a-lifetime journey will leave you with unforgettable memories, as you discover why Bhutan is now one of the world's most sought-after wellness destinations.<br /><br /></p>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={Award2ImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={Award1ImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />  <br />

                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES OF ATTRACTIONS IN <strong>KOLKATA TO BHUTAN TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES OF ATTRACTIONS IN <strong>KOLKATA TO BHUTAN TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr class="table-danger"><th><b>PLACES</b></th><th><b>ATTRACTIONS</b></th></tr></thead>

                      <tbody>
                        <tr><td><b>Thimphu</b></td><td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td></tr>

                        <tr><td><b>Paro</b></td><td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>

                        <tr><td><b>Punakha</b></td><td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td></tr>

                        <tr><td><b>Phuentsholing</b></td><td>Bhutan Gate, Zangto Pelri Lhakhang, Zangto Pelri Park, Phuentsholing Market, Phuentsholing Viewpoint, Phuentsholing Park, Torsa River Side, Karbandi Monastery, Palden Tashi Chholing Shedra, Amo Chhu Crocodile Breeding Centre, Ren Tours & Treks etc </td></tr>

                      </tbody>
                    </table>
                  </div>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY ADORABLE VACATION IS THE BEST NATIONAL BRAND FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS THE BEST NATIONAL BRAND FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <li><strong>Trusted Brand: Adorable Vacation LLP</strong> has been a trusted name for <strong>Bhutan tours from Kolkata</strong> since 2010.</li><br />
                  <li><strong>Authorized Agents:</strong> They are authorized selling agents for Bhutan Airlines and Druk Airlines.</li><br />
                  <li><strong>Special Airfare:</strong> As a PSA (Passenger Service Agent) of Bhutan Airlines, they offer special airfare to guests.</li><br />
                  <li><strong>Hotel Availability:</strong> They have pre-purchased hotel room inventory for the super peak season.</li><br />
                  <li><strong>Official Recognition:</strong> Adorable Vacation LLP is authorized by the <strong>Tourism Council of Bhutan (TCB)</strong>.</li><br />
                  <li><strong>Local Presence:</strong> They have their own office and employees in Thimphu, Bhutan.</li><br />
                  <li><strong>Customized Itineraries:</strong> They offer personalized tour itineraries with unique and signature programs.</li><br />
                  <li><strong>Expert Knowledge:</strong> The Company has in-depth knowledge of Bhutan's territory, hotels, local laws, and regulations.</li><br />
                  <li><strong>Assured Service:</strong> They ensure high-quality service to enhance your experience in Bhutan.</li><br />
                  <li><strong>E-Permit Processing:</strong> They process e-permits in advance for visiting different places and sightseeing.</li><br />
                  <li><strong>Personal Attention:</strong> Safe and secure personal attention during your stay in Bhutan.</li><br />
                  <li><strong>Full Responsibility:</strong> They take responsibility for the entire duration of your <strong>Bhutan tour from Kolkata.</strong></li><br />
                  <li><strong>Hassle-Free Service:</strong> Ensuring hassle-free and trustworthy services in Bhutan.</li><br />
                  <li><strong>Best Deals:</strong> Get the best deals for properties and services as per your preferences.</li><br />
                  <li><strong>Flexible Planning:</strong> You can plan a <strong>Bhutan trip from Kolkata</strong> even during weekends and holidays.</li><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>IMPORTANT FACTS NEED TO KNOW BEFORE PLANNING  <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>IMPORTANT FACTS NEED TO KNOW BEFORE PLANNING  <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr class="table-danger"><th>Fact</th><th>Details</th></tr>
                        <tr><td>Capital</td><td>Thimphu</td></tr>
                        <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                        <tr><td>Official Language</td><td>Dzongkha</td></tr>
                        <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                        <tr><td>Area</td><td>38,394 km²</td></tr>
                        <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                        <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                        <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                        <tr><td>National Animal</td><td>the Takin</td></tr>
                        <tr><td>Major Religion</td><td>Buddhism</td></tr>
                        <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>
                        <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>
                        <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>HOW TO REACH BHUTAN FROM KOLKATA?</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>HOW TO REACH BHUTAN FROM KOLKATA?</h3>
                </div>
                <div className="card-body">
                  <p>There are lots of options for <strong>Bhutan tour from Kolkata</strong>. The most cost effective and budget friendly options that Adorable vacation suggest is - <strong>Bhutan tour from Kolkata by Train</strong>. Why? Here it is. <br /><br /></p>

                  <h4 className="tomato1">OPTION 1 : <strong>KOLKATA TO BHUTAN TOUR BY TRAIN</strong></h4>
                  <br /><br />
                  <p>To travel to <strong>Bhutan from Kolkata by train</strong>, you can start your journey from either Sealdah or Howrah Station. Many trains run daily between Sealdah or Howrah and NJP, Siliguri, New Alipurduar, or Hasimara Station. Depending on the train, the journey from Kolkata to New Jalpaiguri (NJP), Siliguri, New Alipurduar, or Hasimara Station takes approximately 8-12 hours, offering a scenic view of endless paddy fields, corn fields, tea gardens, and pineapple orchards along the way. <br /><br />

                    If you are traveling up to NJP or Siliguri, you will be picked up and transferred to Phuentsholing for a direct drive of about 4 to 4.5 hours. The distance from NJP/Siliguri to Phuentsholing is approximately 150 km. Upon reaching Phuentsholing and completing your immigration process, you will be transferred to Thimphu. For those traveling via this route, Adorable Vacation recommends booking your <a href="https://adorablevacation.com/bhutan-package-tour-from-phuentsholing/" target="_blank"><strong>Bhutan package tour from Phuentsholing</strong></a> for a smooth continuation of your journey. <br /><br />

                    However, as the trip from NJP or Siliguri can be long and somewhat tiring, Adorable Vacation always recommends booking your <a href="https://adorablevacation.com/bhutan-package-tour-from-hasimara-new-alipurduar/" target="_blank"><strong>Bhutan package tour from Hasimara Station</strong></a> or New Alipurduar Station. The distance from Hasimara to Phuentsholing is only about 18 km, which takes just 30 minutes to reach Bhutan's nearest border town, Phuentsholing. This is not only more convenient but also hassle-free, cost-effective, and significantly easier compared to traveling via NJP or Siliguri. <br /><br />

                    Alternatively, New Alipurduar is also a great option, as many trains travel directly to New Alipurduar (NOQ) station. From there, the journey to Jaigaon/Phuentsholing is only about 60 km and takes merely 1 hour. So, booking your train tickets up to Hasimara by Kanchankanya Express or New Alipurduar by Padatik Express is an excellent way to ensure a comfortable and easy journey to Bhutan.Following are the train schedules for better understanding. <br /><br /><br /></p>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr class="table-danger"><th><b>TRAIN NAME</b></th><th><b>TRAIN NO</b></th><th><b>DEPRT STN | TIME</b></th><th><b>ARRV STN | TIME</b></th><th><b> FREQUENCY</b></th></tr></thead>
                      <tbody>
                        <tr><td>Kanchankanya Exp</td><td>13149</td><td>SDAH / 8:30 pm</td><td>HSA / 10:45 am</td><td>Monday to Thursday</td></tr>
                        <tr><td>Padatik Express</td><td>12377</td><td>SDAH / 11:15 pm</td><td>NOQ / 12:10 pm</td><td>Sunday to Wednesday </td></tr>
                        <tr><td>Darjeeling Mail</td><td>12343</td><td>SDAH / 22:05 pm</td><td>NJP / 8:00 pm</td><td>Sunday to Monday </td></tr>
                        <tr><td>Saraighat Express</td><td>12345</td><td>HWH / 15:50 pm</td><td>NJP / 1:45 pm</td><td>Sunday to Monday </td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />

                  <h4 className="tomato1">FOR BHUTAN PACKAGE TOUR FROM NJP, YOU CAN ALSO BOOK HOWRAH JUNCTION TO NEW JALPAIGURI [NJP] VANDE BHARAT EXCEPT</h4>
                  <br /><br />
                  <p>Except on Wednesdays, the <b>Howrah-NJP Vande Bharat Express</b> runs with precise timing every day. It is a new-generation train and a pride of the Indian Railway system. Book your tickets for this train and make your <a href="https://adorablevacation.com/bhutan-package-tour-from-njp-station-siliguri-bus-stand/" target="_blank"><strong>Bhutan package tour from NJP Station</strong></a> a successful one. Below is the time schedule for the <b>Howrah-NJP Vande Bharat Express</b>.<br /><br /></p>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr class="table-danger"><th><b>STATION</b></th><th><b>ARRIVAL <br /> DEPARTURE</b></th><th><b>HALT</b></th><th><b>DISTANCE</b></th></tr></thead>
                      <tbody><tr><td>Howrah Jn [HWH]</td><td>Starts <br />05:55</td><td>Howrah (HWH)</td><td>0 km</td></tr>
                        <tr><td>Bolpur Shantiniketan [BHP]</td><td>07:43 <br />07:45</td><td>2 min</td><td>145 km</td></tr>
                        <tr><td>Malda Town [MLTD]</td><td>10:32 <br />10:35</td><td>3 min</td><td>329.5 km</td></tr>
                        <tr><td>Barsoi Jn [BOE]</td><td>11:50 <br />11:52</td><td>2 min</td><td>419.7 km</td></tr>
                        <tr><td>New Jalpaiguri Jn [NJP]</td><td>13:25<br />ends</td><td></td><td>564.6</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <h4 className="tomato1">OPTION 2 : <strong>KOLKATA TO BHUTAN TOUR BY ROAD VIA BAGDOGRA AIRPORT</strong></h4>
                  <br /><br />
                  <p>This option is perfect for those who want to travel to Bhutan with comfort, save time, and experience the scenic beauty of an India-to-Bhutan trip by road. It is time-efficient, hassle-free, and comfortable, as a flight will get you to Bagdogra in less than an hour. Kolkata to Bagdogra is just under 450 km by air, making it one of the easiest ways to get close to Phuentsholing, the border town of Bhutan.<br /><br />

                    The journey from Bagdogra to Phuentsholing is only about 160 km and can be covered in approximately 4 hours by road. So, guests traveling from Kolkata can easily fly to Bagdogra Airport, from where Adorable Vacation offers exclusive <a href="https://adorablevacation.com/bhutan-tour-package-from-bagdogra/" target="_blank"><strong>Bhutan package tours from Bagdogra Airport</strong></a>. This is one of the best ways to travel to <strong>Bhutan from Kolkata by road, via Bagdogra Airport</strong>, combining the convenience of air travel with the beauty of a scenic road trip.Following are the Kolkata to Bagdogra flight schedule for your convenience. <br /><br /><br /></p>
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr class="table-danger"><td><b>AIRLINES</b></td><td><b>ORIGIN</b></td><td><b>DEPARTURE</b></td><td><b>DESTINATION</b></td><td><b>ARRIVAL</b></td><td><b>DURATION</b></td></tr></thead>
                      <tbody>
                        <tr><td>Air Asia</td><td>Kolkata [CCU]</td><td>08:45</td><td>Bagdogra [IXB]</td><td>09:55</td><td>01 h 10 m (non-stop)</td></tr>
                        <tr><td>Air Asia</td><td>Kolkata [CCU]</td><td>07:15</td><td>Bagdogra [IXB]</td><td>08:20</td><td>01 h 05 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Kolkata [CCU]</td><td>10:05</td><td>Bagdogra [IXB]</td><td>11:05</td><td>01 h 00 m (non-stop)</td></tr>
                        <tr><td>SpiceJet</td><td>Kolkata [CCU]</td><td>08:05</td><td>Bagdogra [IXB]</td><td>09:10</td><td>01 h 05 m (non-stop)</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <h4 className="tomato1">OPTION 3 : <strong>BHUTAN PACKAGE TOUR FROM KOLKATA WITH DIRECT FLIGHT</strong></h4>
                  <br /><br />
                  <p>Guests looking for the easiest and most comfortable way to travel from Kolkata to Bhutan, booking a <strong>Bhutan tour package from Kolkata</strong> with the Kolkata-Paro direct flight by Bhutan Airlines is the best option. Every day, Bhutan Airlines operates a direct flight between Kolkata and Paro. This flight is actually a connecting flight between Bangkok and Paro, but it provides a seamless and convenient way to travel directly to Bhutan. So, book this direct flight and enjoy your <strong>Bhutan trip from Kolkata</strong> with Adorable Vacation.Following are the Kolkata to Paro flight schedule for your convenience. <br /><br /><br /></p>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr class="table-danger"><th><b>SECTOR</b></th><th><b>FLIGHT NO</b></th><th><b>DEP DAY</b></th><th><b>DEP TIME</b></th><th><b>ARR TIME</b></th><th><b>DURATION</b></th></tr></thead>
                      <tbody>
                        <tr><td>CCU - PBH</td><td>B3 704</td><td>EVERYDAY</td><td>11:25am</td><td>12:55pm</td><td>01 hrs</td></tr>
                        <tr><td>PBH - CCU</td><td>B3 700</td><td>EVERYDAY</td><td>10:35am</td><td>11:15pm</td><td>01 hrs 10 mnts</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br /><br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={flight1ImageURL} alt="Sightseeing 1" />
                          <img src={flight2ImageURL} alt="Sightseeing 3" />
                          <img src={flight3ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM KOLKATA</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr class="table-danger">
                          <th><b>PLACES</b></th>
                          <th><b>CITY</b></th>
                          <th><b>FEES</b></th>
                          <th><b>REMARKS</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td>
                        </tr>
                        <tr>
                          <td>Tashichhoe Dzong (Fort)</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td>
                        </tr>
                        <tr>
                          <td>Buddha Dordenma Statue</td>
                          <td>Thimphu</td>
                          <td>No Entry Fee</td>
                          <td>Time-9am to 5pm</td>
                        </tr>
                        <tr>
                          <td>Thimphu Memorial Chorten</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>Anytime</td>
                        </tr>
                        <tr>
                          <td>Changangkha Lhakhang (Temple)</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>Anytime between 8am to 5pm</td>
                        </tr>
                        <tr>
                          <td>Takin Preservation Centre</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>9am to 5pm</td>
                        </tr>
                        <tr>
                          <td>Simtokha Dzong (Fort)</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>9am to 5pm</td>
                        </tr>
                        <tr><td>National Library</td><td>Thimphu</td><td>NU 100</td><td>9am to 5pm on Mon to Fri</td></tr>
                        <tr><td>The Royal Textile Museum</td><td>Thimphu</td><td>NU 250</td><td>Mon to Sat from 9am to 4pm</td></tr>
                        <tr><td>National Institute for Zorig Chusum (Painting School)</td><td>Thimphu</td><td>NU 200</td><td>Mon to Fri 10am to 3:30pm</td></tr>
                        <tr><td>Folk Heritage Museum</td><td>Thimphu</td><td>NU 200</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>
                        <tr><td>Simply Bhutan Museum</td><td>Thimphu</td><td>NU 1000</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>

                        <tr><td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td>
                        </tr>
                        <tr><td>Punakha Dzong (Fort)</td><td>Punakha</td><td>NU 500</td><td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td></tr>
                        <tr><td>Chimmi Lhakhang (Temple)</td><td>Punakha</td><td>NU 500</td><td>9am to 1pm and 2pm to 5pm </td></tr>
                        <tr><td>Khamsum Yueely Namgyal Chorten</td><td>Punakha</td><td>NU 100</td><td>9am to 1pm and 2pm to 5pm </td></tr>

                        <tr><td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Kyichu Lhakhang (temple)</td><td>Paro</td><td>NU 500</td><td>9am to 12pm & 1pm to 4pm </td></tr>
                        <tr><td>Rinpung Dzong (Fort)</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm (Summer) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Dobji Dzong</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                        <tr><td>National Museum of Bhutan</td><td>Paro</td><td>NU 300</td><td>Opens from 9am to 4pm throughout the week except on govt & local holidays </td></tr>
                        <tr><td>Taktshang (Tiger Nest Monastery)</td><td>Paro</td><td>NU 1000</td><td>9am to 5pm </td></tr>

                        <tr><td colspan="4">TRONGSA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Trongsa Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Trongsa Ta Dzong (Watch tower)</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                        <tr><td colspan="4">BUMTHANG SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Jampa Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Kurjey Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Jakar Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                        <tr><td colspan="4">PHUENTSHOLING SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Rinchending Goenpa (temple) </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                        <tr><td>Palden Tashi Choling Shedra </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                        <tr><td colspan="4">NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below. </td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />

                  <table style={{ width: '100%', textAlign: 'center' }}>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img
                            src={sightseen3ImageURL}
                            alt="Bhutan Guwahati group tour from bagdogra with adorable"
                            style={{ margin: '10px' }}
                          />
                          <img
                            src={sightseen6ImageURL}
                            alt="Bhutan Guwahati travel packages"
                            style={{ margin: '10px' }}
                          />
                          <img
                            src={sightseen9ImageURL}
                            alt="travel bhutan with best b2b dmc adorable vacation"
                            style={{ margin: '10px' }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>ENTRY FORMALITIES TO NOTE FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>ENTRY FORMALITIES TO NOTE FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">

                  <li><b>No Visa Needed:</b> Indian citizens do not need a visa to visit Bhutan. However, an entry permit is required. A local tour agency like Adorable Vacation Bhutan can process these permits for your <strong>Bhutan tour from Kolkata</strong>.</li><br />

                  <li><b>Required Documents:</b> You need a valid passport (with at least 6 months of validity) or a Voter ID card for your <strong>Bhutan trip from Kolkata</strong>.</li><br />

                  <li><b>Travel Freedom:</b> With a single permit, tourists can travel beyond Thimphu and Paro.</li><br />

                  <li><b>Sustainable Development Fee (SDF):</b> Under the new Bhutan Tourism Policy 2024, all regional tourists must pay an SDF of Rs. 1200 per person per night, in addition to the package price (flights, hotels, meals, guides, transportation, and monument fees).</li><br />

                  <li><b>Online Permit Processing:</b> All permits for the <strong>Bhutan tour</strong> will be processed online.</li><br />

                  <li><b>Mandatory Hotel and Guide:</b> Booking a hotel approved by the Tourism Council of Bhutan (TCB) and having a tour guide are mandatory.</li><br />

                  <li><b>Permit Processing:</b> Online permits can be processed through a local registered tour operator or any international partners.</li><br /><br />

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO <strong>TRAVEL BHUTAN FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO <strong>TRAVEL BHUTAN FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Bhutan, nestled in the Himalayas, experiences distinct seasons: Summer, Winter, Spring, and Autumn. Each season offers unique beauty, making Bhutan a year-round destination. Here’s a guide to help you decide the best time to book your <strong>Bhutan Package Tour from Kolkata :</strong></p><br />
                  <p><b>MONSOON (JUNE TO AUGUST) :</b> Monsoon season brings heavy rains and frequent landslides, causing roadblocks and traffic issues. Travel becomes difficult, so it's best to avoid planning a <strong>Bhutan tour from Kolkata</strong> during these months. <br /><br /></p>
                  <p><b>AUTUMN (SEPTEMBER TO NOVEMBER) :</b> With little to no rain, the weather is pleasant and ideal for travel and sightseeing. Many important festivals like the <strong>Black-Necked Crane Festival</strong>, <strong>Paro Tshechu</strong>, and <strong>Punakha Tshechu</strong> take place during this time. Colorful festivals with dancers in vibrant masks make it a great time to visit Bhutan.<br /><br /></p>

                  <p><b>WINTER (DECEMBER TO FEBRUARY) :</b>  Winter offers stunning scenic beauty but can be very cold, especially in the northern regions where temperatures drop below zero. During winter cities like Thimphu, Paro, and Punakha often experience heavy snowfall. Despite the cold, festivals like <strong>Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu</strong> are held. If you enjoy winter landscapes and festive activities, winter can also be a good time to visit.<br /><br /></p>
                  <p><b>SPRING (MARCH TO MAY) : </b> According to <strong>Adorable Vacation</strong> – The <strong>best DMC for Bhutan tour from Kolkata</strong>, spring is the best time for <strong>Bhutan tour plan from Kolkata</strong>. The weather is pleasant, with blooming jacaranda and rhododendron flowers decorating the valleys and streets. Clear, fog-free skies make it perfect for trekking, including the famous Jomolhari and Drukpath treks. Tourists from around the world, especially from Europe, the USA, Canada, Brazil, China, Thailand, Cambodia, and Australia, visit Bhutan during this season.<br /><br /></p>
                  <p>No matter the season, Bhutan offers a unique and beautiful experience. Choose the time that best fits your preferences and enjoy the wonders of the <strong>"Land of the Thunder Dragon."<br /><br /></strong></p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                  <h5><b>TEMPARATUTRE TO NOTE FOR</b> <strong>BHUTAN TOUR PLAN FROM KOLKATA</strong></h5><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MONTH</th>
                          <th>MAX TEMP</th>
                          <th>MIN TEMP</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>JANUARY</td>
                          <td>10°C</td>
                          <td>-2°C</td>
                        </tr>
                        <tr>
                          <td>FEBRUARY</td>
                          <td>14°C</td>
                          <td>0°C</td>
                        </tr>
                        <tr>
                          <td>MARCH</td>
                          <td>16°C</td>
                          <td>4°C</td>
                        </tr>
                        <tr>
                          <td>APRIL</td>
                          <td>20°C</td>
                          <td>7°C</td>
                        </tr>
                        <tr>
                          <td>MAY</td>
                          <td>22°C</td>
                          <td>13°C</td>
                        </tr>
                        <tr>
                          <td>JUNE</td>
                          <td>25°C</td>
                          <td>15°C</td>
                        </tr>
                        <tr>
                          <td>JULY</td>
                          <td>26°C</td>
                          <td>16°C</td>
                        </tr>
                        <tr>
                          <td>AUGUST</td>
                          <td>28°C</td>
                          <td>17°C</td>
                        </tr>
                        <tr>
                          <td>SEPTEMBER</td>
                          <td>23°C</td>
                          <td>15°C</td>
                        </tr>
                        <tr>
                          <td>OCTOBER</td>
                          <td>22°C</td>
                          <td>12°C</td>
                        </tr>
                        <tr>
                          <td>NOVEMBER</td>
                          <td>20°C</td>
                          <td>9°C</td>
                        </tr>
                        <tr>
                          <td>DECEMBER</td>
                          <td>18°C</td>
                          <td>7°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>

              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DETAIL <span><strong>BHUTAN TOUR ITINERARY FROM KOLKATA</strong></span>
                      </h2>
                      <h4>5 Nights 6 Days <strong>Bhutan Tour Package from Kolkata</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <h4>DAY 01: PICK UP FROM HASIMARA – TRANSFER TO THIMPHU</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>
                            This is the first day of this exciting <strong>Bhutan package tour from Kolkata</strong>. On arrival at Hasimara Railway Station met & greet with representative who will transfer you to Phuntsholing - The Gateway of Bhutan by Road from India. It is a thriving commercial center on the northern edge of the Indian Plains. On arrival, our Representative guide and driver from Adorable Vacation will greet you and proceed with Immigration. Please note it will take a few minutes to complete the immigration work and our guide will help you complete the work, and later on start towards Thimphu via the wonderful mystic town Gedu, which is about 8500ft above the sea and also you will come across Chukha Dam. On the way, halt at Chozum to take photographs of the confluence of two rivers of Bhutan, (Thimphu Chu and Paro Chu). Most probably you will reach Thimphu in the evening. On arrival check in at the hotel. In the evening, take a good rest and you have to adapt to the weather (Acclimatization). Overnight stay at Thimphu.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <h4>DAY 02: THIMPHU TO PUNAKHA DAY EXCURSION</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            This is the 2nd day of this exciting Kolkata to Bhutan trip. After a heavy breakfast we will set out to explore Punakha – the previous summer capital of Bhutan. Thimphu to Punakha one way is almost 77 km and takes approx. 2 hrs to travel. On the way to Punakha and within city we will explore following attractions:
                          </p>
                          <p><b>Dochula View Point:</b> The first stop on the journey from Thimphu is Dochula Pass, which is about 3100 meters high and often above the clouds. It’s only an hour's drive from Thimphu (20 km). Here, you’ll find 108 memorial stupas called "Druk Wangyal Chortens." The pass offers a stunning view of the Eastern Himalayan Range. Take some time to enjoy the view, take photos, and make videos. In winter, temperatures can drop to -2°C, and the area is covered in snow, creating a beautiful, snowy landscape that many visitors find exciting.</p>
                          <p><b>Punakha Dzong:</b> Also known as the Palace of Great Happiness, Punakha Dzong is located at the meeting point of the Pho Chhu (male) and Mo Chhu (female) rivers in the Punakha-Wangdue valley. It is one of the most scenic of Bhutan’s ancient fortresses. The Dzong is connected to the mainland by a wooden bridge and holds many important relics from when Bhutan's kings ruled from this valley. Dzong means "fort," and these structures were originally built for defence during invasions. Today, they serve as administrative centers and monastic hubs. In winter, Punakha Dzong becomes the monastic capital, hosting all administrative officials and monks for six months. Although Thimphu is now the capital, Punakha Dzong still hosts significant royal events like coronations and royal weddings, making it one of the most important Dzongs in Bhutan.</p>
                          <p><b>Suspension Bridge:</b> This is a major attraction in Punakha, known for being the longest suspension bridge in Bhutan, stretching about 180 meters over the Mochu (male) river. The view from the bridge, with its clear river and surrounding mountains, is breathtaking. The bridge is decorated with prayer flags on both sides and is an excellent spot for bird watching and photography. Walking on the bridge among and viewing the scenic beauty will be a life time experience during your <strong>Bhutan tour from Kolkata</strong>.</p>
                          <p>After exploring all these wonderful places, in the evening return back to Thimphu. Evening is free for leisure. Overnight at the hotel in Thimphu.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 03: THIMPU SIGHTSEEING AND TRANSFER TO PARO</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Wake up early morning and see the serene beauty of sun kissed valley of Thimphu while sipping hot tea. This is the third day of your <strong>Bhutan tour started from Kolkata</strong>.  After a heavy breakfast proceed for following tourist attractions:
                          </p>
                          <p>
                            <b>The National Memorial Chorten :</b> It is a site for evening prayer. The chorten or stupa was built in the memory of the third king, continuously circumambulated by people, murmuring mantras and spinning their prayer wheels. Construction of this landmark was the idea of Bhutan's third king, His Majesty Jigme Dorji Wangchuk ("the father of modern Bhutan") who has wished to erect monument to world peace and prosperity. Completed in 1974 after his untimely death, it serves both as a memorial to the Late King and as a monument to peace.
                          </p>
                          <br />
                          <p><b>Sakyamuni Buddha :</b> On the top of Kuensel Phodrang hill sits the statue of Sakyamuni Buddha. The 51.5 meter long bronze statue would surely attract you and the uninterrupted view of the valley would leave you spellbound. The site is also known as Buddha View Point.</p><br />
                          <p><b>Changangkha Lhakhang :</b> At Thimphu you will go across several monasteries and temples. Changangkha Lhakhang is among the most important religious structures in Thimphu. The structure dates back to the 15th century and is one of the oldest in the region. The structure is dedicated to Avalokiteshvara, the sign of compassion. You will also enjoy the grand view of Thimphu valley from here.</p><br />
                          <p><b>Takin Zoo :</b> You will find the National animal of Bhutan “Takin” in this open zoo. Takin is one of the most endangered species of the World and it is available only in Bhutan.</p>
                          <p><b>Zorig Chusum  :</b> Institute for Zorig Chusum (commonly known as the Painting School) where students undergo a 6-year training course in Bhutan's 13 traditional arts and crafts.</p>
                          <p><b>The Royal Textile Museum :</b> Thimphu also introduces visitors with the culture and heritage of Bhutan. The Royal Textile Museum at Norzin Lam is a site where you will get introduced to Bhutanese textiles, some of which date back to the 1600s.</p>
                          <p><b>Trashi Chhoe Dzong :</b> or the Fortress of the Glorious Region is another ‘must visit’ site. Situated on the west bank of Wang Chhu River, Trashi Chhoe Dzong is the formal coronation site of the fifth King. The Dzong also hosts the annual Tsechu festival. Built in 1641 by the political and religious unifier of Bhutan, Shabdrung Ngawang Namgyal, it was reconstructed in 1960s in traditional Bhutanese manner, without nails or architectural plans.</p>
                          <p>After completing all these sightseeing, proceed towards Paro. Thimphu to Paro is just 50km and takes only 1hrs drive. After reaching check-in hotel and night stay.</p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>Day 04: PARO TIGERS NEST MONASTERY HIKING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Today is the most exciting day of this <strong>Kolkata to Bhutan trip</strong>. After breakfast, start for Taktsang Hiking.</p>
                          <br />
                          <p><b>Taktsang Monastery :</b>  If you can brave the risky cliffs on a high mountain, Paro will astonish you in the form of Taktsang Monastery. After covering 1200 meters on a fierce cliff you will reach this sacred site, also known as the Tiger’s Nest. Take a deep breath after reaching the spot and then comfort your eyes and soul with the stunning view of the scenic surroundings and the valley lying underneath. The Taktsang Monastery was established by Guru Rinpoche who came to Bhutan in 747 CE. Construction of the complex was completed in 1694. Discovering the Tiger’s Nest after 3 hours of trekking is real adventure but you may also opt for a horse ride from the parking lot. </p>
                          <p>Kyichu Lhakhang : Kyichu Lhakhang, (also known as Kyerchu Temple or Lho Kyerchu) is an important Himalayan Buddhist temple situated in Lamgong Gewog of Paro District in Bhutan. The Jowo Temple of Kyichu is one of the oldest temples in Bhutan, originally built in the 7th century by the Tibetan Emperor Songsten Gampo.</p>
                          <p>Return back hotel. Evening is free for leisure. Overnight in Paro.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 05: CHELE-LA PASS EXCURSION & TRANSFER TO PHUENTSHOLING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>
                            Today morning after breakfast start for Chele-La Pass. Located at an elevation of about 3,988 meters (13,083 feet), it is one of the highest motor able pass in Bhutan. Situated about 35 kilometers from Paro, it is a popular destination for breathtaking views and a serene experience. The drive to Chele-La Pass from Paro takes around 1.5 to 2 hours, offering travelers stunning landscapes along the way.
                          </p>
                          <p>
                            At the pass, visitors are greeted with panoramic views of the surrounding valleys and the majestic Himalayas. On clear days, you can see the snow-capped peaks of Mount Jomolhari and other high mountains in Bhutan. The area around the pass is often covered with colorful prayer flags that flutter in the wind, adding a spiritual touch to the landscape.
                          </p>
                          <p>Chele-La Pass is also known for its scenic beauty, with forests of pine and rhododendron trees that bloom in vibrant colors, especially in the spring. The area is a popular spot for photography and nature walks. The high altitude means that the weather can be quite cold, even in summer, so it's advisable to dress warmly.</p>
                          <p>After a delightful excursion of this wonderful place, start descending towards Phuentsholing. Chele-La Pass to Phuentsholing is almost 170km and can takes approx. 5 hrs depending upon weather and road condition. On reaching Phuentsholing, get fresh, and enjoy the leisurely hours. Overnight here at hotel in Phuentsholing.</p>

                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4> DAY 06: DEPARTURE DAY– PHUNTSHOLING TO HASIMARA RLY STN DROP FOR THE EXIT</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and move towards Hasimara Rly Stn. Proceed to your final destination with sweet memories of <strong>Bhutan tour</strong>.
                          </p>
                          {/* <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              <br />  <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR PACKAGE FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR PACKAGE FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Hike to Taktsang Monastery (Tiger's Nest) - Paro</td>
                          <td style={{ fontSize: "16px" }}>Take the Dagala Thousand Lakes Trek - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                          <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                          <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Explore Simtokha Dzong - Thimphu</td>
                          <td style={{ fontSize: "16px" }}>Explore the Chele La Pass</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Visit the Buddha Cave & Buddha Falls</td>
                          <td style={{ fontSize: "16px" }}>Visit Trongsa Dzong - Trongsa</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Visit Rinpung Dzong - Paro</td>
                          <td style={{ fontSize: "16px" }}>Visit Trashigang Dzong - Trashigang District</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Take a Bike Trip around Bhutan</td>
                          <td style={{ fontSize: "16px" }}>Go Camping in Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Try Archery - Bhutan’s National Sport</td>
                          <td style={{ fontSize: "16px" }}>Go Mountain Biking in Bhutan</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Visit the Tashichho Dzong - Thimphu</td>
                          <td style={{ fontSize: "16px" }}>Visit the Buddha Dordenma - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Hike in the beautiful Haa Valley - Haa</td>
                          <td style={{ fontSize: "16px" }}>Relax with a Hot Spring Therapy</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Experience the Nimalung Tsechu Festival</td>
                          <td style={{ fontSize: "16px" }}>Take a scenic drive at Dochula Pass</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Attend the Annual Black-Necked Crane Festival</td>
                          <td style={{ fontSize: "16px" }}>Try Kayaking on the Wang Chhu River - Paro</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Go Rafting in the Mo Chhu River - Punakha</td>
                          <td style={{ fontSize: "16px" }}>Explore Punakha Dzong - Punakha</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Get a Blessing at Gangtey Monastery - Wangdue Phodrang</td>
                          <td style={{ fontSize: "16px" }}>Pray at Chimi Lhakhang Temple - Punakha</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Spot Snow Leopards at Jigme Dorji National Park</td>
                          <td style={{ fontSize: "16px" }}>Try Bhutanese Cuisine - especially Ema Datshi</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP 15 PLACES TO EXPLORE DURING A <strong>BHUTAN TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP 15 PLACES TO EXPLORE DURING A <strong>BHUTAN TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <li><b>1. Paro Taktsang (Tiger's Nest) :</b> Famous monastery perched on a cliff.</li><br />
                  <li><b>2. Thimphu :</b> Bhutan's capital with sights like Tashichho Dzong.</li><br />
                  <li><b>3. Punakha Valley :</b> Beautiful valley with Punakha Dzong and Chimi Lhakhang.</li><br />
                  <li><b>4. Dochula Pass :</b> Offers stunning views of the Himalayas.</li><br />
                  <li><b>5. Rinpung Dzong :</b> A fortress-monastery in Paro.</li><br />
                  <li><b>6. Chele La Pass :</b> One of the highest motorable passes in Bhutan.</li><br />
                  <li><b>7. Haa Valley :</b> Peaceful valley with traditional Bhutanese villages.</li><br />
                  <li><b>8. Buddha Dordenma Statue :</b> Huge Buddha statue in Thimphu.</li><br />
                  <li><b>9. Phobjikha Valley :</b> Known for Gangtey Monastery and Black-necked cranes.</li><br />
                  <li><b>10. National Museum of Bhutan :</b> Located in Paro, showcasing Bhutanese art and history.</li><br />
                  <li><b>11. Jigme Dorji National Park :</b> Home to diverse plants and animals.</li><br />
                  <li><b>12. Kyichu Lhakhang :</b> One of Bhutan's oldest temples.</li><br />
                  <li><b>13. Drukgyel Dzong :</b> Historical site in the Paro Valley.</li><br />
                  <li><b>14. Tamzhing Lhündrup Monastery :</b> A hidden gem in Bumthang.</li><br />
                  <li><b>15. Kurjey Lhakhang :</b> Sacred monastery complex in Bumthang.</li><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Bhutan Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Q. How is the weather in Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseThree" className="accordion-collapse collapseShow" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Thimphu: Temperature ranges from -2.8°C in January to 22°C in summer, with 110 mm of rain.<br />
                          • High Mountains: Temperature varies from 0°C in winter to 10°C in summer, with 350 mm of rain.<br />
                          • Central Bhutan: Cool climate with warm summers and cool, dry winters.<br />
                          • Northern Bhutan: Cold winters with snow-covered peaks and cool summers due to high altitude.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                          Q. Can I use a Credit or Debit Card during my Bhutan tour?
                        </button>
                      </h4>
                      <div id="flush-collapseFour" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Credit/debit card use is limited in Bhutan.<br />
                          • You can withdraw cash from ATMs using your debit card, but check with your bank if your card is valid in Bhutan.<br />
                          • Service charges may apply for ATM withdrawals.<br />
                          • It's advisable to carry Indian cash, and you can bring up to ₹25,000 per person.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                          Q. Can we use Indian currency for Bhutan trip from Kolkata?
                        </button>
                      </h4>
                      <div id="flush-collapseFive" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Yes, Indian currency in small denominations (₹50, ₹100, ₹200) is accepted in Bhutan.<br />
                          • Note: ₹2000 notes are not accepted.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                          Q. Can we get vegetarian/Jain food in hotels/restaurants in Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseSix" className="accordion-collapse collapseShow" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Yes, pure vegetarian food is available in Thimphu and Paro.<br />
                          • Nivana Restaurant in Thimphu and Paro offers Gujarati, Rajasthani, and Jain food.<br />
                          • Hotel New Grand in Thimphu serves vegetarian dishes and is a good option for vegetarians.<br />
                          • Outside Thimphu and Paro, pure vegetarian restaurants are rare, but vegetarian food is available in hotels.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                          Q. Can a woman solo traveler explore Bhutan safely?
                        </button>
                      </h4>
                      <div id="flush-collapseSeven" className="accordion-collapse collapseShow" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Bhutan is considered very safe for women travelers.<br />
                          • It's best to travel via Paro Airport rather than through Phuentsholing.<br />
                          • A tour guide and driver are required for regional tourists (Indian, Maldivian, Bangladeshi).<br />
                          • You can request a female guide if preferred.<br />
                          • Avoid traveling at night and be cautious of unfamiliar situations.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                          Q. What is the Sustainable Development Fee (SDF) and how does it affect Indian tourists?
                        </button>
                      </h4>
                      <div id="flush-collapseEight" className="accordion-collapse collapseShow" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Bhutan's tourism policy is "Low Volume, High Value."<br />
                          • Indian tourists must pay an SDF of ₹1200 per person per night, excluding tour package costs (flights, hotels, meals, guides, transportation, monument fees).<br />
                          • This increases the cost but ensures quality tourism.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                          Q. How are the medical facilities in Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseNine" className="accordion-collapse collapseShow" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Limited medical facilities, with specialist doctors mainly available at Thimphu General Hospital.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                          Q. What is the baggage weight limit on Druk Air?
                        </button>
                      </h4>
                      <div id="flush-collapseTen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Economy class: 20 kg<br />
                          • Business class: 30 kg<br />
                          • Additional 5 kg for carry-on luggage.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                          Q. What is the currency of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseEleven" className="accordion-collapse collapseShow" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Ngultrum (Nu, BTN) is the official currency.<br />
                          • 1 Ngultrum is equal to 1 Indian Rupee.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                          Q. What is the national game of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseTwelve" className="accordion-collapse collapseShow" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Archery is the national game.<br />
                          • You can try archery during your Bhutan tour with bamboo, recurve, or compound bows.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                          Q. What is the national bird of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseThirteen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • The Raven is the national bird and is featured in the Royal Crown, representing the deity Gonpo Jarodongchen.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                          Q. What is the national flower of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseFourteen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • The Blue Poppy (Himalayan Blue Poppy) is the national flower, found in high-altitude regions.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                          Q. What is the language of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseFifteen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Dzongkha is the national language.<br />
                          • Over two dozen languages are spoken, with Nepali being one of the major ones.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
              <div className="modal-dialog">
                <div className="modal-content cust-modContent">
                  <div className="modal-header">
                    <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                    </h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                  </div>
                  <div className="modal-body">
                    <form className="row g-3">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name *"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.onChangeHandler} />
                      </div>
                      {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="10 Digit Mobile No.*"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email *"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-12">
                        <textarea
                          className="form-control"
                          placeholder="Comments"
                          rows="2"
                          name="comments"
                          value={this.state.comments}
                          onChange={this.onChangeHandler}></textarea>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <div className='container'>
                      <div className="row">
                        <div className='col-sm-4'>
                          {
                            (this.state.showLoader) &&
                            <div className='pull-left'><Digital size={25} /></div>
                          }
                        </div>
                        <div className='col-sm-8 '>
                          <div className='pull-right'>
                            <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button>
                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default KolkataToBhutanPackage;
