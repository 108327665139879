import React, { Component } from 'react';
import validation from "../../validation/validation";
import axios from 'axios';
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import 'react-toastify/dist/ReactToastify.css';
import Iframe from 'react-iframe'
import { Digital } from "react-activity";

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            btn: "Send",
            firstName: "",
            mobile: "",
            email: "",
            comments: ''
        }
    }



    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();
        var { firstName, mobile, email, comments } = this.state;
        if (firstName.length === 0) {
            cogoToast.error("Full Name Required.", { position: 'bottom-center' });
        }
        else if (email.length === 0) {
            cogoToast.error("Email Required.", { position: 'bottom-center' });
        }
        else if (mobile.length === 0) {
            cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
        }
        else if (mobile.length > 0 && !(validation.MobileRegx).test(mobile)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
        }
        else {

            this.setState({ btn: "Processing..", showLoader: true });
            try {
                let _ref = document.referrer;
                let _url = window.location.href;

                var referrer = document.referrer;
                console.log("referrer url", referrer);
                console.log(_url);

                var formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    URL: _url,
                    Referrer: _ref,
                    Comments: comments
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                // this.setState({ firstName: '', lastName: '', email: '', mobile: '', ... });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", { position: 'bottom-center' });
                this.setState({ btn: "Submit", showLoader: false })
            }
            catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Failed to submit form. Please try again later.", { position: 'bottom-center' });
            }
        }
    }

    render() {
        const topBannerImageURL = ApiURL.ContactUsImageURL + "/Bhutan-Tour-from-India.webp";
        const packageTopBanner2ImageURL = ApiURL.HomeBannerImageURL + "/bhutan-package-tour-from-mumbai-with-direct-non-stop-chartered-flight-mobile.webp";
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={topBannerImageURL} className="img-fluid px-0 mobile-image2" alt="..." />
                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><br /><br /><br />
                            FOR BOOKING <strong className='tomato'>BEST BHUTAN TOUR PACKAGE</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><hr /></h2>
                        <a href='tel:98833-59713'><img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour" /></a>
                    </div>
                </div>
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="site-heading">
                                        <h2>Contact Us<span>- Get Your Callback</span></h2>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="contact__wrapper shadow-lg">
                                            <div className="row no-gutters">
                                                <div className="col-lg-5 contact-info__wrapper gradient-brand-color order-lg-2">
                                                    <h3 className="color--white mb-5">Contact Address</h3>
                                                    <p>
                                                        <u>Corporate Office :</u>
                                                        <br />
                                                        Ground floor, 5, Dwarik Ganguly St, Anami Sangha, Kalighat, <br />
                                                        Kolkata, West Bengal - 700026<br /><br />
                                                        <u>Bhutan Office :</u><br />
                                                        Lango village,Paro,Bhutan<br />
                                                        <strong>Phone:</strong> +91 98833 59713 / +91 91636 76037 / +91 9163676018<br />
                                                        <strong>Email:</strong> adorablevacationllp@gmail.com<br /><br />
                                                        <u>Ladakh Office :</u><br />
                                                        NAC Complex, Zangsti Road, Leh Main Bazaar
                                                        First floor, (next to Air India office and IDBI Bank)
                                                        Leh, Jammu & Kashmir, India 194101<br /><br />
                                                        <u>Jammu & Kashmir Office :</u><br />
                                                        Kashmir University Road 190003, Saida Kadal Bridge, Rainawari, Srinagar, Jammu and Kashmir 190003<br />
                                                    </p>
                                                </div>
                                                <div className="col-lg-7 contact-form__wrapper p-5 order-lg-1">
                                                    <div className="row">
                                                        <div className="col-sm-12 mb-3">
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    onChange={this.onChangeHandler}
                                                                    className="form-control"
                                                                    name="firstName"
                                                                    value={this.state.firstName}
                                                                    placeholder="First Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <div className="form-group">
                                                                <input
                                                                    type="email"
                                                                    onChange={this.onChangeHandler}
                                                                    className="form-control"
                                                                    name="email"
                                                                    value={this.state.email}
                                                                    placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <div className="form-group">
                                                                <input
                                                                    type="tel"
                                                                    onChange={this.onChangeHandler}
                                                                    className="form-control"
                                                                    name="mobile"
                                                                    value={this.state.mobile}
                                                                    placeholder="10 Digit Mobile No.*" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 mb-3">
                                                            <div className="form-group">
                                                                <textarea
                                                                    onChange={this.onChangeHandler}
                                                                    className="form-control"
                                                                    name="comments"
                                                                    rows="4"
                                                                    value={this.state.comments}
                                                                    placeholder="Please leave your Comments">
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 mb-3">
                                                            <button type="button" className="btn btn-primary" onClick={this.onRequestCallbackSubmit}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                        <div className='col-sm-3'>
                                                            {
                                                                (this.state.showLoader) &&
                                                                <div className='pull-right'><Digital size={25} /></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="container-fluid">
                                        <Iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.5785018370557!2d88.34315877529893!3d22.51999217952915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02776caabc94c1%3A0x8f9358adc1539919!2sAdorable%20Vacation%20LLP!5e0!3m2!1sen!2sin!4v1703227948196!5m2!1sen!2sin"
                                            width="100%"
                                            height="350"
                                            style={{ "border": 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default Contact;
