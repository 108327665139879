import React, { Component } from 'react';
import Slider from "react-slick";
import ApiURL from "../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class Album extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PackagesWeOfferData: []
        }
    }

    componentDidMount() {
        const packagesOfferDataList = [
            {
                ImageName: "Bhutan-Trip.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,399/Person",
                NoOfTours: 43,
                NoOfDeparture: 38,
                MutedText: "International"
            },
            {
                ImageName: "Bali.webp",
                AllternateText: "...",
                Title: "Bali",
                Description: "3N Kuta | 2N UBUD | Sightseeing | Meal | Pvt. Transfer",
                PricePerNight: "26,599/Person",
                NoOfTours: 40,
                NoOfDeparture: 35,
                MutedText: "International"
            },
            {
                ImageName: "Maldives.webp",
                AllternateText: "...",
                Title: "Maldives",
                Description: "2N Water-Villa | 2N Beach-Villa | Private Transfer | All Meals",
                PricePerNight: "59,999/Person",
                NoOfTours: 38,
                NoOfDeparture: 34,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Thailand",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                NoOfTours: 42,
                NoOfDeparture: 37,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Thailand",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                NoOfTours: 42,
                NoOfDeparture: 37,
                MutedText: "International"
            },

        ];

        this.setState({ PackagesWeOfferData: packagesOfferDataList })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const AlbumImageURL = ApiURL.AlbumImageURL + "/" 
        // const packagesWeOfferDataList = this.state.PackagesWeOfferData;
        // const MyView = packagesWeOfferDataList.length > 0 ?
        //     (
        //         packagesWeOfferDataList.map((packageItem, i) => {
        //             const packageImgURL = ApiURL.InternationalExplorerImageURL + "/" + packageItem.ImageName;
        //             return (<div className="col" key={i}>
        //                 <div className="card h-100">
        //                     <img src={packageImgURL} className="card-img-top" alt={packageItem.AllternateText} />
        //                     <div className="card-body">
        //                         <h5 className="card-title">{packageItem.Title} <span> &#8377; {packageItem.PricePerNight}</span></h5>
        //                         <p className="card-text"><i className="fa fa-building tomato"></i>&nbsp;&nbsp;
        //                             <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
        //                             <i className="fas fa-car-side tomato"></i>&nbsp;&nbsp;
        //                             <i className="fa fa-person tomato"></i>&nbsp;&nbsp;
        //                             <i className="fa fa-camera tomato"></i>
        //                         </p>
        //                         <p className="card-text">{packageItem.Description}</p>
        //                         <p>
        //                             <span><b className="tomato">{packageItem.NoOfTours} </b>Tours</span> | &nbsp;
        //                             <span><b className="tomato">{packageItem.NoOfDeparture} </b>Departures</span>
        //                         </p>
        //                     </div>
        //                     <div className="card-footer">
        //                         <small className="text-muted">{packageItem.MutedText}</small>
        //                         <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
        //                     </div>
        //                 </div>
        //                 <br/><br/>
        //             </div>)
        //         })
        //     ) :
        //     (<div></div>)



        return (
            <>
                <div className="container-fluid py-5 bg-gray">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2><span>Gallery</span></h2>
                                <h4>Explorer India</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="album">
                            <div className="responsive-container-block bg">
                                <div className="responsive-container-block img-cont">
                                    <img className="img" src={AlbumImageURL +"srilanka-gallery-3.webp"} alt="Sri Lanka Gallery 3" />
                                    <img className="img" src={AlbumImageURL +"srilanka-gallery-4.webp"} alt="Sri Lanka Gallery 4" />
                                    <img className="img img-last" src={AlbumImageURL +"srilanka-gallery-1.webp"} alt="Sri Lanka Gallery 1" />
                                </div>
                                <div className="responsive-container-block img-cont">
                                    <img className="img img-big" src={AlbumImageURL +"srilanka-gallery-2.webp"} alt="Sri Lanka Gallery 2" />
                                    <img className="img img-big img-last" src={AlbumImageURL +"srilanka-gallery-5.webp"} alt="Sri Lanka Gallery 5" />
                                </div>
                                <div className="responsive-container-block img-cont">
                                    <img className="img" src={AlbumImageURL +"srilanka-gallery-7.webp"} alt="Sri Lanka Gallery 7" />
                                    <img className="img" src={AlbumImageURL +"srilanka-gallery-6.webp"} alt="Sri Lanka Gallery 6" />
                                    <img className="img" src={AlbumImageURL +"srilanka-gallery-8.webp"} alt="Sri Lanka Gallery 8" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default Album;