import React, { Component } from 'react';
import Slider from "react-slick";
import ApiURL from "../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class OthersPackages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PackagesWeOfferData: []
        }
    }

    componentDidMount() {
        const packagesOfferDataList = [
            {
                ImageName: "Bhutan-Trip.webp",
                AllternateText: "...",
                Title: "Bhutan EX-Mumbai",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,399/P",
                Nights: 7,
                Days: 8,
                MutedText: "International"
            },
            {
                ImageName: "Bali.webp",
                AllternateText: "...",
                Title: "Bhutan EX-Bagdora",
                Description: "3N Kuta | 2N UBUD | Sightseeing | Meal | Pvt. Transfer",
                PricePerNight: "26,599/Person",
                Nights: 7,
                Days: 8,
                MutedText: "International"
            },
            {
                ImageName: "Maldives.webp",
                AllternateText: "...",
                Title: "Kashmir Tulip Festival",
                Description: "2N Water-Villa | 2N Beach-Villa | Private Transfer | All Meals",
                PricePerNight: "59,999/Person",
                Nights: 12,
                Days: 13,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Shillong Cherapunjee Tour",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                Nights: 6,
                Days: 7,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Bhutan Ex-Pune",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                Nights: 8,
                Days: 9,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Bhutan Ex-Surat",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                Nights: 8,
                Days: 9,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Arunachal Ex-Guwahati",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                Nights: 42,
                Days: 37,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Bhutan Ex-Bangalore",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                Nights: 42,
                Days: 37,
                MutedText: "International"
            },
            {
                ImageName: "Thailand.webp",
                AllternateText: "...",
                Title: "Arunachal Ex-Mumbai",
                Description: "3N Phuket | 2N Krabi | Private Transfer | MEAL | Sightseeing",
                PricePerNight: "26,899/Person",
                Nights: 42,
                Days: 37,
                MutedText: "International"
            },
        ];

        this.setState({ PackagesWeOfferData: packagesOfferDataList })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const packagesWeOfferDataList = this.state.PackagesWeOfferData;
        const MyView = packagesWeOfferDataList.length > 0 ?
            (
                packagesWeOfferDataList.map((packageItem, i) => {
                    const packageImgURL = ApiURL.InternationalExplorerImageURL + "/" + packageItem.ImageName;
                    return (<div className="col" key={i}>
                        <div className="card h-100">
                            <img src={packageImgURL} className="card-img-top" alt={packageItem.AllternateText} />
                            <div className="card-body">
                                <h5 className="card-title tomato">{packageItem.Title}</h5>
                                <h5><span style={{color:"black"}}> &#8377; {packageItem.PricePerNight}</span></h5>
                                <p className="card-text"><i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fas fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-person tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text">{packageItem.Description}</p>
                                <p>
                                    <span><b className="tomato">{packageItem.Nights}</b>N</span> / 
                                    <span><b className="tomato">{packageItem.Days}</b>D</span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{packageItem.MutedText}</small>
                                <a href="tel:9883359713"><button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button></a>
                            </div>
                        </div>
                        <br/><br/>
                    </div>)
                })
            ) :
            (<div></div>)



        return (
            <>
                <div className="container-fluid py-5 bg-gray">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2>Hot <span>Deals</span></h2>
                                <h4>Explorer India</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner-two">
                                <div className="carousel-item active">
                                    <Slider {...settings}>
                                        {MyView}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </>
        );
    }
}

export default OthersPackages;