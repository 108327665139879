import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_arunachalkolkata_9n_10d";

class ArunachalTourKolkata_9N_10D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-mobile-banner.webp";
    const navDay1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-bhalukpong.webp";
    const navDay2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-dirang.webp";
    const navDay3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-tawang.webp";
    const navDay4ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-tawang.webp";
    const navDay5ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-dirang.webp";
    const navDay7ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-bomdila.webp";
    const navDay8ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-national-park-jungle-safari-package-tour.webp";
    const navDay9ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/manas-national-park-elephant-safari-package-tour.webp";
    const navDay10ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-with-ziro-valley.webp";
    

    const sightseen1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata.webp";
    const sightseen2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-from-kolkata.webp";
    const sightseen3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-package-from-kolkata.webp";
    const sightseen4ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-cost-from-kolkata.webp";
    const sightseen5ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-from-kolkata-airport.webp";
    const sightseen6ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-pradesh-package-tour-from-kolkata-airport.webp";
    const sightseen7ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-pradesh-tour-package-from-kolkata-airport.webp";
    const sightseen8ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-from-kolkata.webp";
    const sightseen9ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-package-from-kolkata-airport.webp";
    const sightseen10ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-from-kolkata-with-flight.webp";
    const sightseen11ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-itinerary-from-kolkata.webp";
    const sightseen12ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-itinerary-from-kolkata.webp";
    const sightseen13ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-plan-from-kolkata.webp";
    const sightseen14ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kolkata-to-arunachal-package-tour.webp";
    const sightseen15ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kolkata-to-arunachal-package-tour-booking.webp";
    const sightseen16ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-group-packages-from-kolkata.webp";
    const sightseen17ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-pradesh-tour-package-cost-from-kolkata-airport.webp";
    const sightseen18ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/explore-arunachal-pradesh-from-kolkata.webp";
    const sightseen19ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-package-tour-booking-from-kolkata.webp";
    const sightseen20ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-pradesh-package-tour-itinerary-from-kolkata.webp";
    const CallButtonSonaliImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/call-button-north-east-adorable-vacation.webp";
    const Adventure1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-with-adventure-1.webp";
    const Adventure2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-with-adventure-2.webp";
    const Adventure3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-with-adventure-3.webp";
    const valley1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-with-valley-1.webp";
    const valley2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-with-valley-2.webp";
    const valley3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachal-tour-with-valley-3.webp";
    const Review1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachak-package-tour-from-kolkata-review-1.webp";
    const Review2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachak-package-tour-from-kolkata-review-2.webp";
    const Review3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/arunachak-package-tour-from-kolkata-review-3.webp";
    const Elephant1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-national-park-elephant-safari-package-tour.webp";
    const Elephant2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-manas-national-park-elephant-safari-package-tour.webp";
    const Elephant3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-manas-nameri-national-park-safari-package-tour.webp";
    const Jeep1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-manas-nameri-pobitora-national-park-package-tour.webp";
    const Jeep2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-manas-national-park-package-tour.webp";
    const Jeep3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-elephant-safari-package-tour-booking.webp";
    const Animal1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-nameri-national-park-safari-package-tour-itinerary.webp";
    const Animal2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-national-park-jeep-safari-package-tour.webp";
    const Animal3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/kaziranga-elephant-safari-tour-packages-from-guwahati.webp";

    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="Arunachal package tour from kolkata" />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Arunachal Package Tour from Kolkata</strong> - BEST DEAL 2025</center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center><strong>Arunachal Package Tour from Kolkata</strong> - BEST DEAL 2025</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <a href="tel:9674738480"><img src={packageTopBanner2ImageURL} className="mobile-image" alt="Arunachal Pradesh tour packages from kolkata" /></a>
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        5 Places:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Bhalukpong
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Bomdila
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Tawang
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Dirang
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Kaziranga
                        </a>
                      </div>
                    </div>
                    <br />
                    <p className="p-2">
                    Adorable Vacation offers special <strong>Arunachal package tour from Kolkata</strong>, giving you the chance to explore the beauty of this hidden gem in northeastern India. Known as "<b>The Land of the Rising Sun</b>" Arunachal Pradesh is the first place in India to see the sunrise. With its stunning mountains, peaceful rivers, and lush green forests, it's a perfect destination for nature lovers and adventure seekers. Our <strong>Arunachal tour packages</strong> are designed to help you experience the best of Arunachal Pradesh, making your trip easy and memorable. <br /><br />
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong className='tomato'>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong>?<br /><br /> FOR BOOKING <strong className='tomato'>ARUNACHAL TOUR FROM KOLKATA</strong> <a href="tel:+91-9674738480"> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong className='tomato'>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong>?<br /><br /> FOR BOOKING <strong className='tomato'>ARUNACHAL TOUR FROM KOLKATA</strong> <a href="tel:+91-96747-38480"> ☎ CALL +91-96747-38480</a><br /><br /></h3>
                    <br />
                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> EMBARK ON AN EXTRAORDINARY JOURNEY TO <strong>ARUNACHAL PRADESH TOUR FROM KOLKATA</strong> WITH ADORABLE VACATION</h3>
                        <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> EMBARK ON AN EXTRAORDINARY JOURNEY TO <strong>ARUNACHAL PRADESH TOUR FROM KOLKATA</strong> WITH ADORABLE VACATION</h4>
                      </div>
                      <div className="card-body">
                        <p>Embark on an unforgettable adventure through the stunning landscapes of Arunachal Pradesh with our exclusive <strong>Arunachal package tour from Kolkata</strong>. This carefully crafted tour offers a chance to experience the untouched beauty of one of India's most scenic and serene states. From rolling hills and fast-flowing rivers to majestic mountains and lush valleys, Arunachal Pradesh's raw natural beauty is perfect for those seeking a peaceful escape from the hustle of everyday life. Our <strong>Arunachal Pradesh package tour from Kolkata</strong> is designed to give you a perfect balance of adventure, relaxation, and cultural immersion. <br /><br />

                          Known as the least visited state in India, Arunachal Pradesh is a hidden gem that offers winding green valleys, pristine rivers, and panoramic views of the mighty Himalayas. Whether you're looking to relax by the riverside or enjoy the thrill of outdoor adventures, our <strong>Arunachal package tour from Kolkata</strong> ensures you'll experience the state's untouched charm. For those who want to truly unwind and escape the noise of city life, the tranquil atmosphere of Arunachal Pradesh offers a much-needed retreat. <br /><br />

                          Along with its stunning natural beauty, Arunachal Pradesh is rich in culture and heritage. Our <strong>Arunachal Pradesh package tour from Kolkata</strong> includes visits to spiritual sites such as the famous Tawang Monastery. Here, you'll get a glimpse of the region's deep-rooted spiritual traditions and connect with its peaceful vibes. The tour also offers a chance to explore the vibrant customs and traditions of the local tribes, adding an enriching cultural experience to your journey. <br /><br />

                          Nature lovers will be delighted by the fragrant orchards filled with sweet oranges and the colorful beauty of orchids that decorate the landscape. The food scene is equally exciting, offering local delicacies that add a delicious dimension to your travel experience. Whether you're a food lover or an avid nature enthusiast, Arunachal Pradesh is sure to satisfy your senses. <br /><br />

                          Our <strong>Arunachal tour packages from Kolkata</strong> are designed to meet the needs of every traveler. Whether you're an adventurous trekker, a culture enthusiast, or simply looking for a peaceful retreat, we have the perfect itinerary for you. With seamless travel arrangements, our Arunachal packages ensure a smooth and enjoyable experience from start to finish. <br /><br />

                          Book your <strong>Arunachal Pradesh package tour from Kolkata Airport</strong> today, and let the breathtaking beauty and serene landscapes of Arunachal Pradesh awaken your soul. Every moment will be filled with discovery, and every landscape will leave you in awe. Don't miss out on this incredible journey-your escape to the Land of the Rising Sun awaits. <br /><br />Apart from this <strong>Arunachal tour package</strong>, we also offer <a href="https://adorablevacation.com/arunachal-pradesh-package-tour-from-guwahati/" target="_blank"><strong>Arunachal Pradesh package tour from Guwahati</strong></a>, <a href="https://adorablevacation.com/arunachal-pradesh-group-tour-packages-from-guwahati-airport-assam/" target="_blank"><strong>Arunachal Pradesh group package tour from Guwahati</strong></a>, <a href="https://adorablevacation.com/arunachal-pradesh-package-tour-from-mumbai/" target="_blank"><strong>Arunachal Pradesh package tour from Mumbai</strong></a> at Best Price. So, for any <strong>Arunachal Pradesh tour plan</strong>, Think Adorable Vacation - <strong>Arunachal tour specialist</strong>.<br/><br/></p>
                        <br /><br />
                        <table className="image-table">
                          <tbody>
                            <tr>
                              <td className="image-cell">
                                <img src={sightseen1ImageURL} alt="Arunachal Pradesh package tour from Kolkata Airport" />
                                <img src={sightseen2ImageURL} alt="Arunachal Pradesh tour Packages from Kolkata Airport" />
                                <img src={sightseen3ImageURL} alt="Arunachal Pradesh tour cost from Kolkata" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br /><br /><br />
                        <table className='img-center'>
                          <tbody>
                            <tr>
                              <td className="image-cell">
                                <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Arunachal pradesh tour packages from kolkata" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>KOLKATA TO ARUNACHAL TOUR PACKAGE</strong> : 9N | 10D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}> DURATION FOR <strong>KOLKATA TO ARUNACHAL TOUR PACKAGE</strong> : 9N | 10D</h4>
                </div>
                <div className="card-body">
                  <br />
                  <h4>DESTINATIONS COVERED IN <strong className='tomato'>ARUNACHAL TOUR STARTING FROM KOLKATA</strong> : BHALUKPONG 1N | BOMDILA 1N | DIRANG 2N | TAWANG 3N | KAZIRANGA 2N </h4><br />
                  <h4>PICKUP & DROP FOR <strong className='tomato'>ARUNACHAL TOUR PACKAGE FROM KOLKATA</strong> : LOKPRIYA GOPINATH BORDOLOI INTERNATIONAL AIRPORT [GAU]</h4>

                  <br /><br />
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger">
                          <th>CITY</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td>Kolkata</td><td>9 m</td><td>22.5744° N, 88.3629° E</td></tr>
                        <tr><td>Guwahati</td><td>51 m</td><td>26.1158° N, 91.7086° E</td></tr>
                        <tr><td>Bhalukpong</td><td>213 m</td><td>27.0137° N, 92.6345° E</td></tr>
                        <tr><td>Bomdila</td><td>2,415 m</td><td>27.2645° N, 92.4159° E</td></tr>
                        <tr><td>Dirang</td><td>1,620 m</td><td>27.3584° N, 92.2409° E</td></tr>
                        <tr><td>Tawang</td><td>3,048 m</td><td>27.6325° N, 91.7539° E</td></tr>
                        <tr><td>Kaziranga</td><td>225 m</td><td>26.6445° N, 93.3525° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br />  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Arunachal Tour Cost from kolkata" />
                          <img src={sightseen11ImageURL} alt="Arunachal Package tour from kolkata" />
                          <img src={sightseen12ImageURL} alt="Arunachal tour itinerary from kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE PLAN FOR <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE PLAN FOR <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              PICKUP FROM GUWAHATI - TRANSFER TO BHALUKPONG
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>BHALUKPONG TO DIRANG</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span> DIRANG DAY EXCURSION [MANDALA TOP, THEMBHANG VILLAGE]</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>DIRANG TO TAWANG TOUR</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>TAWANG - BUMLA PASS - TAWANG</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>TAWANG LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>TAWANG - BOMDILA</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingEight"
                          >
                            <b>DAY 8 → </b>
                            <span>BOMDILA TO KAZIRANGA NATIONAL PARK</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingEight"
                          >
                            <b>DAY 9 → </b>
                            <span>JUNGLE ACTIVITIES AT KAZIRANGA</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingEight"
                          >
                            <b>DAY 10 → </b>
                            <span>TRANSFER TO GUWAHATI [TOUR ENDS]</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    <table className='img-center'>
                      <tbody>
                        <tr>
                          <td className="image-cell">
                            <img src={Review3ImageURL} alt="ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS : <strong>KOLKATA TO ARUNACHAL PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS : <strong>KOLKATA TO ARUNACHAL PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              ALL TRANSPORTATION BY A DEDICATED AND EXCLUSIVE VEHICLE
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              ACCOMMODATION IN BEST PROPERTIES IN MENTION PLACES
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              ALL PARKING, TOLL CHARGES, FUEL CHARGES, STATE PERMITS ETC
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              ARUNACHAL PRADESH ENTRY PERMITS (ILP)
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /> <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}><strong>ARUNACHAL PRADESH TOUR PACKAGE COST</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><strong>ARUNACHAL PRADESH TOUR PACKAGE COST</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>CATEGORY</th><th>DURATION</th><th>COST</th><th>BOOK NOW</th></tr></thead>
                      <tbody>
                        <tr>
                          <td>Tawang Zemithang Package</td>
                          <td>7 Nights 8 Days</td>
                          <td>Rs. 16,999/P</td>
                          <td><a href="tel:9674738480" class="btn btn-success"><b>CALL NOW</b></a>
                          </td>
                        </tr>
                        <tr>
                          <td>Tawang Dirang Shergaon Package</td>
                          <td>7 Nights 8 Days</td>
                          <td>Rs. 17,199/P</td>
                          <td><a href="tel:9674738480" class="btn btn-success"><b>CALL NOW</b></a></td>
                        </tr>
                        <tr><td>Meghalaya Arunachal Package Tour</td><td>12 Nights 13 Days</td><td>Rs. 39,999/P</td> <td><a href="tel:9674738480" class="btn btn-success"><b>CALL NOW</b></a></td></tr>
                        <tr><td>North East Package Tour</td><td>10 Nights 11 Days</td><td>Rs. 37,999/P</td>
                          <td><a href="tel:9674738480" class="btn btn-success"><b>CALL NOW</b></a></td></tr>
                        <tr><td>Arunachal Tour Package</td><td>9 Nights 10 Days</td><td>Rs. 31,999/P</td>
                          <td><a href="tel:9674738480" class="btn btn-success"><b>CALL NOW</b></a></td></tr>
                        <tr><td>Assam Meghalaya Arunachal Tour</td><td>19 Nights 20 Days</td><td>Rs. 65,999/P</td>
                          <td><a href="tel:9674738480" class="btn btn-success"><b>CALL NOW</b></a></td></tr>
                      </tbody>
                    </table>
                  </div>

                  <br />
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA" />
                          <img src={sightseen5ImageURL} alt="ARUNACHAL PRADESH TOUR Packages FROM KOLKATA" />
                          <img src={sightseen6ImageURL} alt="Arunachal Tour Package from kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /> <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WITH <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong> DISCOVER A TRUE DREAM DESTINATION</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WITH <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong> DISCOVER A TRUE DREAM DESTINATION</h3>
                </div>
                <div className="card-body">
                  <p>Arunachal Pradesh, also known as the "<b>Land of Dawn-Lit Mountains</b>," is a beautiful state filled with stunning landscapes, rich culture, and exciting adventures. If you're planning an <strong>Arunachal package tour from Kolkata</strong>, this untouched gem in the northeastern part of India offers everything from peaceful nature to vibrant traditions and spiritual calm. It's truly a must-visit destination.<br /><br /></p>


                  <h4 className="tomato1">MESMERIZING NATURAL BEAUTY</h4>
                  <p><b>Arunachal Pradesh</b> is home to breathtaking places like the peaceful Mechuka Valley, the impressive Nuranang Falls, and high-altitude spots such as Madhuri Lake. The Sela Pass, with its snowy peaks and frozen lakes, offers an unforgettable experience. Places like Ziro Valley and Gorichen Peak showcase even more of the state's natural beauty, making the <strong>Arunachal Pradesh package tour from Kolkata via Guwahati Airport</strong> a truly enchanting experience.<br /><br /></p>


                  <h4 className="tomato1">THRILLING ADVENTURES</h4>
                  <p>If you love adventure, Arunachal Pradesh is perfect for you. You can trek through the green hills of Ziro, climb towering peaks near Bomdila, or go rafting on fast-flowing rivers. The Indo-China border near Tawang adds an exciting element to your journey. Whether you choose an <strong>Arunachal tour packages from Kolkata Airport</strong> or an offbeat adventure, this state has something for everyone.<br /><br /></p>


                  <h4 className="tomato1">CULTURAL RICHNESS AND TRIBAL TRADITIONS</h4>
                  <p>Arunachal Pradesh is home to over 26 tribes, each with its own unique traditions and customs. Festivals like the Ziro Music Festival and local rituals bring the state's culture to life. During your <strong>Kolkata to Arunachal tour by flight</strong>, you'll have the chance to experience colorful crafts and the fascinating lifestyles of the region's tribes.<br /><br /></p>


                  <h4 className="tomato1">SPIRITUAL AND HISTORICAL SIGNIFICANCE</h4>
                  <p>Arunachal Pradesh is also known for its deep spiritual significance. The Tawang Monastery, India's largest and the second-largest in the world, is a major highlight of the <strong>Arunachal Pradesh package tour from Kolkata</strong>. Sitting at 10,000 feet, this peaceful monastery offers a calm atmosphere and stunning views. The towns of Bomdila and Itanagar also add historical and cultural depth to your journey.<br /><br /></p>


                  <h4 className="tomato1">SERENITY AND POLLUTION-FREE ENVIRONMENT</h4>
                  <p>Known for its clean air and unspoiled beauty, Arunachal Pradesh is a peaceful escape from the busy city life. Whether you're watching the sunrise at Sela Pass or exploring quiet hillside villages, the calm surroundings make for a refreshing experience during your <strong>Arunachal tour from Kolkata</strong>.<br /><br /></p>


                  <p>From its natural wonders to its cultural richness, Arunachal Pradesh offers a perfect mix of adventure and tranquility. Book your <strong>Arunachal Pradesh package tour from Kolkata</strong> with Adorable Vacation and make unforgettable memories.<br /><br /></p>
                  <br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Arunachal Pradesh package tour from Kolkata" />
                          <img src={sightseen5ImageURL} alt="Arunachal tour from Kolkata" />
                          <img src={sightseen6ImageURL} alt="Arunachal Pradesh package tour from Kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES OF ATTRACTIONS IN <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES OF ATTRACTIONS IN <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>PLACES</th>
                          <th>ATTRACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td><b>Kolkata</b></td><td>Dakshineshwar Kali Temple, Belur Math, Victoria Memorial, Howrah Bridge, Kalighat Kali Temple, Mothers House, Indian Museum, Netaji Bhawan, Prinsep Ghat, Jorasanko Thakur Bari, Park Street, Marble Palace, St. Paul’s Cathedral, Science City, Birla Planetarium, Alipore Zoo, Nicco Park, Eco Park, Rabindra Sarobar, New Town, Biswa Bangla Gate, Indian Coffee House, Quest Mall, College Street, New Market, Kumartuli, Swami Vivekananda’s Ancestral House, Birla Mandir etc.</td></tr>

                        <tr><td><b>Bhalukpong</b></td><td>Sessa Orchid Sanctuary, Pakhui Wildlife Sanctuary, Bhalukpong Fort, Gontse Garden Rabgye Ling etc.</td></tr>

                        <tr><td><b>Bomdila</b></td><td>Bomdila Monastery, Apple Orchards, Bomdila View Point, Eaglenest Wildlife Sanctuary, Tipi Orchid Reserve, Upper Gompa, Lower Gompa, R.R Hills etc.</td></tr>

                        <tr><td><b>Tawang</b></td><td>Kameng River, Jaswant Garh, PT Tso Lake, Gorichen Peak, Tawang War Memorial, Tawang War Memorial, Madhuri Lake, Tawang Monastery, Sela Pass etc.</td></tr>

                        <tr><td><b>Kaziranga</b></td><td>Hollongapar Gibbon Sanctuary, Kaziranga National Orchid & Diversity Park, Ethnic Village (Karbi Anglong District), Hathikuli Tea Estate, Kakochang Waterfalls, Panbari Reserve Forest, Deopahar Ruins etc.</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen13ImageURL} alt="Arunachal Tour Package from kolkata" />
                          <img src={sightseen14ImageURL} alt="Arunachal package tour from kolkata" />
                          <img src={sightseen15ImageURL} alt="Arunachal pradesh package tour from kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHAT IS THE BEST TIME TO BOOK <strong>ARUNACHAL KAZIRANGA PACKAGE TOUR</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHAT IS THE BEST TIME TO BOOK <strong>ARUNACHAL KAZIRANGA PACKAGE TOUR</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>The best time to book your <strong>Arunachal Kaziranga package tour from Kolkata</strong> is during the winter holidays, from late November to the first week of April. Winter in the Northeast, which lasts from early November to late March, is long and offers perfect weather for a vacation. During this time, Kaziranga has pleasant weather with temperatures ranging from 6°C to 24°C. The winter mist adds to the charm, making it the perfect season for an adventure. One of the best ways to explore Kaziranga is with an elephant safari. Unlike jeep safaris, elephant safaris can take you to areas that are difficult to reach, offering a more personal wildlife experience. The vast plains of Kaziranga look especially beautiful in winter.<br /><br />

                    If you prefer a <strong>Jeep Safari in Kaziranga</strong>, winter is also a great time for that. While jeep safaris can be a bit dusty, they provide an exciting way to explore the park's different areas and get close to its wildlife. A Kaziranga Jeep Safari Package during winter is a fantastic way to see the park's natural beauty.<br /><br />

                    Although summer in Kaziranga can be hot and humid, you can still spot wildlife, especially in the cooler mornings and evenings. However, the monsoon season, from June to September, is not a good time to visit. The park gets flooded by the Brahmaputra River, and activities for both visitors and animals stop. To have a better and safer experience, it’s best to avoid visiting Kaziranga during the monsoon, from early June to late September.<br /><br /><br /></p>
                  <h4 className="tomato1">ANIMALS CAN BE SEEN DURING <strong> KAZIRANGA TOUR FROM KOLKATA</strong></h4>
                  <p>During your <strong>Arunachal Kaziranga tour packages from Kolkata</strong>, you can witness wild animals in the open air like - One Horned Rhinoceros, Asiatic water buffalo, Eastern swamp deer, Indian elephants, gaur, sambar, wild boar, hog deer, Bengal tigers, leopards, jungle cat, fishing cat, leopard cat, Indian gray mongoose, small Indian mongooses, large Indian civet, small Indian civets, Bengal fox, golden jackal, sloth bear, Chinese pangolin, Indian pangolins, hog badger, Chinese ferret badgers, flying squirrel etc. <br /><br /></p>
                  <h4 className="tomato1">BIRDS CAN BE SEEN IN <strong>KAZIRANGA PACKAGE TOUR FROM KOLKATA</strong></h4>
                  <p>Kaziranga is like a heaven for birders. Different species of birds that can be seen here are Great Indian hornbill, wreathed hornbill, lesser white-fronted goose, ferruginous duck, Baer's pochard duck, lesser adjutant, greater adjutant, black-necked stork, Asian openbill stork, Blyth's kingfisher, white-bellied heron, Dalmatian pelican, spot-billed pelican, Nordmann's greenshank, black-bellied tern, marsh babblers, common baya weaver, threatened Finn's weavers, Hodgson's bushchat, bristled grassbird, black-breasted parrotbill, rufous-vented grass babbler etc.<br /><br /></p>
                  <h4 className="tomato1"><b>ANIMALS THAT CAN BE FOUND AT KAZIRANGA NATIONAL PARK</b></h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr className="table-danger"><th>Name</th><th>No. (Approx)</th></tr></thead>
                      <tbody>
                        <tr><td>Greater One-Horned Rhinoceros</td><td>2413</td></tr>
                        <tr><td>Indian Elephant</td><td>1940</td></tr>
                        <tr><td>Tiger</td><td>118</td></tr>
                        <tr><td>Gaur</td><td>1300</td></tr>
                        <tr><td>Wild Asian water Buffalo</td><td>1666</td></tr>
                        <tr><td>Sambar</td><td>58</td></tr>
                        <tr><td>Eastern Swamp Deer</td><td>468</td></tr>
                        <tr><td>Indian Muntjac / Barking Deer</td><td>120</td></tr>
                        <tr><td>Wild Boar</td><td>431</td></tr>
                        <tr><td>Hog Deer</td><td>5045</td></tr>
                      </tbody>
                    </table>
                  </div>

                  <br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Animal1ImageURL} alt="Arunachal Tour Package from kolkata" />
                          <img src={Animal2ImageURL} alt="Arunachal package tour from kolkata" />
                          <img src={Animal3ImageURL} alt="Arunachal pradesh package tour from kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>KAZIRANGA JEEP SAFARI PRICE - [KOHORA / BAGORI / AGARATOLI]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>KAZIRANGA JEEP SAFARI PRICE - [KOHORA / BAGORI / AGARATOLI]</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>Range</th>
                          <th>Vehicle</th>
                          <th>Entry Fee/Indian</th>
                          <th>Entry Fee/Foreigner</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2700 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                        <tr>
                          <td>Western Range (Bagori)</td>
                          <td>INR 2800 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                        <tr>
                          <td>Eastern Range (Agaratoli)</td>
                          <td>INR 3200 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Jeep1ImageURL} alt="Sightseeing 7" />
                          <img src={Jeep2ImageURL} alt="Sightseeing 9" />
                          <img src={Jeep3ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>KAZIRANGA ELEPHANT SAFARI PRICE - [KOHORA RANGE / BAGORI RANGE]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>KAZIRANGA ELEPHANT SAFARI PRICE - [KOHORA RANGE / BAGORI RANGE]</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>Citizen</th>
                          <th>Ranges</th>
                          <th>Rate/hour</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Indian</td>
                          <td>Western Range (Bagori)</td>
                          <td>INR 1000</td>
                        </tr>
                        <tr>
                          <td>Foreigner</td>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2100</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Elephant1ImageURL} alt="Sightseeing 7" />
                          <img src={Elephant2ImageURL} alt="Sightseeing 9" />
                          <img src={Elephant3ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                  <p><b>NOTE</b> : <strong>Elephant Safari at the Kohora Range (Central) in Kaziranga National Park</strong> is reserved only for Foreign nationals and VIPs from India while the Bagori Safari Range (Western) offers Elephant Rides for Indian Nationals.</p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY ADORABLE VACATION IS #NO1 <strong>ARUNACHAL TOUR SPECIALIST FROM KOLKATA</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY ADORABLE VACATION IS #NO1 <strong>ARUNACHAL TOUR SPECIALIST FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <p><b>Pre-Purchased Room Inventory</b>: We offer a diverse range of pre-booked rooms in Arunachal Pradesh, guaranteeing you the best valley, mountain, or river-view rooms based on your booking date for the <strong>Arunachal package tour from Kolkata</strong>.<br /><br /></p>

                  <p><b>Premium Stays</b>: Enjoy a luxurious stay with our selection of Premium & Deluxe Hotels and Luxury Home Stays, offering the finest amenities for a comfortable and memorable <strong>Arunachal tour from Kolkata</strong> experience.<br /><br /></p>

                  <p><b>Seamless Transport</b>: Your <strong>Arunachal tour packages from Kolkata</strong> will include well-managed transportation options from key locations like Kolkata, Tezpur, Dibrugarh, Itanagar, and Jorhat, ensuring smooth and hassle-free travel.<br /><br /></p>

                  <p><b>Vehicle Variety</b>: Depending on your budget and preferences, choose from a range of vehicles such as Innova, Ertiga, Scorpio, or XUV 500 (Sunroof) to make your <strong>Kolkata to Arunachal tour</strong> comfortable and convenient.<br /><br /></p>

                  <p><b>Dedicated Operations Team</b>: We have a dedicated operations team based in the Northeast, ensuring uninterrupted support throughout your <strong>Arunachal tour from Kolkata</strong>.<br /><br /></p>

                  <p><b>Expert Guidance</b>: As Arunachal Super Specialists, we guide you through your journey, taking into account peak season rush, weather, and off-season conditions. Your safety and satisfaction are our top priorities, and we always ensure you get the best value for your money.<br /><br /></p>

                  <p><b>Experience You Can Trust</b>: With over 11 years of experience in organizing <strong>Arunachal tour packages from Kolkata</strong>, Adorable Vacation offers top-tier accommodations including Deluxe Homestays, Premium Stays, and Luxury Boutique Resorts for a comfortable stay.<br /><br /></p>

                  <p><b>Personalized Travel Experience</b>: Our team of passionate and experienced travelers personally inspects and certifies every hotel and homestay included in your itinerary, ensuring you have the best experience during your <strong>Arunachal Pradesh tour from Kolkata</strong>.<br /><br /></p>

                  <p><b>Group Tours Expertise</b>: We specialize in conducting <a href="https://adorablevacation.com/arunachal-pradesh-group-tour-packages-from-guwahati-airport-assam/" target="_blank"><strong>Arunachal Pradesh group tour from Guwahati</strong></a>. Every year, we host group departures during peak seasons like Summer Vacations, New Years Holidays, offering affordable and enjoyable travel for groups.<br /><br /></p>

                  <p><b>Complete Assistance</b>: From start to finish, our team provides day-to-day guidance and on-ground assistance during your <strong>Arunachal tour from Kolkata</strong>. We handle everything ourselves, with no third-party sub-agents involved, ensuring the highest level of service and attention throughout your <strong>Kolkata to Arunachal trip</strong>.<br /><br /></p>
                  <br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen16ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen17ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen18ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>TRAVEL DISTANCES TO NOTE FOR <strong>ARUNACHAL PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>TRAVEL DISTANCES TO NOTE FOR <strong>ARUNACHAL PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><th>FROM</th><th>TO</th><th>TRAVEL TIME / DISTANCE</th></tr></thead><tbody>
                        <tr><td>Guwahati</td><td>Bhalukpong</td><td>5 hr 9 min (225.7 km) via NH 15</td></tr>
                        <tr><td>Guwahati</td><td>Kaziranga</td><td>4 hr 16 min (192.8 km) via NH27</td></tr>
                        <tr><td>Bhalukpong</td><td>Dirang</td><td>4 hr 49 min (138.4 km) via NH 15</td></tr>
                        <tr><td>Bhalukpong</td><td>Tezpur</td><td>1 hr 12 min (57.7 km) via NH 15</td></tr>
                        <tr><td>Dirang</td><td>Sangti Valley</td><td>31 min (13.5 km) via NH13</td></tr>
                        <tr><td>Dirang</td><td>Tawang</td><td>4 hr 46 min (134.5 km) via NH13</td></tr>
                        <tr><td>Tawang</td><td>Bomdila</td><td>5 hr 57 min (175.7 km) via NH13</td></tr>
                        <tr><td>Bomdila</td><td>Sangti Valley</td><td>1 hr 30 min (49.6 km) via NH13</td></tr>
                        <tr><td>Bomdila</td><td>Tezpur</td><td>4 hr 46 min (153.9 km) via NH 15</td></tr>
                        <tr><td>Bomdila</td><td>Guwahati</td><td>7 hr 16 min (269.9 km) via NH 15</td></tr>
                        <tr><td>Tezpur</td><td>Kaziranga</td><td>1 hr 17 min (50.6 km) via NH715</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>HOW TO TRAVEL ARUNACHAL PRADESH FROM KOLKATA?</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>HOW TO TRAVEL ARUNACHAL PRADESH FROM KOLKATA?</h3>
                </div>
                <div className="card-body">
                  <h4 className='tomato1'>OPTION 1 : TRAVEL TO ARUNACHAL PRADESH BY FLIGHT FROM KOLKATA</h4>

                  <p>There are two main options for reaching <strong>Arunachal Pradesh by flight from Kolkata</strong>. If you're planning to visit the western part of Arunachal Pradesh, including places like Bhalukpong, Sangti, Tawang, Dirang, and Bomdila, it's best to fly to Guwahati Airport [GAU] from Kolkata. From there, you can start your <strong>West Arunachal Pradesh tour from Kolkata</strong>. <br /><br />

                    For those wanting to explore the eastern part of Arunachal Pradesh, such as Mechuka, Anini, and Aalo, it's recommended to fly to Dibrugarh Airport [DIB]. You can begin your <strong>East Arunachal Pradesh tour</strong> from there. <br /><br />

                    If you have enough time to cover the entire <strong>Arunachal Pradesh tour</strong>, including the western, central, and eastern regions (which requires a minimum of 13 to 14 nights), you can opt for the FIFO [fly-in, fly-out] method. This involves flying into Guwahati Airport to start the trip and then flying out from Dibrugarh Airport after completing the tour. <br /><br /></p>


                  <h4 className="tomato1">AIRPORT IN ARUNACHAL PRADESH </h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>AIRPORT NAME</th>
                          <th>LOCATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td>Itanagar Airport [HGI]</td><td>Itanagar</td></tr>
                        <tr><td>Pasighat Airport [IXT]</td><td>Pasighat</td></tr>
                        <tr><td>Tezu Airport [TEI]</td><td>Tezu</td></tr>
                        <tr><td>Zero Airport [ZER]</td><td>Ziro</td></tr>
                        <tr><td>Along Airport [IXV]</td><td>Aalo</td></tr>
                        <tr><td>Daporijo Airport [DEP]</td><td>Daporijo</td></tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><br />
                  <h4 className="tomato1">OPTION 2 : REACH ARUNACHAL PRADESH BY TRAIN FROM KOLKATA</h4>
                  <p>If you prefer to <strong>travel to Arunachal from Kolkata by train</strong>, Adorable Vacation recommends reaching Guwahati Station in Assam. Several trains run between Kolkata and Guwahati. Here are the available options: <br /><br /></p>


                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><th>Train Name</th><th>Service Days</th><th>Duration</th><th>Arrv Stn</th></tr></thead>
                      <tbody>
                        <tr><td>HWH GHY SPL [03057]</td><td>Tuesday</td><td>16 hrs 20 mins</td><td>Guwahati</td></tr>
                        <tr><td>SDAH AGTL SPL [03173]</td><td>Sun, Tue, Thr, Fri</td><td>35 hrs 20 mins</td><td>Agartala</td></tr>
                        <tr><td>Kamrup Express [15959]</td><td>Allday</td><td>36 hrs 10 mins</td><td>Dibrigarh</td></tr>
                        <tr><td>HWH GHY Weekly Sf Spl [25649]</td><td>All Day</td><td>1 day 17 hrs 45 mins</td><td>Guwahati</td></tr>
                        <tr><td>Saraighat Express [12345]</td><td>All Day</td><td>17 hrs 40 mins</td><td>Guwahati</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h4 className="tomato1">OPTION 3 : REACH ARUNACHAL PRADESH BY ROAD</h4>
                  <p>You can travel to Guwahati Airport or Guwahati Station by flight or train for your <strong>Arunachal package tour from Kolkata</strong>. However, once you arrive in Guwahati, you'll need to complete the rest of the journey by road. From Guwahati, you can start your Arunachal tour and head towards Bhalukpong, which is about 138.4 km away, or a 4-hour 36-minute drive. <br /><br /></p>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Review1ImageURL} alt="Travel with the Best DMC Bhutan" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY GUWAHATI AIRPORT IS THE BEST OPTION TO START <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY GUWAHATI AIRPORT IS THE BEST OPTION TO START  <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Guwahati Airport</b> rather <b>Lokpriya Gopinath Bordoloi International Airport [GAU]</b> is the ideal starting point for your <strong>Arunachal Pradesh tour from Kolkata</strong> due to its excellent connectivity and affordability with Kolkata Airport. As a major airport in the Northeast, <b>Guwahati Airport</b> offers seamless flight connectivity with <b>Kolkata</b>, making it easily accessible for travelers to start any Arunachal tour. Opting for a <strong>Kolkata to Arunachal tour by Flight</strong> ensures that you can quickly and comfortably begin your journey to the serene landscapes of Arunachal Pradesh. <br /><br />

                    The <strong>Arunachal trip cost from Kolkata</strong> is also highly cost-effective, with several airlines offering competitive fares, making it a budget-friendly option for travelers. Whether you're flying with <b>Air India</b>, <b>IndiGo</b>, or <b>SpiceJet</b>, you can find affordable flights that cater to different schedules, allowing you to <strong>plan your Arunachal trip</strong> according to your convenience. Furthermore, with <strong>Arunachal tour operators from Kolkata</strong>, like Adorable Vacation, managing your itinerary, you can expect a hassle-free experience from the moment you land. So, without any hesitation, all people of <b>City of Joy</b> explore the best offer from Adorable Vacation and book your <strong>Arunachal Package</strong> at earliest. <br /><br /><br /></p>



                  <h4 className="tomato1">FLIGHTS TO CHOOSE FOR <strong>ARUNACHAL PACKAGE TOUR FROM KOLKATA</strong></h4>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr><td>AIRLINES</td><td>ORIGIN</td><td>DESTINATION</td><td>DURATION</td></tr></thead>
                      <tbody>
                        <tr><td>IndiGo</td><td>Kolkata [07:25]</td><td>Guwahati [08:45]</td><td>01 h 20 m [non-stop]</td></tr>
                        <tr><td>IndiGo</td><td>Kolkata [10:05]</td><td>Guwahati [11:55]</td><td>01 h 50 m [non-stop]</td></tr>
                        <tr><td>Air India</td><td>Kolkata [05:45]</td><td>Guwahati [07:00]</td><td>01 h 15 m [non-stop]</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST TIME TO BOOK <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST TIME TO BOOK <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>When planning a <strong>trip to Arunachal Pradesh from Kolkata</strong>, it's important to choose the best time to visit, as the weather and scenery change significantly throughout the year. Here's a breakdown by season to help you pick the ideal time for your <strong>Arunachal Pradesh package tour from Kolkata</strong>:<br /><br /><br /></p>


                  <h4 className='tomato1'>SUMMER IN ARUNACHAL PRADESH [MARCH - JUNE]</h4>
                  <p>Summer is one of the best times to book an <strong>Arunachal tour from Kolkata</strong>. The weather is pleasant, with temperatures ranging from 15°C to 25°C, making it ideal for outdoor activities. During this period, the landscapes are lush and vibrant, offering beautiful views of mountains, valleys, and lakes. Popular destinations like <b>Tawang</b>, <b>Bomdila</b>, <b>Mechuka</b>, and <b>Ziro</b> are great for sightseeing, trekking, and cultural experiences. <strong>Arunachal travel packages from Kolkata</strong> are in high demand in the summer, so it's a great time for families, groups, or solo travelers seeking a comfortable and enjoyable experience. <br /><br /><br /></p>


                  <h4 className='tomato1'>MONSOON IN ARUNACHAL PRADESH [JULY - SEPTEMBER]</h4>
                  <p>The monsoon season brings heavy rainfall, turning Arunachal Pradesh into a lush green paradise. However, this time is not ideal for outdoor activities like trekking or road travel, as the rains can cause landslides and flooding in some areas. If you're considering an <strong>Arunachal Pradesh package tour from Kolkata</strong> during the monsoon, make sure to check weather and road conditions first. It's the off-peak season, so <strong>Arunachal tour from Kolkata</strong> may be more affordable, but it's best suited for those who enjoy the rainy season and don't mind occasional travel disruptions. <br /><br /><br /></p>


                  <h4 className='tomato1'>AUTUMN IN ARUNACHAL PRADESH [OCTOBER - NOVEMBER]</h4>
                  <p>Autumn is another great time to book an Arunachal Pradesh package tour from Kolkata. The weather is pleasant and clear after the monsoon rains, with temperatures ranging from 10°C to 20°C. This season is perfect for trekking, sightseeing, and experiencing cultural festivals, especially in places like <b>Ziro</b>. The clear skies and crisp air offer stunning views of sites like the <b>Tawang Monastery</b>, <b>Sela Pass</b>, and <b>Nuranang Falls</b>. <strong>Arunachal travel packages from Kolkata</strong> during autumn offer a good balance of affordability and favorable weather, making it an ideal time for those seeking an enriching experience. <br /><br /><br /></p>


                  <h4 className='tomato1'>WINTER IN ARUNACHAL PRADESH [DEC - FEBRUARY]</h4>
                  <p>Winter in Arunachal Pradesh is cold, with temperatures often dropping below 0°C in high-altitude areas like <b>Tawang</b> and <b>Dirang</b>. This is the perfect time for travelers who love snow, adventure, and winter sports. It's also the peak tourist season for those wanting to experience snow-capped mountains and frozen lakes. <strong>Arunachal tour from Kolkata</strong> during the winter offers the chance to enjoy beautiful landscapes, visit Buddhist monasteries, and stay in cozy homestays or luxury resorts. However, you should pack warm clothes and book your <strong>Arunachal Pradesh package tour from Kolkata</strong> early, as this is the most popular time to visit. <br /><br /><br /></p>


                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr>
                          <th>Month</th>
                          <th>Weather</th>
                          <th>Temperature</th>
                        </tr>
                      </thead>
                      <tr>
                        <td>December</td>
                        <td>Cool and Dry</td>
                        <td>1°C - 3°C</td>
                      </tr>
                      <tr>
                        <td>January</td>
                        <td>Cool and Dry</td>
                        <td>0°C - 3°C</td>
                      </tr>
                      <tr>
                        <td>February</td>
                        <td>Cool and Dry</td>
                        <td>3°C - 5°C</td>
                      </tr>
                      <tr>
                        <td>March</td>
                        <td>Warm and Sunny</td>
                        <td>8°C - 15°C</td>
                      </tr>
                      <tr>
                        <td>April</td>
                        <td>Warm and Sunny</td>
                        <td>15°C - 22°C</td>
                      </tr>
                      <tr>
                        <td>May</td>
                        <td>Hot and Humid</td>
                        <td>20°C - 28°C</td>
                      </tr>
                      <tr>
                        <td>June</td>
                        <td>Hot and Humid</td>
                        <td>22°C - 29°C</td>
                      </tr>
                      <tr>
                        <td>July</td>
                        <td>Hot and Humid</td>
                        <td>22°C - 29°C</td>
                      </tr>
                      <tr>
                        <td>August</td>
                        <td>Hot and Humid</td>
                        <td>25°C - 34°C</td>
                      </tr>
                      <tr>
                        <td>September</td>
                        <td>Warm and Rainy</td>
                        <td>18°C - 22°C</td>
                      </tr>
                      <tr>
                        <td>October</td>
                        <td>Cool and Dry</td>
                        <td>16°C - 20°C</td>
                      </tr>
                      <tr>
                        <td>November</td>
                        <td>Cool and Dry</td>
                        <td>5°C - 10°C</td>
                      </tr>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>

              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DAY WISE DETAIL <span><strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong></span>
                      </h2>
                      <h4>9 Nights 10 Days <strong>Arunachal Tour Package from Kolkata</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <h4>DAY 01: GUWAHATI TO BHALUKPONG </h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Welcome to Guwahati Airport for your long-awaited <strong>Arunachal Pradesh package tour from Kolkata</strong>. After you check out from the airport, you'll be picked up and driven straight to Bhalukpong, which is about 260 km away. Bhalukpong is located by the beautiful Kameng River, in the West Kameng District, and is 56 km from Tezpur. Sitting at 700 feet above sea level, this small town lies along the Kameng River and is surrounded by dense forests near the Pakhui Wildlife Sanctuary. Bhalukpong is a perfect destination for wildlife lovers and nature enthusiasts. With its scenic views, tall waterfalls, and the riverbanks surrounded by lush, evergreen forests, it's a paradise for photographers. You will stay overnight in Bhalukpong. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="Arunachal Pradesh package tour from Kolkata" /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Arunachal Pradesh Tour Packages from kolkata" /></center>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <h4>DAY 02: BHALUKPONG TO DIRANG TOUR</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>This is the 2nd day of your <strong>Arunachal package tour from Kolkata</strong>. After a hearty breakfast, we will begin our journey towards Dirang. We'll enter Arunachal Pradesh through the Bhalukpong check post. Dirang is a charming town located in the West Kameng district, known for its stunning natural beauty. The area is famous for its vast apple orchards and sheep breeding farms, which add to its rural charm. As we drive through Dirang, you'll be surrounded by lush orchards filled with apples and kiwi, creating a scenic and serene atmosphere. The town offers a perfect escape into nature, with breathtaking views of the mountains and valleys. It's a peaceful place to relax and enjoy the outdoors. We will stay overnight at a comfortable hotel in Dirang to rest and prepare for the next part of our adventure. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="Arunachal Pradesh Tour Cost" /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Arunachal Pradesh Tour Itinerary" /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 03: EX DIRANG DAY EXCURSION TO MANDALA TOP & THEMBHANG VILLAGE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Today marks the third day of your <strong>Arunachal tour from Kolkata</strong>, and we'll be spending the entire day exploring the beautiful town of Dirang. After breakfast, we'll head to Mandala Top, a remarkable site featuring a series of 108 Buddhist stupas arranged in a circle atop a mountain in Arunachal Pradesh. Built in 2018, this new and impressive site is a must-visit for anyone on an <strong>Arunachal tour package from Kolkata</strong>. It's not only a place of spiritual significance but also attracts many birdwatchers, making it a great spot for nature lovers. The stunning views from the top offer the perfect backdrop for photography, so don't forget your camera. <br /><br />

                            We'll stop for lunch along the way, and then continue our drive towards the historic Thambang heritage village, which is a UNESCO-protected site. Thambang is an ancient village that dates back before the first century AD, making it one of the oldest in the region. The village was once called Yuchho-Pema-Chen, which translates to "lotus-like village." This cultural gem is full of history and is a great place to learn more about the local traditions and heritage. <br /><br />

                            After a day of sightseeing, we'll return to our hotel in Dirang in the evening. You'll spend the night there, ready for more adventures tomorrow. If you're considering booking an <strong>Arunachal package tour from Kolkata</strong>, make sure to consult with <strong>Arunachal Tour Operators from Kolkata</strong> for the best options and pricing. They can help you plan a seamless trip, including all the must-see destinations like Mandala Top and Thambang. <br /><br /></p>
                          <br />
                          {/* <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br /><br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>Day 04: DIRANG TO TAWANG TRANSFER</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Today, we'll be driving towards <b>Tawang</b>, passing through the stunning <b>Sela Pass</b>, which sits at an impressive height of 13,720 feet. This pass runs along a narrow ridge and offers views of the breathtaking Paradise Lake, making it a popular spot for travelers. We'll take a moment to stop and enjoy the views of Sela Pass and Paradise Lake, capturing the serene beauty of this high-altitude area. After soaking in the scenery, we'll continue our drive, heading down the winding roads toward Jong Village. <br /><br />

                            On our way to Jong, we'll make a special visit to the <b>Yashwant Garh War Memorial</b>, a poignant site that honors the soldiers who lost their lives in the 1962 India-China war. It's a place of great significance and a must-see for history buffs and anyone interested in the region's rich heritage.<br /><br />

                            After a peaceful lunch in Jong Village, we'll continue our journey to Tawang. On the way, we'll stop at the spectacular Nuranang Falls, a hidden gem of the region. The waterfall is surrounded by lush greenery, making it a beautiful spot for photographs.<br /><br />

                            As you explore Tawang and its surrounding areas, you might be considering a more convenient way to start your Arunachal adventure. If you prefer to save time, you can opt for a Kolkata to <strong>Arunachal tour by flight</strong>. This option offers a quicker and more comfortable way to reach Guwahati Airport, from where you can continue your journey by road to Tawang. Whether you choose a flight or drive, this scenic and historical tour of Tawang will surely be an unforgettable experience. You'll spend the night in Tawang, ready for more exploration tomorrow. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 05: TAWANG - BUMLA PASS - TAWANG EXCURSION</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Today, enjoy a full-day excursion to explore some of Tawang's most stunning sites. First, we'll visit <b>PT Tso Lake</b>, a serene and picturesque location perfect for nature lovers. Then, we'll continue our journey to <b>Bumla Pass</b>. Afterward, we'll head to Sangetsar Lake, which is also known as Madhuri Lake. The lake earned its name after Bollywood's legendary actress Madhuri Dixit, as it gained popularity after being featured in a film. Located 35 km from Tawang near the China border, this lake is not only beautiful but also historically significant. <br /><br />

                            The drive to <b>Sangetsar Lake</b> is filled with excitement, offering a thrilling experience as we pass through landscapes that tell stories of the past. Along the way, you can still spot remnants of war bunkers used during the 1962 India-China conflict, adding a historical touch to the journey. Once we arrive at the lake, you'll be mesmerized by its beauty and tranquil surroundings. <br /><br />

                            After spending time at Sangetsar Lake, we'll drive back to Tawang, where you'll spend the night, reflecting on the day's adventures. This excursion is a highlight of any <strong>Tawang tour from Kolkata</strong>, offering both natural beauty and a glimpse into the region's history. Whether you're traveling by road or planning a more convenient option like a flight, this tour promises a memorable experience of Tawang's best attractions.<br /><br /></p>
                          {/* <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                          <br /><br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4> DAY 06: FULL DAY TAWANG LOCAL SIGHTSEEING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>After breakfast in the morning, we'll start our full-day <strong>Tawang sightseeing tour</strong>. We'll begin by exploring Tawang city and its surroundings. Our first stop will be the famous Tawang Monastery, which is over 400 years old. The monastery is home to more than 500 monks and contains priceless Buddhist texts and scriptures. The Tibetan influence is clear, seen in the beautifully painted wooden windows and other designs. Inside, you'll find an impressive 8-meter-high gilded Buddha statue, along with many ancient Thankas (traditional paintings) and manuscripts.<br /><br />

                            Next, we'll visit the Craft Centre, where you can see local arts and crafts, and the Tawang War Memorial, which honors the soldiers who fought in the region. After these visits, you'll have some free time to relax and explore at your own pace. In the evening, you can enjoy the peaceful surroundings before returning to your hotel for the night.<br /><br />

                            If you're planning this trip as part of an <strong>Arunachal Pradesh package tour from Kolkata</strong>, you'll be able to enjoy the best of Tawang's cultural and historical landmarks, making it a truly enriching experience. <br /><br /></p>
                          {/* <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day7"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4> DAY 07: TAWANG TO BOMDILA</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Today is the 8th day of your <strong>Arunachal Pradesh tour from Kolkata</strong>. We will say goodbye to Tawang and begin our journey to the next destination. On the way, we will stop at Tipi Orchidarium, a beautiful place known for its variety of orchids. Afterward, we'll continue our drive to Bomdila, which is located at an altitude of around 8,000 feet. Bomdila is nestled among the majestic Himalayan mountain ranges and serves as the headquarters of the West Kameng district in Arunachal Pradesh. From here, you can enjoy stunning panoramic views of the Himalayan landscape. We'll spend the night in Bomdila, ready for more exploration tomorrow.<br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day8"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4> DAY 08: BOMDILA TO KAZIRANGA VIA KAMAKHYA DEVI TEMPLE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After a hearty breakfast, we begin the day with a visit to the famous Kamakhya Devi Temple. Located on the Nilachal Hill in Assam, Kamakhya Temple is one of the most revered pilgrimage sites in India. Dedicated to the Goddess Kamakhya, it is an important center for Tantric worship and a significant part of the 51 Shakti Peethas in India. After offering prayers at this historic temple, we'll continue our journey towards Kaziranga National Park, one of the top wildlife destinations in India. <br /><br />

                            Kaziranga covers an expansive area of 430 square kilometers and is home to the world's largest population of one-horned rhinoceroses. The park is also a sanctuary for a wide range of wildlife, including tigers, elephants, panthers, bears, and thousands of bird species. In 1985, Kaziranga was declared a UNESCO World Heritage Site, recognized for its exceptional biodiversity and natural beauty. A visit to Kaziranga is truly an immersive experience into India's rich wildlife and landscapes. <br /><br />

                            If you're planning a wildlife adventure, the <strong>Arunachal Assam package tour</strong> or <strong>Tawang Kaziranga package tour</strong> are excellent ways to experience both the cultural and natural wonders of the region. For a seamless experience, you can also opt for the <strong>Arunachal Kaziranga package tour from Kolkata</strong>, combining both the beauty of Tawang's monasteries and the breathtaking wildlife of Kaziranga. After a day of exploration, we'll spend the night at Kaziranga National Park, surrounded by the sounds of nature. <br /></p>
                            <br />
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day9"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4> DAY 09: JUNGLE SAFARI & EXPLORATION AT KAZIRANGA</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After an early wake-up, we'll take you to the designated riding point for the <strong>Kaziranga Elephant Safari</strong> in the Western Range. This is an incredible opportunity to get up close to the famous one-horned rhinos as they roam the jungle, you can observe them from as close as a stone's throw! Once your elephant safari is complete, we'll head back to the resort to relax and refresh. <br /><br />

                            After lunch, we'll drive to the Central Range of Kaziranga National Park for the exciting <strong>Kaziranga Jeep Safari</strong>. The Central Zone is the main ecotourism hub of the park, offering some of the best opportunities for wildlife sightings. The entry gate to this zone is located just 2 km from the main highway, passing through the village of Kohora. As you journey through the park, you'll have the chance to witness the magnificent rhinos grazing or wallowing in the vast grasslands, herds of elephants with their adorable babies, and a variety of other wildlife species native to this lush area. The sights and sounds of Kaziranga's diverse flora and fauna will make this safari truly unforgettable.<br /><br />

                            After a day full of adventure and wildlife sightings, you'll spend the night at Kaziranga National Park, immersed in nature's beauty and tranquility.<br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay9ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day10"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4> DAY 10: DROP TO GUWAHATI AIRPORT FOR RETURN JOURNEY TO KOLKATA</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>After an early breakfast, check out from your hotel and begin your transfer to Guwahati, which is approximately a 4-hour drive. Once you arrive at Guwahati, you'll be taken to the airport for your departure. Your <strong>Arunachal Pradesh package tour from Kolkata</strong> concludes here, leaving you with wonderful memories of your incredible journey through Arunachal Pradesh and Assam.<br /><br /></p>
                          <br />
                          {/* <div className="content-desktop">
                            <center><img src={navDay10ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /><br /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              <br />  <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST FOODS MUST TRY DURING <strong>ARUNACHAL PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST FOODS MUST TRY DURING <strong>ARUNACHAL PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Food plays an integral role in the culture of Arunachal Pradesh, with dishes that reflect the diverse traditions of its indigenous tribes. The staple food in Arunachal Pradesh is rice, which is served with most meals. The simplicity and versatility of rice make it the perfect base for the region's flavorful dishes.</p>


                  <p>One popular dish is <b>Panch Phoran Tarkari</b>, a quick, easy, and tasty curry made with a unique blend of spices and vegetables. It's a comforting dish that showcases the rich flavors of the region and is often enjoyed with rice. Another dish that stands out is <b>Laksa Stock</b>, a rich, spicy broth that features laksa noodles. This dish brings a warming, aromatic experience with its blend of spices and hearty noodles.</p>


                  <p><b>Bamboo Shoot</b> is a widely used ingredient in Arunachal Pradesh, adding a distinct flavor to many local dishes. It's used in curries, soups, and stir-fries, providing a unique texture and taste. For those who enjoy pickled foods, <b>Pika Pika</b> is a must-try. This spicy pickle is made from bamboo shoots, pork fat, and the fiery king chili, creating a bold and tangy condiment that accompanies many meals.</p>


                  <p><b>Lukter</b> is a dish that combines cooked dry meat with chili flakes from the notorious king chili, also known as Bhut Jolokia. It's a spicy treat for those who enjoy heat, delivering a powerful kick of flavor. For a spicier addition to your meal, try <b>Pehak</b>, a rich chutney made from fermented soya beans and chili. It's a tangy, spicy accompaniment that enhances the flavors of other dishes.</p>


                  <p>If you're looking to try a local drink, <b>Apong</b> is a traditional rice beer made from millet. This mildly alcoholic beverage is brewed locally and is often enjoyed during festivals and gatherings, offering a refreshing way to celebrate the culture of Arunachal Pradesh.</p>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES TO COVERE IN <strong>ARUNACHAL TOUR STARTING FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES TO COVERE IN <strong>ARUNACHAL TOUR STARTING FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Bhalukpong:</b> Begin your adventure with a trip to Bhalukpong, where you can indulge in thrilling activities such as trekking and river rafting in the Kameng River. The Pakhui Wildlife Sanctuary offers excellent wildlife spotting opportunities, where you might catch glimpses of tigers, elephants, and various species of birds. Visit the Tipi Orchid Research Center, home to hundreds of orchid varieties, and immerse yourself in the local Nyishi tribal culture. <br /><br /></p>


                  <p><b>Bomdila:</b> Located amidst the scenic beauty of the Himalayas, Bomdila offers a tranquil environment perfect for relaxation. You can visit craft centers to buy local handicrafts, embark on short hikes, and explore the Bomdila Monastery and Upper Gompa for a spiritual experience. The panoramic views of the Himalayan ranges are sure to leave you in awe. <br /><br /></p>


                  <p><b>Tawang:</b> Tawang is the highlight of any <strong>Arunachal Pradesh tour package from Kolkata</strong>. Known for its breathtaking landscapes, you can visit the Tawang Monastery, the largest Buddhist monastery in India, and pay respects at the Tawang War Memorial. Don't miss the stunning views of Sela Pass and Sela Lake, and discover the birthplace of the 6th Dalai Lama at Urgelling Monastery. For souvenir hunters, the local markets offer beautiful handicrafts that showcase the region's culture. <br /><br /></p>


                  <p><b>Dirang:</b> Dirang is a charming town that combines natural beauty with cultural attractions. Relax in the hot water springs, visit Dirang Dzong, and take a walk through apple orchards and kiwi farms. The trek to Sangti Valley offers some of the best scenic views, while Dirang Monastery and Kalachakra Gompa add a spiritual touch to your journey. <br /><br /></p>
                  <p>The <strong>Kaziranga package tour from Kolkata</strong> offers an unforgettable wildlife adventure, taking you to the world-renowned Kaziranga National Park. Known for its thriving population of one-horned rhinoceroses, the park also features diverse wildlife such as tigers, elephants, and rare bird species. This tour combines thrilling jeep and elephant safaris with the chance to explore the park's stunning landscapes. Perfect for nature lovers and wildlife enthusiasts, the Kaziranga tour provides an immersive experience into Assam's rich natural heritage. <br /><br /></p>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>ADVENTURE ACTIVITIES TO INDULGE WITH <strong>ARUNACHAL TOUR PLAN FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>ADVENTURE ACTIVITIES TO INDULGE WITH <strong>ARUNACHAL TOUR PLAN FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Arunachal Pradesh offers a variety of thrilling adventure activities that make it a must-visit destination for any travel enthusiast. Whether you're an adrenaline junkie or someone who simply loves exploring nature, this northeastern state of India promises unforgettable experiences. Here's a look at the exciting adventures you can enjoy during your <strong>Arunachal travel package from Kolkata</strong>:<br /><br /></p>

                  <p><b>Trekking:</b> Arunachal Pradesh is a trekker's paradise, with various trekking routes weaving through its scenic Himalayan landscapes. From the breathtaking views of Tawang, Bomdila, Bhalukpong, and Ziro's Talley Valley, to the secluded beauty of Mechuka, every trail offers an opportunity to discover diverse cultures and stunning vistas. These treks are perfect for adventure enthusiasts, making <strong>Arunachal tour from Kolkata</strong> a rewarding experience.<br /><br /></p>


                  <p><b>White Water River Rafting:</b> The mighty rivers of Arunachal Pradesh provide the perfect environment for white-water rafting. Jia Bharali River (on the Assam-Arunachal border) and the Siang River (near Pasighat) offer exhilarating rapids, making them ideal spots for thrill-seekers looking for a high-adrenaline rafting adventure during their <strong>Arunachal trip from Kolkata</strong>.<br /><br /></p>


                  <p><b>Mountaineering:</b> For mountaineers, Arunachal Pradesh presents an exciting challenge with unexplored peaks like Gorichen and Kangto. These towering peaks offer a tough yet rewarding mountaineering experience. Whether you're a seasoned climber or a beginner, <strong>Arunachal tour package cost from Kolkata</strong> provides the best opportunities to scale the heights of these majestic mountains.<br /><br /></p>


                  <p><b>Paragliding:</b> Get a bird's-eye view of the stunning valleys and mountains by indulging in paragliding in places like Dirang and Tawang. Soar above lush landscapes and enjoy the serenity and panoramic views, making it one of the top adventure activities in your <strong>Arunachal trip package from Kolkata</strong>.<br /><br /></p>


                  <p><b>Caving:</b> Arunachal Pradesh is home to numerous caves with fascinating geological formations. Explore hidden caves in areas like Daporijo and Namdapha and experience the thrill of underground exploration during your <strong>Arunachal tour from Kolkata</strong>.<br /><br /></p>


                  <p><b>Wildlife Safaris:</b> Nature lovers and wildlife enthusiasts can embark on safaris in the Namdapha and Pakke sanctuaries. These reserves are home to some of the rarest species in the world, such as the Red Panda and Snow Leopard, giving you a unique opportunity to spot exotic wildlife during your <strong>Arunachal travel packages from Kolkata</strong>.<br /><br /></p>


                  <p><b>Mountain Biking:</b> If you enjoy cycling, Menchuka offers some of the best trails for mountain biking, with rugged terrains and scenic views that make for an exhilarating ride. This is one of the best ways to explore the natural beauty of Arunachal Pradesh in an adventurous manner.<br /><br /></p>


                  <p><b>Rock Climbing:</b> Arunachal Pradesh has many natural rock formations, making it a great destination for rock climbing. Areas like Itanagar and Ziro are ideal spots for climbers, whether you're a beginner or an experienced rock climber, offering a thrilling challenge against the backdrop of beautiful landscapes. <br /><br /></p>


                  <p><b>Bird Watching:</b> Arunachal Pradesh's rich biodiversity makes it a haven for birdwatchers. Places like Bomdila and Tenga offer the perfect setting for birdwatching, where you can spot rare and endemic species of birds. This makes the region an excellent choice for nature enthusiasts. <br /><br /></p>


                  <p><b>Motorcycling Expeditions:</b> For motorcycling enthusiasts, Arunachal Pradesh offers some of the most challenging and scenic terrains, such as the famous Sela Pass. These motorcycle expeditions allow you to explore remote corners of the state, providing both an adventure and an opportunity to witness the region's untouched beauty. <br /><br /></p>
                  <p><b>Camping:</b> Arunachal Pradesh offers numerous camping opportunities, whether you prefer setting up camp in the forests, along riversides, or near high-altitude lakes. Camping amidst the wilderness is an immersive experience, with Eagle Nest Wildlife Sanctuary near Bomdila being a birder's paradise. Camping here offers a chance to experience nature up close and personal during your <strong>Arunachal tour package from Kolkata</strong>. <br /><br /></p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Adventure1ImageURL} alt="Sightseeing 7" />
                          <img src={Adventure2ImageURL} alt="Sightseeing 9" />
                          <img src={Adventure3ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SOME OFFBEAT PLACES TO EXPLORE IN <strong>ARUNACHAL TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>SOME OFFBEAT PLACES TO EXPLORE IN <strong>ARUNACHAL TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Arunachal Pradesh is a land of untouched beauty, with hidden gems that remain relatively unexplored, offering a perfect mix of adventure, culture, and nature. If you're seeking a unique and offbeat adventure, here's a look at some of the lesser-known yet breathtaking destinations you can experience on your <strong>Arunachal Pradesh package tour from Kolkata</strong>.<br /><br /></p>

                  <p><b>Dambuk:</b> Nestled in a picturesque valley, Dambuk is famous for its stunning landscapes, serene rivers, and the celebrated Orange Festival, which showcases the region's rich culture. The valley is also home to the indigenous Adi tribe, offering a deep insight into their traditions. With its pristine environment and the exhilarating Orange Festival, this place is a must-visit during your <strong>Arunachal tour package from Kolkata</strong>.<br /><br /></p>


                  <p><b>Pakke-Kessang:</b> Known for its untouched beauty, Pakke-Kessang is a hidden paradise for nature lovers. Home to the Pakke Wildlife Sanctuary, it's renowned for birdwatching, where you can spot a variety of rare and endemic bird species. The region is also inhabited by the Nyishi tribe, offering a blend of nature and culture. If you're interested in ecotourism, this is one destination that should not be missed on your <strong>Arunachal trip from Kolkata</strong>.<br /><br /></p>


                  <p><b>Doni Polo Village:</b> Immerse yourself in the authentic traditions of the Tagin tribe by visiting Doni Polo Village, located in the Upper Subansiri district. The village offers breathtaking views of the surrounding mountains and a cultural experience that allows you to witness the traditions of one of Arunachal's most unique tribes. This village is the perfect spot for those looking to explore tribal culture during their <strong>Arunachal tour package from Kolkata Airport</strong>. <br /><br /></p>


                  <p><b>Sangti Valley:</b> A tranquil valley surrounded by majestic mountains, Sangti Valley is known for its migratory Black-Necked Cranes, which visit the region every winter. The valley offers a peaceful retreat where visitors can bask in nature's serenity and observe rare wildlife. It's an ideal destination for birdwatchers and nature enthusiasts, making it a highlight of your <strong>Arunachal tour from Kolkata</strong>. <br /><br /></p>


                  <p><b>Miao:</b> Serving as the gateway to Namdapha National Park, Miao is a nature lover's paradise. The park is home to a rich variety of flora and fauna, including tigers, leopards, and elephants, making it a prime destination for wildlife enthusiasts. Miao offers not just adventure, but a deep connection to nature, making it a must-see on your <strong>Arunachal trip cost from Kolkata</strong>.<br /><br /></p>


                  <p><b>Tuting:</b> Tuting is an adventure lover's dream, offering river rafting on the thrilling Siang River. The region is rugged, raw, and offers a glimpse into the remote beauty of Arunachal Pradesh. Those seeking to explore pristine rivers and untouched landscapes should definitely include Tuting in their <strong>Arunachal Pradesh trip from Kolkata</strong>. <br /><br /></p>


                  <p><b>Roing:</b> Roing is one of Arunachal Pradesh's hidden gems, with its pristine lakes, rich tribal cultures, and the serene Mehao Wildlife Sanctuary. Roing offers a perfect mix of natural beauty and cultural experiences. It is ideal for those looking to explore less-touristy spots during their <strong>Arunachal Pradesh package tour from Guwahati Airport</strong>.<br /><br /></p>


                  <p><b>Khonsa:</b>Discover the tribal traditions of the Nocte tribe in Khonsa, a remote town that offers an offbeat travel experience. With untouched landscapes, Khonsa offers a glimpse into the tribal way of life and the beauty of unexplored territories. This destination is perfect for travelers who are looking for a unique experience during their <strong>Arunachal tour package from Kolkata</strong>. <br /><br /></p>


                  <p><b>Yinkiong:</b> Located in Upper Siang, Yinkiong is known for its beautiful landscapes and Tibetan Buddhist culture. With serene places like the Yamne River, this town offers a peaceful escape for those seeking tranquility. Yinkiong provides an immersive cultural experience and is a must-see for those on an <strong>Arunachal package tour from Kolkata</strong>. <br /><br /></p>


                  <p><b>Bhalukpong Hot Springs:</b> For those looking to relax, Bhalukpong is a great destination to enjoy the healing properties of natural hot springs. Located in the foothills of the Eastern Himalayas, the hot springs offer a peaceful retreat amidst nature's tranquility. This is a great stop to unwind during your <strong>Arunachal package from Kolkata</strong>. <br /><br /></p>


                  <p><b>Anini:</b> Known for its remoteness, Anini is a small town that offers a unique cultural experience. With its rich tribal heritage, Dibang Wildlife Sanctuary, and challenging terrain, Anini is a great destination for offbeat travelers. The journey to Anini itself is an adventure, making it an exciting addition to your <strong>Arunachal tour plan from Kolkata</strong>. <br /><br /></p>


                  <p><b>Menchuka:</b> Located near the China border, Menchuka is one of the most scenic destinations in Arunachal Pradesh. Known for its stunning landscapes, the pristine Siom River, and serene ambiance, Menchuka offers a rare opportunity to explore untouched beauty and is ideal for those seeking an offbeat destination in their <strong>Arunachal trip from Kolkata</strong>. <br /><br /></p>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Review3ImageURL} alt="Travel with the Best DMC Bhutan" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>VALLEYS MUST VISIT DURING <strong>ARUNACHAL TOUR PACKAGE FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>VALLEYS MUST VISIT DURING <strong>ARUNACHAL TOUR PACKAGE FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Arunachal Pradesh, known as the "<b>Land of the Rising Sun</b>" is home to some of the most breathtaking valleys that leave every traveler and nature lover in awe. The valleys here are not only picturesque but also offer lush greenery, pine forests, crystal-clear streams, and endless apple and orange orchards. If you're planning your <strong>Arunachal tour package from Kolkata Airport</strong>, exploring these valleys will be an unforgettable experience. Here's a glimpse of some of the most captivating valleys to explore during your <strong>Arunachal trip from Kolkata</strong>.<br /><br /></p>

                  <p><b>Sangti Valley:</b> Situated in the West Kameng District of Arunachal Pradesh, Sangti Valley is especially popular during the summer months due to its pleasant climate. Known for its apple, orange, and kiwi orchards, this valley is home to the majestic black-necked cranes. The combination of lush greenery and beautiful orchards makes it a must-visit destination for nature enthusiasts. If you're traveling via an <strong>Arunachal package tour from Kolkata Airport</strong>, don't miss out on the tranquility and beauty of Sangti Valley.<br /><br /></p>


                  <p><b>Mechuka Valley:</b> Located in the Shi Yomi District, Mechuka Valley sits at an altitude of 6,000 feet above sea level and is only 29 kilometers away from the Indo-China border. Surrounded by snow-capped mountains, mountain streams, and lush green meadows, Mechuka is one of the most picturesque valleys in Arunachal Pradesh. It's a popular destination for both nature lovers and adventurers, offering a blend of stunning scenery and unique cultural experiences. This valley is an essential stop in your <strong>Arunachal trip from Kolkata</strong>.<br /><br /></p>

                  <p><b>The Three Valleys:</b> A combination of Pakke Valley, Pappu Valley, and Passa Valley, these valleys together offer a truly magnificent landscape. In the summer, the valleys are adorned with wildflowers, and crystal-clear streams flow through the lush terrain. These valleys are also a haven for migratory birds, making them an ideal destination for birdwatchers. If you're a nature enthusiast, the Three Valleys are an excellent choice when planning your <strong>Arunachal package tour from Kolkata Airport</strong>. <br /><br /></p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={valley1ImageURL} alt="Sightseeing 7" />
                          <img src={valley2ImageURL} alt="Sightseeing 9" />
                          <img src={valley3ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO GET ILP [INNER LINE PERMIT] FOR ARUNACHAL TOUR STARTING FROM KOLKATA?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>HOW TO GET ILP [INNER LINE PERMIT] FOR ARUNACHAL TOUR STARTING FROM KOLKATA?</h4>
                </div>
                <div className="card-body">
                <p>It is important to note that due to Arunachal Pradesh's strategic significance, you will encounter a heavy deployment of Army personnel and military hardware in various regions. As such, to travel in sensitive areas of Arunachal Pradesh, you are required to obtain a special permit known as the <b>Inner Line Permit (ILP)</b>. This permit is mandatory to enter Arunachal Pradesh. After crossing Bhalukpong, travelers must produce the ILP in several locations such as Dirang, Bomdila, Tawang in the western part, and Itanagar in the eastern part. Additionally, for Ziro and other protected areas, a special permit called the <b>Restricted Area Permit (RAP)</b> is necessary, which can be conveniently arranged by Adorable Vacation. This process ensures that your <strong>Arunachal Pradesh package tour from Kolkata</strong> remains smooth, without any hassle regarding permits.<br /><br /></p>

                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4><strong>Arunachal Pradesh tour from Kolkata</strong> Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Can I get Jain Veg. food during my Arunachal Tour Package from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, but please inform us at the time of booking.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Are medical shops available widely across Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">It is better to carry prescribed and general medicines as many remote areas lack proper medical shops.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFive">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Do I need to carry high altitude medicines while traveling to Arunachal Pradesh Tour Plan from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Generally not required, but consult with your physician to be safe.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSix">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Can we visit any offbeat destination during our Arunachal Pradesh package Tour from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, offbeat destinations like Ziro, Mechuka Valley, Pasighat, and Aalo can be included in your package.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSeven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            How to reach Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Arunachal Pradesh is about 350 km from Guwahati Airport. You can book a car to reach Itanagar, the capital.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEight">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Who is the chief minister of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Shri Pema Khandu is the chief minister.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingNine">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                            How many districts are there in Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">There are 26 districts as of 2024.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                            How many Airports are there in Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">There are a few commercial airports, including Itanagar Airport (Donyi Polo Airport). The Indian Air Force also has several Advance Landing Grounds (ALG) in the state.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEleven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                            Which is the state animal of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Gayal, locally known as Mithun (Bos frontalis).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTwelve">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                            Which is the state bird of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Great hornbill (Buceros bicornis).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Which is the state flower of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Lady Slipper Orchid (Cypripedioideae).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFourteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                            Which line separates Arunachal Pradesh from Tibet?
                          </button>
                        </h4>
                        <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">McMahon Line.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFifteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                            Which is the main river in Arunachal Trip?
                          </button>
                        </h4>
                        <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Brahmaputra River, known as Yarlung Tsangpo in Tibet, Luit/Dilao in Assam, and Siang/Dihang in Arunachal.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSixteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                            Is it safe to travel to Arunachal Pradesh Tour Packages from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Arunachal Pradesh is very safe, but carry your identity cards and an Inner Line Permit (ILP).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSeventeen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                            Do I need a permit to travel to Arunachal Pradesh Tour Package?
                          </button>
                        </h4>
                        <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, an Inner Line Permit (ILP) is required.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEighteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                            Can I receive snowfall in Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, from the end of November to the end of April.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingNineteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                            How many days does it take to complete the Arunachal Tour Booking from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">A 10-day tour package is enough to cover vital places.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTwenty">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                            How are the accommodations in Arunachal Trip from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Accommodations are limited, so pre-booking is recommended. Homestays are available, and travel agents can help secure the best options.</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Arunachal <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-967473-8480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default ArunachalTourKolkata_9N_10D_Package;
