import React, { Component } from 'react';

class WhatWeOffer extends Component {
    render() {

        return (
            <>
                <div className="we-offer-area text-center bg-gray about-adorable">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="site-heading text-center">
                                    <h2>What We <span>Offer</span></h2>
                                    <h4>Travel Jackpot Services</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fas fa-hotel"></i>
                                    <h4>Hotel Reservation</h4>
                                    <p>
                                        'Adorable' is the ideal for travel and is one of the top hotel portals accross the
                                        world.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fab fa-cc-visa"></i>
                                    <h4>VISA</h4>
                                    <p>
                                        Simple and much less complicated than dealing with foreign governments.
                                    </p>
                                </div>
                            </div>
                            
                            <div className="col-md-3 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fas fa-plane-departure"></i>
                                    <h4>Flight Booking</h4>
                                    <p>
                                        Online flight ticket bookings just got easier & quicker. Booking done in a minute.
                                    </p>
                                </div>
                            </div>             
                            <div className="col-md-3 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fa fa-money fontIcon"></i>
                                    <h4>FOREX</h4>
                                    <p>
                                        Convert your currency at a very cheap rate. Exciting offer includes with holidays.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WhatWeOffer;