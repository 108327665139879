import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HomeBannerSliderData: []
        }
    }

    componentDidMount() {
        const bannerDataList = [
            { ImageName: "srilanka-package-tour-adorable-vacation.webp", AlternateText: "..." },
            { ImageName: "bhutan-package-tour-adorable-vacation.webp", AlternateText: "..." },
            { ImageName: "baku-azarbaizan-package-tour-adorable-vacation.webp", AlternateText: "..." },
            { ImageName: "kashmir-package-tour-adorable-vacation.webp", AlternateText: "..." },
            { ImageName: "japan-package-tour-adorable-vacation.webp", AlternateText: "..." },
            { ImageName: "sikkim-package-tour-adorable-vacation.webp", AlternateText: "..." },
            { ImageName: "shillong-cherrapunji-package-tour-adorable-vacation.webp", AlternateText: "..." },
        ];

        this.setState({ HomeBannerSliderData: bannerDataList })
    }

    render() {
        const packageTopBanner2ImageURL = ApiURL.HomeBannerImageURL + "/bhutan-package-tour-from-mumbai-with-direct-non-stop-chartered-flight-mobile.webp";
        const sliderList = this.state.HomeBannerSliderData;
        const MyView = sliderList.length > 0 ?
            (<Swiper
                spaceBetween={30}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                centeredSlides={true}
                autoplay={{ delay: 6000, disableOnInteraction: false }}
                /* onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')} */
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {sliderList.map((sliderItem, i) => {
                    console.log(sliderItem);
                    const sliderImgURL = ApiURL.HomeBannerImageURL + "/" + sliderItem.ImageName;
                    return (<SwiperSlide key={i}>
                        <div className="carousel-item active">
                            <img src={sliderImgURL} className="d-block w-100" alt={sliderItem.AlternateText} />
                        </div>
                    </SwiperSlide>)
                })}
            </Swiper>) :
            (<div></div>)

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div id="carouselExampleSlidesOnly" className="carousel slide px-0 mobile-image2" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                {MyView}
                            </div>
                        </div>
                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><br /><br /><br />
                            FOR BOOKING <strong className='tomato'>BEST BHUTAN TOUR PACKAGE</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><hr /></h2>
                        <a href='tel:98833-59713'><img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour" /></a>

                    </div>
                </div>
            </>
        );
    }
}

export default Banner;