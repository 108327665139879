import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ApiURL from "../../../api/ApiURL";

class IndividualIntervalsReview extends Component {
    
  render() {
    const Review1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flight-review-adorable-vacation-8.webp";
    const Review2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flight-review-adorable-vacation-12.webp";
    const Review3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flight-review-adorable-vacation-2.webp";
    const Review4ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flight-review-adorable-vacation-5.webp";
    const Review5ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flight-review-adorable-vacation-6.webp";
    const Review6ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flight-review-adorable-vacation-1.webp";
    return (
      <Carousel fade>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Review1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            {/* <h3 className='crousel-slide-h3'>Bhutan Tour Package from Mumbai</h3> */}
            {/* <p className='carousel-text-p'>Fly directly from Bangalore to Bhutan for a faster, more convenient travel experience</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Review2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          {/* <h3 className='crousel-slide-h3'>Bhutan Tour with Direct Chartered</h3> */}
         
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Review3ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            {/* <h3 className='crousel-slide-h3'>Bhutan Package tour from Mumbai with Direct Flight</h3> */}
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Review4ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            {/* <h3 className='crousel-slide-h3'>Bhutan Package tour from Mumbai with Direct Flight</h3> */}
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Review5ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            {/* <h3 className='crousel-slide-h3'>Bhutan Package tour from Mumbai with Direct Flight</h3> */}
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Review6ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            {/* <h3 className='crousel-slide-h3'>Bhutan Package tour from Mumbai with Direct Flight</h3> */}
           
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default IndividualIntervalsReview;
