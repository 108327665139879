import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_Bangalore";
import IndividualIntervalsExample from "./carousel-slide"
class BangaloreToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  render() {
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Bhutan-Tour Package-from-Bangalore.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Bhutan-Package-Tour-from-Bangalore.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/ADVENTURE-IN-BHUTAN.webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/HIKKING-IN-TIGER-NEST.webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/RAFTING-IN-BHUTAN-PUNAKHA.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-LUXURY-ACCOMMODATION.webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-LUXURY-STAY.webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-VIEW-ROOM.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-package-from-guwahati-airport-with-adorable-vacation-3.webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-package-from-guwahati-8.webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-BUDDHA-POINT.webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-CULTURE.webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-1.webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-TRADITATION.webp";
    const sightseen13ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-trip-tour-from-guwahati-with-adorable-vacation-9.webp";
    const sightseen14ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/travel-bhutan-from-guwahati-with-adorable-vacation.webp";
    const sightseen15ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-package-tour-from-guwahati-with-adorable-vacation-8.webp";
    const sightseen16ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-llp-9.webp";
    const sightseen17ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-package-tours-from-guwahati-airport-with-adorable-vacation-10.webp";
    const sightseen18ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/bhutan-tour-from-guwahati-airport-with-adorable-vacation-2.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/IXB-Airport–Phuentsholing.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Phuentsholing-to-Thimphu.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Thimphu-Local-Sightseeing.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Thimphu-to-Punakhka.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Punakha-to-Paro.webp";
    const navDay6ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Paro-Local-Sightseeing.webp";
    const navDay7ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Paro-to-Lataguri.webp";
    const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/call-button-bablu-adorable-vacation.webp";
    const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/call-button-sonali-adorable-vacation.webp";
    const Flight1ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-package-from-guwahati-with-direct-flight.webp";
    const Flight2ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
    const Flight3ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
    const Award1ImageURL = ApiURL.BhutanTourPackageFromNashikImageURL + "/International-Wellness-Destination-for-Paro-Travel-Leisure-Indias-Best-Awards-2024.webp";
    const Award2ImageURL = ApiURL.BhutanTourPackageFromNashikImageURL + "/Runner-Up-for-Favourite-Overseas-Destination-Condé-Nast-Traveller-Readers-Travel-Awards-2024.webp";
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromBangaloreImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );



    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromBangaloreImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="Bhutan Package Tour from Bangalore"
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Bhutan Package Tour from Bangalore</strong> - BEST DEAL 2025</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Bhutan Package Tour from Bangalore</strong> - BEST DEAL 2025</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Tour Packages from Bangalore" />

                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        3 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuentsholing
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lataguri
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      Bangalore, the Silicon Valley of India, thrives on its bustling IT industry, tight project deadlines, and relentless targets. The constant grind of corporate life can leave anyone yearning for a breather. If you're seeking to escape this high-pressure lifestyle, Bhutan offers the perfect retreat. Known for its serene landscapes, rich culture, and tranquil spirituality, Bhutan is the ideal destination to disconnect from stress and reconnect with inner peace. When you think of a break, think <strong>Bhutan package tour from Bangalore</strong> with Adorable Vacation. <br /><br />

                      Adorable Vacation, a trusted Destination Management Company (DMC), offers the best <strong>Bhutan tour package from Bangalore</strong>. Our curated <strong>Bangalore to Bhutan tour packages</strong> include departures from Bagdogra Airport, Kolkata Airport or Guwahati Airport. With competitive pricing, premium accommodations, and expertly planned itineraries, your <strong>Bhutan trip from Bangalore</strong> is sure to be seamless and unforgettable. So, when you think of Bhutan, think Adorable Vacation!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>BHUTAN TOUR PACKAGE FROM BANGALORE</strong>?<br /><br />
                    FOR BOOKING <strong>BANGALORE TO BHUTAN PACKAGES</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>BHUTAN TOUR PACKAGE FROM BANGALORE</strong>?<br /><br />
                    FOR BOOKING <strong>BANGALORE TO BHUTAN PACKAGES</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a> <br /><br /></h3>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ADORABLE VACATION : YOUR SPECIALIST FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ADORABLE VACATION : YOUR SPECIALIST FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <p>Are you a resident of Bangalore, where the constant buzz of the IT hub leaves you feeling drained after weeks of hard work and long hours? If you're in need of a break, it's time to escape the hustle and bustle of the city with a <strong>Bhutan package tour from Bangalore</strong>. What better way to unwind than by embracing the peaceful tranquility of Bhutan? With its pristine landscapes, clean air, and relaxed pace of life, Bhutan is the perfect destination to rejuvenate your mind and body. <br /><br />

                    A <strong>Bhutan package tour from Bangalore via Bagdogra Airport</strong> offers a serene retreat from the everyday grind. Bhutan, known as the "<b>Land of Happiness</b>" has earned a reputation as the happiest country in the world. It's no wonder that people from bustling cities like Bangalore flock to Bhutan in search of peace and contentment. This Himalayan kingdom is the perfect antidote to the noise, pollution, and chaos of urban life. Imagine a place where the air is fresh, there's no traffic congestion, and the only sounds you hear are the whispers of nature. Bhutan's natural beauty is awe-inspiring, from lush green valleys to snow-capped mountains, and its tranquil environment is a breath of fresh air for anyone looking to escape the daily grind. <br /><br />

                    Bhutan's unique approach to development focuses on Gross National Happiness rather than GDP, a concept that permeates the country's way of life. During your <a href="/bhutan-package-tour-from-bangalore-with-flight-from-kolkata-airport-guwahati-airport" target="_blank"><strong>Bhutan package tour from Bangalore with flight</strong></a>, you'll witness how this philosophy is reflected in the peaceful way of living, the well-preserved environment, and the emphasis on cultural traditions. Bhutanese people live in harmony with nature, and this balance between modernity and tradition makes it an ideal destination for those seeking solace in nature while exploring a rich cultural heritage. <br /><br />
                    Bhutan's festivals are an essential part of the country's cultural experience. If your visit coincides with one of the many annual festivals like the Paro Tshechu or Thimphu Tshechu, you'll be treated to colorful dances, religious ceremonies, and a chance to immerse yourself in Bhutanese traditions. These festivals are not just about entertainment but offer profound insights into Bhutan's spiritual and cultural heritage. <br /><br />

                    In addition to the cultural experiences, Bhutan's natural beauty will leave you speechless. From the rugged landscapes of the Dochula Pass to the breathtaking views of Mount Jomolhari, the kingdom offers some of the most stunning vistas in the world. Whether you're hiking through pine forests, taking scenic drives, or simply relaxing in a peaceful monastery, every moment spent in Bhutan feels like a return to a simpler, more harmonious world. <br /><br />

                    Embark on a <strong>Bhutan package tour from Bangalore</strong>, and experience the perfect blend of adventure, relaxation, and cultural immersion. This Bhutan tour promises to be an unforgettable journey where you'll not only explore breathtaking landscapes but also witness how Bhutan has harmonized tradition and modernity to create a lifestyle centered around happiness and peace. Book your <strong>Bangalore to Bhutan tour</strong> today and let the beauty and tranquility of this Himalayan paradise refresh your Mind and Soul.<br /><br /></p>
                  <p>Apart from <strong>Bhutan Package Tour From Bangalore</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like
                    <a href="/bhutan-package-tour-from-nagpur" target="_blank"><b class="tomato"> Bhutan Package Tour from Nagpur</b></a>, <a href="/bhutan-tour-package-from-pune" target="_blank"><b class="tomato">Bhutan Package Tour from Pune</b></a>, <a href="/bhutan-tour-from-delhi" target="_blank"><b class="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b class="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b class="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bangalore" target="_blank"><b class="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b class="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b class="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b class="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b class="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b class="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, <a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b class="tomato">Bhutan B2B Package Tour</b></a>, etc at Best Price.</p>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Bhutan package tour from Bangalore" />
                          <img src={sightseen3ImageURL} alt="Bhutan package tour from Bangalore Airport" />
                          <img src={sightseen2ImageURL} alt="Bhutan package tour Cost from Bangalore" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                 <br/><br/>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> : 7N | 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DURATION FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> : 7N | 8D</h4>
                </div>
                <div className="card-body">
                  <h4>DESTINATIONS COVERED IN <strong className="tomato">BANGALORE TO BHUTAN TOUR PACKAGES</strong> : PHUENTSHOLING 1N | THIMPHU 2N | PUNAKHA 1N | PARO 2N | LATAGURI 1N</h4><br />
                  <h4>PICKUP & DROP POINT FOR <strong className="tomato">BANGALORE TO BHUTAN TOUR</strong> : BAGDOGRA INTERNATIONAL AIRPORT [IXB] </h4>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>CITY</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Thimphu</td>
                          <td>2,334 m</td>
                          <td>27.4712° N, 89.6339° E</td>
                        </tr>
                        <tr>
                          <td>Punakha</td>
                          <td>1,274 m</td>
                          <td>27.5921° N, 89.8797° E</td>
                        </tr>
                        <tr>
                          <td>Paro</td>
                          <td>2,200 m</td>
                          <td>27.4287° N, 89.4164° E</td>
                        </tr>
                        <tr>
                          <td>Phobjikha Valley</td>
                          <td>3,000 m</td>
                          <td>27.4594° N, 90.1802° E</td>
                        </tr>
                        <tr>
                          <td>Chele La Pass</td>
                          <td>3,988 m</td>
                          <td>27.3698° N, 89.3466° E</td>
                        </tr>
                        <tr>
                          <td>Dochula Pass</td>
                          <td>3,100 m</td>
                          <td>27.2924° N, 89.4501° E</td>
                        </tr>
                        <tr>
                          <td>Tigers Nest Monastery</td>
                          <td>3,120 m</td>
                          <td>27.2930° N, 89.2148° E</td>
                        </tr>
                        <tr>
                          <td>Haa Valley</td>
                          <td>2,670 m</td>
                          <td>27.3333° N, 89.1833° E</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSoumalyaImageURL} alt="BANGALORE TO BHUTAN TOUR" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br/><br/>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DAY WISE SUMMERY FOR <strong>BHUTAN TOUR PLAN FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DAY WISE SUMMERY FOR <strong>BHUTAN TOUR PLAN FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              BAGDOGRA AIRPORT TO PHUENTSHOLING
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>PHUENTSHOLING TO THIMPHU</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>THIMPHU LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>PUNAKHA / WANGDUE TO PARO</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>PARO TO LATAGURI </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>LATAGURI TO BAGDOGRA AIRPORT DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>INCLUSIONS : <strong>BANGALORE TO BHUTAN PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>INCLUSIONS : <strong>BANGALORE TO BHUTAN PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              Accommodation in TCB listed Hotels (Double/Twin Sharing Room)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Meal Plan MAP (Breakfast and Dinner)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Parking, Toll Tax, E-Permits
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Thimphu Local Sightseeing
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Paro City Tour
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              English / Hindi Speaking Tour Guide
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              All transfer will be on point-to-point basis
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              SDF Charges (Sustainable Development Fee)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              01 Bottle Packaged Drinking Water Per Day on Vehicle
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Guest Service Support during the trip from Bhutan & India Office 24/7
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="BANGALORE TO BHUTAN TOUR Itinerary" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BANGALORE TO BHUTAN TOUR PACKAGES</strong> : YOUR GATEWAY TO PEACE & HAPPINESS</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BANGALORE TO BHUTAN TOUR PACKAGES</strong> : YOUR GATEWAY TO PEACE & HAPPINESS</h4>
                </div>
                <div className="card-body">
                  <p>Bangalore, often hailed as the Silicon Valley of India, is the nerve center of the country's booming IT industry. With its fast-paced lifestyle, relentless work deadlines, demanding projects, and ever-tightening targets, life in Bangalore often feels like a high-speed treadmill. The pressures of the corporate world can leave one yearning for a much-needed break to recharge and rejuvenate. What better way to escape the hustle and bustle of city life than by immersing yourself in the serene beauty and calming spirituality of Bhutan? <br /><br />

                    When you think of a break, think <strong>Bhutan tour from Bangalore</strong> with Adorable Vacation. Known for its breathtaking landscapes, rich culture, and tranquil environment, Bhutan offers the perfect antidote to the stress of urban living. Take a breather, disconnect from the daily grind, and reconnect with yourself in the peaceful lap of the Himalayas.<br /><br />

                    If you're planning an unforgettable trip to Bhutan and looking for a great <strong>Bhutan Package Tour from Bangalore</strong>, Adorable Vacation is your trusted partner. As a leading Destination Management Company (DMC), we provide expertly curated <strong>Bhutan tour packages from Bangalore</strong>. Our <strong>Bangalore to Bhutan tour</strong> options include convenient departures from locations like Bagdogra Airport, Hasimara Station, New Alipurduar Station, NJP Station, Siliguri Station, Jaigaon, or Phuentsholing Town.<br /><br />

                    We pride ourselves on offering the best rates for <strong>Bangalore to Bhutan packages</strong>, ensuring your <strong>Bhutan trip from Bangalore</strong> is not just affordable but also an unforgettable adventure. Whether you're seeking a peaceful retreat, an adventurous trek, or a cultural exploration, Bhutan has it all.<br /><br /></p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WITH <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> : DISCOVER A GLOBAL AWARD-WINNING DESTINATION</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WITH <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> : DISCOVER A GLOBAL AWARD-WINNING DESTINATION</h4>
                </div>
                <div className="card-body">
                  <p>As the 2025 travel season draws near, now is the perfect time to explore Bhutan - a serene Himalayan kingdom that's rapidly gaining recognition for its wellness offerings and rich cultural heritage. On December 16, 2024, Bhutan received two prestigious awards that reflect its rising prominence in global tourism: <b>International Wellness Destination for Paro</b> at the Travel + Leisure India's Best Awards 2024 and <b>Runner-Up in the Favourite Overseas Destination category</b> at the Condé Nast Traveller Readers' Travel Awards. These accolades highlight Bhutan's growing reputation as a top destination for sustainable and rejuvenating travel experiences.<br /><br />

                    If you're looking for a peaceful retreat that combines wellness, nature, and cultural immersion, Bhutan is a must-visit destination for you. Known for its pristine landscapes, spiritual ambiance, and commitment to sustainable tourism, Bhutan offers a unique opportunity to reconnect with nature and restore your inner balance. Whether you're looking to unwind in the tranquil valleys of Paro, explore ancient monasteries, or experience Bhutan's holistic wellness programs, the kingdom offers something for everyone seeking rejuvenation.<br /><br />

                    A <strong>Bhutan package tour from Bangalore</strong> is the perfect way to experience this peaceful haven. This once-in-a-lifetime journey will leave you with unforgettable memories, as you discover why Bhutan is now one of the world's most sought-after wellness destinations.<br /><br /></p>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={Award2ImageURL} alt="Bhutan package tour from Bangalore" /></a>
                        </td>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={Award1ImageURL} alt="Bhutan Trip From Bangalore" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>ENTRY REQUIREMENTS FOR INDIAN TOURISTS FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>ENTRY REQUIREMENTS FOR INDIAN TOURISTS FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">

                  <li>Indian citizens don't need a visa for a <strong>Bhutan Tour Package from Bangalore</strong>, but an entry permit is required. A local Bhutan tour agency like Adorable Vacation LLP Bhutan can help process this permit.</li><br />
                  <li>A valid passport (with at least 6 months' validity) or a Voter ID card is needed for the tour.</li><br />
                  <li>A single permit allows tourists to travel anywhere beyond Thimphu and Paro.</li><br />
                  <li>According to the new Tourism Policy, all regional tourists must pay a Sustainable Development Fee (SDF) of Rs. 1200 per person per night, in addition to the package price (covering flights, hotels, meals, guides, transportation, and monument fees).</li><br />
                  <li>All permits for <strong>Bhutan Package Tour From Bangalore</strong> will be processed online.</li><br />
                  <li>Hotel bookings and tour guides approved by the Tourism Council of Bhutan (TCB) are mandatory.</li><br />
                  <li>Online permits can be processed through local registered tour operators or any international partners.</li><br /><br />
                  <h5 className="tomato1">WHAT DOCUMENTS ARE NEEDED FOR <strong>BHUTAN TOUR FROM BANGALORE?</strong></h5><br />
                  <li>For child below 18 years a valid Birth Certificate is required. </li>
                  <li>A valid Indian passport with at least 6 months of validity from the intended departure date from Bhutan.</li>
                  <li>A valid voter ID card issued by the Election Commission of India.</li>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Bhutan Tour Package from Bangalore" />
                          <img src={sightseen11ImageURL} alt="Bhutan package tour from Bangalore" />
                          <img src={sightseen12ImageURL} alt="Bhutan Tour Itinerary from bangalore" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BHUTAN SIGHTSEEING TOUR & ENTRY FEES</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BHUTAN SIGHTSEEING TOUR & ENTRY FEES</b></h4>
                </div>
                <div className="card-body">

                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MUSEUMS & MONUMENTS</th>
                          <th>LOCATION</th>
                          <th>FEES(NU)</th>
                          <th>REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Bhutan Post Office Museum</td>
                          <td>Thimphu</td>
                          <td>150</td>
                          <td>9AM - 5PM</td>
                        </tr>
                        <tr>
                          <td>Folk Heritage Museum</td>
                          <td>Thimphu</td>
                          <td>200</td>
                          <td>9AM - 5PM</td>
                        </tr>
                        <tr>
                          <td>Zorig Chusum Arts and Crafts School</td>
                          <td>Thimphu</td>
                          <td>200</td>
                          <td>9AM - 4:30PM</td>
                        </tr>
                        <tr>
                          <td>Simply Bhutan Museum</td>
                          <td>Thimphu</td>
                          <td>1000</td>
                          <td>9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td>Takin Preserve Center</td>
                          <td>Thimphu</td>
                          <td>300</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>National Handicraft Emporium</td>
                          <td>Thimphu</td>
                          <td>100</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>National Library</td>
                          <td>Thimphu</td>
                          <td>100</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Jungshi Handmade Paper Factory</td>
                          <td>Thimphu</td>
                          <td>100</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>National Memorial Chorten</td>
                          <td>Thimphu</td>
                          <td>500</td>
                          <td>9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td>Zilukha Nunnery</td>
                          <td>Thimphu</td>
                          <td>100</td>
                          <td>9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td>Tashichho Dzong</td>
                          <td>Thimphu</td>
                          <td>500</td>
                          <td>5PM – 6PM, 9AM – 5PM (PH)</td>
                        </tr>
                        <tr>
                          <td>Lampelri Botanical Garden</td>
                          <td>Thimphu</td>
                          <td>300</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Changangkha Lhakhang</td>
                          <td>Thimphu</td>
                          <td>500</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>National Textile Museum</td>
                          <td>Thimphu</td>
                          <td>250</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Traditional Handmade Paper Mill</td>
                          <td>Thimphu</td>
                          <td>100</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Traditional Medicine Center</td>
                          <td>Thimphu</td>
                          <td>500</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Botanical Garden Servithang</td>
                          <td>Thimphu</td>
                          <td>200</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Ta Dzong / National Museum</td>
                          <td>Paro</td>
                          <td>300</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Taktshang / Tiger's Nest Temple</td>
                          <td>Paro</td>
                          <td>1000</td>
                          <td>9AM – 12PM, 2PM – 5PM</td>
                        </tr>
                        <tr>
                          <td>Kyichu Lhakhang</td>
                          <td>Paro</td>
                          <td>500</td>
                          <td>9AM – 12PM, 1PM – 4:30PM</td>
                        </tr>
                        <tr>
                          <td>Jangtsa Dumtseg Lhakhang</td>
                          <td>Paro</td>
                          <td>1000</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Tachogang Lhakhang</td>
                          <td>Paro</td>
                          <td>500</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Punakha Dzong</td>
                          <td>Punakha</td>
                          <td>500</td>
                          <td>9AM- 5PM</td>
                        </tr>
                        <tr>
                          <td>Chhimi Lhakhang</td>
                          <td>Punakha</td>
                          <td>500</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Khamsum Yulley Namgyal Chorten</td>
                          <td>Punakha</td>
                          <td>100</td>
                          <td>9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td>Sangchhen Dorji Lhuendrup Nunnery</td>
                          <td>Punakha</td>
                          <td>200</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Black-Necked Crane Education Center</td>
                          <td>Phobjikha</td>
                          <td>200</td>
                          <td>9AM-5PM</td>
                        </tr>
                        <tr>
                          <td>Gangtey Monastery</td>
                          <td>Phobjikha</td>
                          <td>100</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Trongsa National Museum</td>
                          <td>Trongsa</td>
                          <td>300</td>
                          <td>9AM-5PM</td>
                        </tr>
                        <tr>
                          <td>Swiss Factory</td>
                          <td>Bumthang</td>
                          <td>700</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Ogyen Choling Palace Museum</td>
                          <td>Bumthang</td>
                          <td>100</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Palden Tashi Choling Shedra</td>
                          <td>Phuentsholing</td>
                          <td>1000</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>PLACES OF ATTRACTIONS IN <strong>BANGALORE TO BHUTAN TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PLACES OF ATTRACTIONS IN <strong>BANGALORE TO BHUTAN TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>PLACES</th>
                          <th>ATTRACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td><b>Thimphu</b></td><td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td></tr>

                        <tr><td><b>Paro</b></td><td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>

                        <tr><td><b>Punakha</b></td><td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td></tr>

                        <tr><td><b>Phobjikha Valley</b></td><td>Gangte Goemba [Gangtey Monastery], Black-Necked Crane Information Centre, Gangtey Nature Trail, Nyelung Drachaling, Kuenzang Chholing Shedra, Khewang Lhakhang, Phobjikha Valley Hiking Trails, Kumbhu Lhakhang, Damcho Lhakhang, Dzongdrakha Goemba, Bumdeling Wildlife Sanctuary, Jigme Singye Wangchuck National Park, Gogona Village trek etc.</td></tr>

                        <tr><td><b>Wangdue Phodrang</b></td><td>Adha and Rukha Village, Bajo Town, Black Necked Crane Center, Dargay Goempa, Gangtey Monastery, Gaselo and Nahee Village, Khewang Lhakhang, Pele La Pass, Phobjikha Valley, Punatsangchhu Hydropower Plant, Rada Lhakhang, Rinchengang Village, Samtengang Village, Wangdue Phodrang Dzong, Wind Farm Rubesa etc.</td></tr>

                        <tr><td><b>Haa Valley</b></td><td>Juneydrak Hermitage, Katsho Goemba, Lhakhang Karpo, Lhakhang Nagpo, Rigsum Gonpo, Trout Farm, Wangchuk Lo Dzong, Yangthang Goemba etc.</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br />    <br />    <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Bangalore to bhutan package tour" />
                          <img src={sightseen5ImageURL} alt="Bangalore to bhutan package tour Itinerary" />
                          <img src={sightseen6ImageURL} alt="Bangalore to bhutan tour Cost" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
              <br /><br />
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> WHY ADORABLE VACATION IS THE BEST NATIONAL BRAND FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong>?</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY ADORABLE VACATION IS THE BEST NATIONAL BRAND FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong>?</h3>
                </div>
                <div className="card-body">
                  <ul>
                    <li><b>Trusted Brand : </b> Adorable Vacation has been the most trusted brand for <strong>Bhutan Package tour from Bangalore</strong> since last 10 years.<br /><br /></li>
                    <li><b>Passenger Service Agent (PSA) : </b> We are proud to be the PSA for Bhutan Airlines and Druk Airlines.<br /><br /></li>
                    <li><b>Luxury Packages : </b> Experience the best <a href="https://adorablevacation.com/bhutan-luxury-package-tour-with-six-senses-hotel/" target="_blank"><strong>Bhutan Luxury Package Tour with Hotel Six Senses</strong></a>, Amankora, COMO Uma, Le Méridien, and Pemako.<br /><br /></li>
                    <li><b>Super Peak Season Inventory : </b> We have pre-purchased hotel room inventory for peak season for <a href="https://adorablevacation.com/mumbai-special-chartered-flight/" target="_blank"><strong>Bhutan Package Tour with Chartered Flight</strong></a>.<br /><br /></li>
                    <li><b>Authorized Travel House : </b> We are an authorized travel house by the TCB - Tourism Council of Bhutan [License No: 1053228]. <br /><br /></li>
                    <li><b>Direct Employment : </b> Our employees in Bhutan are directly on our payroll, ensuring quality service.<br /><br /></li>
                    <li><b>Customized Itineraries : </b> We offer tailored <strong>Bhutan tour itineraries from Bangalore</strong> with unique and signature programs. <br /><br /></li>
                    <li><b>In-Depth Knowledge : </b> Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws. <br /><br /></li>
                    <li><b>Assured Services : </b> We ensure premium services to enrich your experience while in Bhutan. <br /><br /></li>
                    <li><b>E-Permit Processing : </b> We handle the e-permit process in advance for all your sightseeing needs. <br /><br /></li>
                    <li><b>Personalized Attention : </b> Enjoy safe and secure personal attention throughout your stay in Bhutan. <br /><br /></li>
                    <li><b>Complete Responsibility : </b> Adorable Vacation takes full responsibility for your entire <strong>Bhutan tour from Bangalore</strong>. <br /><br /></li>
                    <li><b>Hassle-Free Experience : </b> We guarantee hassle-free and trustworthy services in Bhutan. <br /><br /></li>
                    <li><b>Best Deals : </b> Access the best deals for top properties and services based on your preferences. <br /><br /></li>
                    <li><b>Flexible Travel Options : </b>Plan a customized <strong>Bhutan private trip</strong> or join a <strong>Bhutan group tour</strong> with Adorable Vacation. <br /><br /></li>
                  </ul>

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> QUICK FACTS NEED TO KNOW BEFORE PLANNING <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>QUICK FACTS NEED TO KNOW BEFORE PLANNING <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> </h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody><tr><th>Fact</th><th>Details</th></tr>
                        <tr><td>Capital</td><td>Thimphu</td></tr>
                        <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                        <tr><td>Official Language</td><td>Dzongkha</td></tr>
                        <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                        <tr><td>Area</td><td>38,394 km²</td></tr>
                        <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                        <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                        <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                        <tr><td>National Animal</td><td>the Takin</td></tr>
                        <tr><td>Major Religion</td><td>Buddhism</td></tr>
                        <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>

                        <tr><td>Notable Attractions in Bhutan</td><td>
                          Paro Taktsang, Dochula Pass, Punakha Dzong, National Memorial Chhorten, Tashichho Dzong, National Museum of Bhutan, Gangtey Monastery, Punakha Suspension Bridge, Royal Textile Academy, Trashigang Dzong, Ura valley, Dechen Phodrang Monastery, National Library & Archives Of Bhutan</td></tr>

                        <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>

                        <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen16ImageURL} alt="BHUTAN PACKAGE TOUR FROM BANGALORE" />
                          <img src={sightseen17ImageURL} alt="BHUTAN TOUR Packages FROM BANGALORE" />
                          <img src={sightseen18ImageURL} alt="Bangalore to Paro Direct Flight" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>HOW TO REACH BHUTAN FROM BANGALORE</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>HOW TO REACH BHUTAN FROM BANGALORE</h4>
                </div>
                <div className="card-body">
                  <p>Here to note that, there are no <strong>Bangalore to Paro Direct Flight</strong>. Yet there are lots of options for <strong>Bangalore to Bhutan tour</strong>. The most cost effective and budget friendly options that Adorable Vacation suggest is - <strong>Bhutan tour from Bangalore via Bagdogra Airport</strong>. Why? Here it is.</p>
                  <br />
                  <h3 className="tomato1">OPTION 1 : <strong>BANGALORE TO BHUTAN PACKAGE TOUR FROM BAGDOGRA AIRPORT</strong> </h3>
                  <br />
                  <p>Hello travelers, getting to Bhutan - The Land of Thunder Dragons, is easy from Bangalore. The easiest way for <strong>Bhutan tour from Bangalore</strong> is from Bagdogra Airport by Land. There are many flights from <b>Kempegowda International Airport</b> (BLR) in Bangalore to Bagdogra Airport (IXB) on daily basis. <br /><br />

                    Bagdogra is about 16 km west of Siliguri in northern West Bengal and is well-connected to all major cities in India by Air, including Bangalore. From Bagdogra Airport, you can travel to Phuentsholing, the gateway to Bhutan, which is just 154 km away (about a 3.5-hour drive via NH 27). It's a scenic 4-hour journey through the beautiful greenery of the Tarai and Dooars. Guests from Bangalore can easily fly to Bagdogra Airport, from where Adorable Vacation offers many Bhutan packages. So, fly to Bagdogra and start your <strong>Bhutan Package tour from Bangalore</strong> from there!<br /><br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>ARRIVAL</th>
                          <th>DURATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>AirAsia</td>
                          <td>Bangalore [BLR]</td>
                          <td>05:20</td>
                          <td>Bagdogra [IXB]</td>
                          <td>08:20</td>
                          <td>03 h 00 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td>SpiceJet</td>
                          <td>Bangalore [BLR]</td>
                          <td>06:05</td>
                          <td>Bagdogra [IXB]</td>
                          <td>09:05</td>
                          <td>03 h 00 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td>IndiGo</td>
                          <td>Bangalore [BLR]</td>
                          <td>06:25</td>
                          <td>Bagdogra [IXB]</td>
                          <td>09:15</td>
                          <td>02 h 50 m (non-stop)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />

                  <h3 className="tomato1">OPTION 2 : <strong>BANGALORE TO BHUTAN TOUR PACKAGE</strong> FROM KOLKATA AIRPORT</h3>
                  <br />
                  <p>Guests looking for the easiest and most comfortable way to travel Bhutan, for them booking a <a href="https://adorablevacation.com/bhutan-tour-package-from-kolkata/" target="_blank"><strong>Bhutan tour package from Kolkata</strong></a> with the Kolkata-Paro direct flight by <b>Bhutan Airlines</b> is the best option. <br /><br />

                    Every day, Bhutan Airlines operates direct flight between Kolkata and Paro. This flight is actually a connecting flight between Bangkok and Paro, but it provides a seamless and convenient way to travel directly to Bhutan. So, guests looking for <strong>Bangalore to Bhutan tour</strong>, first can fly upto Kolkata and then book this <b>Kolkata to Paro Direct Flight</b> and enjoy your <strong>Bhutan trip with Adorable Vacation</strong>. Following are the Kolkata to Paro flight schedule for your convenience. <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>SECTOR</th>
                          <th>FLIGHT NO</th>
                          <th>DEP DAY</th>
                          <th>DEP TIME</th>
                          <th>ARR TIME</th>
                          <th>DURATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CCU - PBH</td>
                          <td>B3 704</td>
                          <td>EVERYDAY</td>
                          <td>11:25am</td>
                          <td>12:55pm</td>
                          <td>01 hrs</td>
                        </tr>
                        <tr>
                          <td>PBH - CCU</td>
                          <td>B3 700</td>
                          <td>EVERYDAY</td>
                          <td>10:35am</td>
                          <td>11:15pm</td>
                          <td>01 hrs 10 mnts</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br /><br />
                  <h3 className="tomato1">OPTION 3 : <strong>BHUTAN TOUR FROM GUWAHATI</strong> [DIRECT FLIGHT]</h3>
                  <br />
                  <p>Druk Air operates a <strong>Direct Flight from Guwahati to Paro</strong>, departing at 3:20 PM every Sunday. Guests from Bangalore can easily arrive in Guwahati by a morning flight and then board the flight to Bhutan.</p>
                  <p>Additionally, starting April 6th, there will be another direct flight from <strong>Guwahati to Paro</strong>, departing on Thursdays, with a return flight from Paro to Guwahati on Wednesdays. The <strong>Guwahati to Paro Flight</strong> schedule will be as follows:</p>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>FLIGHT NO</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>DEP DAYS</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Drukair-Royal Bhutan Airlines</td>
                          <td>KB 541</td>
                          <td>[GAU]</td>
                          <td>15:20</td>
                          <td>[PBH]</td>
                          <td>Every Sunday</td>
                        </tr>
                        <tr>
                          <td>Drukair-Royal Bhutan Airlines</td>
                          <td>KB 541</td>
                          <td>[GAU]</td>
                          <td>15:20</td>
                          <td>[PBH]</td>
                          <td>Every Thursday</td>
                        </tr>
                        <tr>
                          <td>Drukair-Royal Bhutan Airlines</td>
                          <td>KB 540</td>
                          <td>[PBH]</td>
                          <td>08:05</td>
                          <td>[GAU]</td>
                          <td>Every Saturday</td>
                        </tr>
                        <tr>
                          <td>Drukair-Royal Bhutan Airlines</td>
                          <td>KB 540</td>
                          <td>[PBH]</td>
                          <td>08:05</td>
                          <td>[GAU]</td>
                          <td>Every Wednesday</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={Flight1ImageURL} alt="Bhutan tour from Bangalore" />
                          <img src={Flight2ImageURL} alt="BHUTAN PACKAGE TOUR FROM BANGALORE" />
                          <img src={Flight3ImageURL} alt="Bhutan Holiday Package from Bangalore" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST TIME TO BOOK  <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST TIME TO BOOK  <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <h4 className="tomato1">SUMMER [APRIL - JUNE]</h4>
                  <p><b>Summer</b> in Bhutan spans from June to September, with temperatures in central Bhutan typically ranging between 20 to 25 degrees Celsius. The weather is generally sunny and mildly humid, while the nights can get quite chilly, so light woolen clothes or jackets are advisable. The landscapes around cities and tourist attractions are lush and green, and the clear skies offer a breathtaking view of the snow-capped Himalayan peaks. If you visit during this period, you can also experience the Haa Festival, which showcases Bhutanese cuisine, culture, traditions, and lifestyle. With fewer crowds, you can peacefully explore numerous sightseeing spots. Therefore, summer is one of the best seasons to opt for <strong>Bhutan Holiday Package from Bangalore</strong>. <br /><br /><br /></p>


                  <h4 className="tomato1">AUTUMN [SEPTEMBER - NOVEMBER]</h4>
                  <p>Autumn is widely regarded as the best season for booking <strong>Bhutan tour from Bangalore</strong>. The weather during this time is quite pleasant, ranging from 10 to 15 degrees, making sightseeing and travel enjoyable throughout the day, while the nights are comfortably chilly. This is also the festive season in Bhutan, offering travelers a glimpse into the rich cultural heritage and vibrant festivals of the country. Festivals such as the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu are celebrated with great enthusiasm, with dzongs and monasteries coming alive with colorful flags, large Thonkas, and masked dances. For tourists, it’s the perfect time to visit. However, Adorable Vacation recommends booking your in advance during the summer months to avoid the peak season rush and the unavailability of flights and hotel rooms. <br /><br /><br /></p>


                  <h4 className="tomato1">WINTER [DECEMBER - FEBRUARY]</h4>
                  <p><b>Winter</b> in Bhutan can be quite harsh. Due to the high Himalayan altitude, temperatures drop rapidly, and cities like Thimphu, Paro, and Punakha are often blanketed in snow, creating a serene and still atmosphere. In the northern, high-altitude regions, temperatures can even fall to sub-zero levels. As the weather becomes severe, the government of Bhutan typically declares holidays for schools, colleges, and government offices. Travel between different regions may become difficult due to heavy snowfalls. However, if you're keen to experience the snow and cold and want to enjoy the festivals that occur during winter, you can certainly plan your <strong>Bhutan package tour in December</strong>. Some of the notable festivals, like Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu, take place between November and December. So, if you’re excited about experiencing Bhutan's winter charm, don't hesitate to book your <strong>Bhutan tour from Bangalore</strong> this season. Winter in Bhutan is truly magical! <br /><br /><br /></p>


                  <h4 className="tomato1">SPRING [MARCH - MAY]</h4>
                  <p>After the cold and harsh winter, Bhutan comes to life during spring. The blooming of Jacaranda and Rhododendron flowers marks the arrival of spring, carpeting the roads, valleys, and surroundings in purple petals, creating a heavenly atmosphere. From March to May, the weather is typically dry with minimal rainfall, and the skies become crystal clear, signaling the perfect time for the world-famous Jomolhari and Drukpath treks. Additionally, this period hosts some of the most significant and auspicious festivals, including Paro Tshechu, Gasa Tshechu, Rhododendron festivals, and Talo Tshechu, where the King of Bhutan also actively participates. Since Bhutan is usually bustling with tourists during these months, Adorable Vacation advises booking your <strong>Bhutan package in May</strong> well in advance, along with your flights, to avoid the peak season surcharges, extra hotel costs, and increased flight prices. <br /><br /><br /></p>

                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>SEASON</th>
                          <th>MONTH</th>
                          <th>WEATHER</th>
                          <th>TEMPERATURE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><b>Winter</b></td>
                          <td>December</td>
                          <td>Cool and Dry</td>
                          <td>15°C - 24°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>January</td>
                          <td>Cool and Dry</td>
                          <td>14°C - 24°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>February</td>
                          <td>Cool and Dry</td>
                          <td>15°C - 25°C</td>
                        </tr>
                        <tr>
                          <td><b>Spring</b></td>
                          <td>March</td>
                          <td>Warm and Sunny</td>
                          <td>17°C - 29°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>April</td>
                          <td>Warm and Sunny</td>
                          <td>21°C - 32°C</td>
                        </tr>
                        <tr>
                          <td><b>Summer</b></td>
                          <td>May</td>
                          <td>Hot and Humid</td>
                          <td>24°C - 34°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>June</td>
                          <td>Hot and Humid</td>
                          <td>25°C - 35°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>July</td>
                          <td>Hot and Humid</td>
                          <td>26°C - 35°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>August</td>
                          <td>Hot and Humid</td>
                          <td>25°C - 34°C</td>
                        </tr>
                        <tr>
                          <td><b>Autumn</b></td>
                          <td>September</td>
                          <td>Warm and Rainy</td>
                          <td>24°C - 32°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>October</td>
                          <td>Cool and Dry</td>
                          <td>22°C - 30°C</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>November</td>
                          <td>Cool and Dry</td>
                          <td>18°C - 28°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9674738480'><img src={CallButtonSoumalyaImageURL} alt="BHUTAN PACKAGE TOUR FROM BANGALORE" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> BEST HOTELS ADORABLE VACATION PROVIDE FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}> BEST HOTELS ADORABLE VACATION PROVIDE FOR <strong>BHUTAN PACKAGE TOUR FROM BANGALORE</strong> </h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th className="text-center">Destination</th>
                          <th className="text-center">Hotels Name</th>
                          <th className="text-center">Hotel Type</th>
                          <th className="text-center">No of Nights</th>
                          <th className="text-center">Meal Plan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td>Phuentsholing</td>
                          <td>Hotel Tsheringma / Meto Pema Hotel</td>
                          <td>3 Star</td>
                          <td>2N</td>
                          <td>Room with All Meals</td>
                        </tr>
                        <tr className="text-center">
                          <td>Thimphu</td>
                          <td>Peaceful Resort / Hotel Ugyen</td>
                          <td>3 Star</td>
                          <td>2N</td>
                          <td>Room with All Meals</td>
                        </tr>
                        <tr className="text-center">
                          <td>Paro</td>
                          <td>Tshongdu Boutique / Penchu Boutique Hotel / Hotel Center Point</td>
                          <td>3 Star</td>
                          <td>2N</td>
                          <td>Room with All Meals</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img
                            src={sightseen7ImageURL}
                            alt="Bhutan Guwahati group tour from bagdogra with adorable"
                            style={{ margin: '10px' }}
                          />
                          <img
                            src={sightseen8ImageURL}
                            alt="Bhutan Guwahati travel packages"
                            style={{ margin: '10px' }}
                          />
                          <img
                            src={sightseen9ImageURL}
                            alt="travel bhutan with best b2b dmc adorable vacation"
                            style={{ margin: '10px' }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h3>
                      DETAIL ITINERARY FOR <span><strong className="tomato">BHUTAN TOUR PACKAGES FROM BANGALORE</strong></span>
                    </h3>
                    <h4>7N/8D <strong className="tomato">Bhutan Package Tour Itinerary From Bangalore </strong> from Adorable Vacation</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h4> DAY 01: WELCOME TO BAGDOGRA AND TRANSFER TO PHUENTSHOLING </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Upon arrival at Bagdogra Airport, you will be warmly greeted by our representative as part of your <strong>Bhutan package tour from Bangalore</strong>. From there, embark on a scenic drive towards the Indo-Bhutanese Border (Jaigaon - Phuentsholing), passing through lush green tea estates. After reaching Phuentsholing, check in to your hotel and enjoy the rest of the evening at your leisure. <br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="BHUTAN PACKAGE TOUR FROM BANGALORE" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="BHUTAN PACKAGE TOUR FROM BANGALORE" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h4>DAY 02: PHUENTSHOLING TO THIMPHU TRANSFER</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>Today marks the start of your long-awaited <a href="https://adorablevacation.com/bhutan-package-tour-from-phuentsholing/" target="_blank"><strong>Bhutan package tour from Phuentsholing</strong></a>. In the morning our representative will assist you with the permit process. You'll need to complete various formalities, including biometric data collection. The offline permit process typically takes between 2 to 6 hours, depending on the time of year and the crowd at the immigration office in Phuentsholing. During this waiting time, you can have lunch. Once your permit is ready, you'll begin your journey to Thimphu, the capital of Bhutan, which is about 4.5 hours away by car.<br /><br />

                          If you have already processed your permits online, you can head directly to the Bhutan Gate to complete the biometric process. This usually takes about 1 hour, and once done, you can continue your journey to Thimphu. <br /><br />

                          Upon reaching Thimphu, check in at your hotel in the afternoon or evening. After freshening up, take a stroll around the city. You can explore the local nightlife, enjoy a local beer, try karaoke, or relax with a traditional hot stone bath at a spa. End your day with a delicious dinner and rest for the night in Thimphu. <br /><br /></p>
                        <h4>BARS & NIGHT CLUBS TO HANGOUT NIGHTLIFE IN THIMPHU</h4><br />
                        <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">

                            <thead><tr><th>NAME</th><th>ADDRESS</th><th>TIMINGS</th></tr></thead>
                            <tbody><tr><td>Mojo Park</td><td>Chang Lam, Thimphu, Bhutan</td><td>5 PM - 12 AM</td></tr>
                              <tr><td>Space 34</td><td>Chang Lam, Main Town, Thimphu</td><td>9 PM - 1 AM</td></tr>
                              <tr><td>Om Bar</td><td>Thimphu, Bhutan</td><td>5 PM - 1 AM</td></tr>
                              <tr><td>Vivacity</td><td>Sheten Lam, Thimphu, Bhutan</td><td>7 PM - 12 AM</td></tr>
                              <tr><td>Club Ace</td><td>Thimphu, Bhutan</td><td>9 PM - 1 AM</td></tr>
                              <tr><td>Barz Brothers</td><td>Thimphu, Bhutan</td><td>Open 24 hours</td></tr>
                              <tr><td>Benez Bar</td><td>Jangchhub Lam, Thimphu, Bhutan</td><td>10 AM - 11 PM</td></tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="Bhutan tour cost from bangalore" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="bhutan trip from bangalore" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 03: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>Start your day with a hot cup of <b>Suja (Butter Tea)</b> as the sun shines over the beautiful Thimphu valley. After enjoying breakfast, get ready for a tour of Thimphu's local attractions. <br /><br /></p>

                        <p><b>The National Memorial Chorten (Thimphu Chorten)</b> : This place is where many locals come for evening prayers. It was built in memory of Bhutan’s third king, Jigme Dorji Wangchuk, and was completed in 1974 after his untimely death. People walk around the chorten, spinning prayer wheels and chanting mantras. It stands as a symbol of peace and prosperity. <br /><br />

                          <b>The Clock Tower</b> : Located in the center of Thimphu, the Clock Tower Square is a beautiful example of Bhutanese architecture. The tower has four clock faces and is decorated with intricate carvings of flowers, paintings, and dragons. The dragons are facing each clock and represent the country’s independence. This area is popular for both locals and tourists, and it’s surrounded by shops, restaurants, and hotels. The square often hosts live music events, making it a lively spot. You can also buy gifts here or enjoy a picnic in the nearby playground. <br /><br />

                          <b>Sakyamuni Buddha</b> : On top of Kuensel Phodrang hill stands the giant statue of Buddha Dordenma. This 51.5-meter statue is made of gilded bronze, and it offers a stunning view of the valley. It’s a peaceful and breathtaking site, also known as Buddha Viewpoint. <br /><br />

                          <b>Changangkha Lhakhang</b> : One of the oldest and most important monasteries in Thimphu, dating back to the 15th century. It’s dedicated to Avalokiteshvara, the symbol of compassion. From here, you can enjoy a panoramic view of the Thimphu valley. <br /><br />

                          <b>Takin Zoo</b> : This zoo is home to Bhutan's national animal, the Takin, an endangered species found only in Bhutan. The animals live in an open habitat, allowing you to see them in a more natural environment. <br /><br />

                          <b>Trashi Chhoe Dzong</b> : Known as the Fortress of the Glorious Region, this site is a must-visit. It sits on the west bank of the Wang Chhu River and is the coronation site of Bhutan’s fifth king. It also hosts the annual <strong>Tsechu Festival</strong>. Built in 1641, it was later rebuilt in the 1960s using traditional Bhutanese methods, without nails or architectural plans. You’ll spend the night at a hotel in Thimphu. <br /><br /></p>

                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="Bhutan tour cost from Bangalore" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Bhutan trip cost from bangalore" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 04: THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS & SIGHTSEEING </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>Start your 4th day of <strong>Bhutan Trip from Bangalore</strong> in Thimphu with a cup of Hot Ngaja (sweet Bhutanese milk tea) and enjoy the view of the beautiful valley from your room. After breakfast, head to Punakha, the former capital of Bhutan. Punakha is a peaceful place, much quieter than the busy cities, sitting at an altitude of 1200 meters. It’s warm in winter and cool in summer. The valley is famous for growing red and white rice along the Pho and Mo Chu rivers. On the way, you’ll visit: <br /><br />

                          <b>Punakha Dzong</b>: Also called "<b>The Palace of Great Happiness</b>", this stunning fortress sits at the meeting point of the Pho Chhu (father) and Mo Chhu (mother) rivers. It’s one of the most beautiful dzongs in Bhutan. The dzong is connected to the mainland by an arched wooden bridge and is home to many valuable relics from the time when the kings ruled Bhutan from this valley. <br /><br />

                          <b>Suspension Bridge</b>: This hidden gem in the Punakha Valley connects the valley to the old administrative center of Punakha Dzongkhag. The bridge is covered with prayer flags and offers a great spot for birdwatching and taking photos. <br /><br />

                          <b>Chimi Lhakhang (Fertility Temple)</b>: Located on a hill, this temple is dedicated to Lama Drukpa Kuenley, also known as the 'Divine Madman.' He used humor and songs to teach his lessons. Chimi Lhakhang is considered a fertility temple, and it is believed that childless couples who visit and pray here will be blessed by Lama Drukpa Kuenley. The temple is reached by walking along a beautiful rural path through paddy fields. After crossing a small stream, a short climb will take you to the temple. You will stay overnight at a hotel in Punakha. <br /><br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour from bangalore" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour from bangalore" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 05: PUNAKHA TO PARO TRANSFER & LOCAL SIGHTSEEING </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, start your journey to Paro and visit the following places on the way: <br /><br />

                          <b>National Museum of Bhutan</b> : Located above Rinpung Dzong in Paro, the National Museum of Bhutan showcases Bhutan’s culture. The museum was built in 1968 inside the old Ta Dzong building. It displays some of the best examples of Bhutanese art and culture, including beautiful paintings and bronze statues. The museum has large galleries where you can learn about Bhutan’s history and culture. <br /><br />

                          <b>Drukgyal Dzong</b> : Drukgyal Dzong was a fortress and Buddhist monastery, now in ruins, located in the upper Paro Valley. It was built in 1649 to celebrate Bhutan’s victory over a Tibetan invasion. The site is currently closed. <br /><br />

                          <b>Ta Dzong</b> : Ta Dzong was originally a watchtower built to protect Rinpung Dzong during the 17th-century wars. It became the National Museum of Bhutan in 1968. <br /><br />

                          <b>Rinpung Dzong</b> : Built in 1646 by Shabdrung Ngawang Namgyal, the first ruler of Bhutan, Rinpung Dzong houses the monks of Paro, the district head (Dzongda), and the judge (Thrimpon). To reach the dzong, you cross a traditional covered bridge called Nemi Zam. <br /><br />

                          <b>Nya Mey Zam Bridge</b> : Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. Locals believe that seeing a fish in the river is a bad omen. The original bridge, which could be folded during times of war, was destroyed in a flood in 1969. <br /><br />

                          <b>Paro Airport View Point</b> : This viewpoint offers the best view of Paro Valley and Paro Airport. You can see the Paro River, the dzongs, and monasteries from here. Overnight stay at a hotel in Paro. <br /><br /><br /></p>

                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="Bhutan Trip from Bangalore" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan Trip from Bangalore with flight" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING / CHELE-LA PASS EXCURSION</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>Wake up in the beautiful Paro Valley as the sun rises, surrounded by nature. Enjoy the amazing view of the river and mountains. Have a hearty breakfast with plenty of protein, because the hike to Taktsang Monastery will require some energy. <br /><br />

                          <b>Taktsang Monastery</b>: The hike to Taktsang Monastery, also known as the Tiger's Nest, takes you up a steep cliff for 1,200 meters. Once you reach the top, take a deep breath and enjoy the fresh air and the incredible view of the valley below. The monastery was built in 1694 and is a sacred site founded by Guru Rinpoche, who brought Buddhism to Bhutan in 747 CE. After a 3-hour trek, you’ll reach the monastery. If you prefer, you can take a horse ride from the parking lot to the top. During your hike, you’ll be treated to beautiful views of Paro town, with its green valleys, clear blue sky, and sparkling river. It’s a perfect place to enjoy nature. Overnight in Paro. <br /><br /><br /></p>

                        <h4>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY</h4>
                        <br /><br />
                        <div id="no-more-tables">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">

                            <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                              <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                              <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                              <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                              <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                              <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                              <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                              <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="best Tour Operator for Bhutan from bangalore" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="best Tour Operator for Bhutan from bangalore" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 07: PARO TO PHUENTSHOLING TRANSFER</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, begin your transfer from Paro to Phuentsholing. Upon arrival, check in to your hotel and enjoy the rest of the evening at leisure. Overnight stay in Phuentsholing. <br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 08: PHUENTSHOLING TO BAGDOGRA AIRPORT DROP</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, check out from the hotel and proceed to Bagdogra Airport [IXB]. With this unforgettable <strong>Bhutan package tour from Bangalore via Bagdogra Airport</strong>, take home memories to cherish forever. <br /><br /><br /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DO'S & DONT'S FOR <strong>BANGALORE TO BHUTAN TRIP</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DO'S & DONT'S FOR <strong>BANGALORE TO BHUTAN TRIP</strong></h4>
                </div>
                <div className="card-body">
                  <p>When traveling to a new place, it's important to understand the local culture and customs. Here’s some helpful information to know before visiting Bhutan. Please read it to make sure you enjoy your trip responsibly. <br /><br /></p>

                  <h4 className="tomato1"><b>DO'S</b></h4>
                  <br />
                  <ul>
                    <li>Make sure to use TCB-certified hotels, tour guides, and operators for your <strong>Bhutan Trip</strong>. <br /><br /></li>
                    <li>When booking your <strong>Bhutan Tour Package</strong>, check the cancellation policy of the tour operator or hotel, as each one has its own rules. <br /><br /></li>
                    <li>Always carry valid travel documents and show them when needed. <br /><br /></li>
                    <li>Dress appropriately when visiting religious sites; for example, avoid wearing miniskirts, as they are not considered respectful in Bhutan. <br /><br /></li>
                    <li>Always ask for permission before entering temples or monasteries. <br /><br /></li>
                    <li>Remove your shoes before entering a temple. <br /><br /></li>
                    <li>Take off your hat when entering a fortress or temple. <br /><br /></li>
                    <li>If photography is not allowed, store your camera safely. <br /><br /></li>
                    <li>Always walk clockwise around Chorten (stupa), temples, or other religious sites. <br /><br /></li>
                    <li>You need a permit to export antiques or religious artifacts. <br /><br /></li>
                    <li>Don’t litter; be responsible for your own waste. <br /><br /></li>
                    <li>Use the designated zebra crossings for your safety. <br /><br /></li>
                    <li>Respect the Bhutanese people and avoid raising your voice, as it is not a common practice in Bhutan. <br /><br /></li>
                    <li>Carry enough cash, as US dollars and other currencies can only be exchanged at the airport or major banks in larger towns. <br /></li>
                  </ul>
                  <br />
                  <h4 className="tomato1"><b>DON'TS</b></h4>
                  <br />
                  <ul>
                    <li>Smoking in public areas is restricted, and you will be fined if caught. <br /><br /></li>
                    <li>Do not misbehave at monasteries, temples, or dzongs. <br /><br /></li>
                    <li>Do not throw garbage anywhere except in designated trash bins. <br /><br /></li>
                    <li>Make sure to register all your electronic devices, such as cameras, laptops, and mobile phones, with customs upon arrival. <br /><br /></li>
                    <li>Do not climb on or step over religious artifacts or temples. <br /><br /></li>
                    <li>Don’t wear hats or sunglasses inside religious places. <br /><br /></li>
                    <li>Some places restrict photography or filming, so always check with your tour guide. <br /><br /></li>
                    <li>Swimming, washing, or throwing objects into lakes or water bodies is prohibited, as they are considered sacred. <br /><br /></li>
                    <li>Refrain from spitting on walls or other places. <br /><br /></li>
                    <li>Do not touch religious paintings or stupas in temples, as it is disrespectful in Bhutanese culture. <br /><br /></li>
                    <li>Do not sit with your legs stretched out in front of an altar, as it is culturally offensive. <br /><br /></li>
                  </ul>
                  <br /><br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen13ImageURL} alt="BHUTAN TOUR FROM BANGALORE" />
                          <img src={sightseen14ImageURL} alt="Bhutan package tour from Bangalore via Bagdogra Airport" />
                          <img src={sightseen15ImageURL} alt="Bhutan Package tour from bangalore" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                </div>
              </div>
              <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>BHUTAN TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>BHUTAN TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <ul>
                    <li>Locals call their country "Druk Yul" which means "the Land of the Dragons." <br /><br /></li>
                    <li>Bhutan has never been conquered or ruled by outside forces. <br /><br /></li>
                    <li>Bhutan is the only carbon-negative country in the world. <br /><br /></li>
                    <li>Bhutan was isolated from the rest of the world until the 1970s. <br /><br /></li>
                    <li>You won’t find any traffic lights in Bhutan. <br /><br /></li>
                    <li>Bhutan is a constitutional monarchy. <br /><br /></li>
                    <li>Bhutan does not use GDP as a measure of economic growth. <br /><br /></li>
                    <li><b>GNH</b> (Gross National Happiness) is the measure of growth and development in Bhutan. <br /><br /></li>
                    <li>Bhutan is a plastic-free country. <br /><br /></li>
                    <li>There are fewer than 20 qualified pilots who can fly into Bhutan's only airport. <br /><br /></li>
                    <li>Smoking is not allowed in Bhutan and is considered a punishable offense. <br /><br /></li>
                    <li>Bhutan has some of the world’s highest unclimbed mountain peaks, including Gangkhar Puensum at 7,570m (24,981ft). <br /><br /></li>
                    <li>The national animal of Bhutan is the Takin, a goat-antelope. <br /><br /></li>
                    <li>Bhutanese food is very spicy, and you will find chilies in almost every dish. <br /><br /></li>
                    <li>Education and healthcare are free for all citizens. <br /><br /></li>
                    <li>Archery is the national sport of Bhutan. <br /><br /></li>
                    <li>Bhutanese people love their king, and you’ll find his photo in every home. <br /><br /></li>
                    <li>Bhutan has a very low crime rate. <br /><br /></li>
                    <li>Wearing the national dress (Gho for men and Kira for women) is mandatory in schools, government offices, and during festivals. <br /><br /></li>
                    <li>Cleanliness is very important in Bhutan, and the environment is highly prioritized. <br /><br /></li>
                    <li>Bhutanese people often chew betel leaf and areca nut. <br /><br /></li>
                    <li>Ema Datshi is the national dish of Bhutan, made with spicy chilies and local cheese. <br /><br /></li>
                  </ul>
                  <br />    <br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="BHUTAN TOUR FROM BANGALORE" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Hike to Taktsang Monastery (Tiger's Nest) - Paro</td>
                          <td style={{ fontSize: "16px" }}>Take the Dagala Thousand Lakes Trek - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                          <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                          <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Explore Simtokha Dzong - Thimphu</td>
                          <td style={{ fontSize: "16px" }}>Explore the Chele La Pass</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Visit the Buddha Cave & Buddha Falls</td>
                          <td style={{ fontSize: "16px" }}>Visit Trongsa Dzong - Trongsa</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Visit Rinpung Dzong - Paro</td>
                          <td style={{ fontSize: "16px" }}>Visit Trashigang Dzong - Trashigang District</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Take a Bike Trip around Bhutan</td>
                          <td style={{ fontSize: "16px" }}>Go Camping in Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Try Archery - Bhutan’s National Sport</td>
                          <td style={{ fontSize: "16px" }}>Go Mountain Biking in Bhutan</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Visit the Tashichho Dzong - Thimphu</td>
                          <td style={{ fontSize: "16px" }}>Visit the Buddha Dordenma - Thimphu</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Hike in the beautiful Haa Valley - Haa</td>
                          <td style={{ fontSize: "16px" }}>Relax with a Hot Spring Therapy</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Experience the Nimalung Tsechu Festival</td>
                          <td style={{ fontSize: "16px" }}>Take a scenic drive at Dochula Pass</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Attend the Annual Black-Necked Crane Festival</td>
                          <td style={{ fontSize: "16px" }}>Try Kayaking on the Wang Chhu River - Paro</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Go Rafting in the Mo Chhu River - Punakha</td>
                          <td style={{ fontSize: "16px" }}>Explore Punakha Dzong - Punakha</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Get a Blessing at Gangtey Monastery - Wangdue Phodrang</td>
                          <td style={{ fontSize: "16px" }}>Pray at Chimi Lhakhang Temple - Punakha</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Spot Snow Leopards at Jigme Dorji National Park</td>
                          <td style={{ fontSize: "16px" }}>Try Bhutanese Cuisine - especially Ema Datshi</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>FREQUENTLY ASKED QUESTIONS ABOUT <strong>BHUTAN TOUR PACKAGES FROM BANGALORE</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          1. Can I get AC Rooms in Bhutan Group Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Bhutan generally has a cold climate except in places like Punakha. In Thimphu, Paro, and some hotels in Punakha, you can find rooms with AC facilities. During July and August, AC may be needed in Thimphu and Paro, and even in April and May for Punakha. It's best to discuss AC facilities with Adorable Vacation Bhutan experts before booking your <strong>Bhutan Tour from Bangalore</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          2. What kind of cars are available for a group of 6 on a <strong>Bhutan Tour Package from Bangalore</strong>?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>For a comfortable trip starting from Paro Airport, consider Toyota Hiace, known for its spaciousness and comfort. For larger groups of 8 to 12 travelers, Toyota Coaster cars are available. At Adorable Vacation, guest comfort during travel is a top priority.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          3. Do Indians need a visa to enter Bhutan Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Indian passport holders do not need a visa for Bhutan. However, an entry permit is required, which can be processed on arrival at Paro Airport or in advance through a local travel agent like Adorable Vacation Bhutan.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          4. Where can I take photos in Bhutanese dress?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>In Paro, outside Paro Dzong, you can take photos in Bhutanese dresses. For women, it's called Kira & Tego, and for men, it's called Goh. Dressing like Bhutanese locals against the backdrop of beautiful landscapes makes for a memorable experience.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          5. Are there wheelchair-friendly hotels in Bhutan Trip from Bangalore?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Most hotels in Bhutan are not wheelchair-friendly, but Adorable Vacation has assisted many wheelchair travelers. Contact the Adorable vacation team for references from guests who have traveled to Bhutan with a wheelchair.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          6. What is Sustainable Development Fee (SDF) and how does it impact Indian tourists?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>The SDF is Rs. 1200 per person per night for Indian (regional) tourists, excluding tour package costs (flight, hotels, meals, guides, transportation, and monument fees). This fee aims to promote quality tourism in Bhutan.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          7. How is the climate and weather in Bhutan Sightseeing Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Bhutan's climate is generally cold in tourist areas, even in summer. Thimphu, Paro, Phobjikha, and Bumthang have pleasant summers with light woolens needed during the day. Winters are cold with occasional snow in January and February. Rainfall mainly occurs at night during the rainy season (typically July and August).</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          8. What is the luggage weight limit on Druk Air?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>Druk Air allows 20 kg in economy class and 30 kg in business class. An additional 5 kg is permitted for overhead bins or under the passenger's seat.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine"
                        >
                          9. What is the currency of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingNine"
                      >
                        <div className="accordion-body">
                          <p>The official currency of Bhutan is Ngultrum (Nu, BTN), where 1 Ngultrum equals 1 Indian Rupee.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          10. What is the National Game of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>Archery is the National Game of Bhutan. You can try your archery skills with bamboo bows in many places during your <strong>Bhutan tour from Bangalore</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question11">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEleven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEleven"
                        >
                          11. What is the National Bird of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEleven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEleven"
                      >
                        <div className="accordion-body">
                          <p>The Raven is the national bird of Bhutan and holds significance in Bhutanese culture and the Royal Crown.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question12">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwelve"
                        >
                          12. What is the National Flower of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwelve"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwelve"
                      >
                        <div className="accordion-body">
                          <p>The Blue Poppy (Meconopsis) is Bhutan's national flower, found in high-altitude regions in various colors like red, pink, and white.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question13">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThirteen"
                        >
                          13. What is the language of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThirteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThirteen"
                      >
                        <div className="accordion-body">
                          <p>Dzongkha is Bhutan's national language. Over two dozen languages are spoken in Bhutan, with Nepali being one of the major languages.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div >
      </>
    );
  }
}
export default BangaloreToBhutanPackage;
