import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS : <strong>ARUNACHAL GROUP DEPARTURE FROM GUWAHATI</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;ALL TRANSFERS & SIGHTSEEING BY 01 TEMPO TRAVELLER AS PER THE ITINERARY
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;ACCOMMODATION ON DOUBLE SHARING ROOM FOR 07 NIGHTS
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;MEAL PLAN - BREAKFAST WITH DINNER (MAPAI) BASIS</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;INNER LINE PERMIT</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;LOCAL VEHICLE FOR BUMLA PASS</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;ALL PARKING AND ROAD TOLLS</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;ALL APPLICABLE TAXES</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;24X7 OPERATION EXECUTIVE SUPPORT</li>
                </ul>
                <br/><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS : <strong>ARUNACHAL TOUR PACKAGES FROM GUWAHATI IN GROUP</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;AIR FARE</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;CAMERA FEES</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;GUIDE</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;ANYTHING NOT MENTIONED IN THE ‘PACKAGE INCLUSIONS' PART</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;ANY EXPENSES OF PERSONAL NATURE </li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion;