import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import ArunachalTourKolkata_9N_10D_Package from '../../../components/package/Arunachal_Pradesh/ArunachalTourKolkata_9N_10D_Package';
import { Helmet } from "react-helmet";

class ArunachalTourKolkata_9N_10D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description:"2N Kohima | 1N Imphal | 1N Moreh | 1N Imphal | Private Transfer | Deluxe Hotel  ",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            }

        ];

        const reviewersDataList = [
            {
                Name: "Indumathi Chelliah",
                ImageName: "Indumathi-chelliah.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "We went to Arunachal Pradesh last week and the tour was conducted by Adorable Vacation. The stays and transports arranged by them were really good and as per expectation. I would strongly recommend Adorable Vacation for any tour in north east India. Thanks a lot Mr Akash & Team Adorable for this memorable trip.",
            },
            {
                Name: "Arun Kumar",
                ImageName: "Arun-Kumar.webp",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "We went for a holiday trip to eastern Arunachal Pradesh (Tezpur -Bomdila,-Tawang-Dirang-Nameri) which was arranged by Adorable Vacation. They are very reliable.Arrangement was best. They booked very good hotels with very good room as per our choice.They were very much reliable and whenever we called them for any support their coordinator immediately responded. Special thanks to Mr. Akash and Mr. Soumya. A big thumbs up and highly recommended.",
            },
            {
                Name: "Sunita Mehta",
                ImageName: "Sunitha-Mehta.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "Thank you for your immense service , dedication and cooperation. Initially we were little bit tensed. All tourists are above 65 years and plan was to cross Bumla pass, sela pass. Fortunately,they didn’t face any challenge and completed the trip successfully 🙂.",
            },
            {
                Name: "Victor Prasad",
                ImageName: "Victor-Prasad.webp",
                AllternateText: "...",
                Location: "@Ahmedabad",
                Comment: "Parents were extremely happy by the service you provided. Each service was commendable. Specially to take care and address each and every requirement - like room heater, lift issue etc.A special thanks to the driver who actually drives not only car but full tour. Last but not the least- a big Thank you for your commitment , your careness and your end to end managemnt. Kudos to you and your team for such successful trip. Wish you all the best and we'll continue more tours in coming days. Cheers.",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
             <Helmet>
                    <title>Arunachal Package Tour from Kolkata, Arunachal tour package from Kolkata</title>
                    <meta name="description" content="Explore the majestic landscapes of Arunachal with our exclusive Arunachal Pradesh package tour from Kolkata. FOR BOOKING ☎ CALL +91-96747-38480" />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Arunachal Pradesh Package Tour from Mumbai Reviews",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.8",
                                "bestRating": "5",
                                "ratingCount": "352"
                            }
                        })}
                    </script>
                </Helmet>
                <MainHeader />
                <ArunachalTourKolkata_9N_10D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default ArunachalTourKolkata_9N_10D_Page;