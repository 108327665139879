import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IndividualIntervalsExample from "./carousel-slideMumbaitoBhutan";
import IndividualIntervalsReview from "./carousel-slideMumbaitoBhutan_Review_slider";
import Activityfecilities from "./Activity_bhutanPackageTourMumbai"
class MumbaiToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick1 = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-direct-non-stop-chartered-flight-web.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-direct-non-stop-chartered-flight-mobile.webp";
    const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-from-mumbai-with-chartered-flight.webp";
    const PromotionBannerImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-direct-non-stop-chartered-flight-page-banner-adorable-vacation.webp";
    const PromotionBanner2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-with-direct-non-stop-chartered-flight-page-banner-adorable-vacation.webp";
    const flight1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Airlines-1.webp";
    const flight2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Airlines-2.webp";
    const flight3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Airlines-3.webp";
    const flightBhutanPackages2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Dirict-Flight-on-Friday-Thursday.webp";
    const flightBhutanPackagesmobile0ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Mumbai-to-Bhutan-Flight-Every-Tuesday.webp";
    const flightBhutanPackagesmobile1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-to-Mumbai-Flight-Every-Monday.webp";
    const flightBhutanPackagesmobile2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Mumbai-to-Bhutan-Flight-Every-Friday.webp";
    const flightBhutanPackagesmobile3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-to-Mumbai-Flight-Every-Thursday.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/mumbai-to-bhutan-tour-with-direct-flight-tickets.webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-trip-from-mumbai-with-flights.webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-price-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-booking-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-trip-from-mumbai-with-chartered-flight-tickets.webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/mumbai-to-bhutan-tour-by-direct-flight.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-trip-from-mumbai-with-adorable-vacation.webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-with-direct-non-stop-chartered-flight-tickets.webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-cost-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-from-mumbai-with-non-stop-direct-chartered-flight.webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const sightseen13ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-chartered-flight.webp";
    const sightseen14ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-itinerary-from-mumbai-with-direct-non-stop-chartered-flight.webp";
    const sightseen15ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-itinerary-from-mumbai-airport-with-direct-chartered-flights.webp";
    const sightseen16ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-cost-from-mumbai-with-direct-non-stop-chartered-flight-tickets.webp";
    const sightseen17ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-from-mumbai.webp";
    const sightseen18ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-airport.webp";
    const sightseen19ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-packages-from-mumbai.webp";
    const sightseen20ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-flight.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-and-transfer-to-thimphu.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-booking-from-mumbai-city-airport-explore-buddha-dordenma.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/with-bhutan-package-tour-from-mumbai-with-direct-flight-explore-dochula-pass.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-and-explore-phobjhika-valley.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/with-bhutan-packages-from-mumbai-explore-punkha-suspension-bridge.webp";
    const navDay6ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-booking-from-mumbai-and-explore-tigers-nest-monastery.webp";
    const navDay7ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-cost-from-mumbai-and-explore-chele-la-pass.webp";
    const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/call-button-bablu-adorable-vacation.webp";

    const hotPackagesDataList = this.props.HotPackagesData;
    const hotPackagesView = hotPackagesDataList.length > 0 ? (
      hotPackagesDataList.map((dataItem, i) => {
        const hotelImgURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + dataItem.ImageName;
        const packageURL = dataItem.Url;
        return (
          <div className="col-md-4" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h5 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}/-</span></h5>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">{dataItem.Description}</p>
                <p>
                  <span>
                    <b className="tomato">{dataItem.NoOfTours} </b>Tours
                  </span>{" "}
                  |
                  <span>
                    <b className="tomato">{dataItem.NoOfDeparture} </b>
                    Departures
                  </span> |
                  <span><b className="sdf-blue"> {dataItem.SDFInclude}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">{dataItem.MutedText}</small>
                <Link
                  to={packageURL}
                  target="_blank"
                  className="btn btn-Warning btn-sm float-end"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    // const hotelDataList = this.props.HotelsData;
    // const hotelDataView = hotelDataList.map((hotelItem, i) => {
    //   const hotelImageURL =
    //     ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + hotelItem.ImageName;
    //   return (
    //     <div className="col-md-3" key={i}>
    //       <img
    //         src={hotelImageURL}
    //         className="rounded mx-auto d-block"
    //         alt={hotelItem.AllternateText}
    //       />
    //       <br />
    //       <span>
    //         <b>{hotelItem.HotelName}</b> |{" "}
    //         <span className="tomato">{hotelItem.HotelStar}</span>
    //       </span>
    //       <br />
    //       <span className="rating-box">
    //         <span className="star">{hotelItem.RatingBoxStar}</span>
    //         <b className="rating-value">{hotelItem.RatingValue}</b>
    //       </span>{" "}
    //       &nbsp;<b>{hotelItem.Reviews}</b>
    //     </div>
    //   );
    // });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromMumbaiImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                  <i className="fab fa-whatsapp"></i> WhatsApp
                </a>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + sliderItem.ImageName;
        const contactURL = sliderItem.Url;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b> Departures</span> |
                  <span><b className="sdf-blue"> SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center>BOOK <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center>BOOK <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></center>
                      <hr />
                    </h3>
                    <div className="row">
                      <a href="tel:9883359713"><img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." /></a>
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        4 Places:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Pobjikha
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <br />
                    <p className="p-2">
                      Embark on your <strong>Bhutan package tour from Mumbai</strong> with Adorable Vacation, the leading DMC for Bhutan, and indulge in a smooth journey surrounded by towering mountains, historic monasteries, and authentic local experiences. Secure your <strong>Bhutan tour package from Mumbai</strong> now and transform your dream vacation into reality! <br /><br />
                      <b>Bhutan</b>, also known as the Land of Happiness, is an essential travel destination for Mumbai residents in search of stunning Himalayan vistas, rich cultural heritage, and peaceful surroundings. Famous for its vibrant traditions, untouched natural beauty, zero pollution, no traffic signals, tranquil atmosphere, and majestic valleys, Bhutan guarantees an unforgettable getaway. <br /><br />
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong className="info-red">BHUTAN PACKAGE TOUR FROM MUMBAI WITH FLIGHT</strong>?<br /><br />
                      FOR BOOKING <strong className="info-red">BHUTAN PACKAGE TOUR FROM MUMBAI WITH DIRECT FLIGHT</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h2>
                    <h3 className="content-mobile p-4" style={{ fontSize: "6.5vw" }}>LOOKING FOR <strong className="info-red">BHUTAN PACKAGE TOUR FROM MUMBAI WITH FLIGHT</strong>?<br /><br /><br />
                      FOR BOOKING <strong className="info-red">BHUTAN PACKAGE TOUR FROM MUMBAI WITH DIRECT FLIGHT</strong> <a href="tel:+91-9883359713"> ☎ CALL +91-98833-59713</a><br /><br /></h3>
                    <br />
                    <table className='img-center'>
                      <tbody>
                        <tr>
                          <td className="image-cell">
                            <a href='tel:9883359713'><img src={PromotionBannerImageURL} alt="bhutan tour package from mumbai" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}><strong>MUMBAI TO BHUTAN TOUR PACKAGE WITH NON-STOP FLIGHT</strong></h3>
                        <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><strong>MUMBAI TO BHUTAN TOUR PACKAGE WITH NON-STOP FLIGHT</strong></h4>
                      </div>
                      <div className="card-body">
                        <p><b className="sdf-blue">7 Nights 8 Days</b> All Included Premium Package | <b className="sdf-blue">Return Flights</b> | <b className="sdf-blue">3/4* Hotel</b> Accomodation, All transfer and Sightseeing in <b className="sdf-blue">Luxury Vehicles</b> | Activities and Many More</p><br />
                        <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                          <table class="col-md-12 table-bordered table-striped table-condensed cf">
                            <thead class="cf">
                              <tr class="table-danger">
                                <th>JOURNEY DATE</th>
                                <th>RETURN DATE</th>
                                <th>DEPART</th>
                                <th>ARRIVE</th>
                                <th>BOOK NOW</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>May 15, 2025</td>
                                <td>May 22, 2025</td>
                                <td>Mumbai [13:00 PM]</td>
                                <td>Paro [16:00 PM]</td>
                                <td><a href="tel:+91-9883359713" className="btn btn-success">
                                  GET QUOTE
                                </a></td>
                              </tr>
                              <tr>
                                <td>May 18, 2025</td>
                                <td>May 25, 2025</td>
                                <td>Mumbai [13:00 PM]</td>
                                <td>Paro [16:00 PM]</td>
                                <td><a href="tel:+91-9883359713" className="btn btn-success">
                                  GET QUOTE
                                </a></td>
                              </tr>
                              <tr>
                                <td>May 22, 2025</td>
                                <td>May 29, 2025</td>
                                <td>Mumbai [13:00 PM]</td>
                                <td>Paro [16:00 PM]</td>
                                <td><a href="tel:+91-9883359713" className="btn btn-success">
                                  GET QUOTE
                                </a></td>
                              </tr>
                              <tr>
                                <td>May 25, 2025</td>
                                <td>June 1, 2025</td>
                                <td>Mumbai [13:00 PM]</td>
                                <td>Paro [16:00 PM]</td>
                                <td><a href="tel:+91-9883359713" className="btn btn-success">
                                  GET QUOTE
                                </a></td>
                              </tr>
                              <tr>
                                <td>May 29, 2025</td>
                                <td>June 5, 2025</td>
                                <td>Mumbai [13:00 PM]</td>
                                <td>Paro [16:00 PM]</td>
                                <td><a href="tel:+91-9883359713" className="btn btn-success">
                                  GET QUOTE
                                </a></td>
                              </tr>
                              <tr>
                                <td>June 1, 2025</td>
                                <td>June 8, 2025</td>
                                <td>Mumbai [13:00 PM]</td>
                                <td>Paro [16:00 PM]</td>
                                <td><a href="tel:+91-9883359713" className="btn btn-success">
                                  GET QUOTE
                                </a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                        <table className="image-table">
                          <tbody>
                            <tr>
                              <td className="image-cell">
                                <img src={flight1ImageURL} alt="bhutan package tour from mumbai with flight" />
                                <img src={flight2ImageURL} alt="bhutan package tour from mumbai with direct flight" />
                                <img src={flight3ImageURL} alt="bhutan package tour from mumbai with return flight" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DAY WISE ITINERARY [PARO-PARO] : <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DAY WISE ITINERARY [PARO-PARO] : <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <p>The 7N/8D <strong>Bhutan tour package itinerary from Mumbai</strong> as mentioned below :</p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                WELCOME AT PARO AIRPORT, TRANSFER TO THIMPHU [N/STAY THIMPHU]
                              </span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>THIMPHU FULL DAY SIGHTSEEING TOUR [N/STAY THIMPHU]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>THIMPU TO PHOBJIKHA VALLEY  [N/STAY PHOBJIKHA]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>PHOBJIKHA TO PUNAKHA TRANSFER  [N/STAY PUNAKHA]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>PUNAKHA TO PARO TRANSFER [N/STAY PARO]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>PARO TAKTSANG MONASTERY HIKING [N/STAY PARO]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>PARO TO CHELE-LA-PASS DAY TRIP [N/STAY PARO]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>PARO AIRPORT TRANSFER, TOUR ENDS</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>INCLUSIONS : <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH RETURN FLIGHT</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>INCLUSIONS : <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH RETURN FLIGHT</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              Accommodation in TCB listed Hotels (Double/Twin Sharing Room)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Meal Plan MAP (Breakfast and Dinner)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Parking, Toll Tax, E-Permits
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Thimphu Local Sightseeing
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Paro City Tour
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              English / Hindi Speaking Tour Guide
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              All transfer will be on point-to-point basis
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              SDF Charges (Sustainable Development Fee)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              01 Bottle Packaged Drinking Water Per Day on Vehicle
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Guest Service Support during the trip from Bhutan & India Office 24/7
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="bhutan package tour booking from mumbai" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /> <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>EXCLUSIONS : <strong>BHUTAN TOUR PACKAGES FROM MUMBAI WITH AIRFARE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>EXCLUSIONS : <strong>BHUTAN TOUR PACKAGES FROM MUMBAI WITH AIRFARE</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#" style={{ color: 'black' }}>
                              Extras of personal nature such as personal tips, laundry charges, and additional tour charges
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Travel Insurance (Mandatory)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Driver tips
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Insurance, Medical & Emergency rescue costs
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Does not include Hard drinks liquor or soft drinks/juices
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Does not include Entrance Fees at Monasteries / Monuments / Museums etc
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Additional sightseeing or extra usage of vehicles, other than those mentioned in the itinerary
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Mini bar
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Any cost arising due to natural calamities like landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable" data-target="panelsStayOpen-headingOne">
                            <a href="#" style={{ color: 'black' }}>
                              Any increase in taxes or fuel price, leading to an increase in cost on surface transportation & land arrangements, which may come into effect prior to departure
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="bhutan package tour itinerary from mumbai" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <br />
              <table className='img-center'>
                <tbody>
                  <tr>
                    <td className="image-cell">
                      <a href='tel:9883359713'><img src={PromotionBanner2ImageURL} alt="bhutan package tour guide from mumbai" /></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br /><br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong> : 7N | 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DURATION FOR <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong> : 7N | 8D</h4>
                </div>
                <div className="card-body">
                  <h4>DESTINATIONS COVERED IN 7N 8D <strong className="tomato">BHUTAN PACKAGE TOUR FROM MUMBAI</strong> : THIMPHU 2N | PUNAKHA 1N | PHOBJIKHA 1N | PARO 3N</h4><br /><br />
                  <h4><b>PICKUP & DROP</b> : PARO INTERNATIONAL AIRPORT [PBH]</h4><br />

                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf"><tr class="table-danger"><th>CITY</th><th>ALTITUDE</th><th>CO-ORDINATES</th></tr></thead>
                      <tbody>
                        <tr><td>Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                        <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                        <tr><td>Paro</td><td>2,200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                        <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>
                        <tr><td>Chele La Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                        <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                        <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="bhutan tour guide from mumbai" />
                          <img src={sightseen5ImageURL} alt="bhutan tour package from mumbai with return flight" />
                          <img src={sightseen6ImageURL} alt="bhutan tour package booking from mumbai" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>  <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <p>Hello Mumbaikars..! Welcome to Bhutan - "Kuzu Zangpo La"! Are you feeling exhausted from the daily grind of work and traffic? Do you dream of escaping into nature and experiencing ancient cultures? If so, our <strong>Bhutan package tour from Mumbai</strong> is perfect for you! Explore Bhutan like never before, where every moment celebrates its stunning natural beauty, rich culture, and pursuit of happiness. Bhutan, a Buddhist Kingdom, has a magical charm that touches your soul, offering peace and happiness amidst hectic schedules. Don't wait, book your <strong>Bhutan tour package from Mumbai</strong> now and immerse yourself in luxury and tranquillity, far from the hustle and bustle of Mumbai's chaos. <br /><br />

                    Nestled in the majestic Himalayas, Bhutan is known for its pristine beauty and spiritual tranquillity. Our <a href="/mumbai-special-chartered-flight/" target="_blank"><strong>Bhutan package tour from mumbai with Chartered flight</strong></a> give you a rare chance to discover this enchanting kingdom, where lush valleys, snowy mountains, and sparkling rivers create breath-taking landscapes at every turn.<br /><br />

                    Bhutan is a unique country celebrated for its stunning scenery, rich culture, and dedication to preserving its environment and traditions. If you're considering <strong>Bhutan tour packages from Mumbai with flights</strong>, Adorable Vacation offers several options. As a leading <strong>Bhutan travel agency in Mumbai</strong>, Adorable Vacation provides unparalleled expertise, ensuring top accommodations and services for discerning travellers from across India. Known for its excellent service throughout Bhutan, Adorable Vacation has quickly become the most trusted name for <strong>Bhutan package tour from mumbai with return flight</strong>, offering competitive prices and group packages. Let's explore the wonders of Bhutan together.<br /><br />

                    In Bhutan, happiness isn't just a goal but a way of life. As the only country that measures success by Gross National Happiness [GNH], Bhutan offers a refreshing perspective on what truly matters. Our <strong>Bhutan package tour from Mumbai</strong> allows you to delve into this philosophy, interact with friendly Bhutanese people, and discover the secrets to genuine happiness.<br /><br />

                    Escape the hustle and bustle of city life and rejuvenate your mind, body, and soul in Bhutan's unspoiled environment. With its lush forests, fresh air, and peaceful atmosphere, Bhutan is ideal for yoga, meditation, and spiritual renewal. Let go of stress as you connect with nature and find inner peace in this land of the thunder dragon.<br /><br />

                    Our <strong>Bhutan package tour from Mumbai with direct flight</strong> includes exclusive opportunities for yoga and meditation retreats led by experienced instructors. Whether you're a seasoned yogi or new to meditation, Bhutan's serene landscapes and spiritual energy provide the perfect setting to awaken your senses, calm your mind, and refresh your spirit.<br /><br />

                    Don't miss the chance to embark on a transformative journey to Bhutan - the land of happiness and harmony. With our carefully crafted <strong>Bhutan package from Mumbai</strong>, you'll create memories to cherish forever as you explore this magical destination. Discover Bhutan with Adorable Vacation, your premier <strong>Bhutan DMC in Mumbai</strong>. We specialize in customizing <strong>Bhutan package tour itinerary from mumbai</strong>, offering luxurious direct chartered flights from Mumbai to Paro, making your journey comfortable and memorable. While there's no regular Mumbai to Paro flight, our special non-stop chartered flights ensure a premium travel experience for Bhutan-bound guests. Come, explore, and embark on an unforgettable <strong>Bhutan tour package from Mumbai</strong> with Adorable Vacation – your premier <strong>Bhutan tour guide from Mumbai</strong>. <br /><br /></p>
                  <br />
                  <div className="container-fluid py-5">
                    <div className="row">
                      <div className="col-md-12">{<IndividualIntervalsExample />}</div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>
                    <strong>BHUTAN TRIP COST FROM MUMBAI</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><strong>BHUTAN TRIP COST FROM MUMBAI</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger">
                          <th>TOUR CATEGORY</th>
                          <th>TOUR DURATION</th>
                          <th>ROOM CATEGORY</th>
                          <th>TOUR PRICE [MIN 6 PAX]</th>
                          <th>BOOK NOW</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>7N/8D BOM-PBH TRIP <div className="reviews mx-5">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div></td><td>7N/8D</td><td>3 STAR</td><td>₹ 93,499/P</td><td><a href="tel:+91-9883359713" className="btn btn-success btn-md">
                            CALL NOW
                          </a></td></tr>
                        <tr><td>7N/8D BOM-PBH TRIP <div className="reviews mx-5">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div></td><td>7N/8D</td><td>4 STAR</td><td>₹ 99,999/P</td><td><a href="tel:+91-9883359713" className="btn btn-success btn-md">
                          CALL NOW
                        </a></td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="bhutan tour package cost from mumbai" />
                          <img src={sightseen11ImageURL} alt="bhutan tour package itinerary from mumbai" />
                          <img src={sightseen12ImageURL} alt="bhutan tour package guide from mumbai" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <p>The <strong>cost of a Bhutan tour package from Mumbai</strong> depends on several factors, such as the <strong>Bhutan tour itinerary from Mumbai</strong> you choose, the accommodation you prefer, the type of vehicles, activities you wish to do, the duration of your tour, and the number of travelers. <br /><br />

                    The <strong>Bhutan trip cost from Mumbai</strong> can vary based on factors like the tour duration, the type of accommodation selected, the number of people in your group, and the season of travel. However, all tourists visiting Bhutan are required to pay a minimum daily tariff set by the Bhutanese government, which includes accommodation, meals, transport, and a licensed guide. <br /><br />

                    The minimum daily tariff for <strong>Bhutan tourism</strong> was established when Bhutan reopened its borders after the long coronavirus-related closure. The daily visa fee is 100 USD per person per night in the country, and Indians must pay ₹ 1,200 per day per person as a Sustainable Development Fee (SDF). <br /><br />

                    Our 2025 Summer special <strong>Bhutan package from Mumbai with airfare</strong> include flights to and from Bhutan with BOM-PBH-BOM direct Non-Stop Chartered Flight. It also covers SDF, travel insurance, 5 star lunch, all meals and activitie such as Tiger Nest Hiking. It is important to note that Bhutan follows a "<b>High Value, Low Impact</b>" tourism policy, designed to promote sustainable tourism and preserve the country's culture and environment. The minimum daily tariff ensures that tourism benefits local communities and contributes to Bhutan's development. <br /><br />

                    It is always advisable to compare <strong>Bhutan tour price from Mumbai</strong> with different <strong>Bhutan tour operators from Mumbai</strong> and review their packages, inclusions, and other offerings carefully to get the best value for your money. The <strong>Bhutan tour package from Mumbai price</strong> may also be higher during peak season, so it's recommended to book your <strong>Bhutan package tour from Mumbai with a return flight</strong> in advance with Adorable Vacation to avoid last-minute price hikes or the unavailability of rooms in Bhutan. <br /><br /></p>
                  {/* <a href="tel://9883359713" className="btn btn-Warning">
                    Book Now
                  </a> */}
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review</span> from our esteemed Guests Mr. & Mrs. Dalal from Mumbai</b><br /></h3>
                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan Package tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <a href='/mumbai-special-chartered-flight' target='_blank'><strong>Bhutan tour from Mumbai with Direct Flight</strong></a>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                <div class="video-container">
                  <iframe
                    src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>PLACES OF ATTRACTIONS IN <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH FLIGHT</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>PLACES OF ATTRACTIONS IN <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH FLIGHT</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>PLACES</b></th><th><b>ATTRACTIONS</b></th></tr></thead>

                      <tbody>
                        <tr><td><b>Thimphu</b></td><td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td></tr>

                        <tr><td><b>Paro</b></td><td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>

                        <tr><td><b>Punakha</b></td><td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td></tr>

                        <tr><td><b>Phobjikha Valley</b></td><td>Gangte Goemba [Gangtey Monastery], Black-Necked Crane Information Centre, Gangtey Nature Trail, Nyelung Drachaling, Kuenzang Chholing Shedra, Khewang Lhakhang, Phobjikha Valley Hiking Trails, Kumbhu Lhakhang, Damcho Lhakhang, Dzongdrakha Goemba, Bumdeling Wildlife Sanctuary, Jigme Singye Wangchuck National Park, Gogona Village trek etc.</td></tr>

                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen13ImageURL} alt="bhutan tour guide from mumbai at best price" />
                          <img src={sightseen14ImageURL} alt="bhutan tour operator from mumbai" />
                          <img src={sightseen15ImageURL} alt="bhutan tour agency in mumbai" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHY YOU SHOULD BOOK <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong> WITH ADORABLE VACATION</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHY YOU SHOULD BOOK <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong> WITH ADORABLE VACATION</h4>
                </div>
                <div className="card-body">
                  <ul>

                    <li><b>Trusted Brand :</b> Adorable Vacation has built a reputation as the most trusted brand for <strong>Bhutan package tour from Mumbai</strong>, with years of experience in curating exceptional travel experiences to Bhutan. Our commitment to providing high-quality services, customer satisfaction, and seamless trip planning has made us the preferred choice for travelers from Mumbai seeking to explore Bhutan. <br /><br /></li>

                    <li><b>Passenger Service Agent [PSA] :</b> We are proud to be the official Passenger Service Agent [PSA] for Bhutan Airlines and Druk Airlines, which allows us to offer exclusive access to flights and provide smooth travel experiences for our clients. Our partnership ensures that your flight arrangements are well-managed, with top-notch service and seamless transitions from <strong>Mumbai to Bhutan Tour</strong>. <br /><br /></li>

                    <li><b>Luxury Packages :</b> Experience the best of Bhutan with our <a href="https://adorablevacation.com/bhutan-luxury-package-tour-with-six-senses-hotel/" target="_blank"><strong>Bhutan Luxury Package Tour with Hotel Six Senses</strong></a>, one of the most prestigious resorts in the country, along with other top-tier accommodations like Amankora, COMO Uma, Le Méridien, and Pemako. These luxurious stays offer world-class facilities, unparalleled service, and stunning views, providing a truly opulent experience in Bhutan. <br /><br /></li>

                    <li><b>Super Peak Season Inventory :</b> We understand how important it is to secure accommodation during peak seasons. That's why we have pre-purchased hotel room inventory for the busiest periods, ensuring that our guests have guaranteed accommodation during their visit. In addition, we offer <a href="https://adorablevacation.com/mumbai-special-chartered-flight/" target="_blank"><strong>Bhutan package tour from Mumbai with direct non-stop chartered flights</strong></a>, allowing you to enjoy a comfortable and stress-free journey even during the high-demand months. <br /><br /></li>

                    <li><b>Authorized Travel House :</b> As an authorized travel house by the <b>Tourism Council of Bhutan</b>, we operate with full legitimacy and adhere to the highest standards of quality and professionalism. Our license [No: 1053228] ensures that your trip is organized through an official and trusted operator, guaranteeing the safety and reliability of all services we provide. <br /><br /></li>

                    <li><b>Direct Employment :</b> Unlike many agencies, we employ all of our staff directly in Bhutan, which means that our employees are fully dedicated to providing exceptional service and knowledge. This direct employment structure ensures that we maintain quality control and that you receive the best possible service from local experts throughout your stay. <br /><br /></li>

                    <li><b>Customized Itineraries :</b> We offer tailored <strong>Bhutan tour itineraries from Mumbai</strong>, designed to suit your specific interests, whether you're looking for a cultural exploration, adventure, relaxation, or a mix of everything. Our team works closely with you to create a personalized itinerary that reflects your preferences and ensures a memorable and unique Bhutan experience. <br /><br /></li>

                    <li><b>In-Depth Knowledge :</b> Our team is composed of experts with extensive knowledge about Bhutan’s geography, local customs, hotels, and laws. This expertise ensures that you have accurate and valuable insights into every aspect of your trip, allowing you to explore Bhutan with confidence and ease. <br /><br /></li>

                    <li><b>Assured Services : </b> We are committed to providing premium services throughout your <strong>Mumbai to Bhutan trip</strong>, ensuring that every detail is taken care of. From airport transfers to guided sightseeing, we ensure that you enjoy a seamless and comfortable experience that enhances your Bhutan journey. <br /><br /></li>

                    <li><b>E-Permit Processing :</b >We handle all the paperwork required for your trip, including the e-permit processing for sightseeing in Bhutan. This ensures that you won’t have to worry about any administrative tasks and can focus entirely on enjoying your vacation. <br /><br /></li>

                    <li><b>Personalized Attention :</b> At Adorable Vacation, we believe in offering safe and secure personalized attention throughout your stay in Bhutan. Our local guides and team members are always available to assist you, ensuring that your <strong>Bhutan Package Tour from Mumbai</strong> is smooth, enjoyable, and stress-free. <br /><br /></li>

                    <li><b>Complete Responsibility :</b> We take full responsibility for your entire <strong>Bhutan tour Package from Mumbai with return flight</strong>, from the moment you land in Bhutan until your return. We manage every aspect of the tour to ensure you have a hassle-free and delightful experience. <br /><br /></li>

                    <li><b>Hassle-Free Experience :</b> With Adorable Vacation, you can be assured of a hassle-free experience in Bhutan. We take care of all logistics, including transportation, accommodation, and sightseeing, so that you can focus on enjoying the beauty and culture of Bhutan without any worries. <br /><br /></li>

                    <li><b>Best Deals :</b> We offer the best deals for top properties and services based on your preferences. Whether you are looking for luxury, mid-range, or budget options, we work with a network of trusted partners to provide you with competitive prices, ensuring excellent value for your money.<br /><br /></li>

                    <li><b>Flexible Travel Options :</b> Whether you want to enjoy the freedom of a customized <strong>Bhutan private trip from Mumbai</strong> or experience the camaraderie of a <strong>Bhutan group tour from Mubai</strong>, Adorable Vacation gives you the flexibility to choose the best travel option based on your preferences and group size. No matter your choice, we guarantee an unforgettable Bhutan experience. <br /><br /></li>

                  </ul>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen16ImageURL} alt="bhutan package tour from mumbai" />
                          <img src={sightseen17ImageURL} alt="bhutan tour package from mumbai" />
                          <img src={sightseen18ImageURL} alt="bhutan package tour from mumbai with flight" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <h3 className='site-heading text-center'>GUEST REVIEW <br /><span className='tomato'><strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></span><br /></h3>
                <div className="row">
                  <div className="col-md-12">{<IndividualIntervalsReview />}</div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>QUICK FACTS NEED TO KNOW FOR  <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH FLIGHT</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>QUICK FACTS NEED TO KNOW FOR  <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH FLIGHT</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr><th>Fact</th><th>Details</th></tr>
                        <tr><td>Capital</td><td>Thimphu</td></tr>
                        <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                        <tr><td>Official Language</td><td>Dzongkha</td></tr>
                        <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                        <tr><td>Area</td><td>38,394 km²</td></tr>
                        <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                        <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                        <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                        <tr><td>National Animal</td><td>the Takin</td></tr>
                        <tr><td>Major Religion</td><td>Buddhism</td></tr>

                        <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>

                        <tr><td>Notable Attractions in Bhutan</td><td>
                          Paro Taktsang, Dochula Pass, Punakha Dzong, National Memorial Chhorten, Tashichho Dzong, National Museum of Bhutan, Gangtey Monastery, Punakha Suspension Bridge, Royal Textile Academy, Trashigang Dzong, Ura valley, Dechen Phodrang Monastery, National Library & Archives Of Bhutan</td></tr>

                        <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>

                        <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>

                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="bhutan package tour from mumbai with direct flight" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>

              <br /><br /><br />
              {/* start short itinerary */}
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>WHICH IS THE BEST WAY TO REACH BHUTAN FROM MUMBAI?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>WHICH IS THE BEST WAY TO REACH BHUTAN FROM MUMBAI?</h4>
                </div>
                <div className="card-body">
                  <p>This is a common question for anyone considering a <strong>Bhutan tour package from Mumbai</strong>. There are 3 Ways to reach Bhutan from Mumbai. Let's take a look.<br /><br /></p>
                  <h4 className="flight-red">OPTION 1 : <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH DIRECT FLIGHT</strong></h4><br />
                  <p>We are thrilled to offer a special convenience for travelers by introducing the <strong>Bhutan package tour from Mumbai with direct chartered flight</strong>. This exclusive service provides a non-stop flight from <strong>Mumbai to Paro</strong> during the peak travel season, making your journey faster and more seamless. In just 3 hours, guests will be transported directly from Mumbai to Paro, eliminating layovers and ensuring a hassle-free experience. This year 2025 May month, Adorable Vacation is offering <strong>Mumbai to Paro Fixted Departure</strong> with Direct Chartered Flight on <b>May 15th, 18th, 22nd, 25th, 29th & June 1st</b>. This is very prestigious flights that will run only 6 days in May month of year 2025. So, don't miss the opportunity. This BOM-PBH direct flight guarantees a convenient and comfortable journey, ensuring you get the most out of your Bhutan adventure.</p>
                  <br />
                  <h4 className="tomato1">SCHEDULE FOR MUMBAI TO PARO DIRECT NON-STOP CHARTERED FLIGHT</h4><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <td><b>SECTOR</b></td>
                          <td><b>DEPART TIME</b></td>
                          <td><b>ARRIVAL TIME</b></td>
                          <td><b>DATE</b></td>
                          <td><b>BOOK NOW</b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>B3 [BOM - PBH] </td>
                          <td>13:00 PM</td>
                          <td>16:00 PM</td>
                          <td>MAY 15, 18, 22, 25, 29 | JUNE 1</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>B3 [PBH - BOM] </td>
                          <td>08:00 AM</td>
                          <td>11:30 AM</td>
                          <td>MAY 22, 25, 29 | JUNE 01, 05, 08</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <h4 className="flight-red">OPTION 2 : <strong>BHUTAN TOUR WITH DIRECT FLIGHT TO PARO FROM DELHI | KOLKATA | GUWAHATI</strong></h4>
                  <p>Bhutan Airlines and Druk Airways offer regular flights from Delhi, Kolkata, and Guwahati airports to Paro Airport (PBH) throughout the year. Passengers traveling from Mumbai can take a domestic flight to Delhi, Kolkata, or Guwahati, and then board a direct flight to Paro. Please be aware that flight schedules and airfares may fluctuate from time to time.</p>
                  <br /> <br />
                  <h4 className="tomato1">DELHI TO PARO DIRECT FLIGHT DETAILS </h4>
                  <div id="no-more-tables" itemscope itemtype="https://schema.org/Table">

                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger">
                          <th><b>SECTOR</b></th>
                          <th><b>DEP DAY</b></th>
                          <th><b>DEP TIME</b></th>
                          <th><b>ARR TIME</b></th>
                          <th><b>DURATION</b></th>
                          <th><b>BOOK NOW</b></th>
                        </tr></thead>

                      <tbody>
                        <tr>
                          <td>CCU-PBH</td>
                          <td>EVERYDAY</td>
                          <td>11:25am</td>
                          <td>12:55pm</td>
                          <td>01 hrs</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>PBH-CCU</td>
                          <td>EVERYDAY</td>
                          <td>10:35am</td>
                          <td>11:15pm</td>
                          <td>01 hrs 10 mnts</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br />
                  <h4 className="tomato1">KOLKATA TO PARO DIRECT FLIGHT DETAILS</h4>
                  <div id="no-more-tables" itemscope itemtype="https://schema.org/Table">

                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger">
                          <th><b>SECTOR</b></th>
                          <th><b>DEP DAY</b></th>
                          <th><b>DEP TIME</b></th>
                          <th><b>ARR TIME</b></th>
                          <th><b>DURATION</b></th>
                          <th><b>BOOK NOW</b></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>CCU-PBH</td>
                          <td>EVERYDAY</td>
                          <td>11:25am</td>
                          <td>12:55pm</td>
                          <td>01 hrs</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>PBH-CCU</td>
                          <td>EVERYDAY</td>
                          <td>10:35am</td>
                          <td>11:15pm</td>
                          <td>01 hrs 10 mnts</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br /> <br />
                  <h4 className="tomato1">GUWAHATI TO PARO DIRECT FLIGHT DETAILS </h4>
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger">
                          <th>AIRLINES</th>
                          <th>DEPARTURE</th>
                          <th>ARRIVAL</th>
                          <th>DAYS</th>
                          <th>BOOK NOW</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Drukair</td>
                          <td>KB 541 [GAU] 15:20</td>
                          <td>[PBH] 16:40 </td>
                          <td>Every Sunday</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>Drukair</td>
                          <td>KB 540 [PBH] 07:50</td>
                          <td>[GAU] 08:15 </td>
                          <td>Every Saturday</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /> <br />
                  <h4 className="flight-red">OPTION 3 : TAKE FLIGHT UPTO BAGDOGRA, THEN ENTER BHUTAN BY ROAD</h4>

                  <p>Bagdogra Airport is another starting point for <strong>Bhutan tour from Mumbai</strong>. Booking any <a href="https://adorablevacation.com/bhutan-tour-package-from-bagdogra/" target="_blank"><strong>Bhutan package tour from Bagdogra</strong></a>, is very cost effective and relaxing as you can experience the journey with scenic beauty on the way. So, book <strong>Bhutan package tour from Mumbai via Bagdogra</strong> and experience the magic of Bhutan with Adorable Vacation - The <strong>#1 Bhutan specialist in Mumbai</strong>. Following are the Mumbai to Bagdogra Flight schedule to note. </p>

                  <br />
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger">
                          <th>AIRLINES</th>
                          <th>DEPARTURE</th>
                          <th>ARRIVE</th>
                          <th>DURATION</th>
                          <th>BOOK NOW</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>IndiGo</td>
                          <td>Mumbai [BOM] 08:15</td>
                          <td>Bagdogra [IXB] 10:55</td>
                          <td>02 h 40 m</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>SpiceJet</td>
                          <td>Mumbai [BOM] 08:25</td>
                          <td>Bagdogra [IXB] 11:10</td>
                          <td>02 h 45 m</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>Akasa Air</td>
                          <td>Mumbai [BOM] 11:05</td>
                          <td>Bagdogra [IXB] 13:55</td>
                          <td>02 h 50 m</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>IndiGo</td>
                          <td>Mumbai [BOM] 05:40</td>
                          <td>Bagdogra [IXB] 10:45</td>
                          <td>05 h 05 m</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                        <tr>
                          <td>Air India</td>
                          <td>Mumbai [BOM] 05:55</td>
                          <td>Bagdogra [IXB] 11:10</td>
                          <td>05 h 15 m</td>
                          <td><a href="tel:+91-9883359713" className="btn btn-success">
                            GET QUOTE
                          </a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="bhutan package tour from mumbai with return flight" />
                          <img src={sightseen15ImageURL} alt="bhutan package tour booking from mumbai" />
                          <img src={sightseen19ImageURL} alt="bhutan package tour cost from mumbai" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>SIGHTSEEING PLACES & ENTRY FEES :<strong> BHUTAN TOUR FROM MUMBAI</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>SIGHTSEEING PLACES & ENTRY FEES :<strong> BHUTAN TOUR FROM MUMBAI</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>PLACES</th>
                          <th>CITY</th>
                          <th>FEES</th>
                          <th>REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td>
                        </tr>
                        <tr>
                          <td>Tashichhoe Dzong (Fort)</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td>
                        </tr>
                        <tr>
                          <td>Buddha Dordenma Statue</td>
                          <td>Thimphu</td>
                          <td>No Entry Fee</td>
                          <td>Time-9am to 5pm</td>
                        </tr>
                        <tr>
                          <td>Thimphu Memorial Chorten</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>Anytime</td>
                        </tr>
                        <tr>
                          <td>Changangkha Lhakhang (Temple)</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>Anytime between 8am to 5pm</td>
                        </tr>
                        <tr>
                          <td>Takin Preservation Centre</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>9am to 5pm</td>
                        </tr>
                        <tr>
                          <td>Simtokha Dzong (Fort)</td>
                          <td>Thimphu</td>
                          <td>NU 500</td>
                          <td>9am to 5pm</td>
                        </tr>
                        <tr><td>National Library</td><td>Thimphu</td><td>NU 100</td><td>9am to 5pm on Mon to Fri</td></tr>
                        <tr><td>The Royal Textile Museum</td><td>Thimphu</td><td>NU 250</td><td>Mon to Sat from 9am to 4pm</td></tr>
                        <tr><td>National Institute for Zorig Chusum (Painting School)</td><td>Thimphu</td><td>NU 200</td><td>Mon to Fri 10am to 3:30pm</td></tr>
                        <tr><td>Folk Heritage Museum</td><td>Thimphu</td><td>NU 200</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>
                        <tr><td>Simply Bhutan Museum</td><td>Thimphu</td><td>NU 1000</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>

                        <tr><td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td>
                        </tr>
                        <tr><td>Punakha Dzong (Fort)</td><td>Punakha</td><td>NU 500</td><td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td></tr>
                        <tr><td>Chimmi Lhakhang (Temple)</td><td>Punakha</td><td>NU 500</td><td>9am to 1pm and 2pm to 5pm </td></tr>
                        <tr><td>Khamsum Yueely Namgyal Chorten</td><td>Punakha</td><td>NU 100</td><td>9am to 1pm and 2pm to 5pm </td></tr>

                        <tr><td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Kyichu Lhakhang (temple)</td><td>Paro</td><td>NU 500</td><td>9am to 12pm & 1pm to 4pm </td></tr>
                        <tr><td>Rinpung Dzong (Fort)</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm (Summer) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Dobji Dzong</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                        <tr><td>National Museum of Bhutan</td><td>Paro</td><td>NU 300</td><td>Opens from 9am to 4pm throughout the week except on govt & local holidays </td></tr>
                        <tr><td>Taktshang (Tiger Nest Monastery)</td><td>Paro</td><td>NU 1000</td><td>9am to 5pm </td></tr>

                        <tr><td colspan="4">TRONGSA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Trongsa Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Trongsa Ta Dzong (Watch tower)</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                        <tr><td colspan="4">BUMTHANG SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Jampa Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Kurjey Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                        <tr><td>Jakar Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                        <tr><td colspan="4">PHUENTSHOLING SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                        <tr><td>Rinchending Goenpa (temple) </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                        <tr><td>Palden Tashi Choling Shedra </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                        <tr><td colspan="4">NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below. </td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="bhutan package tour itinerary from mumbai" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <h4 className="tomato1">SUMMER [JUN - SEP]</h4>
                  <p><b>Summer</b> in Bhutan spans from June to September, with temperatures in central Bhutan typically ranging between 20 to 25 degrees Celsius. The weather is generally sunny and mildly humid, while the nights can get quite chilly, so light woolen clothes or jackets are advisable. The landscapes around cities and tourist attractions are lush and green, and the clear skies offer a breathtaking view of the snow-capped Himalayan peaks. If you visit during this period, you can also experience the Haa Festival, which showcases Bhutanese cuisine, culture, traditions, and lifestyle. With fewer crowds, you can peacefully explore numerous sightseeing spots. Therefore, summer is one of the best seasons to opt for <strong>Bhutan Holiday Package from Mumbai</strong>.<br /><br /><br /></p>

                  <h4 className="tomato1">AUTUMN [SEP - NOV]</h4>
                  <p><b>Autumn</b> is widely regarded as the best season for booking <strong>Bhutan tour from Mumbai</strong>. The weather during this time is quite pleasant, ranging from 10 to 15 degrees, making sightseeing and travel enjoyable throughout the day, while the nights are comfortably chilly. This is also the festive season in Bhutan, offering travelers a glimpse into the rich cultural heritage and vibrant festivals of the country. Festivals such as the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu are celebrated with great enthusiasm, with dzongs and monasteries coming alive with colorful flags, large Thonkas, and masked dances. For tourists, it’s the perfect time to visit. However, Adorable Vacation recommends booking your in advance during the summer months to avoid the peak season rush and the unavailability of flights and hotel rooms. <br /><br /><br /></p>


                  <h4 className="tomato1">WINTER [DEC - FEB]</h4>
                  <p><b>Winter</b> in Bhutan can be quite harsh. Due to the high Himalayan altitude, temperatures drop rapidly, and cities like Thimphu, Paro, and Punakha are often blanketed in snow, creating a serene and still atmosphere. In the northern, high-altitude regions, temperatures can even fall to sub-zero levels. As the weather becomes severe, the government of Bhutan typically declares holidays for schools, colleges, and government offices. Travel between different regions may become difficult due to heavy snowfalls. However, if you're keen to experience the snow and cold and want to enjoy the festivals that occur during winter, you can certainly plan your <strong>Bhutan package tour in December from Mumbai</strong>. Some of the notable festivals, like Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu, take place between November and December. So, if you’re excited about experiencing Bhutan's winter charm, don't hesitate to book your <strong>Bhutan tour from Mumbai</strong> this season. Winter in Bhutan is truly magical! <br /><br /><br /></p>


                  <h4 className="tomato1">SPRING [MAR - MAY]</h4>
                  <p>After the cold and harsh winter, Bhutan comes to life during spring. The blooming of Jacaranda and Rhododendron flowers marks the arrival of spring, carpeting the roads, valleys, and surroundings in purple petals, creating a heavenly atmosphere. From March to May, the weather is typically dry with minimal rainfall, and the skies become crystal clear, signaling the perfect time for the world-famous Jomolhari and Drukpath treks. Additionally, this period hosts some of the most significant and auspicious festivals, including Paro Tshechu, Gasa Tshechu, Rhododendron festivals, and Talo Tshechu, where the King of Bhutan also actively participates. Since Bhutan is usually bustling with tourists during these months, Adorable Vacation advises booking your <strong>Bhutan package in May from Mumbai</strong> well in advance, along with your flights, to avoid the peak season surcharges, extra hotel costs, and increased flight prices.  <br /><br /><br /></p>


                  <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr class="table-danger"><th>MONTH</th><th>WEATHER</th><th>TEMPERATURE</th></tr></thead>
                      <tbody>
                        <tr><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td></tr>
                        <tr><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td></tr>
                        <tr><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td></tr>
                        <tr><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td></tr>
                        <tr><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td></tr>
                        <tr><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td></tr>
                        <tr><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td></tr>
                        <tr><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td></tr>
                        <tr><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td></tr>
                        <tr><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td></tr>
                        <tr><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td></tr>
                        <tr><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td></tr>
                      </tbody>
                    </table>
                    <br />
                  </div>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={PromotionBannerImageURL} alt="bhutan package tour guide from mumbai" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <table className='img-center'>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="bhutan tour guide from mumbai" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />

                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DAY WISE <strong><span>BHUTAN TOUR PACKAGES FROM MUMBAI</span></strong>
                      </h2>
                      <h4>8 Nights 9 Days <strong>Bhutan Tour Itinerary from Mumbai</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <h4>DAY 1 : WELCOME TO PARO AND TRANSFER TO THIMPHU</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Welcome to Bhutan, the Land of Thunder Dragon for your much awaited <strong>Bhutan package tour from Mumbai</strong>. Bhutan is like a painter's dream, a colourful country surrounded by the Himalayas, a small dreamy paradise with vast green valleys, beautiful landscapes, rugged terrain, crystalline rivers, and happy people. Once the aircraft enters the Paro valley, look down to see the first glimpse of the Paro Dzong on the hillside overlooking Paro Chu (river) with Ta Dzong. Our representative will welcome you in a Bhutanese style with Tashi Khada (white scarf) and guide you at the airport, which marks the beginning of your <strong>Bhutan Tour from Mumbai</strong> with transfer to Thimphu (2334 m). Overnight stay at hotel in Thimphu. <br /><br /></p>
                          {/* <br /> <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <h4>DAY 02: FULL DAY THIMPHU TOUR</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>Start your day with a hot cup of <b>Suja (Butter Tea)</b> as the sun shines over the beautiful Thimphu valley, and enjoy the peaceful morning on your <strong>Bhutan package tour from Mumbai with flight</strong>. After enjoying breakfast, get ready for a tour of Thimphu's local attractions. <br /><br /></p>

                          <p><b>The National Memorial Chorten (Thimphu Chorten)</b> : This place is where many locals come for evening prayers. It was built in memory of Bhutan's third king, Jigme Dorji Wangchuk, and was completed in 1974 after his untimely death. People walk around the chorten, spinning prayer wheels and chanting mantras. It stands as a symbol of peace and prosperity. As part of your <strong>Bhutan tour package from Mumbai</strong>, you'll have the chance to visit this serene and spiritual site. <br /><br />

                            <b>The Clock Tower</b> : Located in the center of Thimphu, the Clock Tower Square is a beautiful example of Bhutanese architecture. The tower has four clock faces and is decorated with intricate carvings of flowers, paintings, and dragons. The dragons are facing each clock and represent the country's independence. This area is popular for both locals and tourists, and it's surrounded by shops, restaurants, and hotels. The square often hosts live music events, making it a lively spot. You can also buy gifts here or enjoy a picnic in the nearby playground, as recommended by your <strong>Bhutan package tour guide from Mumbai</strong>. <br /><br />

                            <b>Sakyamuni Buddha</b> : On top of Kuensel Phodrang hill stands the giant statue of Buddha Dordenma. This 51.5-meter statue is made of gilded bronze, and it offers a stunning view of the valley. It's a peaceful and breathtaking site, also known as Buddha Viewpoint. As part of your <strong>Bhutan package tour from Mumbai</strong>, this is a must-see landmark that will leave you in awe. <br /><br />

                            <b>Changangkha Lhakhang</b> : One of the oldest and most important monasteries in Thimphu, dating back to the 15th century. It's dedicated to Avalokiteshvara, the symbol of compassion. From here, you can enjoy a panoramic view of the Thimphu valley. Your <strong>Bhutan tour package from Mumbai</strong> will include a visit to this spiritual and historic site. <br /><br />

                            <b>Takin Zoo</b> : This zoo is home to Bhutan's national animal, the Takin, an endangered species found only in Bhutan. The animals live in an open habitat, allowing you to see them in a more natural environment, a highlight of your <strong>Bhutan package tour from Mumbai with flight</strong>. <br /><br />

                            <b>Trashi Chhoe Dzong</b> : Known as the Fortress of the Glorious Region, this site is a must-visit. It sits on the west bank of the Wang Chhu River and is the coronation site of Bhutan's fifth king. It also hosts the annual <strong>Tsechu Festival</strong>. Built in 1641, it was later rebuilt in the 1960s using traditional Bhutanese methods, without nails or architectural plans. Your <strong>Bhutan package tour guide from Mumbai</strong> will take you through the fortress's rich history and architectural beauty. You'll spend the night at a hotel in Thimphu, concluding a day full of unforgettable experiences. <br /><br /><br /></p>

                          {/* <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 03: THIMPHU TO PUNAKHA TRANSFER VIA DO-CHULA PASS & SIGHTSEEING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Start your 3rd day of your <strong>Bhutan package tour from Mumbai with direct flight</strong> in Thimphu with a cup of Hot Ngaja (sweet Bhutanese milk tea) and enjoy the view of the beautiful valley from your room. After breakfast, head to Punakha, the former capital of Bhutan. Punakha is a peaceful place, much quieter than the busy cities, sitting at an altitude of 1200 meters. It's warm in winter and cool in summer. The valley is famous for growing red and white rice along the Pho and Mo Chu rivers. On the way, you'll visit: <br /><br />

                            <b>Punakha Dzong</b>: Also called "<b>The Palace of Great Happiness</b>", this stunning fortress sits at the meeting point of the Pho Chhu (father) and Mo Chhu (mother) rivers. It's one of the most beautiful dzongs in Bhutan. The dzong is connected to the mainland by an arched wooden bridge and is home to many valuable relics from the time when the kings ruled Bhutan from this valley. This visit is a highlight of your <strong>Bhutan package tour from Mumbai with flight</strong>, giving you an incredible cultural and historical experience. <br /><br />

                            <b>Suspension Bridge</b>: This hidden gem in the Punakha Valley connects the valley to the old administrative center of Punakha Dzongkhag. The bridge is covered with prayer flags and offers a great spot for birdwatching and taking photos, a perfect moment to capture on your <strong>Bhutan package tour from Mumbai with flight</strong>. <br /><br />

                            <b>Chimi Lhakhang (Fertility Temple)</b>: Located on a hill, this temple is dedicated to Lama Drukpa Kuenley, also known as the 'Divine Madman.' He used humor and songs to teach his lessons. Chimi Lhakhang is considered a fertility temple, and it is believed that childless couples who visit and pray here will be blessed by Lama Drukpa Kuenley. The temple is reached by walking along a beautiful rural path through paddy fields. After crossing a small stream, a short climb will take you to the temple. After this remarkable visit, you will stay overnight at a hotel in Punakha, enjoying the serene atmosphere. <br /><br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>Day 04: PUNAKHA TO PHOBJIKHA VALLEY EXCURSION</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Say a very Goooooooood Morning Punakha on your 4th day of <strong>Bhutan trip from Mumbai with Airfare</strong>. As the Sun shines up high in the Land of Happiness, open your eyes to a beautiful morning in the lap of serene nature, and sip into your cup of hot tea while admiring the green valley from your bed before moving towards Phobjikha Valley post breakfast. <br /><br />

                            <b>Phobjikha Valley</b> : A treat for all nature lovers, and an idle place for spotting various birds, including the famous Black Necked Cranes that migrate to this valley. Being one of the most important wildlife preserves in Bhutan, this serene and untouched beauty of nature away from the hustle-bustle of the city life is a pleasure to enjoy with the happiest people surrounding the entire region that makes this place worth the visit. The Phobjikha Valley is the largest and the widest in Bhutan, known for its panoramic vistas and tranquil nature. This serene environment not only attracts nature lovers to witness its beauty but also draws adventure buffs who wish to follow trekking and hiking routes while exploring the untouched beauty of nature. A <strong>Bhutan package tour from Mumbai</strong> ensures you don't miss out on this stunning experience. Overnight at hotel in Punakha.<br /><br /><br /></p>

                          <h4 className="tomato1">Following are the places one can visit during <b>Phobjikha Valley Trip</b> : </h4><br /><br />

                          <li>Black-Necked Crane Information Centre <br /></li>
                          <li>Gangtey (Hilltop) Monastery or Gangtey Goemba <br /></li>
                          <li>Gangtey Nature Trails <br /></li>
                          <li>Dotsho (Traditional Hot Stone Bath) <br /></li>
                          <li>Experience Stay in Bhutanese Farm Stay <br /></li>
                          <li>Khewang Lhakhang <br /></li>
                          <li>Experience Archery with Local People <br /></li>
                          <li>Open Air Campsite at Phobjikha <br /></li>
                          <li>Picnic with Family And Friends <br /></li>
                          <li>Kumbhu Lhakhang <br /></li>
                          <li>Restaurant in Phobjikha <br /></li>
                          <li>Nyelung Dechenling <br /></li>
                          <li>Bird Watching in Phobjikha <br /></li>
                          <li>Wangmo Hand-Woven Carpet Factory (Phobjikha) <br /></li>
                          <br /><br />
                          {/* <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 05: PUNAKHA TO PARO TRANSFER</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, start your journey to Paro and visit the following places on the way: <br /><br />

                            <b>National Museum of Bhutan</b> : Located above Rinpung Dzong in Paro, the National Museum of Bhutan showcases Bhutan's rich culture. Built in 1968 inside the old Ta Dzong building, it displays some of the finest examples of Bhutanese art and culture, including beautiful paintings and bronze statues. The museum’s extensive galleries provide insights into Bhutan’s history, culture, and traditions. A <strong>Bhutan tour package from Mumbai</strong> ensures you don’t miss this historical gem. <br /><br />

                            <b>Drukgyal Dzong</b> : Drukgyal Dzong, once a fortress and Buddhist monastery, now lies in ruins in the upper Paro Valley. Built in 1649 to commemorate Bhutan's victory over a Tibetan invasion, it remains an important historical site, although it is currently closed to visitors. <br /><br />

                            <b>Ta Dzong</b> : Originally a watchtower constructed to protect Rinpung Dzong during the 17th-century wars, Ta Dzong became the National Museum of Bhutan in 1968. It holds numerous artifacts and exhibits that reflect Bhutan’s fascinating heritage. <br /><br />

                            <b>Rinpung Dzong</b> : Built in 1646 by Shabdrung Ngawang Namgyal, the first ruler of Bhutan, Rinpung Dzong is home to the monks of Paro and the district head (Dzongda). To reach the dzong, visitors must cross the traditional Nemi Zam bridge, a covered structure that adds to the dzong’s charm. <br /><br />

                            <b>Nya Mey Zam Bridge</b> : Known as "The Bridge with No Fish," Nya Mey Zam connects Paro Town to the Dzong. Locals believe that seeing a fish in the river is an ill omen. The original bridge, which was foldable in times of war, was destroyed by a flood in 1969. <br /><br />

                            <b>Paro Airport View Point</b> : Offering the best view of Paro Valley and Paro Airport, this viewpoint provides a breathtaking panoramic view of the Paro River, dzongs, and monasteries. It's the perfect spot to conclude your day of sightseeing. Overnight stay at a hotel in Paro. <br /><br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>Wake up in the beautiful Paro Valley as the sun rises, surrounded by nature. Enjoy the amazing view of the river and mountains. Have a hearty breakfast with plenty of protein, because the hike to Taktsang Monastery will require some energy. <br /><br />

                            <b>Taktsang Monastery</b>: The hike to Taktsang Monastery, also known as the Tiger's Nest, takes you up a steep cliff for 1,200 meters. Once you reach the top, take a deep breath and enjoy the fresh air and the incredible view of the valley below. The monastery was built in 1694 and is a sacred site founded by Guru Rinpoche, who brought Buddhism to Bhutan in 747 CE. After a 3-hour trek, you'll reach the monastery. If you prefer, you can take a horse ride from the parking lot to the top. During your hike, you'll be treated to beautiful views of Paro town, with its green valleys, clear blue sky, and sparkling river. It's a perfect place to enjoy nature. This experience is part of the <strong>Bhutan package tour from Mumbai</strong>, ensuring you don't miss out on one of Bhutan’s most iconic landmarks. Overnight in Paro. <br /><br /><br /></p>
                          <div id="no-more-tables">
                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                              <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                              </tbody>
                            </table>
                          </div>
                          <br /><br />
                          {/* <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day7"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 07: CHELE-LA PASS DAY TRIP, EVENING LEISURE</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After breakfast an excursion trip to Chele-La-Pass (13,088 ft.) – the highest motorable road in Bhutan. Enjoy the scenic beauty &amp; memorable landscapes from Chele-La-Pass. Back to Paro. Rest of the day is free for leisure. <br /><br /></p>

                          <h4 className="tomato1">HOW TO GET TO CHELE-LA PASS?</h4>

                          <p>To visit Chele La Pass, it's best to start your day early. The pass is located at a very high altitude (13,000 ft / 3,988 m), so there aren't any food options available once you get there. Make sure you have a hearty breakfast before you leave and pack a lunch along with bottled water. As you drive out of Paro City, you'll notice the landscape starting to change, with thick forests of pine and spruce trees surrounding you. The road is smooth, and the view of the evergreen trees is stunning. You'll feel refreshed by the fresh mountain air as you enjoy the natural beauty along the way. As you go higher, you'll start to see snow on both sides of the road, with trees gently covered in snow. Eventually, you'll reach the highest point of the pass, where it can be very windy and cold. On clear days, you can see amazing views of Mount Jumolhari (the second-highest peak in Bhutan), Jichu Drake, and other northern peaks. The view from here is truly breathtaking. The area is decorated with colorful prayer flags, and it's a perfect place to take pictures. There's also a small shop run by locals where you can enjoy hot Maggi noodles and tea. After spending some time here, you can either continue to Haa Valley (25 km / 1 hour) or return to Paro. You'll have some free time to relax and explore. Overnight stay at a hotel in Paro. <br /><br /><br /></p>


                          <h4 className="tomato1">BEST TIME TO VISIT CHELE-LA PASS</h4>

                          <p>Because of its high altitude (13,000 ft / 3,988 m), the best time to visit Chele La Pass is from the end of April to the end of June. This is during the summer, when you can enjoy the views of snow-covered mountains, the vibrant rhododendron flowers, and the beautiful landscape of the valleys surrounding the pass. This is also a great time for family vacations, picnics, and trekking. From July to September, the area experiences monsoon rains, making travel risky. From October to March, winter sets in, and the area becomes covered in thick snow, with cold temperatures that can make life difficult and even make the pass inaccessible. So, if you want to experience the beauty of Chele-La Pass, consider booking your <strong>Bhutan package tour from Mumbai</strong> between the end of March and the end of June. You can also book a Mumbai to Bhutan trip or a Mumbai to Bhutan direct flight package for a smooth and easy travel experience.
                            <br /><br /><br /></p>


                          <h4 className="tomato1">ALL YOU NEED TO KNOW ABOUT CHELE LA PASS</h4>

                          <li>Paro to Chelela Pass distance is about 39.8 km</li>
                          <li>It takes amost 1hr and 30 mnts to reach Chelela Pass from Paro</li>
                          <li>Chelela Pass is situated at an altitude of 3,988 m</li>
                          <li>It is the highest motor able road of Bhutan</li>
                          <li>Bhutan's most sacred peak, Mount Jomolhari (22,000 ft) can be clearly seen from this pass</li>
                          <li>There are so many hiking trails that start from this pass</li>
                          <li>Mountain slopes of this region are bloomed with white poppy which can’t be seen anywhere else in the world</li>

                          <br /><br />
                          <h4 className="tomato1">IMPORTANT HIKING TRAILS THAT STARTS FROM CHELELA PASS</h4>

                          <div id="no-more-tables">
                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                              <thead className="cf"><tr><th>PLACE / LOCATION</th><th>DISTANCE</th><th>ALTITUDE (M)</th></tr></thead>
                              <tbody><tr><td>Chelela - Gangkarpo</td><td>2 hrs walk</td><td>3900 - 4100 m</td></tr>
                                <tr><td>Gungkarpo - Kalela</td><td>1.30 hrs walk</td><td>4100 - 3700 m</td></tr>
                                <tr><td>Kalela - Nigula (camp site)</td><td>4 hrs walk</td><td>3700 - 3800 m</td></tr>
                                <tr><td>Ningula - Sagala</td><td>6 hrs walk</td><td>3800 - 3700 m</td></tr>
                                <tr><td>Sagala - Talung</td><td>3 hrs walk</td><td>3700 - 3000 m</td></tr>
                              </tbody>
                            </table>
                          </div>
                          <br /><br />
                          {/* <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /><br /> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="day8"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          <h4>DAY 08: BID ADIEU BHUTAN WITH SWEET MEMORIES</h4>
                        </button>
                      </div>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>After enjoying a hearty breakfast, say goodbye to the captivating Kingdom of Bhutan, taking with you cherished memories from your unforgettable journey. As you head towards Paro Airport, recall yourself of the cultural immersion, stunning landscapes, and deep tranquillity you discovered during your <strong>Bhutan trip from Mumbai</strong>. Let these experiences be a treasure, inspiring you to embrace each moment and continue exploring the richness of our world and the beauty of life's journey.</p>
                          <br /><br />
                          <table className='img-center'>
                            <tbody>
                              <tr>
                                <td className="image-cell">
                                  <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="bhutan tour package from mumbai with return flight" /></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <table className="table-borderDodont">
                  <thead>
                    <tr>
                      <td><h4>DO'S : <strong className='tomato'>BHUTAN TRIP FROM MUMBAI</strong></h4></td>
                      <td><h4>DONT'S : <strong className='tomato'>BHUTAN TOUR FROM MUMBAI</strong></h4></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Listen to your tour guide and follow their instructions. </td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs of local people, monks, events, or objects without their permission.  </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Bring enough cash, as many places don’t take credit cards. </td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs inside dzongs, temple, monasteries, sacred places, and religious sites.  </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Be polite and respectful to older people.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not climb on to the top of any of the religious sites or sacred places. </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Only enter temples and monasteries if you have permission.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not touch holy objects inside the monastery or any sacred sites. </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Dress modestly; avoid wearing sleeveless tops and short skirts.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not point your finger or feet at anyone while talking.
                      </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Take off your shoes before going into a temple.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not touch the forehead of any person as it is considered a sign of disrespect.
                      </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> If photography isn’t allowed, keep your camera safe.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not wear revealing or skimpy clothes when entering any place of religious significance. </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Don’t wear hats or sunglasses inside religious sites.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not speak loudly inside the religious premises</td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Keep your valuables, like wallets and passports, in a safe place.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Use Dustbin when needed. Do not through rubbish inside temples or any scared sites. </td>
                    </tr>
                    <tr>
                      <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Always follow traffic rules when on the road.</td>
                      <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not say anything negative about the country, its Royal couple, or discuss political issues with locals.</td>
                    </tr>
                  </tbody>
                </table>
                <br /><br />
                <table className="image-table">
                  <tbody>
                    <tr>
                      <td className="image-cell">
                        <img
                          src={sightseen19ImageURL}
                          alt="Bhutan package tour from mumbai direct flight"
                        />
                        <img
                          src={sightseen2ImageURL}
                          alt="bhutan tour package booking from mumbai"
                        />
                        <img
                          src={sightseen6ImageURL}
                          alt="bhutan tour package cost from mumbai"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>IMPORTANT FACTS TO KNOW FOR <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                </div>
                <div className="card-body">
                  <li>Locals call their country "Druk Yul," which means "the Land of the Dragons." <br /><br /></li>
                  <li>Bhutan has never been conquered or ruled by outside forces. <br /><br /></li>
                  <li>Bhutan is the only carbon-negative country in the world. <br /><br /></li>
                  <li>Bhutan was isolated from the rest of the world until the 1970s. <br /><br /></li>
                  <li>You won’t find any traffic lights in Bhutan. <br /><br /></li>
                  <li>Bhutan is a constitutional monarchy. <br /><br /></li>
                  <li>Bhutan does not use GDP as a measure of economic growth. <br /><br /></li>
                  <li><b>GNH</b> (Gross National Happiness) is the measure of growth and development in Bhutan. <br /><br /></li>
                  <li>Bhutan is a plastic-free country. <br /><br /></li>
                  <li>There are fewer than 20 qualified pilots who can fly into Bhutan's only airport. <br /><br /></li>
                  <li>Smoking is not allowed in Bhutan and is considered a punishable offense. <br /><br /></li>
                  <li>Bhutan has some of the world’s highest unclimbed mountain peaks, including Gangkhar Puensum at 7,570m (24,981ft). <br /><br /></li>
                  <li>The national animal of Bhutan is the Takin, a goat-antelope. <br /><br /></li>
                  <li>Bhutanese food is very spicy, and you will find chilies in almost every dish. <br /><br /></li>
                  <li>Education and healthcare are free for all citizens. <br /><br /></li>
                  <li>Archery is the national sport of Bhutan. <br /><br /></li>
                  <li>Bhutanese people love their king, and you’ll find his photo in every home. <br /><br /></li>
                  <li>Bhutan has a very low crime rate. <br /><br /></li>
                  <li>Wearing the national dress (Gho for men and Kira for women) is mandatory in schools, government offices, and during festivals. <br /><br /></li>
                  <li>Cleanliness is very important in Bhutan, and the environment is highly prioritized. <br /><br /></li>
                  <li>Bhutanese people often chew betel leaf and areca nut. <br /><br /></li>
                  <li>Ema Datshi is the national dish of Bhutan, made with spicy chilies and local cheese. <br /><br /></li>

                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img
                            src={sightseen13ImageURL}
                            alt="bhutan tour package itinerary from mumbai"
                          />
                          <img
                            src={sightseen16ImageURL}
                            alt="bhutan tour package guide from mumbai"
                          />
                          <img
                            src={sightseen18ImageURL}
                            alt="bhutan tour guide from mumbai at best price"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> ACTIVITIES TO ENJOY DURING <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>ACTIVITIES TO ENJOY DURING <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {
                        <Activityfecilities />
                      }
                    </div>
                  </div>
                  <br /><br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img
                            src={sightseen14ImageURL}
                            alt="bhutan tour operator from mumbai"
                          />
                          <img
                            src={sightseen17ImageURL}
                            alt="bhutan tour agency in mumbai"
                          />
                          <img
                            src={sightseen11ImageURL}
                            alt="Bhutan Package Tour from Mumbai"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>DISTANCE & TRAVEL DURATION : <strong>BHUTAN TOUR PACKAGES FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>DISTANCE & TRAVEL DURATION : <strong>BHUTAN TOUR PACKAGES FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>PLACES FROM</th>
                          <th>PLACES TO</th>
                          <th>ROAD DISTANCES</th>
                          <th>TRAVEL DURATION</th>
                        </tr>
                      </thead>
                      <tbody><tr><td>Paro Airport</td><td>Thimphu</td><td>47 km</td><td>1 hrs 15 min</td></tr>
                        <tr><td>Thimphu</td><td>Punakha</td><td>73 Km</td><td>2 hrs 5 min</td></tr>
                        <tr><td>Punakha</td><td>Paro</td><td>115 km</td><td>3 hrs 10 min</td></tr>
                        <tr><td>Paro</td><td>Haa Valley</td><td>66 km</td><td>2 hrs 10 min</td></tr>
                        <tr><td>Bagdogra</td><td>Phuentsholing</td><td>180 km</td><td>4 hrs</td></tr>
                        <tr><td>Phuentsholing</td><td>Thimphu</td><td>163 km</td><td>4 hrs 30 min</td></tr>
                        <tr><td>Punakha</td><td>Phobjikha valley</td><td>79 km</td><td>2 hrs 30 min</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="bhutan package tour from mumbai" />
                          <img src={sightseen2ImageURL} alt="bhutan tour package from mumbai" />
                          <img src={sightseen3ImageURL} alt="bhutan package tour from mumbai with flight" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>TCB APPROVED HOTELS WE OFFER FOR <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>TCB APPROVED HOTELS WE OFFER <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <div className="overflow-table">
                    <table className="table table-bordered">
                      <tr className="table-primary text-center">
                        {/* <!-- Add the "text-center" className to center content --> */}
                        <th colspan="6">HOTEL CATEGORY [3 STAR TCB APPROVED]</th>
                      </tr>
                      <tr className="table-danger">
                        <th className="text-center">Destination</th>
                        <th className="text-center">Hotel Names</th>
                        <th className="text-center">Hotel Type</th>
                        <th className="text-center">No of Nights</th>
                        <th className="text-center">Meal Plan</th>
                      </tr>
                      <tr>
                        <td>Thimpu</td>
                        <td>Hotel Ugyan / Hotel Peaceful / Hotel Nordenma / Hotel Riverside Bhutan</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Punakha</td>
                        <td>Hotel Khuru Khuru / Kingaling Resort & Spa / White Dragon / Punakha Residency</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Paro</td>
                        <td>Lha Yhul Hotel / Tsheringma Resort / Hotel Gawaling / Tshongdu Boutique</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                    </table>
                    <br /><br />
                    <table className="table table-bordered">
                      <tr className="table-primary text-center">
                        {/* <!-- Add the "text-center" className to center content --> */}
                        <th colspan="6">HOTEL CATEGORY [4 STAR TCB APPROVED]</th>
                      </tr>
                      <tr className="table-danger">
                        <th className="text-center">Destination</th>
                        <th className="text-center">Hotel Names</th>
                        <th className="text-center">Hotel Type</th>
                        <th className="text-center">No of Nights</th>
                        <th className="text-center">Meal Plan</th>
                      </tr>
                      <tr>
                        <td>Thimpu</td>
                        <td>Asura / Pelyang Boutique / Tashi Yid-Wong Grand</td>
                        <td>4 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Punakha</td>
                        <td>Zhingkham / Rivervalley / Wangdue Ecolodge</td>
                        <td>4 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Paro</td>
                        <td>Tashi Namgay / Khangkhu Resort / Thim Dorji / Paro Grand</td>
                        <td>4 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Branded Bhutan Tour <span>- The SIX SENSE</span>
                      </h2>
                      <h4>
                        <i>Next Level Luxury Sense</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <img src={sixSenseBhutanTourPackagesImageURL} alt="bhutan package tour from mumbai with hotel aman kora" />
                </div>
                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-danger">
                        <th>Packages</th>
                        <th>Duration</th>
                        <th>Hotel Brand</th>
                        <th>Price (Per Head)</th>
                        <th>Book Now</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Luxury Trip</td>
                        <td>5N/6D</td>
                        <td>
                          The SIX SENSE (Ultra Luxury)
                          <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                        </td>
                        <td>Rs 5,36,789/- </td>
                        <td><a href="tel:+91-9883359713" className="btn btn-success">
                          GET QUOTE
                        </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card mx-0 my-5">
                  <div
                    className="card-header custHeaer"
                  >
                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b> Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand</b></h3>

                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.<br /><br />
                    </p>
                    <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience with a <a href="https://adorablevacation.com/bhutan-luxury-package-tour-with-six-senses-hotel/" target="_blank"><strong>Bhutan Package Tour with hotel Six Senses</strong></a>. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>

                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</b></h3>
                    <br />
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Branded Bhutan Tour <span>- The AMAN KORA</span>
                      </h2>
                      <h4>
                        <i>Stay with Trust</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <img src={amanKoraBhutanPackagesImageURL} alt="bhutan package tour from mumbai with hotel aman kora" />
                </div>
                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-danger">
                        <th>Packages</th>
                        <th>Duration</th>
                        <th>Hotel Brand</th>
                        <th>Price (Per Head)</th>
                        <th>Book Now</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Premium Trip</td>
                        <td>5N/6D</td>
                        <td>
                          The AMAN KORA (Premium)
                          <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                        </td>
                        <td>Rs 4,70,389/- </td>
                        <td><a href="tel:+91-9883359713" className="btn btn-success">
                          GET QUOTE
                        </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card mx-0 my-5">
                  <div
                    className="card-header custHeaer"
                  >
                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</b></h3>

                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.<br /><br />
                    </p>
                    <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>

                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</b></h3>
                    <br />
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Bhutan Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Is Bhutan visa free for Indian?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Indian citizens do not require a visa to enter
                          Bhutan.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How can I go to Bhutan from India?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          You can take a flight to Bagdogra (IXB). After that
                          enter to Bhutan by road via PHUENTSHOLING. This will
                          be an economical option. In terms of luxury option,
                          you can avail Direct Non Stop flight to PARO (PBH)
                          airport. Operated from three major cities DELHI,
                          KOLKATA & GUWAHATI.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Do Bhutanese Speak English?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Dzongkha is the national language of the Bhutan, which
                          is predominantly spoken in the western region of
                          Bhutan. However, there are 25 dialects spoken in
                          Bhutan. Bhutanese people speak English as it is the
                          medium of instruction in the schools. Hence it is easy
                          to talk to Bhutanese people if you are fluent in
                          English. The official documents, road signs, and even
                          the major national newspapers are published in English
                          making it easier for tourists to understand.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is Alcohol cheaper in Bhutan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, alcohol is cheaper in Bhutan, and the locally
                          made alcohol is good in taste, and these beverages act
                          as a great souvenir for the boozers. Do try out Ara,
                          the traditional hot alcoholic beverage consumed by the
                          majority of the population and is made of rice,
                          barley, wheat, or millet, and herbs which is either
                          fermented or diluted.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Can I use Indian money in Bhutan Tour from Mumbai?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, you can carry Indian Note to Bhutan. Except Rs
                          2000/- Note, which is not a legal tender in Bhutan?
                          However, many merchants in Bhutan now days accept Rs
                          2000/- Note.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          How are the Bhutan Mobile / Internet Connectivity?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          ndian Mobile Operators provide the Global Roaming
                          Connectivity in Bhutan, However, it is expensive.
                          Please check with your operator about Global Roaming
                          Activation / Incoming and Out Going Charges. Tourists
                          can easily get the Bhutan Tourists SIM card (Tashi
                          Cell and BMobile ) at Rs 200/- with data option at Rs
                          99/- for 400 MB data.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Can I get wine in Bhutan including imported Brands?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          In Paro Duty free shop, you can get various brands of
                          imported foreign Whisky, Wine, Vodka, Beer etc and
                          price is relatively cheaper compared to any Indian
                          Duty Free shop. In Thimphu, you can get the imported
                          wine brand, however in Paro and Punakha, you may get
                          the imported brands only in your hotel, not in any off
                          shop. However, in Bhutan, availability of local brand
                          wines is abundant and they are really worthy to buy.
                          While you are in Bhutan Package Tour, you must buy
                          Local Peach Wine and am confident you will love it.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MumbaiToBhutanPackage;
