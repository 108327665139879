import React, { Component, Fragment } from "react";
import FooterDesktop from "../components/common/FooterDesktop";
import MainHeader from "../components/common/MainHeader";
import Testimonial from "../components/home/Testimonial";
import Banner from "../components/home/Banner";
import WhatWeOffer from "../components/home/WhatWeOffer";
import DomesticExplorer from "../components/home/DomesticExplorer";
import InternationalExplorer from "../components/home/InternationalExplorer";
import SocialMediaCounter from "../components/home/SocialMediaCounter";
import HotPackageTour from "../components/home/HotPackageTour";
import Newsletter from "../components/home/Newsletter";
import AboutAdorablevacation from "../components/home/AboutAdorablevacation";
import OthersPackages from "../components/home/OthersPackages";
import Album from "../components/home/Album"
import { Helmet } from "react-helmet";

class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Home</title>
        </Helmet>

        <MainHeader />
        <Banner />
        <AboutAdorablevacation />
        <WhatWeOffer />
        {/* <DomesticExplorer /> */}
        <OthersPackages />
        <InternationalExplorer />
        <Testimonial />
        <Album />
        <HotPackageTour />
        <Newsletter />

        <FooterDesktop />

      </Fragment>
    );
  }
}

export default HomePage;
