import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Bhutan/InclusionExclusion";
import InclusionExclusion from "../Bhutan/InclusionExclusion";


class DelhiToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick1 = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/Bhutan-Tour-Package-from-Delhi-5N-6D.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/Bhutan-Package-Tour-from-Delhi.webp";
    const bhutanTravelAgentImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/Bhutan-Travel-Agents.webp";
    const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/Six-Sense-Bhutan-Tour-Packages.webp";
    const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/Aman-Kora-Bhutan-Packages.webp";


    const hotPackagesDataList = this.props.HotPackagesData;
    const hotPackagesView = hotPackagesDataList.length > 0 ?
      (
        hotPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/" + dataItem.ImageName;
          const packageURL = dataItem.Url;
          return (<div className="col-md-4" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h5 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight} /-</span></h5>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">{dataItem.Description}</p>
                <p>
                  <span>
                    <b className="tomato">{dataItem.NoOfTours} </b>Tours
                  </span>{" "}
                  |
                  <span>
                    <b className="tomato">{dataItem.NoOfDeparture} </b>
                    Departures
                  </span> |
                  <span><b className="sdf-blue"> {dataItem.SDFInclude}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">{dataItem.MutedText}</small>
                <Link to={packageURL} target="_blank" className="btn btn-Warning btn-sm float-end">Book Now</Link>
              </div>
            </div>
          </div>)
        })
      ) :
      (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img src={hotelImageURL} className="rounded mx-auto d-block" alt={hotelItem.AllternateText} /><br />
          <span><b>{hotelItem.HotelName}</b> | <span className="tomato">{hotelItem.HotelStar}</span></span><br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span> &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      )
    })

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map((sliderItem, i) => {
      const sliderImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/" + sliderItem.ImageName;
      return (
        <div className="theme-cards-wrapper" key={i}>
          <div className="theme-card">
            <div className="image-wrapper">
              <img src={sliderImageURL} alt={sliderItem.AllternateText} />
            </div>
            <div className="card-body">
              <h5 className="card-title">{sliderItem.Title}</h5>
              <p className="card-text">{sliderItem.Description}</p>
              <Link to={"#"} className="btn btn-primary">Book Now</Link>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      )
    });

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/" + sliderItem.ImageName;

        return (

          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">
                  <span>{sliderItem.Description}</span>
                </p>
                <p>
                  <span>
                    <b className="tomato">{sliderItem.NoOfTours} </b>Tours
                  </span>{" "}
                  |
                  <span>
                    <b className="tomato">{sliderItem.NoOfDeparture} </b>
                    Departures
                  </span>
                </p>
                <div className="card-footer">
                  <small className="text-muted">
                    <strong className="tomato">EX-IXB</strong>
                  </small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">  Book Now</Link>
                </div>
              </div>
            </div>
          </div>

        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.BhutanTourPackageFromDelhiImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>

        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><b>Delhi to Bhutan Tour Package</b> - 5N 6D Plan</h1>
                    <h3 className="content-mobile" style={{ fontSize: "6vw" }}><center><b>Delhi to Bhutan Tour Package</b> - 5N 6D Plan</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                    </div>
                    <hr />

                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        3 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      A Bhutan Trip to your favourite chillout spot or a new
                      adventure, you've come to the right place!
                    </p>
                    <hr />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.4vw" }}> For Booking <b>BHUTAN PACKAGES FROM DELHI</b> <a href='tel:9674738480' class="tomato">☎ CALL Ms. Sonali Halder +91- 96747-38480</a></h2>
                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <b>BHUTAN PACKAGES FROM DELHI</b> <a href='tel:9674738480' class="tomato">☎ CALL Ms. Sonali Halder +91- 96747-38480</a></h2>
                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                         <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>Bhutan Tour Operator</b> (Own office at
                          PARO)</h3>
                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>Bhutan Tour Operator</b> (Own office at
                          PARO)</h3>
                      </div>
                      <div className="card-body">
                        <p>Embark on an unforgettable journey from <b>Delhi</b> to the mystical kingdom of <b>Bhutan Tour packages from Delhi</b>, where ancient traditions blend harmoniously with stunning natural landscapes. Our <b>Delhi to Bhutan tour package by flight</b> offers an immersive experience, allowing you to explore the highlights of this enchanting country in a seamless and hassle-free manner. <b>Best Tour Travel agent for Bhutan</b> Discovering the historic charms of Paro, home to the iconic Taktsang Monastery (Tiger's Nest) perched on a cliffside. Exploring the vibrant capital city of <b>Thimphu</b>, where you'll encounter a blend of modernity and tradition, from bustling markets to ancient monasteries with <b>Cheap Bhutan Tour.</b> Marveling at the majestic <b>Punakha Dzong, Dochula Pass</b> a masterpiece of Bhutanese architecture nestled amidst picturesque landscapes. <b>Delhi to Bhutan Trip</b> soaking in the serene ambiance of Bhutan's countryside, dotted with traditional villages, terraced fields, and snow-capped peaks. Experiencing the warmth of Bhutanese hospitality by <b>Book Bhutan cheap packages</b> through interactions with locals and savoring authentic Bhutanese cuisine.</p>
                        <p>We <b>India’s best tour planner for Bhutan</b> meticulously crafted <b>Luxury Bhutan tour</b> ensures comfortable accommodation, expert guidance, and seamless transportation throughout your journey, allowing you to focus on soaking in the beauty and tranquility of <b>Bhutan Six Sense Tour package.</b> Whether you're drawn to its spiritual heritage, breathtaking scenery, or vibrant culture, our <b>Delhi to Bhutan tour Plan</b> promises an enriching and memorable experience from start to finish with <b>Best Bhutan travel planer from Delhi.</b> Absolutely! Our <b>Delhi to Bhutan tour packages</b> offer an ideal blend of convenience, affordability, and enriching experiences for travelers seeking to explore the mystical kingdom of Bhutan. With carefully planned itineraries and seamless logistics, reaching <b>Bhutan from Delhi</b> is hassle-free, allowing you to focus on immersing yourself in the country's unique charms.</p>
                        <p>Apart from <strong>Bhutan package from Delhi</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bangalore" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, <a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b className="tomato">Bhutan B2B Package Tour </b></a> etc at Best Price.</p>

                        <h3 style={{ fontSize: "1.5vw" }}>
                          The Best Way to Reach Bhutan from Delhi-
                        </h3>

                        <p className="tomato">
                          <b>LUXURY OPTION</b></p>
                        <p>
                          <b>Direct Non-Stop Charter Flight from Delhi to Paro: </b>
                          An exclusive non-stop charter flight from Delhi to Paro
                          ensures a seamless and comfortable journey. Facility is
                          only available during Summer month only.{" "}
                          <i>(APRIL, MAY & JUNE)</i>
                        </p>
                        <br />
                        <b>
                          <p className="tomato">ECONOMICAL OPTION</p> Direct
                          Flight to Paro via Delhi (DEL), Kolkata (CCU), or
                          Guwahati (GAU): </b>

                        If you prefer traditional flights, you can take domestic
                        flights from Mumbai to Delhi, Kolkata, or Guwahati, and
                        then board a direct flight to Paro.
                        <br />
                        <br />
                        <p className="tomato">
                          {" "}
                          <b>CHEAPEST OPTION</b></p><p><b>Reach Bhutan by Road from
                            Bagdogra Airport: </b> For a different adventure, fly to
                          Bagdogra Airport and then proceed to the picturesque
                          town of Phuentsholing in Bhutan. Our office in Bhutan
                          will ensure a smooth transition as you cross the
                          border.
                        </p>

                        <a href="tel://9883359713" className="btn btn-Warning">
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Our Bhutan <span>Tour Packages</span>
                      </h2>
                      <h4>Trending Bhutan Trip from Delhi</h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                  {hotPackagesView}
                </div>
              </div>
              <br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                  style={{ fontSize: "1.2vw" }}
                >
                  Why Choose Adorable Vacation LLP ?
                </div>
                <div className="card-body">
                  <p>
                    When it comes to Bhutan tour packages from Delhi, Adorable
                    Vacation LLP stands out for numerous reasons:
                  </p>
                  <p>
                    <b>Years of Expertise: </b>
                    With years of experience in the travel industry, we have
                    honed our skills in creating unforgettable journeys for our
                    clients. Our expertise ensures that your Bhutan tour is
                    seamless and truly memorable.
                    <br />
                    <br />
                    <b>Bhutan Experts: </b>
                    Having our own office in Bhutan, located at Lamgong, Chukha,
                    Paro, sets us apart as true Bhutan experts. Our in-depth
                    understanding of the country, its culture, and its hidden
                    gems ensures that you experience the authentic essence of
                    Bhutan.
                    <br /> <br />
                    <b>Quality Accommodation: </b>
                    We offer stays in some of the best hotels in Bhutan,
                    providing you with comfort and luxury throughout your
                    journey.
                    <br /> <br />
                    <b>Ethical and Responsible Tourism: </b>
                    At Adorable Vacation LLP, we are committed to promoting
                    sustainable tourism practices. By traveling with us, you can
                    rest assured that your journey contributes positively to
                    Bhutan’s environment and communities. Choose Adorable
                    Vacation LLP for an extraordinary Bhutan tour from Delhi
                    and immerse yourself in the Land of Happiness. Our TCB
                    License No. 1051529 and registration with the Tourism
                    Council of Bhutam assure you of our credibility and
                    dedication to offering you the best travel experience. Let
                    us be your trusted travel companion as you embark on this
                    magical journey to the Land of Happiness.
                    <br /> <br />
                    <b>Local Connections: </b>
                    Our local team in Bhutan is dedicated to providing you with
                    the best local guides, immersive experiences, and valuable
                    insights into the country’s culture and traditions.
                    Moreover, with Mr. Ganesh Pradhan at the helm, our local
                    connections extend far beyond the usual tourist trails. Our
                    Bhutan Tour Manager takes pride in sharing his profound
                    insights and ensuring that you have a deeper understanding
                    of Bhutan’s way of life, values, and customs.
                    <br /> <br />
                    <b>Exclusive Charter Flights: </b>
                    Our non-stop charter flights from Mumbai to Paro offer you a
                    hassle-free and premium travel experience.
                    <br /> <br />
                    <b>Personalized Itineraries: </b>
                    At Adorable Vacation LLP, we believe in tailoring your
                    journey to suit your preferences and interests. Our
                    personalised itineraries guarantee that every moment of your
                    trip is unique and tailored just for you..
                  </p>
                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Day Wise Bhutan Tour <span>Plan from India</span>
                      </h2>
                      <h4>5 Nights 6 Days Bhutan Plan from Delhi</h4>
                    </div>
                  </div>
                </div>

                <div className="container-fluid py-3">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>DAY WISE BHUTAN TOUR ITINERARY</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              Welcome to Paro And Transfer To Thimphu [1 Hr 30 Min (65 Km) Via Paro - Thimphu Hwy]
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>Full Day Thimphu Sightseeing</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>Thimphu To Punakha [2 Hr 15 Min (75 Km) Via Thimphu-Punakha Hwy] </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>Punakha To Paro Transfer [2 Hr 15 Min (125 Km) Via Thimphu - Punakha Hwy] </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>Paro Sightseeing – Tiger Nest Monastery [6-8 Hrs] </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>Paro To Airport Drop </span>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
                {/* <!--end Short description--> */}

                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 01: Welcome to Paro And Transfer To Thimphu [1 Hr 30 Min (65 Km) Via Paro - Thimphu Hwy]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          Welcome to Bhutan, the Land of Thunder Dragon. Bhutan is like a painter's dream, a colourful country surrounded by the Himalayas, a small dreamy paradise with vast green valleys, beautiful landscapes, rugged terrain, crystalline rivers, and happy people. Once the aircraft enters the Paro Valley, look down to see the first glimpse of the Paro Dzong on the hillside overlooking Paro Chu (river) with Ta Dzong. Our representative will welcome you in a Bhutanese style with TashiKhada (white scarf) and guide you at the airport, which marks the beginning of your Bhutan Tour Package from Mumbai with a transfer to Thimphu (2334 m).
                          <br />
                          <br />
                          <p>
                            <b>Overnight in Thimphu.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingTwo"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 02: Full Day Thimphu Sightseeing
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>As the sun spreads its warm rays across the beautiful Thimphu valley, begin your day with a hot cup of Suja (Butter Tea) and move towards the breakfast table to enjoy their delicious spread before leaving for Thimphu local sightseeing.</p>
                          <p><b>The National Memorial Chorten</b> - Also known as Thimphu Chorten is a site for evening prayer. The chorten was built in the third King's memory, and it was continuously circumambulated by people, murmuring mantras and spinning their prayer wheels. The idea to construct this landmark was proposed by Bhutan's third King, His Majesty Jigme Dorji Wangchuk ("the father of modern Bhutan"), who wished to erect a monument to the World for peace and prosperity. Completed in 1974 after Majesty's untimely death, it served as a memorial to the late King and a monument of peace. </p>
                          <p><b>Shakyamuni Buddha -</b> On the top of KuenselPhodrang hill sits the statue of Great Buddha Dordenma. The 51.5-meter-long gilded bronze statue would surely attract you, and the uninterrupted view of the valley would leave you spellbound. This site is also known as Buddha Viewpoint. </p>
                          <p><b>Changangkha Lhakhang -</b> At Thimphu, you will come across several monasteries and temples, but Changangkha Lhakhang is the most important religious structure of all. The fabric is dedicated to Avalokiteshvara, a sign of compassion dating back to the 15th century, the oldest in the region, and from here, enjoy the grand view of Thimphu valley. </p>
                          <p><b>Takin Zoo –</b> In here, you will find the National animal of Bhutan, "Takin". Takin is one of the most endangered species of the World, and it is available only in Bhutan and preserved in open habitat.</p>
                          <p><b>TrashiChhoe Dzong -</b> or the Fortress of the Glorious Region is another 'must visit' site on a Bhutan Package Tour. Situated on the west bank of Wang Chhu river, the TrashiChhoe Dzong is the formal coronation site of the fifth King that hosts the annual Tsechu festival. This Dzong was built in the year 1641 by the political and religious unifier of Bhutan, ShabdrungNgawang Namgyal, later it was reconstructed in the 1960s in a traditional Bhutanese manner, without the use of nails or architectural plans. </p>
                          <p>
                            <b>Overnight stay at Thimphu.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingThree"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 03: Thimphu To Punakha [2 Hr 15 Min (75 Km) Via Thimphu-Punakha Hwy]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>As your Hot Ngaja (a Sweet Bhutanese milk tea) arrives sip into it while enjoying the beautiful valley from the room. Post breakfast head towards Punakha, the former capital of Bhutan</p>
                          <p>
                            Punakha is much backward from the chaotic city life at an elevation of 1200 m, which makes this place quite warm in winter and hot during summer. The valley is famous for Red and white rice farming along the Pho and Mo Chu Rivers stretch. En-route visit.
                          </p>
                          <p>
                            <b>Punakha Dzong -</b> Also known as "the palace of great happiness", Punakha Dzong is located at the confluence of the Pho Chhu (father) and Mo Chhu (mother) rivers in the
                          </p>
                          <p><b>Punakha–Wangdue valley</b> This Dzong is the most picturesque of all Bhutan ancient fortresses and it joins the mainland by an arched wooden bridge that contains many precious relics from the days when successive kings reigned the Kingdom from this Punakha valley. </p>
                          <p><b>Chimi Lhakhang -</b> Stationed at the top of a hillock, the Chimi Lhakhang is a site dedicated to Lama Drukpa Kuenley. Known as the 'Divine Madman,' Lama Drukpa Kuenley is said to use songs, humor, and outrageous behavior to preach his teachings. Chimi Lhakhang temple is believed to be the temple of fertility, and the Bhutanese hold a strong belief that childless couples are blessed by Lama Drukpa Kuenley if they visit the temple and pray for a child. The road to the temple follows a picturesque rural trail through the paddy field that takes visitors to the temple from the road, and after crossing the paddy field, you will find a tiny stream from where Chimi Lhakhang is just a short climb away. </p>
                          <p><b>Suspension Bridge - </b>This hidden treasure in the Punakha Valley connects the rest of the valley to the old administrative center of Punakha Dzongkhag. The whole deck is covered with prayer flags and provides a perfect spot for bird-watching and photography. </p>
                          <p>
                            <b>Overnight stay in Punakha.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingFour"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 04: Punakha To Paro Transfer [2 Hr 15 Min (125 Km) Via Thimphu - Punakha Hwy]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>From the beautiful valley, the next destination is the picturesque Paro town, located at an elevation of 2200 m. Paro valley is the most extensive valley in entire Bhutan and is blessed with mesmerizing nature, fertile rice fields, deep valleys, and a crystalline river. The beautiful view of the mountains, clear sky, and dense coniferous forest with chirping birds can make you fall in love with its first glimpse.</p>
                          <p>En route enjoy Paro sightseeing – </p>
                          <p><b>National Museum of Bhutan -</b> In 1968, The National Museum of Bhutan was built, which stands inside the ancient Ta-dzong building that showcases Bhutan culture and is stationed right above Rinpung Dzong in Paro. This museum is a house of beautiful specimens from Bhutanese art and culture, with the main highlights being the paintings and bronze statues, which are placed in extensive galleries, from where you can get to know a lot about Bhutan art and culture.</p>
                          <p><b>Rinpung Dzong -</b> Built by ShabdrungNgawang Namgyal in 1646, the first spiritual and temporal ruler of Bhutan, the Dzong houses the monastic body of Paro, the office of the Dzongda (district administrative head) and Thrimpon (judge) of Paro district. The approach to the Dzong is through a traditional covered bridge called NemiZam. </p>
                          <p>The Paro town is full of Dzongs (monasteries), where you can join the monks for prayers as they wrap themselves in red robes with priceless smiles or walk down to the bed of Paro Chu and dip your feet in the cold crystalline water. </p>
                          <p>
                            <b>Overnight Stay in Paro.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingFive"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 05:  Paro Sightseeing – Tiger Nest Monastery [6-8 Hrs]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>As the sun's rays touch the beautiful Paro valley, wake up in the middle of Mother Nature and relish the beautiful view of the river and mountains in a single frame. Enjoy the gala spread but make sure to add some protein to it, as climbing to the top of the Taktsang Monastery and back will require a good boost. </p>
                          <p><b>Taktsang Monastery -</b> After covering 1200 meters on a fierce cliff, you will reach this sacred site known as the Tiger Nest. Once you climb to the top, take a deep breath of the fresh air and soother the eyes and soul with the stunning view of the scenic surroundings and the valley lying underneath—the Taktsang Monastery, established by Sain Guru Rinpoche in 747 CE. Construction of the complex was completed in 1694. After 3 hours of some adventurous trek, discover the Tiger Nest monastery, and if you wish, opt for a horse ride from the parking lot to reach the top. While trekking, witness the glorious view of Paro town, with its lush green valleys, sparkling river, clear blue sky, and moving clouds, in-short, enjoy nature at its best. </p>
                          <p><b>Kyichu Lhakhang -</b> Kyichu Lhakhang is an important Himalayan Buddhist temple situated in Lango Gewog of Paro Dzongkhag in Bhutan. Kyichu Lhakhang, Paro Valley.</p>
                          <p>
                            <b>Overnight Stay in Paro.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingSix"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 06: Paro To Airport Drop
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                          Post breakfast bid farewell to the Last Buddhist Kingdom and start for Paro Airport with long-lasting sweet Bhutan Holiday memories, while chanting one mantra, "Life is short, and the World is wide" with <b>Adorable Vacation LLP.</b> 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid navtabCustom">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-price" aria-selected={customActiveTab === 'tab1'}>Price</button>
                  <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-hotels" aria-selected={customActiveTab === 'tab2'}>Hotels</button>
                  <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-travellers-info" aria-selected={customActiveTab === 'tab3'}>Travellers Info</button>
                  <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-flight" aria-selected={customActiveTab === 'tab4'}>Flight</button>
                  </div>
                <div className="tab-content" id="nav-tabContent">
                  <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                    <div id="no-more-tables">
                      <table class="col-md-12 table-bordered table-striped table-condensed cf">
                        <thead class="cf">
                          <tr className="table-primary text-center">
                            <th colSpan="5">Bhutan Tour Package Price from Delhi</th>
                          </tr>
                          <tr className="table-danger">
                            <th>Itinerary</th>
                            <th>02 Pax (INR)</th>
                            <th>04 Pax (INR)</th>
                            <th>06 Pax (INR)</th>
                            <th>08 Pax (INR)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>05 nights – 06 days</td>
                            <td>42,600 PP</td>
                            <td>32,700 PP</td>
                            <td>29,200 PP</td>
                            <td>27,500 PP</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotels-tab">
                    <h5 className="center font-weight">TCB Approved 3-Star Hotels</h5>
                    <div className="row row-cols-1 row-cols-md-4 g-4">

                      {hotelDataView}

                    </div>
                  </div>
                  <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                    <h5><b>Bhutan Visa/ Permit</b></h5>
                    <span className="green-dot px-3">●</span>While booking a package tour to Bhutan you need a permit/ visa
                    to enter Bhutan.
                    <br /><br />
                    <span className="green-dot px-3">●</span>For Indians – 4 copies passport size recent colour
                    photographs, Photo ID proof (Passport/ Voter ID) & hotel confirmation vouchers. For minor, its compulsory to carry
                    original birth certificate along with school photo ID or passport.<br /><br />
                    <span className="green-dot px-3">●</span>PAN & AADHAR cards are strictly not valid.<br /><br />
                    <span className="green-dot px-3">●</span>Foreigners (other than Indians) must have visa clearance
                    before entering Bhutan. Visa can be processed online by providing photo page of your passport.<br /><br />
                    <span className="green-dot px-3">●</span>Passport should have a minimum 6 months validity
                    left.<br /><br />
                    <span className="green-dot px-3">●</span>Permit can be issued during Govt.holidays & weekends(Online
                    & Offline both).<br /><br />
                    <span className="green-dot px-3">●</span>Thimphu / Paro / Phuentsholing immigration office is opened on
                    Saturday, Sunday & Government holidays.But online permit process will be closed, Guest has to
                    choose the offline process.<br /><br />
                    <h5><b>Bhutan Currency</b></h5>
                    <span className="green-dot px-3">●</span>Bhutan currency in called Ngultrum & carries equal value to
                    Indian Rupee.Indian Rupees are widely accepted except 10, 20, 50, 100, 500 rupee notes only.
                    <br /><br />
                    <span className="green-dot px-3">●</span>Bank & major hotels can exchange major currencies.But it’s
                    important to carry the bank exchange receipt to get it changed back to US Dollar.Foreigners cannot carry
                    Indian Rupee.<br /><br />
                    <span className="green-dot px-3">●</span>Credit & Debit cards(Visa & Mastercard) are accepted in
                    Bhutan.But the service is not widely available.So it is always advisable to carry sufficient cash.<br /><br />
                    <h5><b>Communication </b></h5>
                    While on your Bhutan Package Tour your Indian SIM card will not be working in the country unless you have
                    an international roaming facility.So it’s important to carry a local SIM card.It will help you to
                    communicate with the service providers.Our team will be in touch on a regular basis through your local
                    no.You can easily opt for a local SIM by showing your Bhutan permit / visa.
                    <br /><br />
                    Almost all the hotels have WiFi facility.So you can communicate with any Indian / International no through
                    WhatsApp or any other communication APP while you are on your Bhutan package tour.
                    <br /><br />
                  </div >
                  <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flight-tab">
                    <span>Bhutan Flight From - <b>Kolkata</b> <i>(CCU - PBH)</i></span>
                    <br />
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>Airlines</th>
                          <th>Flight Number</th>
                          <th>Operating Days</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Druk Air</td>
                          <td>KB 211, KB 210</td>
                          <td>Sat, Mon, Wed, Fri</td>
                          <td>9:30 - 11:30</td>
                        </tr>
                        <tr>
                          <td>Bhutan Airlines</td>
                          <td>B3 701, B3 700</td>
                          <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                          <td>8:15 - 9:55</td>
                        </tr>
                      </tbody>
                    </table>
                    <span>Bhutan Flight From - <b>Delhi</b> <i>(DEL - PBH)</i></span>
                    <br />
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>Airlines</th>
                          <th>Flight Number</th>
                          <th>Operating Days</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Druk Air</td>
                          <td>KB 201, KB 200</td>
                          <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                          <td>12:30 - 15:20</td>
                        </tr>
                        <tr>
                          <td>Bhutan Airlines</td>
                          <td>B3 774, B3 773</td>
                          <td>Mon, Wed, Sat, Sun</td>
                          <td>10:55 - 14:35</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <div className="row py-5">
                <img src={bhutanTravelAgentImageURL} alt="..." />
              </div>

              <div className="container-fluid">
                <div className="row placetoVisit">
                  <h2>7 BEST PLACES TO VISIT AT BHUTAN</h2>
                  <ul>
                    <li>
                      <strong>Punakha Dzong - </strong> The Punakha Dzong is
                      located in a serene surroundings. You have to cross the
                      Punakha river on a swinging bridge to reach the fort which
                      houses the monastery and the royal living quarters. The
                      whole place is very majestic and decorative. It is quite a
                      large space and it takes almost an hour to do even a quick
                      tour tour of the place. The doors, windows and the
                      courtyard are all so ornate and colourful. The prayer area
                      is very colourful and the statues are large sized and
                      realistic.
                    </li>
                    <li>
                      {" "}
                      <strong>Paro Taktsang - </strong> If you google Bhutan,
                      it's impossible not to have come across an image of a
                      monastery perched precariously in the cliff side. Yes,
                      that's Paro Taktsang, also known as Tiger's Nest
                      monastery. If it is your first time there, you ought to
                      visit. According to legend, back in 8th century, Guru
                      Rinpoche (second Buddha) flew here on the back of a
                      tigress. Due to its location, the only way to reach up is
                      to hike or you could hire a horse for a major part of the
                      distance.
                    </li>
                    <li>
                      {" "}
                      <strong>Buddha Dordenma - </strong> Great Buddha Dordenma
                      is a gigantic Shakyamuni Buddha statue in the mountains of
                      Bhutan celebrating the 60th anniversary of fourth king
                      Jigme Singye Wangchuck. The statue houses over one hundred
                      thousand smaller Buddha statues, each of which, like the
                      Great Buddha Dordenma itself, are made of bronze and
                      gilded in gold. The Great Buddha Dordenma is sited amidst
                      the ruins of Kuensel Phodrang, the palace of Sherab
                      Wangchuck, the thirteenth Desi Druk, overlooking the
                      southern approach to Thimphu, the capital of Bhutan.
                    </li>
                    <li>
                      {" "}
                      <strong>Dochula (Pass) - </strong> Nestled in the eastern
                      edge of the Himalayas, the Kingdom of Bhutan boasts of
                      spectacular mountain views and some of the happiest people
                      in the world. And with this beautiful outlook of life came
                      the creation of Dochula Pass, a breath-taking mountain
                      pass, in commemoration of Bhutanese soldiers who passed
                      away in military operations for the country. Along the
                      Dochula Pass, there are 108 memorial chortens (also known
                      as stupas) called the 'Druk Wangyal Chortens' for each
                      soldier's life lost in war. The hills are also decorated
                      with colourful religious flags in five colours to
                      represent the natural elements: blue for the sky, red for
                      fire, green for water, white for clouds, and yellow for
                      earth. To the local Buddhist people, these flags are
                      symbols of veneration and the inscriptions of prayers on
                      each flag signals peace and prosperity for Bhutan.
                    </li>
                    <li>
                      {" "}
                      <strong>Tashichho Dzong - </strong> Tashichho Dzong is a
                      fortress located about 2 km away from the city of Thimphu
                      in Bhutan. It had been constructed in 1641 and underwent
                      several restorations since then. It currently serves both
                      as a monastery and the central secretariat of the present
                      government. Also known as the "fortress of the glorious
                      religion", it is found right on the banks of the Wangchhu
                      River. Originally built in 1216, the original structure of
                      the Tashichho Dzong was destroyed in a fire, with the
                      current dzong structure being newly built. A lovely rose
                      garden in front of the building welcomes the visitors to
                      the fortress. The manicured garden surrounding the
                      building is a beautiful sight too. The tall building is a
                      prominent landmark of Thimphu with almost all sightseeing
                      tours of the city beginning with a visit to this beautiful
                      Dzong resplendent with a golden roof.{" "}
                    </li>
                    <li>
                      <strong>Chele La Pass - </strong> Bhutan, being a country
                      with a mountainous landscape, is linked with a series of
                      passes. Located at approximately 13,000 feet between the
                      valley of Paro and Haa, Chele La Pass is the highest
                      motorable road pass in Bhutan. The pass is famous for the
                      stunning Himalayan views it offers, especially Mt.
                      Jhomolari which is Bhutan’s most sacred peak at 22,000
                      feet, Tsherimgang mountains, Jichu Drake as well as views
                      of both the valleys, Paro and Haa. Just a two-hour drive
                      from the valley floor in Paro, you will reach Chele La
                      Pass, covered in untouched forests, home to thriving flora
                      and fauna. The surrounding area of this pass has several
                      ancient trails, perfect for hikers.{" "}
                    </li>
                    <li>
                      {" "}
                      <strong>Pobjikha valley - </strong> Against the backdrop
                      of western slopes of the Black Mountains in Wangdue
                      Phodrang, lies the bowl-shaped Phobjikha valley that
                      offers breathtaking views of vast expanses of green
                      fields. It is home to the endangered black-necked crane
                      that migrates to this area during winters. Phobjikha
                      valley also called Gangtey, is one of the few glacial
                      valleys in the Kingdom of Bhutan. Bordering the Jigme
                      Singye Wangchuck National Park, the valley is located at
                      an altitude of 3000m above sea level, and hence
                      comparatively cooler. It boasts being the winter home to
                      black-necked cranes that fly from Tibet to escape the
                      harsh winter and also one of the most prominent
                      conservative sites of Bhutan. Relatively untouched by
                      outsiders, the use of electricity in the valley began only
                      a few years ago.{" "}
                    </li>
                  </ul>
                </div>
                <div className="container-fluid py-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="site-heading text-center">
                        <h2>
                          Branded Bhutan Tour <span>- The SIX SENSE</span>
                        </h2>
                        <h4>
                          <i>Next Level Luxury Sense</i>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="row py-3">
                    <img
                      src={sixSenseBhutanTourPackagesImageURL}
                      alt="..."
                    />
                  </div>
                </div>

                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-danger">
                        <th>Packages</th>
                        <th>Duration</th>
                        <th>Hotel Brand</th>
                        <th>Price (Per Head)</th>
                        <th>Book Now</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Luxury Trip</td>
                        <td>5N/6D</td>
                        <td>
                          The SIX SENSE (Ultra Luxury)
                          <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                        </td>
                        <td>Rs 5,36,789/- </td>
                        <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                          <i className="fab fa-whatsapp"></i> WhatsApp
                        </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card mx-0 my-5">
                  <div className="card-header custHeaer">
                    Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand
                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.
                    </p>
                    <h3 className="px-2">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>
                    <i>
                      <h3 style={{ fontSize: "1vw" }}>
                        Service available at Paro, Punakha, Thimphu, Bumthang
                        and Gangtey.
                      </h3>
                    </i>
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Branded Bhutan Tour <span>- The AMAN KORA</span>
                      </h2>
                      <h4>
                        <i>Stay with Trust</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <img
                    src={amanKoraBhutanPackagesImageURL}
                    alt="..."
                  />
                </div>

                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-danger">
                        <th>Packages</th>
                        <th>Duration</th>
                        <th>Hotel Brand</th>
                        <th>Price (Per Head)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Premium Trip</td>
                        <td>5N/6D</td>
                        <td>The AMAN KORA (Premium)<div className="reviews">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div></td>
                        <td>Rs 4,70,389/- </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div className="card mx-0 my-5">
                  <div
                    className="card-header custHeaer"
                    style={{ fontSize: "1.2vw" }}
                  >
                    AMAN KORA Bhutan Packages - A Super Premium Five Star Brand
                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.
                    </p>
                    <h3 className="px-2">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>
                    <i>
                      <h3 style={{ fontSize: "1vw" }}>
                        Service available at Paro, Punakha, Thimphu, Bumthang
                        and Gangtey.
                      </h3>
                    </i>
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Our Bhutan <span>Tour Packages</span>
                      </h2>
                      <h4>Trending Bhutan Trip from PARO Airport</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Slider {...settings}>
                          {trendingBhutanDataView}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Bhutan Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Is Bhutan visa free for Indian?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Indian citizens do not require a visa to enter
                          Bhutan.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How can I go to Bhutan from India?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          You can take a flight to Bagdogra (IXB). After that
                          enter to Bhutan by road via PHUENTSHOLING. This will
                          be an economical option. In terms of luxury option,
                          you can avail Direct Non Stop flight to PARO (PBH)
                          airport. Operated from three major cities DELHI,
                          KOLKATA & GUWAHATI.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Do Bhutanese Speak English?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Dzongkha is the national language of the Bhutan, which
                          is predominantly spoken in the western region of
                          Bhutan. However, there are 25 dialects spoken in
                          Bhutan. Bhutanese people speak English as it is the
                          medium of instruction in the schools. Hence it is easy
                          to talk to Bhutanese people if you are fluent in
                          English. The official documents, road signs, and even
                          the major national newspapers are published in English
                          making it easier for tourists to understand.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is Alcohol cheaper in Bhutan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, alcohol is cheaper in Bhutan, and the locally
                          made alcohol is good in taste, and these beverages act
                          as a great souvenir for the boozers. Do try out Ara,
                          the traditional hot alcoholic beverage consumed by the
                          majority of the population and is made of rice,
                          barley, wheat, or millet, and herbs which is either
                          fermented or diluted.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Can I use Indian money in Bhutan Tour from Delhi?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, you can carry Indian Note to Bhutan. Except Rs
                          2000/- Note, which is not a legal tender in Bhutan?
                          However, many merchants in Bhutan now days accept Rs
                          2000/- Note.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          How are the Bhutan Mobile / Internet Connectivity?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          ndian Mobile Operators provide the Global Roaming
                          Connectivity in Bhutan, However, it is expensive.
                          Please check with your operator about Global Roaming
                          Activation / Incoming and Out Going Charges. Tourists
                          can easily get the Bhutan Tourists SIM card (Tashi
                          Cell and BMobile ) at Rs 200/- with data option at Rs
                          99/- for 400 MB data.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Can I get wine in Bhutan including imported Brands?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          In Paro Duty free shop, you can get various brands of
                          imported foreign Whisky, Wine, Vodka, Beer etc and
                          price is relatively cheaper compared to any Indian
                          Duty Free shop. In Thimphu, you can get the imported
                          wine brand, however in Paro and Punakha, you may get
                          the imported brands only in your hotel, not in any off
                          shop. However, in Bhutan, availability of local brand
                          wines is abundant and they are really worthy to buy.
                          While you are in Bhutan Package Tour, you must buy
                          Local Peach Wine and am confident you will love it.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Theme Package</span>
                        </b>
                      </h3>
                      <h4>Similar Mountain Trip</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Slider {...settings}>

                          {themePackagesDataView}

                        </Slider>
                      </div>
                    </div>
                  </div>


                  <br />
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Climate Guide</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Yearly Temperature Update"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-primary">
                        <th scope="col">Destination</th>
                        <th scope="col"></th>
                        <th scope="col">Jan</th>
                        <th scope="col">Feb</th>
                        <th scope="col">Mar</th>
                        <th scope="col">Apr</th>
                        <th scope="col">May</th>
                        <th scope="col">Jun</th>
                        <th scope="col">Jul</th>
                        <th scope="col">Aug</th>
                        <th scope="col">Sept</th>
                        <th scope="col">Oct</th>
                        <th scope="col">Nov</th>
                        <th scope="col">Dec</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-danger">
                        <th scope="row" rowspan="3">Paro</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-1<br />11<br />26</td>
                        <td rowspan="3">1<br />11<br />23</td>
                        <td rowspan="3">4<br />14<br />25</td>
                        <td rowspan="3">7<br />17<br />25</td>
                        <td rowspan="3">10.5<br />20.4<br />22</td>
                        <td rowspan="3">13<br />21<br />14</td>
                        <td rowspan="3">15<br />22<br />14</td>
                        <td rowspan="3">14<br />22<br />16</td>
                        <td rowspan="3">13<br />21<br />20</td>
                        <td rowspan="3">8<br />19<br />27</td>
                        <td rowspan="3">3<br />16<br />27</td>
                        <td rowspan="3">-1<br />13<br />29</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>

                      {/* <!-- Next set of data for Paro --> */}
                      <tr>
                        <th scope="row" rowspan="3">Thimphu</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-2.6<br />13<br />27</td>
                        <td rowspan="3">0.6<br />14.4<br />23</td>
                        <td rowspan="3">4<br />13<br />25</td>
                        <td rowspan="3">7<br />16<br />25</td>
                        <td rowspan="3">9.5<br />20<br />22</td>
                        <td rowspan="3">12.5<br />21<br />21</td>
                        <td rowspan="3">14<br />22<br />14</td>
                        <td rowspan="3">14<br />21<br />17</td>
                        <td rowspan="3">12<br />21<br />20</td>
                        <td rowspan="3">7<br />19<br />27</td>
                        <td rowspan="3">2<br />15<br />28</td>
                        <td rowspan="3">-1.5<br />12<br />29</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr className="table-danger">
                        <th scope="row" rowspan="3">Jakar</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-3<br />9<br />29</td>
                        <td rowspan="3">-6<br />8<br />26</td>
                        <td rowspan="3">3<br />12<br />29</td>
                        <td rowspan="3">6<br />18.5<br />29</td>
                        <td rowspan="3">9.5<br />22.1<br />22</td>
                        <td rowspan="3">13<br />22<br />14</td>
                        <td rowspan="3">14<br />22<br />19</td>
                        <td rowspan="3">13<br />23<br />21</td>
                        <td rowspan="3">12<br />21<br />22</td>
                        <td rowspan="3">7<br />19<br />28</td>
                        <td rowspan="3">1<br />15<br />30</td>
                        <td rowspan="3">-2.5<br />12.5<br />30</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr>
                        <th scope="row" rowspan="3">Bumthang</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-3.7<br />11.1<br />29</td>
                        <td rowspan="3">-7<br />8<br />26</td>
                        <td rowspan="3">-5<br />4<br />29</td>
                        <td rowspan="3">-1<br />7<br />28</td>
                        <td rowspan="3">8.5<br />19.6<br />23</td>
                        <td rowspan="3">14<br />23<br />16</td>
                        <td rowspan="3">13<br />21<br />19</td>
                        <td rowspan="3">13<br />21<br />21</td>
                        <td rowspan="3">11<br />20<br />22</td>
                        <td rowspan="3">6<br />18<br />28</td>
                        <td rowspan="3">0<br />14<br />30</td>
                        <td rowspan="3">-3.5<br />11.5<br />30</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr className="table-danger">
                        <th scope="row" rowspan="3">Punakha</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">4.9<br />16.8<br />29</td>
                        <td rowspan="3">7<br />18.7<br />27</td>
                        <td rowspan="3">10.5<br />22<br />29</td>
                        <td rowspan="3">13.8<br />24.7<br />29</td>
                        <td rowspan="3">16.5<br />25.8<br />19</td>
                        <td rowspan="3">19<br />23<br />16</td>
                        <td rowspan="3">20<br />27<br />20</td>
                        <td rowspan="3">19<br />26<br />22</td>
                        <td rowspan="3">18<br />26<br />23</td>
                        <td rowspan="3">15<br />25<br />28</td>
                        <td rowspan="3">9.5<br />21<br />29</td>
                        <td rowspan="3">6<br />18<br />30</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="card">
                  <div
                    className="card-header custHeaer"
                    style={{ fontSize: "1.2vw" }}
                  >
                    Discover the Best Time to Book Bhutan Tour Package from
                    Delhi with Adorable Vacation LLP
                  </div>
                  <div className="card-body">
                    <span className="icon-img-color">
                      <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                      <span className="black">
                        <strong>Summer Season in Bhutan</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      The summer months are June, July, and August. It is the
                      best time to visit Bhutan as these months are the warmest
                      and sunniest, excellent for all the outdoor activities and
                      sightseeing.
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-umbrella" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Monsoon Season in Bhutan</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      Though, during the months of June to August, there might
                      be mild showers in the afternoon; but the regions of
                      Bhutan do not experience the monsoon season as such, for
                      the rain fall very rarely.
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Monsoon Season in Bhutan</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      Though, during the months of June to August, there might
                      be mild showers in the afternoon; but the regions of
                      Bhutan do not experience the monsoon season as such, for
                      the rain fall very rarely.
                    </span>
                    <br />
                    <br />
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
        <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-96747-38480">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9674738480</small></span> */}
                    </a>
                </div>
                <div class="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick1}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                            <small>+91 9147061467</small>
                        </span> */}
                    </a>

                </div>
      </>
    );
  }
}
export default DelhiToBhutanPackage;
